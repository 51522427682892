import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import CRCOModuleStore from "../../store/CRCOModuleStore";
import CRInformationSection from "../CRInformationSection";

import Button from "../../atom/Button";
import SearchBox from "../../molecule/SearchBox";
import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";
import LoadingBox from "../LoadingBox";
import LoadingArrowAnimation from "../../molecule/LoadingArrowAnimation";
import BoldText from "../../atom/BoldText";
import AttachmentSection from "../AttachmentSection";
import RouteInitSection from "../RouteInitSection";
import txt from "../../Text";

const CRCOAddSectionStyle = styled.div`
  display: flex;
  position: relative;
  .tempSave {
    height: 40px;

    position: absolute;
    top: -40px;
    left: calc(58% - 110px);
    display: flex;
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`;
const FormSectionStyle = styled.div`
  width: 58%;
  height: calc(100vh - 230px);
  overflow: scroll;
  padding-left: 18%;
  padding-right: 1.5%;
  padding-top: 20px;
  .gap {
    height: 45px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
`;
const SearchBoxSectionStyle = styled.div`
  width: 42%;
`;
function CRAddSection() {
  const { tokenAxios } = commonStore((state) => ({
    tokenAxios: state.tokenAxios,
  }));

  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const formRef = useRef();
  const {
    // crinfo
    CRId,
    setCRId,
    crReasonId,
    CRReasonText,
    crImportanceId,
    crSourceId,
    CRTitle,
    CRBody,
    CRSolution,
    CRAffectedItem,
    // attachment
    attachmentFileArray,
    attachmentTagArray,
    attachmentCommentArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    // route

    setrouteNumber,
    targetMember,
    settargetMember,
    members,
    setsearchBoxType,

    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // searchBox
    searchBoxType,
    setsearchBoxProperty,
    deletemember,
    // etc
    isRouteActivate,
    setisRouteActivate,
    setcurrentCategory,
    initCRModule,
  } = CRCOModuleStore();
  const [isFirstTempId, setisFirstTempId] = useState(false);

  const appendCRFormData = () => {
    const formData = new FormData();
    formData.append("crReasonId", crReasonId.id);
    formData.append("crReason", CRReasonText);
    formData.append("crImportanceId", crImportanceId);
    formData.append("crSourceId", crSourceId.id ? crSourceId.id : "");
    formData.append("name", CRTitle);
    formData.append("content", CRBody);
    formData.append("solution", CRSolution);
    formData.append("itemId", CRAffectedItem.id ? CRAffectedItem.id : "");
    // attachment
    attachmentFileArray.forEach((file) => {
      formData.append("attachments", file);
    });

    formData.append("attachmentComment", attachmentCommentArray);
    formData.append("tag", attachmentTagArray);

    return formData;
  };

  const saveCR = async () => {
    setisLoading(true);
    const formData = appendCRFormData();
    try {
      let response = "";
      if (!isFirstTempId) {
        response = await tokenAxios.post("/cr", formData);
      } else {
        response = await tokenAxios.put(
          `/cr/temp/end/${isFirstTempId}`,
          formData
        );
      }
      const crid = response.data.result.data.id;
      setisRouteActivate(true);
      setCRId(crid);
      setisLoading(false);
    } catch (error) {
      if (error.response && error.response.data.result) {
        console.log(error.response);
        alert(error.response.data.result.msg);
      } else {
        console.log(error);
        navigate("/notFound");
      }
      setisRouteActivate(false);
    }
  };
  const tempSave = async () => {
    setisLoading(true);
    const formData = appendCRFormData();
    try {
      if (isFirstTempId) {
        const response = await tokenAxios.put(
          `/cr/temp/${isFirstTempId}`,
          formData
        );
      } else {
        const response = await tokenAxios.post("/cr/temp", formData);
        setisFirstTempId(response.data.result.data.id);
      }
      alert(txt.approveDone);

      setisLoading(false);
    } catch (error) {
      if (error.response.data.result) {
        console.log(error.response);
        alert(error.response.data.result.msg);
      } else {
        console.log(error.response);
        navigate("/notFound");
      }
    }
  };

  useEffect(() => {
    setcurrentCategory("CR");

    return () => {
      initCRModule();
    };
  }, []);

  return (
    <CRCOAddSectionStyle>
      <div className="tempSave">
        {!isRouteActivate && (
          <Button
            type="button"
            onClick={tempSave}
            backgroundColor="transparent"
            fontSize="12px"
            color="var(--textDarkGray)"
          >
            {txt.saveAsDraft}{" "}
          </Button>
        )}
        <Button
          type="button"
          backgroundColor="transparent"
          color="var(--textDarkGray)"
          fontSize="12px"
          onClick={() => {
            navigate("/C/R");
          }}
        >
          <span className="material-icons">close</span>
        </Button>
      </div>
      <LoadingBox isLoading={isLoading}>
        <LoadingArrowAnimation />
        <BoldText fontSize="26px" color="var(--eciBlue)">
          S a v i n g
        </BoldText>
        <br />
        <br />
        Please wait while we set
        <br />
        thing up for you!
      </LoadingBox>
      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, txt.crco.CRInformation],
            [attachmentRef, txt.crco.CRAttachment],
            [routeRef, txt.crco.routeInfo],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>
      <FormSectionStyle ref={formRef}>
        <CRInformationSection readOnly={isRouteActivate} />

        <AttachmentSection
          readOnly={isRouteActivate}
          attachmentFileArray={attachmentFileArray}
          setattachmentFileArray={setattachmentFileArray}
          attachmentTagArray={attachmentTagArray}
          setattachmentTagArray={setattachmentTagArray}
          attachmentCommentArray={attachmentCommentArray}
          setattachmentCommentArray={setattachmentCommentArray}
          setattachmentRef={setattachmentRef}
          title={txt.crco.crAttachment}
          tagOptionList={txt.crco.crTagOptionList}
          deletedFileIds={[]}
          attachmentIdArray={[]}
          attachmentOriginArray={[]}
          setattachmentIdArray={() => {}}
          setattachmentOriginArray={() => {}}
          attachmentUploaderArray={[]}
          setattachmentUploaderArray={() => {}}
          attachmentDateArray={[]}
          setattachmentDateArray={() => {}}
        />

        <div className="gap" />
        {isRouteActivate ? (
          ""
        ) : (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveCR}
            condition={
              !!crReasonId &&
              !!(crImportanceId.id || typeof crImportanceId === "string") && // 여기 확인
              !!crSourceId &&
              !!CRTitle &&
              !!CRBody
            }
          >
            {txt.saveAndRoute}{" "}
          </Button>
        )}

        <RouteInitSection
          activate={isRouteActivate}
          itemType="CR"
          setrouteRef={setrouteRef}
          itemId={CRId}
          setsearchBoxType={setsearchBoxType}
          targetMember={targetMember}
          settargetMember={settargetMember}
          members={members}
          url="/route/cr"
        />
      </FormSectionStyle>
      <SearchBoxSectionStyle>
        <SearchBox
          width="100%"
          height="100vh - 218px"
          type={searchBoxType}
          setproperty={setsearchBoxProperty}
          propertyIndex={targetMember}
          members={members}
          deletememberArray={deletemember}
        />
      </SearchBoxSectionStyle>
    </CRCOAddSectionStyle>
  );
}
export default React.memo(CRAddSection);
