import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TableIndexRow from "../atom/TableIndexRow";
import commonStore from "../store/commonStore";
import PaginationTable from "./PaginationTable";
import SearchBar from "./SearchBar";
import txt from "../Text";
import AdminItemRow from "./AdminItemRow";

const AdminPermissionSearchBoxStyle = styled.div`
  width: calc(${(props) => props.width});
  padding: 20px 10px;
  height: calc(${(props) => props.height});
  .white {
    border-radius: 10px;
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 10px;
    border: solid 2px
      ${(props) => (props.blueBorder ? "var(--eciBlue)" : "white")};
  }
  .tableSection {
    height: calc(100%);
  }
`;

export default function AdminPermissionSearchBox({
  width,
  height,
  setproperty,
  propertyIndex, // 멤버처럼 인덱스를 건드려야 할 경우
  members,
  deletememberArray,
  blueBorder,
  userId,
}) {
  const { tokenAxios } = commonStore();
  const [searchText, setSearchText] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [pageNum, setPageNum] = useState("0");
  const [rowsPerPage, setrowsPerPage] = useState(7);
  const [dataList, setdataList] = useState([]);
  const [totalIds, settotalIds] = useState([]);
  const [selectAllFlag, setselectAllFlag] = useState([]);
  const indexRow = (
    <>
      {/* <div className="curPer">{txt.admin.currentPosition}</div> */}
      <TableIndexRow
        widthArray={[1, 1, 1, 1]}
        itemArray={txt.admin.permissionSearchIndexArr}
      />
      <AdminItemRow
        data={{ itemNumber: txt.selectAll, itemId: -42 }}
        setmemberArray={(value) => {
          setproperty(totalIds, "all");
          setselectAllFlag([-42]);
        }}
        deletememberArray={() => {
          setproperty([], "all");
          setselectAllFlag([]);
        }}
        members={selectAllFlag}
      />
    </>
  );

  const itemRowList =
    dataList &&
    dataList.map((item, i) => {
      const itemArray = Object.values(item).map((itm) => {
        return itm;
      });
      return (
        <AdminItemRow
          key={i}
          data={item}
          setmemberArray={(value) => {
            setproperty(value);
          }}
          deletememberArray={(id) => {
            deletememberArray(id);
          }}
          members={members && members.map((id) => (id.itemId ? id.itemId : id))}
        />
      );
    });

  useEffect(() => {
    setdataList([]);

    const response = tokenAxios
      .get(`route-responsible/${userId}`)
      .then((res) => {
        setdataList([...res.data.contents]);
        setTotalPage(res.data.totalPages);
        settotalIds(res.data.all);
      });
  }, [
    setdataList,
    tokenAxios,
    pageNum,
    rowsPerPage,
    propertyIndex,
    searchText,
    userId,
  ]);
  useEffect(() => {
    setPageNum("0");
  }, [searchText]);
  return (
    <AdminPermissionSearchBoxStyle
      width={width}
      height={height}
      blueBorder={blueBorder}
    >
      <div className="white">
        <div className="tableSection">
          <br />
          <PaginationTable
            width="100%"
            height="100%"
            noshadow
            totalPage={totalPage}
            setRowsPerPage={setrowsPerPage}
            rowsPerPageDefault={7}
            pageNum={pageNum}
            rowsPerPage={rowsPerPage}
            setpageNum={setPageNum}
          >
            {indexRow}
            {itemRowList}
          </PaginationTable>
        </div>
      </div>
    </AdminPermissionSearchBoxStyle>
  );
}
