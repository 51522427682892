import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { Outlet, Link, useParams, useNavigate } from "react-router-dom";
import BoldText from "../atom/BoldText";
import commonStore from "../store/commonStore";
import LoadingBox from "../organism/LoadingBox";
import LoadingFileAnimation from "../molecule/LoadingFileAnimation";
import BOMModuleStore from "../store/BOMModuleStore";
import PreliminaryBOM from "../organism/page/PreliminaryBOM";
import DevelopBOM from "../organism/page/DevelopBOM";
import CompareBOM from "../organism/page/CompareBOM";
import text from "../Text";

const BOMModuleStyle = styled.div`
  margin-top: 80px;
  width: 100%;
  min-width: 1000px;
  min-height: 580px;
  height: calc(100vh - 80px);

  background-color: var(--lightGray);
  overflow: scroll;

  ::-webkit-scrollbar {
    height: 7px;
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
  position: relative;
`;
const BOMModulePageStyle = styled.div`
  padding-top: 24px;
  padding-right: 4%;
  width: 100%;
  min-width: 1000px;
  /* min-height: calc(100vh - 80px); */
  //80px는 헤더 높이
  position: relative;

  .itemInfo {
    height: 80px;
    display: flex;
    align-items: center;
    line-height: 10px;
    img {
      width: 50px;
      height: 50px;
      border-radius: 100px;
      background-color: var(--textDarkGray);
      margin-right: 5px;
    }
    .itemNumber {
      font-size: 12px;
      color: var(--textDarkGray);
      font-weight: 700;
    }
    .itemName {
      font-size: 16px;
    }
  }

  .outlet {
    width: 96%;
    position: absolute;
    left: 0;
    top: 200px;
  }
  .preliminaryContainer {
    display: ${(props) =>
      props.currentCategory === "preliminary" ? "" : "none"};
  }
  .developContainer {
    display: ${(props) => (props.currentCategory === "develop" ? "" : "none")};
  }
  .compareContainer {
    display: ${(props) => (props.currentCategory === "compare" ? "" : "none")};
  }
`;

const CategoryBar = styled.div`
  width: 100%;
  border-bottom: solid 1px var(--eciBlue);

  display: flex;
  align-items: center;

  .category {
    height: 40px;
    width: 18%;

    font-size: 12px;

    line-height: 40px;
    text-decoration: none;
    transform: translate(0, 1.5px);
    padding-left: 4px;
    cursor: pointer;
  }

  .preliminary {
    border-bottom: ${(props) =>
      props.currentCategory === "preliminary"
        ? "solid 3px var(--eciBlue)"
        : "none"};
  }
  .develop {
    border-bottom: ${(props) =>
      props.currentCategory === "develop"
        ? "solid 3px var(--eciBlue)"
        : "none"};
  }
  .compare {
    border-bottom: ${(props) =>
      props.currentCategory === "compare"
        ? "solid 3px var(--eciBlue)"
        : "none"};
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: var(--eciBlueHover);
`;
function BOMModule() {
  const { setCurrentModule, tokenAxios } = commonStore();
  const {
    currentCategory,
    setcurrentCategory,
    BOMId,
    itemId,
    itemNumber,
    itemName,
    itemThumbnail,
    setpage,
    initBOMModule,
    setitemInfo,
    preliminaryType,
    developType,
    compareType,
    preliminaryId,
    developId,
    compareId,
  } = BOMModuleStore();

  const params = useParams();

  const getBOMData = async () => {
    const response = await tokenAxios.get(`bom/${params.BOMId}`);
    setitemInfo(
      response.data.result.data.item.id,
      response.data.result.data.item.itemNumber,
      response.data.result.data.item.name,
      response.data.result.data.item.imageAddress
    );
    setpage(
      response.data.result.data.list[0].range,
      response.data.result.data.list[1].range,
      response.data.result.data.list[2].range,
      response.data.result.data.list[0].id,
      response.data.result.data.list[1].id,
      response.data.result.data.list[2].id
    );
    if (response.data.result.data.list[1].range === "review") {
      setcurrentCategory("develop");
    }

    // develop 설정
  };

  useEffect(
    () => {
      setCurrentModule("Item");
      getBOMData();
      return () => {
        initBOMModule();
      };
    },
    [
      // preliminaryType,
      // developType,
      // compareType,
      // preliminaryId,
      // developId,
      // compareId,
    ]
  );
  const navigate = useNavigate();
  return (
    <BOMModuleStyle>
      <LoadingBox>
        <LoadingFileAnimation />
        <BoldText fontSize="26px" color="var(--eciBlue)">
          P R O C E S S I N G
        </BoldText>
        <br />
        <br />
        Please wait while we set
        <br />
        thing up for you !
      </LoadingBox>
      <BOMModulePageStyle currentCategory={currentCategory}>
        <BoldText fontSize="30px" color="var(--eciBlue)">
          BOM
        </BoldText>
        <br />
        <div
          className="itemInfo"
          onClick={() => {
            navigate(`/item/detail/${itemId}`);
          }}
        >
          <img src={itemThumbnail} alt=" " />
          <div className="colbox">
            <span className="itemNumber">{itemNumber}</span>
            <br />
            <br />
            <span className="itemName">{itemName}</span>
          </div>
        </div>
        <CategoryBar currentCategory={currentCategory}>
          <div
            className="preliminary category"
            onClick={() => {
              setcurrentCategory("preliminary");
            }}
          >
            <BoldText
              fontSize="14px"
              color={
                currentCategory === "List"
                  ? "var(--eciBlue)"
                  : "var(--darkGray)"
              }
            >
              Preliminary BOM
            </BoldText>
          </div>
          <div
            className="develop category"
            onClick={() => {
              if (developType === "deactivate") {
                alert("you have no access");
              } else {
                setcurrentCategory("develop");
              }
            }}
          >
            <BoldText
              fontSize="14px"
              color={
                currentCategory === "List"
                  ? "var(--eciBlue)"
                  : "var(--darkGray)"
              }
            >
              Development BOM
            </BoldText>
          </div>
          <div
            className="compare category"
            onClick={() => {
              if (compareType === "deactivate") {
                alert("you have no access");
              } else {
                setcurrentCategory("compare");
              }
            }}
          >
            <BoldText
              fontSize="14px"
              color={
                currentCategory === "List"
                  ? "var(--eciBlue)"
                  : "var(--darkGray)"
              }
            >
              Compare BOM
            </BoldText>
          </div>
        </CategoryBar>
        <div className="outlet preliminaryContainer">
          <PreliminaryBOM />
        </div>
        <div className="outlet developContainer">
          <DevelopBOM />
        </div>
        <div className="outlet compareContainer">
          <CompareBOM />
        </div>
      </BOMModulePageStyle>
    </BOMModuleStyle>
  );
}
export default React.memo(BOMModule);
