import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../atom/Button";
import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";
import LoadingBox from "../LoadingBox";
import LoadingArrowAnimation from "../../molecule/LoadingArrowAnimation";
import BoldText from "../../atom/BoldText";
import documentModuleStore from "../../store/DocumentModuleStore";
import AttachmentSection from "../AttachmentSection";
import DocumentInformationSection from "../DocumentInformationSection";
import ItemSearchBox from "../../molecule/ItemSearchBox";
import SearchBox from "../../molecule/SearchBox";
import RouteInitSection from "../RouteInitSection";
import txt from "../../Text";

const DocumentAddPageStyle = styled.div`
  display: flex;
  position: relative;
  .tempSave {
    height: 40px;

    position: absolute;
    top: -40px;
    left: calc(58% - 110px);
    display: flex;
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`;
const FormSectionStyle = styled.div`
  width: 58%;
  height: ${(props) =>
    props.isClassificationBox ? "760px" : "calc(100vh - 200px)"};
  overflow: scroll;
  padding-left: 18%;
  padding-right: 1.5%;
  padding-top: 20px;
  .gap {
    height: 45px;
  }
  ::-webkit-scrollbar {
    height: 7px;
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
`;
const SearchBoxSectionStyle = styled.div`
  width: 42%;
  position: relative;
  padding-bottom: 30px;
  .searchBox {
    width: 100%;
  }
`;
export default function DocumentAddPage() {
  const tokenAxios = commonStore((state) => state.tokenAxios);
  const {
    // dcument
    documentId,
    setdocumentId,
    documentTag,
    documentContent,
    documentTitle,
    documentClassification,
    setdocumentClassification,
    classification1,
    classification2,
    /// attachment
    attachmentFileArray,
    attachmentTagArray,
    attachmentCommentArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    attachmentTagOptionList,
    attachmentIdArray,
    setattachmentIdArray,
    addedAttachmentArray,
    setaddedAttachmentArray,
    // route

    targetMember,
    settargetMember,
    members,
    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // searchBox
    searchBoxType,
    setsearchBoxType,
    setsearchBoxProperty,
    deletemember,
    // etc
    initdocumentModule,
    setcurrentCategory,
  } = documentModuleStore();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [isClassificationBox, setisClassificationBox] = useState(false);
  const [isFirstTempId, setisFirstTempId] = useState(false);
  const formRef = useRef();
  const params = useParams();
  const [isRouteActivate, setisRouteActivate] = useState(false);
  const appendProjectFormData = () => {
    const formData = new FormData();
    formData.append("classification1Id", classification1);
    formData.append("classification2Id", classification2);
    formData.append("tagId", documentTag.id ? documentTag.id : "");
    formData.append("title", documentTitle);
    formData.append("content", documentContent);
    // duplicate
    if (params.id !== "new" && attachmentIdArray.length > 0) {
      formData.append(
        "duplicateTargetIds",
        attachmentIdArray.filter((item) => item)
      );
    }
    // attachment
    attachmentFileArray.forEach((file) => {
      if (typeof file !== "string") {
        formData.append("attachments", file);
      }
    });

    return formData;
  };

  const saveItem = async () => {
    setisLoading(true);
    const formData = appendProjectFormData();
    try {
      let response = "";
      if (!isFirstTempId) {
        response = await tokenAxios.post("/doc", formData);
      } else {
        response = await tokenAxios.put(
          `/doc/temp/end/${isFirstTempId}`,
          formData
        );
      }
      // const response = await tokenAxios.post("/doc", formData);
      setisLoading(false);
      setisRouteActivate(true);

      // route부분으로 스크롤
      setdocumentId(response.data.result.data.id);
    } catch (err) {
      if (err.response.data.result) {
        console.log(err.response);
        alert(err.response.data.result.msg);
      } else {
        console.log(err.response);
        navigate("/notFound");
      }
      setisLoading(true);
    }
  };
  const tempSave = async () => {
    setisLoading(true);
    const formData = appendProjectFormData();
    try {
      if (isFirstTempId) {
        const response = await tokenAxios.put(
          `/doc/temp/${isFirstTempId}`,
          formData
        );
      } else {
        const response = await tokenAxios.post("/doc/temp", formData);
        setisFirstTempId(response.data.result.data.id);
        // console.log(typeof response);
      }
      alert(txt.approveDone);

      setisLoading(false);
    } catch (err) {
      console.log(err);
      if (err.response.data.result) {
        console.log(err.response);
        alert(err.response.data.result.msg);
      } else {
        console.log(err.response);
        navigate("/notFound");
      }
    }
  };

  useEffect(() => {
    setcurrentCategory("Add");
    return () => {
      initdocumentModule();
    };
  }, []);
  return (
    <DocumentAddPageStyle>
      <div className="tempSave">
        {!isRouteActivate && (
          <Button
            type="button"
            onClick={tempSave}
            backgroundColor="transparent"
            fontSize="12px"
            color="var(--textDarkGray)"
          >
            {txt.saveAsDraft}{" "}
          </Button>
        )}
      </div>
      <LoadingBox isLoading={isLoading}>
        <LoadingArrowAnimation />
        <BoldText fontSize="26px" color="var(--eciBlue)">
          S a v i n g
        </BoldText>
        <br />
        <br />
        Please wait while we set
        <br />
        thing up for you!
      </LoadingBox>

      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, txt.doc.docInfo],
            [routeRef, txt.doc.routeInfo],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>
      <FormSectionStyle ref={formRef} isClassificationBox={isClassificationBox}>
        <DocumentInformationSection
          setisClassificationBox={setisClassificationBox}
          isClassificationBox={isClassificationBox}
          readOnly={isRouteActivate}
        />
        <AttachmentSection
          oneLine
          readOnly={isRouteActivate}
          attachmentFileArray={attachmentFileArray}
          setattachmentFileArray={setattachmentFileArray}
          attachmentTagArray={attachmentTagArray}
          setattachmentTagArray={setattachmentTagArray}
          attachmentCommentArray={attachmentCommentArray}
          setattachmentCommentArray={setattachmentCommentArray}
          setattachmentRef={setattachmentRef}
          title={txt.doc.docAttachment}
          tagOptionList={attachmentTagOptionList}
          deletedFileIds={[]}
          attachmentIdArray={attachmentIdArray}
          attachmentOriginArray={[]}
          setattachmentIdArray={setattachmentIdArray}
          setattachmentOriginArray={() => {}}
          attachmentUploaderArray={[]}
          setattachmentUploaderArray={() => {}}
          attachmentDateArray={[]}
          setattachmentDateArray={() => {}}
          addedAttachmentArray={addedAttachmentArray}
          setaddedAttachmentArray={setaddedAttachmentArray}
        />
        <div className="gap" />
        {isRouteActivate ? (
          ""
        ) : (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveItem}
            condition={!!classification1 && !!documentTitle}
          >
            {txt.saveAndRoute}{" "}
          </Button>
        )}
        <RouteInitSection
          activate={isRouteActivate}
          itemType="document"
          setrouteRef={setrouteRef}
          itemId={documentId}
          setsearchBoxType={setsearchBoxType}
          targetMember={targetMember}
          settargetMember={settargetMember}
          members={members}
          url="/route/doc"
        />
      </FormSectionStyle>
      <SearchBoxSectionStyle>
        <div className="searchBox">
          <ItemSearchBox
            width="100%"
            activate={isClassificationBox && !searchBoxType}
            setitemClassification={setdocumentClassification}
            document
          />
        </div>
        <div className="searchBox">
          <SearchBox
            width="100%"
            height="100vh - 218px"
            type={searchBoxType}
            setproperty={setsearchBoxProperty}
            propertyIndex={targetMember}
            members={members}
            deletememberArray={deletemember}
          />
        </div>
      </SearchBoxSectionStyle>
    </DocumentAddPageStyle>
  );
}
