import React, { useEffect, useState } from "react";
import styled from "styled-components";

import commonStore from "../../store/commonStore";

import TeamSearchUi from "../../molecule/TeamSearchUi";
import PropChangeSearchBox from "../PropChangeSearchBox";

const AdminPropChangePageStyle = styled.div`
  display: flex;

  .right {
    padding-top: 20px;
    padding-left: 24px;
    width: 50%;
  }
`;

export default function AdminPropChangePage() {
  const { tokenAxios } = commonStore((state) => ({
    tokenAxios: state.tokenAxios,
  }));

  const [teamTreeData, setteamTreeData] = useState([]);
  const [propertyKeyword, setpropertyKeyword] = useState("");

  // const [searchWord, setsearchWord] = useState("");
  // const [rowsPerPage, setRowsPerPage] = useState("10");
  // const [pageNum, setpageNum] = useState("0");
  // const [totalPage, setTotalPage] = useState(1);

  useEffect(async () => {
    const response = await tokenAxios.get("/feature");
    setteamTreeData(response.data.result.data);
  }, []);
  return (
    <AdminPropChangePageStyle>
      <TeamSearchUi
        width="22%"
        height="58vh"
        teamTreeData={teamTreeData}
        state={propertyKeyword}
        setstate={setpropertyKeyword}
        nosearchBar
        unfold
      />
      {propertyKeyword && (
        <div className="right">
          <PropChangeSearchBox
            width="100%"
            height="58vh"
            type={propertyKeyword.api}
          />
        </div>
      )}
    </AdminPropChangePageStyle>
  );
}
