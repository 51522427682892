import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BoldText from "../../atom/BoldText";
import ModalBox from "../ModalBox";
import AnimationInput from "../../molecule/AnimationInput";
import SearchInput from "../../molecule/SearchInput";
import BOMModuleStore from "../../store/BOMModuleStore";
import Button from "../../atom/Button";
import commonStore from "../../store/commonStore";
import TableIndexRow from "../../atom/TableIndexRow";
import TableRow from "../../atom/TableRow";
import RoundTextBox from "../../atom/RoundTextBox";
import SearchBar from "../../molecule/SearchBar";
import PaginationTable from "../../molecule/PaginationTable";
import text from "../../Text";
import FilterSearchBox from "../../molecule/FilterSearchBox";

const RowBox = styled.div``;

const CompareBOMStyle = styled.div`
  width: 100%;
  padding-left: 18%;
  padding-right: 1.5%;
  padding-top: 20px;
  .itemName {
    background-color: white;
    height: 40px;
    width: 500px;
    border-radius: 10px;
    padding-left: 20px;
    font-size: 15px;
    line-height: 40px;
    color: var(--eciBlue);
  }
  .resultSection {
    width: 100%;
    min-height: 200px;
    border: solid 1px var(--eciBlue);
    border-radius: 10px;
    padding: 10px 10px;

    display: flex;

    background-color: white;
    .leftBox {
      width: 75%;
    }
    .rightBox {
      padding-top: 10px;
      width: 25%;
    }
  }
  .BlueBoxContainer {
    display: flex;
  }
  .BlueBox {
    height: 40px;
    line-height: 40px;
    width: 50%;
    border-top: solid 1px white;
    background-color: var(--eciBlue);
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: 600;
  }
`;

export default function CompareBOM() {
  const { tokenAxios } = commonStore();
  const {
    itemId,
    itemName,
    compareTargetName,
    setcompareTargetName,
    compareTargetId,
    setcompareTargetId,
    compareDataArray,
    setcompareDataArray,
  } = BOMModuleStore();
  const [isSearchBox, setisSearchBox] = useState(false);

  const compare = async () => {
    const response = await tokenAxios.get(
      `/compare?compareId=${itemId}&againstId=${compareTargetId}`
    );
    setcompareDataArray(response.data.result.data);
  };
  // 모달
  const [searchText, setsearchText] = useState("");
  const [itemNameForModal, setitemNameForModal] = useState("");
  const [itemNumber, setitemNumber] = useState("");
  const [carType, setcarType] = useState("");
  const [carTypeArr, setcarTypeArr] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [pageNum, setPageNum] = useState("0");
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [dataList, setdataList] = useState([]);
  const modalRows =
    dataList &&
    dataList[0] &&
    dataList[0].map((item, i) => {
      return (
        <TableRow
          key={i}
          widthArray={[0.2, 1, 1, 1, 1, 1]}
          itemArray={[
            "",
            item && item.cardNumber,
            item && item.classification,
            item && item.cardName,
            item && item.cardType && item.cardType,
            item && item.sharing && item.sharing === "공용" ? (
              <RoundTextBox
                width="50%"
                backgroundColor="var(--lightGray)"
                color="var(--eciBlue)"
                height="30px"
              >
                {text.item.sharing}{" "}
              </RoundTextBox>
            ) : (
              <RoundTextBox
                width="50%"
                backgroundColor="var(--lightGray)"
                color="var(--eciBlue)"
                height="30px"
              >
                {text.item.notSharing}
              </RoundTextBox>
            ),
          ]}
          onClick={() => {
            setisSearchBox(false);
            setcompareTargetId(item.cardId);
            setcompareTargetName(item.cardName);
            setisSearchBox(false);
          }}
        />
      );
    });
  const getModalData = async () => {
    if (itemId) {
      const response = await tokenAxios.get(
        `compare/bom/items/page?size=${rowsPerPage}&page=${pageNum}&name=${searchText}&itemName=${itemNameForModal}&itemNumber=${itemNumber}&carTypeId=${carType}`
      );

      setdataList([response.data.content]);
      setTotalPage(response.data.totalPages);
    }
  };
  const rows =
    compareDataArray &&
    compareDataArray.map((item) => {
      return (
        <RowBox left={item.different}>
          <TableRow
            widthArray={[1, 5, 5, 6, 5, 4]}
            itemArray={[
              "",
              item.cardNumber,
              item.cardName,
              item.classification,
              item.cardType,
              item.sharing === "공용" ? (
                <RoundTextBox
                  width="40%"
                  backgroundColor="var(--lightGray)"
                  color="var(--eciBlue)"
                  height="30px"
                >
                  {text.item.sharing}{" "}
                </RoundTextBox>
              ) : (
                <RoundTextBox
                  width="40%"
                  backgroundColor="var(--lightGray)"
                  color="var(--eciBlue)"
                  height="30px"
                >
                  {text.item.notSharing}
                </RoundTextBox>
              ),
            ]}
          />
        </RowBox>
      );
    });
  const compareRows =
    compareDataArray &&
    compareDataArray.map((item) => {
      return (
        <div className="BlueBoxContainer">
          <div className="BlueBox">
            {item.different < 0 || item.different === 2 ? "O" : "X"}
          </div>
          <div className="BlueBox">
            {item.different < 0 || item.different === 1 ? "O" : "X"}
          </div>
        </div>
      );
    });
  useEffect(() => {
    getModalData();
  }, [
    searchText,
    itemNameForModal,
    itemNumber,
    carType,
    pageNum,
    rowsPerPage,
    itemId,
  ]);
  return (
    <CompareBOMStyle>
      <ModalBox isActivate={isSearchBox} setisActivate={setisSearchBox}>
        <FilterSearchBox
          searchText={searchText}
          setsearchText={setsearchText}
          setitemName={setitemNameForModal}
          setitemNumber={setitemNumber}
          setcarType={setcarType}
        />{" "}
        <br />
        <br />
        <PaginationTable
          minHeight="480px"
          totalPage={totalPage}
          setRowsPerPage={setrowsPerPage}
          rowsPerPageDefault={7}
          pageNum={pageNum}
          rowsPerPage={rowsPerPage}
          setpageNum={setPageNum}
        >
          <TableIndexRow
            widthArray={[1, 1, 1, 1, 1]}
            itemArray={text.bom.compModalIndexArray}
          />
          {modalRows}
        </PaginationTable>
      </ModalBox>
      <div className="inputSection">
        <BoldText color="var(--eciBlue)" fontSize="15px">
          {text.bom.compare}{" "}
        </BoldText>
        <br />
        <br />
        <div className="itemName">{itemName}</div>
        <br />
        <BoldText color="var(--eciBlue)" fontSize="15px">
          To
        </BoldText>
        <br />
        <br />
        <SearchInput
          width="500px"
          height="40px"
          state={compareTargetName}
          onClick={() => {
            setisSearchBox(true);
            setcompareDataArray([]);
          }}
          placeholder="Search item name"
        />
      </div>
      <br />
      <br />
      {compareDataArray && compareDataArray[0] ? (
        <div className="resultSection">
          <div className="leftBox">
            <TableIndexRow
              widthArray={[1, 5, 5, 6, 5, 4 /* 5,5 */]}
              noBorder
              itemArray={text.bom.compIndexArray}
            />
            {rows}
          </div>
          <div className="rightBox">
            <div className="BlueBoxContainer">
              <div className="BlueBox">{itemName} </div>
              <div className="BlueBox">{compareTargetName} </div>
            </div>

            {compareRows}
          </div>
        </div>
      ) : (
        <Button
          backgroundColor="var(--eciBlue)"
          width="200px"
          height="30px"
          color="white"
          onClick={compare}
          condition
        >
          {text.bom.compare}{" "}
        </Button>
      )}
    </CompareBOMStyle>
  );
}
