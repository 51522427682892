import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { Link, Outlet } from "react-router-dom";
import BoldText from "../atom/BoldText";
import commonStore from "../store/commonStore";
import documentModuleStore from "../store/DocumentModuleStore";
import text from "../Text";

const DocumentModuleStyle = styled.div`
  margin-top: 80px;
  width: 100%;
  min-width: 1000px;
  min-height: 580px;
  height: calc(100vh - 80px);

  background-color: var(--lightGray);
  overflow: scroll;

  ::-webkit-scrollbar {
    height: 7px;
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
  position: relative;
`;
const DocumentModulePageStyle = styled.div`
  padding-top: 24px;
  padding-right: 4%;
  width: 100%;
  min-width: 1000px;
  min-height: calc(100vh - 80px);
  //80px는 헤더 높이
`;

const CategoryBar = styled.div`
  width: 100%;
  min-width: 1000px;
  border-bottom: solid 1px var(--eciBlue);

  display: flex;

  .category {
    height: 40px;
    width: 18%;

    font-size: 12px;

    line-height: 40px;
    text-decoration: none;
    transform: translate(0, 1.5px);
  }
  .List {
    border-bottom: ${(props) =>
      props.currentCategory === "List" ? "solid 3px var(--eciBlue)" : "none"};
  }
  .Add {
    padding-left: 4px;
    border-bottom: ${(props) =>
      props.currentCategory === "Add" ? "solid 3px var(--eciBlue)" : "none"};
    .plus {
      font-size: 14px;
      color: black;
      color: ${(props) =>
        props.currentCategory === "Add"
          ? " var(--eciBlue)"
          : " var(--darkGray)"};
      margin-right: 10px;
    }
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: var(--eciBlueHover);
`;
function DocumentModule() {
  const { setCurrentModule } = commonStore();
  const { currentCategory } = documentModuleStore();
  useEffect(() => {
    setCurrentModule("Document");
  }, [setCurrentModule]);
  return (
    <DocumentModuleStyle>
      <DocumentModulePageStyle>
        <BoldText fontSize="30px" color="var(--eciBlue)">
          {text.doc.document}
        </BoldText>
        <br />

        <CategoryBar currentCategory={currentCategory}>
          <div className="List category">
            <StyledLink to="/document">
              <BoldText
                fontSize="14px"
                color={
                  currentCategory === "List"
                    ? "var(--eciBlue)"
                    : "var(--darkGray)"
                }
              >
                {text.doc.list}{" "}
              </BoldText>
            </StyledLink>
          </div>
          <div className="Add category">
            <StyledLink to="/document/add/new">
              <span className="material-icons plus">add</span>
              <BoldText
                fontSize="14px"
                color={
                  currentCategory === "Add"
                    ? "var(--eciBlue)"
                    : "var(--darkGray)"
                }
              >
                {text.doc.addNewDoc}{" "}
              </BoldText>
            </StyledLink>
          </div>
        </CategoryBar>
        <Outlet />
      </DocumentModulePageStyle>
    </DocumentModuleStyle>
  );
}
export default React.memo(DocumentModule);
