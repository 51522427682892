import React from "react";
import styled from "styled-components";
import blueArrow from "../static/BlueBorderTriangle.png";

const TodoNumberBoxStyle = styled.div`
  width: ${(props) => props.width};
  height: 120px;
  color: ${(props) => (props.isActive ? "white" : "var(--textGray)")};
  background-color: ${(props) => (props.isActive ? "var(--eciBlue)" : "white")};
  border-radius: 10px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  transition: 0.1s;
  .number {
    width: 45px;
    height: 45px;
    border-radius: 100px;
    margin: 15px auto;
    margin-bottom: 30px;
    color: ${(props) => (props.isActive ? "var(--eciBlue)" : "white")};
    background-color: ${(props) =>
      props.isActive ? "white" : "var(--eciBlue)"};
    line-height: 45px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }
  .arrow {
    width: 20px;
    height: 16px;
    /* border: solid black; */
    display: ${(props) => (props.isActive ? "" : "none")};
    position: absolute;
    bottom: -20px;
    left: calc(50% - 10px);
  }
`;

export default function TodoNumberBox({
  number,
  name,
  isActive,
  width,
  onClick,
}) {
  return (
    <TodoNumberBoxStyle width={width} isActive={isActive} onClick={onClick}>
      <div className="number">{number}</div>
      {name}

      {/* <div className="arrow" /> */}
      <img className="arrow" src={blueArrow} alt="" />
    </TodoNumberBoxStyle>
  );
}
