import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import LineTitle from "../atom/LineTitle";
import AnimationInput from "../molecule/AnimationInput";
import PencilInput from "../molecule/PencilInput";
import AnimationDateInput from "../molecule/AnimationDateInput";
import AnimationSelectBox from "../molecule/AnimationSelectBox";
import SearchInput from "../molecule/SearchInput";
import TextBox from "../molecule/TextBox";
import CRCOModuleStore from "../store/CRCOModuleStore";
import ModalBox from "./ModalBox";
import CheckBox from "../molecule/CheckBox";
import RoundTextBox from "../atom/RoundTextBox";
import TableIndexRow from "../atom/TableIndexRow";
import StatusTag from "../atom/StatusTag";
import TableRow from "../atom/TableRow";
import PaginationTable from "../molecule/PaginationTable";
import SearchBar from "../molecule/SearchBar";
import commonStore from "../store/commonStore";
import Table from "../molecule/Table";
import RoundImgBox from "../atom/RoundImgBox";
import txt from "../Text";
import UnitInput from "../molecule/UnitInput";
import FilterSearchBox from "../molecule/FilterSearchBox";

const COInformationSectionStyle = styled.div`
  /* pointer-events: ${(props) => (props.readOnly ? "none" : "")}; */

  .gridContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: repeat(11, 60px);
    grid-column-gap: 15px;
    grid-row-gap: 10px;
  }
  .checkBox {
    grid-column: 1/3;
    grid-row: 8/11;
  }
  .blueBox {
    padding: 15px;
    background-color: var(--eciBlue);
    border-radius: 10px;
  }
  .search {
    display: flex;
    align-items: center;
    background-color: var(--textGray);
    border-radius: 5px;
    height: 30px;
    width: 100%;
    padding: 5px;
    line-height: 30px;
    cursor: pointer;
    .cfid {
      font-size: 12px;
    }
  }
`;
export default function COInformationSection({ readOnly }) {
  const {
    // coinfo
    CONumber,
    setCONumber,
    clientOrganizationId,
    COClientNumber,
    setCOClientNumber,
    COClientPublishDate,
    setCOClientPublishDate,
    CoClientReceiptDate,
    setCoClientReceiptDate,
    COisPastCar,
    setCOisPastCar,
    carTypeId,
    COisCostChange,
    setCOisCostChange,
    COcost,
    setCOcost,
    crReasonId,
    COReasonText,
    setCOReasonText,
    COLevel,
    setCOLevel,
    COTiming,
    setCOTiming,
    COAffect,
    setCOAffect,
    COImportance,
    setCOImportance,
    COTitle,
    setCOTitle,
    COBody,
    setCOBody,
    CORelatedCR,
    setCORelatedCR,
    COAffectedItem,
    changedFeatureId,
    setchangedFeatureId,
    changedFeatureIndex,
    setchangedFeatureIndex,
    setCOAffectedItem,
    // searchbox
    searchBoxType,
    setsearchBoxType,
    // etc
    setinformationRef,
  } = CRCOModuleStore();

  const informationRef = useRef();
  const [isModalBox, setisModalBox] = useState(false);
  const [modalType, setmodalType] = useState("");
  const { tokenAxios } = commonStore();
  const [searchWord, setsearchWord] = useState("");
  const relatedCRRows =
    CORelatedCR &&
    CORelatedCR.map((item, i) => {
      const onDelete = () => {
        const tmpArr = [...CORelatedCR];
        const tmpAIArr = [...COAffectedItem];
        const tmpCF = [...changedFeatureId];
        tmpArr.splice(i, 1);
        let j = 0;
        const newAIArr = tmpAIArr.filter((affectedItemObject) => {
          if (affectedItemObject.crNumber === item.crNumber) {
            tmpCF.splice(j, 1);
            j -= 1;
          }
          j += 1;
          return affectedItemObject.crNumber !== item.crNumber;
        });
        setCORelatedCR(tmpArr);
        setCOAffectedItem(newAIArr);
        setchangedFeatureId(tmpCF);
      };
      return (
        <TableRow
          key={i}
          widthArray={[2, 2, 1, 1, 1, 1, 1]}
          itemArray={[
            item.crNumber,
            item.item && item.item.type,
            item.crReasonId && item.crReasonId.name,
            item.crImportanceId && item.crImportanceId.name,
            item.crSourceId && item.crSourceId.name,
            item.name,
            readOnly ? (
              ""
            ) : (
              <span className="material-icons delete" onClick={onDelete}>
                delete
              </span>
            ),
          ]}
        />
      );
    });
  const affectedItemRows =
    COAffectedItem &&
    COAffectedItem.map((item, i) => {
      const onDelete = () => {
        const tmpArr = [...COAffectedItem];
        const tmpCF = [...changedFeatureId];
        tmpArr.splice(i, 1);
        tmpCF.splice(i, 1);
        setCOAffectedItem(tmpArr);
        setchangedFeatureId(tmpCF);
      };
      const setBody = (val) => {
        const tmpArr = [...COAffectedItem];
        tmpArr[i].changedContent = val;
        setCOAffectedItem(tmpArr);
      };
      return (
        <>
          <TableRow
            key={i}
            widthArray={[3, 3, 3, 4, 2, 3, 1]}
            width="800px"
            itemArray={[
              item.crNumber,
              item.itemNumber,
              item.itemName,
              item.itemClassification && item.itemClassification.value,
              item.itemRevision,
              item.itemStatus,

              readOnly && item.crNumber ? (
                ""
              ) : (
                <span className="material-icons delete" onClick={onDelete}>
                  delete
                </span>
              ),
            ]}
          />
          <TableRow
            key={i}
            widthArray={[3, 6, 4, 6]}
            width="800px"
            itemArray={[
              "",
              <PencilInput
                height="30px"
                width="90%"
                state={item.changedContent}
                setState={setBody}
                backgroundColor="var(--lightGray)"
                readOnly={readOnly}
                required
              />,
              <div
                className="search"
                onClick={() => {
                  setsearchBoxType("changedFeatureId");
                  setchangedFeatureIndex(i);
                }}
              >
                <span className="material-icons">search</span>
                {changedFeatureId[i] && changedFeatureId[i].name}
                <span className="cfid" />
              </div>,
            ]}
          />
        </>
      );
    });
  // Modal
  const [searchText, setsearchText] = useState("");
  const [itemName, setitemName] = useState("");
  const [itemNumber, setitemNumber] = useState("");
  const [carType, setcarType] = useState("");
  const [carTypeArr, setcarTypeArr] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [pageNum, setPageNum] = useState("0");
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [dataList, setdataList] = useState([]);
  const [affectedItemList, setaffectedItemList] = useState([]);
  const [crList, setcrList] = useState([]);
  const rows =
    dataList &&
    dataList[0] &&
    dataList[0]
      .filter((item) => {
        let targetArr = [];
        let bool = true;

        if (modalType === "relatedCR") {
          targetArr = CORelatedCR;
        } else {
          targetArr = COAffectedItem;
        }
        targetArr.forEach((critem) => {
          if (
            modalType === "relatedCR"
              ? critem.id === item.crId
              : critem.itemId === item.cardId
          ) {
            //  item.id확인
            bool = false;
          }
        });
        return bool;
      })
      .map((item, i) => {
        if (modalType === "relatedCR") {
          return (
            <TableRow
              key={i}
              widthArray={[3, 2, 2, 3, 3, 1, 1]}
              itemArray={[
                item.crNumber,
                item.crReason && item.crReason.name,
                item.itemNumber,
                item.itemName,
                item.itemClassification && item.itemClassification.value,
                item.itemRevision,
                <StatusTag height="30px" width="90%">
                  {item.itemStatus}
                </StatusTag>,
              ]}
              onClick={() => {
                setCORelatedCR([
                  ...CORelatedCR,
                  {
                    id: item.crId,
                    crNumber: item.crNumber,
                    item: { type: item.crType },
                    crReasonId: item.crReason, // {id,name}
                    crImportanceId: item.crImportance, // {id,name}
                    crSourceId: item.crSource, // {id,name}
                    name: item.crName,
                  },
                ]);
                setCOAffectedItem([
                  ...COAffectedItem,
                  {
                    crNumber: item.crNumber,
                    itemId: item.itemId,
                    itemNumber: item.itemNumber,
                    itemName: item.itemName,
                    itemClassification: item.itemClassification,
                    itemRevision: item.itemRevision,
                    itemStatus: item.itemStatus,
                    changedContent: "",
                    changedFeature: {},
                  },
                ]);
                setchangedFeatureId([...changedFeatureId, ""]);
                setisModalBox(false);
              }}
            />
          );
        }
        return (
          <TableRow
            key={i}
            widthArray={[0.2, 1, 1, 1, 1, 1]}
            itemArray={[
              <RoundImgBox
                src={
                  item.imageAddress &&
                  `${process.env.REACT_APP_BASE_URL}/${item.imageAddress}`
                }
                alt=""
              />,
              item && item.cardNumber,
              item && item.cardName,
              item && item.classification,
              item && item.cardType,
              item && item.sharing && item.sharing === "공용" ? (
                <RoundTextBox
                  width="50%"
                  backgroundColor="var(--lightGray)"
                  color="var(--eciBlue)"
                  height="30px"
                >
                  {txt.item.sharing}{" "}
                </RoundTextBox>
              ) : (
                <RoundTextBox
                  width="50%"
                  backgroundColor="var(--lightGray)"
                  color="var(--eciBlue)"
                  height="30px"
                >
                  {txt.item.notSharing}{" "}
                </RoundTextBox>
              ),
            ]}
            onClick={() => {
              setCOAffectedItem([
                ...COAffectedItem,
                {
                  itemId: item.cardId,
                  itemNumber: item.cardNumber,
                  itemName: item.cardName,
                  itemClassification: { value: item.classification },
                  itemRevision: item.itemRevision,
                  itemStatus: item.itemStatus,
                  changedContent: item.changedContent
                    ? item.changedContent
                    : " ",
                  changedFeature: item.changedFeature,
                },
              ]);
              setchangedFeatureId([...changedFeatureId, ""]);
              setisModalBox(false);
            }}
          />
        );
      });
  const getAIData = async () => {
    const response = await tokenAxios.get(
      `affected/items/page?size=${rowsPerPage}&page=${pageNum}&name=${searchText}&itemName=${itemName}&itemNumber=${itemNumber}&carTypeId=${carType}`
    );
    setaffectedItemList([response.data.content]);
    setdataList([response.data.content]);
    setTotalPage(response.data.totalPages);
  };
  const getCRData = async () => {
    const response = await tokenAxios.get(
      `co/cr/page?size=${rowsPerPage}&page=${pageNum}&name=${searchText}&itemName=${itemName}&itemNumber=${itemNumber}&carTypeId=${carType}`
    );
    setcrList([response.data.content]);
    setdataList([response.data.content]);

    setTotalPage(response.data.totalPages);
  };
  const setModalData = async (type) => {
    if (type === "relatedCR") {
      setdataList(crList);
    } else {
      setdataList(affectedItemList);
    }
  };
  // 모달 끝

  useEffect(() => {
    setinformationRef(informationRef);
    if (!readOnly) {
      getCRData();
    } else {
      getAIData();
    }
  }, []);

  useEffect(() => {
    setModalData(modalType);
  }, [modalType]);

  useEffect(() => {
    if (modalType === "relatedCR") {
      getCRData();
    } else {
      getAIData();
    }
  }, [searchText, itemName, itemNumber, carType, pageNum, rowsPerPage]);
  return (
    <COInformationSectionStyle ref={informationRef} readOnly={readOnly}>
      <ModalBox isActivate={isModalBox} setisActivate={setisModalBox}>
        <FilterSearchBox
          searchText={searchText}
          setsearchText={setsearchText}
          setitemName={setitemName}
          setitemNumber={setitemNumber}
          setcarType={setcarType}
        />
        <br />
        <br />
        <PaginationTable
          minHeight="480px"
          totalPage={totalPage}
          setRowsPerPage={setrowsPerPage}
          rowsPerPageDefault={7}
          pageNum={pageNum}
          rowsPerPage={rowsPerPage}
          setpageNum={setPageNum}
        >
          <TableIndexRow
            widthArray={
              modalType === "relatedCR"
                ? [3, 2, 2, 3, 3, 1, 1]
                : [1, 1, 1, 1, 1]
            }
            itemArray={
              modalType === "relatedCR"
                ? txt.crco.relCrIndexArray
                : txt.crco.aiIndexArray
            }
          />
          {rows}
        </PaginationTable>
      </ModalBox>
      <LineTitle
        fontSize="16px"
        color="var(--eciBlue)"
        width="100%"
        height="60px"
      >
        {txt.crco.coInfo}
      </LineTitle>
      <div className="gridContainer">
        <AnimationInput
          width="100%"
          height="40px"
          placeholder={txt.crco.coNum}
          state={CONumber}
          setState={setCONumber}
          required
          backgroundColor="var(--textGray)"
          readOnly
        />
        <div />
        <SearchInput
          width="100%"
          height="40px"
          placeholder={txt.crco.client}
          state={clientOrganizationId && clientOrganizationId.name}
          required
          onClick={() => {
            setchangedFeatureIndex("");
            setsearchBoxType("clientOrganizationId");
          }}
          readOnly={readOnly}
        />
        <AnimationInput
          width="100%"
          height="40px"
          placeholder={txt.crco.clientCoNum}
          state={COClientNumber}
          setState={setCOClientNumber}
          readOnly={readOnly}
        />
        <AnimationDateInput
          width="100%"
          height="40px"
          placeholder={txt.crco.startDate}
          state={COClientPublishDate}
          setState={setCOClientPublishDate}
          readOnly={readOnly}
        />
        <AnimationDateInput
          width="100%"
          height="40px"
          placeholder={txt.crco.endDate}
          state={CoClientReceiptDate}
          setState={setCoClientReceiptDate}
          error={
            COClientPublishDate &&
            CoClientReceiptDate &&
            COClientPublishDate > CoClientReceiptDate
          }
          errorMessage={txt.project.protoError}
          readOnly={readOnly}
        />
        <AnimationSelectBox
          width="100%"
          height="40px"
          placeholder={txt.crco.isPastCar}
          state={COisPastCar}
          setState={setCOisPastCar}
          required
          readOnly={readOnly}
        >
          <option value="true">{txt.crco.true}</option>
          <option value="false">{txt.crco.false}</option>
        </AnimationSelectBox>
        {COisPastCar === "true" ? (
          <SearchInput
            required
            width="100%"
            height="40px"
            placeholder={txt.crco.carType}
            state={carTypeId && carTypeId.name}
            onClick={() => {
              setchangedFeatureIndex("");
              setsearchBoxType("carTypeId");
            }}
            readOnly={readOnly}
          />
        ) : (
          <div />
        )}
        <AnimationSelectBox
          width="100%"
          height="40px"
          placeholder={txt.crco.isCost}
          state={COisCostChange}
          setState={setCOisCostChange}
          required
          readOnly={readOnly}
        >
          <option value="true">{txt.crco.true}</option>
          <option value="false">{txt.crco.false}</option>
        </AnimationSelectBox>
        {/* <AnimationInput
          width="100%"
          height="40px"
          placeholder={txt.crco.costChange}
          state={COisCostChange === "true" ? COcost : "0 $"}
          setState={setCOcost}
          readOnly={readOnly}
        /> */}
        <UnitInput
          width="100%"
          height="40px"
          placeholder={txt.crco.costChange}
          state={COisCostChange === "true" ? COcost : "0 $"}
          setState={COisCostChange === "true" ? setCOcost : () => {}}
          readOnly={readOnly}
          optionArray={[
            {
              value: "$",
              name: "$",
            },
            {
              value: "¥",
              name: "¥",
            },
            {
              value: "₩",
              name: "₩",
            },
          ]}
        />
        <SearchInput
          required
          width="100%"
          height="40px"
          placeholder={txt.crco.coReason}
          state={crReasonId && crReasonId.name}
          onClick={() => {
            setchangedFeatureIndex("");
            setsearchBoxType("crReasonId");
          }}
          readOnly={readOnly}
        />
        {crReasonId && crReasonId.id === 1 ? (
          <AnimationInput
            width="100%"
            height="40px"
            placeholder={txt.crco.coReason}
            state={COReasonText}
            setState={setCOReasonText}
            readOnly={readOnly}
          />
        ) : (
          <div />
        )}{" "}
        <AnimationSelectBox
          width="100%"
          height="40px"
          placeholder={txt.crco.costLevel}
          state={COLevel.id}
          setState={setCOLevel}
          required
          readOnly={readOnly}
        >
          <option value="1">Proto</option>
          <option value="2">P1</option>
          <option value="3">P2</option>
          <option value="4">SOP</option>
        </AnimationSelectBox>
        <AnimationDateInput
          width="100%"
          height="40px"
          placeholder={txt.crco.coTiming}
          state={COTiming}
          setState={setCOTiming}
          readOnly={readOnly}
        />
        <div className="checkBox">
          <CheckBox
            readOnly={readOnly}
            title={txt.crco.coAffect}
            width="100%"
            height="200px"
            state={COAffect}
            setstate={setCOAffect}
            checkList={txt.crco.coCheckList}
            required
          />
        </div>
        <AnimationSelectBox
          width="100%"
          height="40px"
          placeholder={txt.crco.coImportance}
          state={COImportance.id}
          setState={setCOImportance}
          required
          readOnly={readOnly}
        >
          <option value="1">{txt.high}</option>
          <option value="2">{txt.mid}</option>
          <option value="3">{txt.low}</option>
        </AnimationSelectBox>
      </div>

      <div className="blueBox">
        <AnimationInput
          width="50%"
          height="40px"
          placeholder={txt.crco.coTitle}
          state={COTitle}
          setState={setCOTitle}
          readOnly={readOnly}
          required
        />
        <TextBox
          width="100%"
          height="100px"
          placeholder={txt.crco.coContent}
          required
          state={COBody}
          setState={setCOBody}
          readOnly={readOnly}
        />
      </div>
      <br />
      <SearchInput
        width="100%"
        height="40px"
        placeholder="Related CR"
        onClick={() => {
          setisModalBox(true);
          setmodalType("relatedCR");
        }}
        readOnly={readOnly}
        backgroundColor="var(--eciBlue)"
      />
      <Table width="100%" minHeight="120px">
        <TableIndexRow
          widthArray={[2, 2, 1, 1, 1, 2]}
          itemArray={txt.crco.relCRResultIndexArray}
        />
        {relatedCRRows}
      </Table>
      <br />
      <SearchInput
        required
        width="100%"
        height="40px"
        placeholder="AffectedItem"
        onClick={() => {
          setisModalBox(true);
          setmodalType("affectedItem");
        }}
        readOnly={readOnly}
        backgroundColor="var(--eciBlue)"
      />
      <Table width="100%" minHeight="120px" innerWidth="800px">
        <TableIndexRow
          widthArray={[3, 3, 3, 4, 2, 4]}
          width="800px"
          itemArray={txt.crco.aiResultIndexArray}
        />
        {affectedItemRows}
      </Table>
    </COInformationSectionStyle>
  );
}
