import React, { useState } from "react";
import styled from "styled-components";
import BOMModuleStore from "../store/BOMModuleStore";
import BOMNumberRow from "./BOMNumberRow";

const BOMNumberTreeStyle = styled.div`
  width: 600px;
  position: relative;

  span {
    cursor: pointer;
  }
  .numberRow {
    display: flex;
    align-items: center;
    .plus {
      margin-bottom: 20px;
    }
  }
  .indentBox {
    margin-left: ${(props) => props.indent};
  }
  .blank {
    width: 25px;
  }
  button {
    span {
      color: var(--eciBlue);
      font-size: 40px;
      transform: translate(3px, -10px);
    }
    margin-left: 8px;
    height: 40px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transform: rotate(${(props) => (props.isToggle ? "-90deg" : "")})
      translate(5px, 10px);
    background-color: transparent;
    border: none;
    outline: none;
  }
  .vertical {
    border-left: solid 1px black;
    opacity: ${(props) => (props.line ? 1 : 0)};

    height: calc(100% - 80px);
    position: absolute;
    top: 40px;
    left: 25px;
  }
  .eraseVertical {
    border-left: solid 1px var(--lightGray);
    opacity: ${(props) => (props.line ? 1 : 0)};

    height: calc(100% - 60px);
    position: absolute;
    top: 20px;
    left: -5px;
  }
  .horizon {
    opacity: ${(props) => (props.leftLine ? 1 : 0)};

    border-bottom: solid 1px black;
    width: 18px;
    position: absolute;
    top: 20px;
    left: -5px;
  }
`;
export default function BOMNumberTree({
  data,
  indent,
  readOnly,
  indexArray,
  searchOnly,
  addTreeState,
  setTreeState,
  setfold,
  deletefold,
  last,
}) {
  const childTree =
    data &&
    data.children &&
    data.children.map((item, i) => {
      return (
        <BOMNumberTree
          key={i}
          data={item}
          indent={indent}
          indexArray={[...indexArray, i]}
          readOnly={readOnly}
          searchOnly={searchOnly}
          addTreeState={addTreeState}
          setTreeState={setTreeState}
          setfold={setfold}
          deletefold={deletefold}
          last={i + 1 === data.children.length}
        />
      );
    });
  const { setsearchBoxIndexArray } = BOMModuleStore();
  const [isToggle, setisToggle] = useState(false);
  const fold = () => {
    setisToggle(!isToggle);
    if (!isToggle) {
      setfold(indexArray);
    } else {
      deletefold(indexArray);
    }
  };
  return (
    <BOMNumberTreeStyle
      indent={indent}
      isToggle={isToggle}
      line={data && data.children && data.children[0]}
      leftLine={indexArray.length !== 1}
    >
      <div className="horizon" />
      <div className="vertical" />
      {last && <div className="eraseVertical" />}
      <div className="numberRow">
        <button type="button" onClick={fold}>
          <span className="material-icons">arrow_drop_down</span>
        </button>
        {!readOnly && data.plusPossible ? (
          <span
            className="material-icons plus"
            onClick={() => {
              addTreeState(...indexArray);
            }}
          >
            add
          </span>
        ) : (
          <span className="blank" />
        )}

        <BOMNumberRow
          searchOnly={searchOnly}
          readOnly={indexArray.length === 1 ? true : readOnly}
          state={data && data.cardNumber} // 수정
          setState={(val) => {
            setTreeState(val, "cardNumber", ...indexArray);
          }}
          openSearchBox={() => {
            setsearchBoxIndexArray(indexArray);
          }}
          width="500px"
          backgroundColor={data && data.gray && "var(--textGray)"}
        />
      </div>

      {data && data.children && data.children[0] && !isToggle ? (
        <div className="indentBox"> {childTree} </div>
      ) : (
        ""
      )}
    </BOMNumberTreeStyle>
  );
}
