import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SelectBox from "../atom/SelectBox";
import TableIndexRow from "../atom/TableIndexRow";
import TableRow from "../atom/TableRow";
import commonStore from "../store/commonStore";
import ItemCategoryLv1 from "./ItemCategoryLv1";
import SearchBar from "./SearchBar";
import Table from "./Table";
import txt from "../Text";

const ItemSearchBoxStyle = styled.div`
  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height});
  padding-top: 24px;
  display: flex;
  display: ${(props) => (props.activate ? "" : "none")};
  .left {
    margin-left: 24px;
  }
  .right {
    margin-left: 24px;
    width: 100%;
  }
`;
function ItemSearchBox({
  width,
  height,
  setitemClassification,
  activate,
  document,
}) {
  const tokenAxios = commonStore((state) => state.tokenAxios);
  const [classification, setclassification] = useState([]);
  const [selectedClassArray, setselectedClassArray] = useState([]);
  const [searchText, setsearchText] = useState("");
  const itemCategoryList =
    classification &&
    classification.map((item, i) => {
      return (
        <ItemCategoryLv1
          key={i}
          width="120px"
          name={item.name}
          lv2Data={item.c2SelectDtos}
          setselectedClassArray={(val) => {
            setselectedClassArray(val);
          }}
          lv2Only={document}
        />
      );
    });
  const itemRowList =
    Array.isArray(selectedClassArray) &&
    selectedClassArray.map((item, i) => {
      return (
        <TableRow
          key={i}
          onClick={() => {
            if (item.last * 1 > 0) {
              setitemClassification({
                value: item.value,
                classification: item.classification,
                tag: item.tag ? item.tag : "",
              });
            }
          }}
          widthArray={[1, 1, 1]}
          itemArray={[
            item.value && item.value.split("/")[0],
            item.value && item.value.split("/")[1],
            item.value && item.value.split("/").slice(2).join("/"),
            item.tag && item.tag.name,
          ]}
        />
      );
    });
  const getItemList = async () => {
    const response = await tokenAxios.get(
      document ? `doc/classification1` : `/classification1`
    );
    setclassification(response.data.result.data);
  };
  const searchFromTree = (Data, Textt) => {
    const tempClassArr = [];
    //  2lv도 찾아야해
    const callTree = (data, text) => {
      if (data) {
        data.forEach((item) => {
          if (item.value && item.value.match(text) && item.last === 1) {
            const newVal = {
              value: item.value,
              classification: item.classification,
              tag: item.c3SelectDtos && item.c3SelectDtos[0],
              last: item.last,
            };
            if (document) {
              tempClassArr.push(newVal);
            } else {
              tempClassArr.push(newVal);
            }
          }
          const children = [item.c2SelectDtos, item.c3SelectDtos].filter(
            (a) => a && a[0]
          );
          callTree(children[0], text);
        });
      }
    };
    callTree(Data, Textt);
    setselectedClassArray(tempClassArr);

    // console.log(children);
  };
  useEffect(() => {
    getItemList();
  }, []);
  useEffect(() => {
    if (searchText) {
      searchFromTree(classification, searchText);
    }
  }, [searchText]);
  return (
    <ItemSearchBoxStyle width={width} height={height} activate={activate}>
      <div className="left">{itemCategoryList}</div>
      <div className="right">
        <div className="searchPart">
          <SearchBar
            width="170px"
            height="30px"
            placeholder="Search Items"
            state={searchText}
            setState={setsearchText}
          />
          <br />
        </div>
        <br />
        <Table width="100%" minHeight="200px">
          <TableIndexRow
            widthArray={[1, 1, 1]}
            itemArray={
              document ? txt.doc.searchBoxIndexArray : ["1Lv", "2LV", "3Lv"]
            }
          />
          {itemRowList}
        </Table>
      </div>
    </ItemSearchBoxStyle>
  );
}
export default React.memo(ItemSearchBox);
