import React, { useState } from "react";
import styled from "styled-components";

const ClassificationSelectTreeStyle = styled.div`
  .titlepb {
    display: flex;
    height: ${(props) => props.height};
    line-height: ${(props) => props.height};
    color: ${(props) => props.color};
    background-color: ${(props) => props.selected && "var(--skyBlue)"};
    padding-left: 12px;
    text-decoration: ${(props) => props.selected && "underline"};
    font-size: 12px;
    font-weight: ${(props) => props.weight};
    cursor: pointer;
    .arrow {
      width: 30px;
      height: 30px;
      transform: rotate(${(props) => (props.isFold ? "-90deg" : 0)});
      /* transition: 0.1s all; */
    }
  }

  border-bottom: ${(props) => props.border} 2px var(--eciBlue);

  .childContainer {
    border-top: solid 1px var(--textGray);
    padding-left: 24px;
  }
  .blank {
    width: 18px;
  }
`;
const Row = styled.div``;
export default function ClassificationSelectTree({
  child,
  title,
  last,
  depth,
  id,
  data,

  treeId,
  onClick,
  currentState,
}) {
  const [isFold, setisFold] = useState(true);
  const childList =
    child &&
    child.map((object, i) => {
      return (
        <ClassificationSelectTree
          key={`${object.id}${depth}`}
          depth={depth + 1}
          title={object.name}
          child={object.children || object.c3SelectDtos || object.c2SelectDtos}
          last={object.last}
          id={object.id}
          data={object}
          treeId={`${treeId}${i}`}
          onClick={onClick}
          currentState={currentState}
        />
      );
    });
  return (
    <ClassificationSelectTreeStyle
      isFold={isFold}
      selected={currentState && currentState.name === data.name}
      height={depth > 0 ? "32px" : "44px"}
      weight={depth > 0 ? 100 : 600}
      border={depth > 0 ? "" : "solid"}
      color={
        last && currentState && currentState.name !== data.name
          ? "var(--textDarkGray)"
          : "var(--eciBlue)"
      }
    >
      <div
        className="titlepb"
        onClick={() => {
          setisFold((state) => !state);

          onClick({ ...data, depth });
        }}
      >
        {!last ? (
          <div className="arrow material-icons">expand_more</div>
        ) : (
          <div className="blank" />
        )}
        {title}
      </div>
      {!isFold && <div className="childContainer">{childList}</div>}
    </ClassificationSelectTreeStyle>
  );
}
