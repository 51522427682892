import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import TableIndexRow from "../atom/TableIndexRow";
import TableRow from "../atom/TableRow";
import MemberRow from "./MemberRow";
import commonStore from "../store/commonStore";
import PaginationTable from "./PaginationTable";
import SearchBar from "./SearchBar";
import txt from "../Text";

const AdminMemberSearchBoxStyle = styled.div`
  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height});
  padding: 20px 10px;
  .white {
    border-radius: 10px;
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 10px;
    border: solid 2px
      ${(props) => (props.blueBorder ? "var(--eciBlue)" : "white")};
  }
  .tableSection {
    height: calc(100% - 20px);
  }
`;

export default function AdminMemberSearchBox({
  width,
  height,
  multiple,
  setproperty,
  members,
  deletememberArray,
  state,
  setstate,
}) {
  const { tokenAxios } = commonStore();
  const [searchText, setSearchText] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [pageNum, setPageNum] = useState("0");
  const [rowsPerPage, setrowsPerPage] = useState(7);
  const [dataList, setdataList] = useState([]);
  const indexRow = (
    <TableIndexRow
      widthArray={[1, 3, 3, 3]}
      itemArray={txt.admin.memberSearchIndexArr}
    />
  );

  const itemRowList =
    dataList &&
    dataList.map((item, i) => {
      if (multiple) {
        return (
          <MemberRow
            key={item.id}
            data={item}
            setmemberArray={(value) => {
              //   setstate(value);
              setproperty(value);
            }}
            deletememberArray={(id) => {
              deletememberArray(id);
            }}
            members={members && members.map((id) => id.id)}
          />
        );
      }
      return (
        <TableRow
          key={i}
          onClick={() => {
            setstate(item);
          }}
          widthArray={[1, 3, 3, 3]}
          itemArray={["", item.username, item.department, item.position]}
        />
      );
    });

  useEffect(() => {
    setdataList([]);

    const response = tokenAxios
      .get(
        `members/page?size=${rowsPerPage}&page=${pageNum}&name=${searchText}`
      )
      .then((res) => {
        setdataList([...res.data.contents]);
        setTotalPage(res.data.totalPages);
      });
  }, [setdataList, tokenAxios, pageNum, rowsPerPage, searchText]);
  useEffect(() => {
    setPageNum("0");
  }, [searchText]);
  return (
    <AdminMemberSearchBoxStyle
      width={width}
      height={height}
      blueBorder={multiple}
    >
      <div className="white">
        <div className="searchSection">
          <SearchBar
            width="170px"
            height="30px"
            placeholder="Search Items"
            state={searchText}
            setState={setSearchText}
          />
        </div>
        <div className="tableSection">
          <PaginationTable
            width="100%"
            height="100%"
            noshadow
            totalPage={totalPage}
            setRowsPerPage={setrowsPerPage}
            rowsPerPageDefault={7}
            pageNum={pageNum}
            rowsPerPage={rowsPerPage}
            setpageNum={setPageNum}
          >
            {indexRow}
            {itemRowList}
          </PaginationTable>
        </div>
      </div>
    </AdminMemberSearchBoxStyle>
  );
}
