import React, { useState } from "react";
import styled from "styled-components";
import BoldText from "../atom/BoldText";

const FileDataToggleBoxStyle = styled.div`
  /* width: calc(${(props) => props.width}); */
  width: 500px;
  height: 255px;
  background-color: white;
  border-radius: 6px;
  padding: 5px;
  margin-bottom: 20px;
  .top {
    display: flex;
    align-items: center;
    width: 500px;
    transform: translate(-5px, 0);
    height: 40px;
    border-bottom: solid 1px var(--eciBlue);
    line-height: 40px;
  }
  .button {
    width: 20px;
  }
  button {
    span {
      color: var(--eciBlue);
      font-size: 50px;
    }
    margin-left: 8px;
    height: 40px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transform: rotate(${(props) => (props.isToggle ? "-90deg" : "")});
    background-color: transparent;
    border: none;
    outline: none;
  }
`;
const RowStyle = styled.div`
  width: 100%;
  padding: 0 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 11px;
  color: var(--deemGray);
  .filename {
    width: 55%;
    height: 80%;
    line-height: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 5px;
    background-color: var(--lightGray);
    padding: 0 5px;
  }
`;
export default function FileDataToggleBox({
  width,
  isToggle,
  setisToggle,
  leftLine,
  rightLine,
  verticalLine,
  itemName,
  designData,
  topAssyDrawing,
  dwg,
  step,
  pdf,
  designDataName,
  topAssyDrawingName,
  dwgName,
  stepName,
  pdfName,
  isToggleButton,
}) {
  return (
    <FileDataToggleBoxStyle
      width={width}
      isToggle={isToggle}
      leftLine={leftLine}
      rightLine={rightLine}
      verticalLine={verticalLine}
    >
      <div className="top">
        {isToggleButton ? (
          <button type="button" onClick={() => setisToggle(!isToggle)}>
            <span className="material-icons">arrow_drop_down</span>
          </button>
        ) : (
          <span className="button" />
        )}

        <BoldText fontSize="14px" color="var(--eciBlue)">
          {itemName}
        </BoldText>
      </div>

      <div className="bottom">
        <RowStyle>
          <div>Top Assy Design Data</div>
          <div className="filename">
            <a
              target="_blank"
              href={
                designData && `${process.env.REACT_APP_BASE_URL}/${designData}`
              }
              rel="noreferrer"
            >
              {designDataName}
            </a>
          </div>
        </RowStyle>
        <RowStyle>
          <div>Top Assy Drawing</div>
          <div className="filename">
            <a
              target="_blank"
              href={
                topAssyDrawing &&
                `${process.env.REACT_APP_BASE_URL}/${topAssyDrawing}`
              }
              rel="noreferrer"
            >
              {topAssyDrawingName}
            </a>
          </div>
        </RowStyle>
        <RowStyle>
          <div>DWG</div>
          <div className="filename">
            <a
              target="_blank"
              href={dwg && `${process.env.REACT_APP_BASE_URL}/${dwg}`}
              rel="noreferrer"
            >
              {dwgName}
            </a>
          </div>
        </RowStyle>
        <RowStyle>
          <div>STEP</div>
          <div className="filename">
            <a
              target="_blank"
              href={step && `${process.env.REACT_APP_BASE_URL}/${step}`}
              rel="noreferrer"
            >
              {stepName}
            </a>
          </div>
        </RowStyle>
        <RowStyle>
          <div>PDF</div>
          <div className="filename">
            <a
              target="_blank"
              href={pdf && `${process.env.REACT_APP_BASE_URL}/${pdf}`}
              rel="noreferrer"
            >
              {pdfName}
            </a>
          </div>
        </RowStyle>
      </div>
    </FileDataToggleBoxStyle>
  );
}
