import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";
import commonStore, { userStore } from "./store/commonStore";

import LogIn from "./template/LogIn";
import ItemModule from "./template/ItemModule";
import NavigationBar from "./organism/NavigationBar";
import Header from "./organism/Header";
import Home from "./template/Home";
import ProjectModule from "./template/ProjectModule";
import ProjectListPage from "./organism/page/ProjectListPage";
import ProjectAddPage from "./organism/page/ProjectAddPage";
import ProjectDetailPage from "./organism/page/ProjectDetailPage";
import DesignModule from "./template/DesignModule";
import DesignDetailPage from "./organism/page/DesignDetailPage";
import DesignAddPage from "./organism/page/DesignAddPage";
import DesignReviewPage from "./organism/page/DesignReviewPage";

import ReleaseModule from "./template/ReleaseModule";
import ReleaseDetailPage from "./organism/page/ReleaseDetailPage";
import ReleaseAddPage from "./organism/page/ReleaseAddPage";
import ReleaseReviewPage from "./organism/page/ReleaseReviewPage";
import ReleaseEditPage from "./organism/page/ReleaseEditPage";
import ReleaseListPage from "./organism/page/ReleaseListPage";

import DocumentModule from "./template/DocumentModule";
import DocumentDetailPage from "./organism/page/DocumentDetailPage";
import DocumentAddPage from "./organism/page/DocumentAddPage";
import DocumentReviewPage from "./organism/page/DocumentReviewPage";
import DocumentEditPage from "./organism/page/DocumentEditPage";
import DocumentListPage from "./organism/page/DocumentListPage";

import ProjectEditPage from "./organism/page/ProjectEditPage";
import ProjectRevisePage from "./organism/page/ProjectRevisePage";
import DesignRevisePage from "./organism/page/DesignRevisePage";
import DesignEditPage from "./organism/page/DesignEditPage";
import ItemAddPage from "./organism/page/ItemAddPage";
import ItemListPage from "./organism/page/ItemListPage";
import ItemEditPage from "./organism/page/ItemEditPage";
import ItemDetailPage from "./organism/page/ItemDetailPage";
import BOMModule from "./template/BOMModule";
import ItemReviewPage from "./organism/page/ItemReviewPage";
import CRCOModule from "./template/CRCOModule";
import CRListPage from "./organism/page/CRListPage";
import CRAddPage from "./organism/page/CRAddPage";
import CRDetailPage from "./organism/page/CRDetailPage";
import COListPage from "./organism/page/COListPage";
import COAddPage from "./organism/page/COAddPage";
import CODetailPage from "./organism/page/CODetailPage";
import CREditPage from "./organism/page/CREditPage";
import CRReviewPage from "./organism/page/CRReviewPage";
import COEditPage from "./organism/page/COEditPage";
import COReviewPage from "./organism/page/COReviewPage";
import ItemRevisePage from "./organism/page/ItemRevisePage";
import DocumentRevisePage from "./organism/page/DocumentRevisePage";

import AdminModule from "./template/AdminModule";
import AdminListPage from "./organism/page/AdminListPage";
import SignUp from "./organism/SignUp";
import AdminTaskChangePage from "./organism/page/AdminTaskChangePage";
import NotFound from "./organism/page/NotFound";
import AdminPropChangePage from "./organism/page/AdminPropChangePage";
import LoadingBox from "./organism/LoadingBox";
import BoldText from "./atom/BoldText";
import LoadingSpinner from "./molecule/LoadingSpinner";

const AppStyle = styled.div`
  width: 100%;
  min-height: 100%;
  .route {
    display: flex;
    padding-left: ${(props) => (props.isHover ? "200px" : "160px")};
    transition: 0.45s;
    background-color: var(--lightGray);
  }
  .loadingLogo {
    margin: 30px;
    width: 120px;
  }
`;

export default function App() {
  const {
    isLogged,
    isHover,
    loadingRequestCnt,
    setloadingRequestCnt,
    tokenAxios,
  } = commonStore((state) => ({
    isLogged: state.isLogged,
    isHover: state.isHover,
    loadingRequestCnt: state.loadingRequestCnt,
    setloadingRequestCnt: state.setloadingRequestCnt,
    tokenAxios: state.tokenAxios,
  }));
  const userData = userStore((state) => state.userData);
  const location = useLocation();
  useEffect(() => {
    tokenAxios.isPass2msObj = {};
    tokenAxios.isResponseArrivedObj = {};
    tokenAxios.timeOutIdArr.forEach((id) => {
      clearTimeout(id);
    });
    tokenAxios.timeOutIdArr = [];
    setloadingRequestCnt(0);
  }, [location.pathname]);

  return (
    <div>
      {!isLogged ? (
        <Routes>
          <Route path="/" element={<LogIn />} />
          <Route path="*" element={<NotFound />} />

          {/* <Route path="/signup" element={<SignUp />} /> */}
        </Routes>
      ) : (
        <AppStyle isHover={isHover}>
          <LoadingBox isLoading={loadingRequestCnt !== 0} isDark>
            {/* <LoadingBox isDark> */}
            {/* <LoadingBox isDark> */}
            {/* <LoadingArrowAnimation /> */}
            <LoadingSpinner />
          </LoadingBox>
          <Header />
          <NavigationBar />
          <div className="route">
            <Routes>
              <Route path="/" element={<Home />} />

              <Route path="/item" element={<ItemModule />}>
                <Route path="" element={<ItemListPage />} />
                <Route path="add/:id" element={<ItemAddPage />} />
                <Route path="edit/:itemId" element={<ItemEditPage />} />
                <Route
                  path="edit/:itemId/:reviseId"
                  element={<ItemRevisePage />}
                />

                <Route path="detail/:itemId" element={<ItemDetailPage />} />
                <Route path="review/:itemId" element={<ItemReviewPage />} />
              </Route>

              <Route path="/project" element={<ProjectModule />}>
                <Route path="" element={<ProjectListPage />} />
                <Route path="add/:itemId" element={<ProjectAddPage />} />
                <Route path="edit/:projectId" element={<ProjectEditPage />} />
                <Route
                  path="edit/:itemId/:reviseId"
                  element={<ProjectRevisePage />}
                />
                <Route
                  path="detail/:projectId"
                  element={<ProjectDetailPage />}
                />
              </Route>

              <Route path="/design" element={<DesignModule />}>
                <Route path="" element={<DesignAddPage />} />
                <Route path="add/:itemId" element={<DesignAddPage />} />
                <Route path="edit/:designId" element={<DesignEditPage />} />
                <Route
                  path="edit/:itemId/:reviseId"
                  element={<DesignRevisePage />}
                />
                <Route path="detail/:designId" element={<DesignDetailPage />} />
                <Route path="review/:designId" element={<DesignReviewPage />} />
              </Route>

              <Route path="/BOM/:BOMId" element={<BOMModule />} />

              <Route path="/C" element={<CRCOModule />}>
                {/* cr */}
                <Route path="R/" element={<CRListPage />} />
                <Route path="R/add/:id" element={<CRAddPage />} />
                <Route path="R/edit/:id" element={<CREditPage />} />
                <Route path="R/detail/:id" element={<CRDetailPage />} />
                <Route path="R/review/:id" element={<CRReviewPage />} />
                {/* co */}
                <Route path="O/" element={<COListPage />} />
                <Route path="O/add/:id" element={<COAddPage />} />
                <Route path="O/edit/:id" element={<COEditPage />} />
                <Route path="O/detail/:id" element={<CODetailPage />} />
                <Route path="O/review/:id" element={<COReviewPage />} />
              </Route>

              <Route path="/release" element={<ReleaseModule />}>
                <Route path="" element={<ReleaseListPage />} />
                <Route path="add/:id/:type" element={<ReleaseAddPage />} />
                <Route path="add/:id/" element={<ReleaseAddPage />} />

                <Route path="edit/:releaseId" element={<ReleaseEditPage />} />

                <Route
                  path="detail/:releaseId"
                  element={<ReleaseDetailPage />}
                />
                <Route
                  path="review/:releaseId"
                  element={<ReleaseReviewPage />}
                />
              </Route>
              <Route path="/document" element={<DocumentModule />}>
                <Route path="" element={<DocumentListPage />} />
                <Route path="add/:id" element={<DocumentAddPage />} />
                <Route path="edit/:documentId" element={<DocumentEditPage />} />

                <Route
                  path="detail/:documentId"
                  element={<DocumentDetailPage />}
                />
                <Route
                  path="review/:documentId"
                  element={<DocumentReviewPage />}
                />
                <Route
                  path="revise/:documentId"
                  element={<DocumentRevisePage />}
                />
              </Route>
              {userData.admin && (
                <Route path="/admin" element={<AdminModule />}>
                  <Route path="/admin" element={<AdminListPage />} />
                  <Route path="/admin/add/" element={<AdminTaskChangePage />} />
                  <Route
                    path="/admin/prop/"
                    element={<AdminPropChangePage />}
                  />
                </Route>
              )}

              <Route path="/notFound" element={<NotFound />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </AppStyle>
      )}
    </div>
  );
}
