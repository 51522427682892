import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import StatusTag from "../../atom/StatusTag";
import TableIndexRow from "../../atom/TableIndexRow";
import TableRow from "../../atom/TableRow";
import PaginationTable from "../../molecule/PaginationTable";
import ProjectManageButton from "../../molecule/ProjectManageButton";
import SearchBar from "../../molecule/SearchBar";
import commonStore from "../../store/commonStore";
import projectModuleStore from "../../store/projectModuleStore";
import resultText from "../../Text";
import OptionModal from "../OptionModal";

const ProjectListSectionStyle = styled.div``;
const SearchSectionStyle = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .buttons {
    padding-right: 20px;
  }
`;
const DropDownButtonStyle = styled.div`
  position: relative;
  .button {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    border: solid 1px var(--textDarkGray);
    border-radius: 8px;
    text-align: center;
    line-height: ${(props) => props.height};
    font-size: 12px;
    cursor: pointer;
    background-color: ${(props) =>
      props.isfilterToggle ? "var(--eciBlue)" : "white"};
    color: ${(props) =>
      props.isfilterToggle ? "white" : "var(--textDarkGray)"};
    .icon {
      font-size: 13px;
    }
  }
  .box {
    position: absolute;
    ${(props) =>
      props.right
        ? css`
            right: 0;
          `
        : css`
            left: 0;
          `}
    top: calc(${(props) => props.height} + 4px);
  }
`;

export default function ProjectListSection() {
  const { tokenAxios } = commonStore((state) => ({
    tokenAxios: state.tokenAxios,
  }));
  const navigate = useNavigate();
  const { setcurrentCategory, setprojectNumber } = projectModuleStore();
  //  필터링 관련
  const [isfilterToggle, setisfilterToggle] = useState(false);
  const [ismanageMode, setismanageMode] = useState(false);
  const [manageButtonFlag, setmanageButtonFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [pageNum, setPageNum] = useState("0");
  const [projectArray, setprojectArray] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [searchText, setsearchText] = useState("");
  const projectRowList =
    projectArray &&
    projectArray.map((item, i) => {
      const lowerRows =
        item.itemProjectDashboardDto &&
        item.itemProjectDashboardDto.map((block, ind) => {
          if (ind === 0) {
            return "";
          }
          return (
            <TableRow
              key={ind}
              itemArray={[
                "",
                block.itemNumber,
                block.name,
                block.phase,
                <StatusTag height="30px" width="90%">
                  {block.status}
                </StatusTag>,
                ismanageMode && "",
              ]}
              widthArray={ismanageMode ? [24, 3, 4, 3, 3, 9] : [24, 3, 4, 3, 3]}
              width={ismanageMode ? "1600px" : "100%"}
            />
          );
        });
      return (
        <div
          onClick={() => {
            navigate(`detail/${item.id}`);
          }}
          key={i}
        >
          <TableRow
            itemArray={[
              item.projectNumber,
              item.name,
              item.projectType,
              item.projectLevel,
              item.produceOrganization,
              item.clientOrganization,
              // 이거 나중에 id붙이기
              item.carType && item.carType.name,
              item.clientItemNumber,
              item.itemProjectDashboardDto &&
                item.itemProjectDashboardDto[0].itemNumber,
              item.itemProjectDashboardDto &&
                item.itemProjectDashboardDto[0].name,
              item.itemProjectDashboardDto &&
                item.itemProjectDashboardDto[0].phase,
              <StatusTag height="30px" width="90%">
                {item.itemProjectDashboardDto[0].status}
              </StatusTag>,
              ismanageMode && (
                <ProjectManageButton
                  disable={false}
                  isactive={item.delete}
                  onClick={async (e) => {
                    e.stopPropagation();

                    await tokenAxios.put(`/project-delete/${item.id}`);
                    setmanageButtonFlag(!manageButtonFlag);
                  }}
                >
                  {resultText.delete}
                </ProjectManageButton>
              ),
              ismanageMode && (
                <ProjectManageButton
                  disable={item.delete}
                  isactive={item.dropped}
                  onClick={async (e) => {
                    e.stopPropagation();

                    await tokenAxios.put(`/project-drop/${item.id}`);
                    setmanageButtonFlag(!manageButtonFlag);
                  }}
                >
                  Drop
                </ProjectManageButton>
              ),
              ismanageMode && (
                <ProjectManageButton
                  disable={item.delete || item.dropped}
                  isactive={item.pending}
                  onClick={async (e) => {
                    e.stopPropagation();

                    await tokenAxios.put(`/project-pending/${item.id}`);
                    setmanageButtonFlag(!manageButtonFlag);
                  }}
                >
                  Pending
                </ProjectManageButton>
              ),
            ]}
            widthArray={
              ismanageMode
                ? [3, 3, 3, 3, 3, 3, 3, 3, 3, 4, 3, 3, 3, 3, 3]
                : [3, 3, 3, 3, 3, 3, 3, 3, 3, 4, 3, 3]
            }
            width={ismanageMode ? "1600px" : "100%"}
          />
          {lowerRows}
        </div>
      );
    });

  useEffect(() => {
    setcurrentCategory("List");
    try {
      tokenAxios
        .get(
          `/project${
            ismanageMode ? "/management" : "/page"
          }?size=${rowsPerPage}&page=${pageNum}&name=${searchText}`
        )
        .then((res) => {
          setprojectArray(res.data.content);
          setTotalPage(res.data.totalPages);
        })
        .catch((err) => {
          //
        });
    } catch (err) {
      // console.log(err);
    }
  }, [setcurrentCategory, pageNum, searchText, rowsPerPage, ismanageMode]);
  useEffect(() => {
    setPageNum("0");
  }, [searchText]);
  useEffect(() => {
    try {
      tokenAxios
        .get(
          `/project/management?size=${rowsPerPage}&page=${pageNum}&name=${searchText}`
        )
        .then((res) => {
          setprojectArray(res.data.content);
        })
        .catch((err) => {
          //
        });
    } catch (err) {
      // console.log(err);
    }
  }, [manageButtonFlag]);
  return (
    <ProjectListSectionStyle>
      <SearchSectionStyle>
        <SearchBar
          width="170px"
          height="30px"
          placeholder="Search Items"
          state={searchText}
          setState={setsearchText}
        />
        <div className="buttons">
          <DropDownButtonStyle
            width="80px"
            height="30px"
            right
            onClick={() => setisfilterToggle(!isfilterToggle)}
            isfilterToggle={isfilterToggle}
          >
            <div
              className="button"
              onClick={() => {
                setisfilterToggle(!isfilterToggle);
              }}
            >
              <span className="material-icons icon">filter_alt</span>
              Filters
            </div>
            {isfilterToggle && (
              <div className="box">
                <OptionModal
                  width="200px"
                  title="Refine Results"
                  uiObject={{
                    "Project Manager": {
                      "Show Every Project": false,
                      "Managing Projects": true,
                    },
                  }}
                  setisModal={setisfilterToggle}
                  setStateList={[setismanageMode]}
                />
              </div>
            )}
          </DropDownButtonStyle>
        </div>
      </SearchSectionStyle>
      <PaginationTable
        width="100%"
        minHeight="300px"
        innerWidth={ismanageMode ? "1600px" : "100%"}
        totalPage={totalPage}
        rowsPerPageDefault={10}
        pageNum={pageNum}
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        setPageNum={setPageNum}
        noshadow
      >
        <TableIndexRow
          itemArray={
            ismanageMode
              ? resultText.project.projectListManageIndexArray
              : resultText.project.projectListIndexArray
          }
          widthArray={
            ismanageMode
              ? [3, 3, 3, 3, 3, 3, 3, 3, 3, 4, 3, 3, 3, 3, 3]
              : [3, 3, 3, 3, 3, 3, 3, 3, 3, 4, 3, 3]
          }
          width={ismanageMode ? "1600px" : "100%"}
        />
        {projectRowList}
      </PaginationTable>
    </ProjectListSectionStyle>
  );
}
