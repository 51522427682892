import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import releaseModuleStore from "../../store/releaseModuleStore";
import ReleaseInformationSection from "../ReleaseInformationSection";

import Button from "../../atom/Button";
import SearchBox from "../../molecule/SearchBox";
import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";
import LoadingBox from "../LoadingBox";
import LoadingArrowAnimation from "../../molecule/LoadingArrowAnimation";
import BoldText from "../../atom/BoldText";
import AttachmentSection from "../AttachmentSection";
import Table from "../../molecule/Table";
import TableIndexRow from "../../atom/TableIndexRow";
import SearchInput from "../../molecule/SearchInput";
import TableRow from "../../atom/TableRow";
import RouteInitSection from "../RouteInitSection";
import txt from "../../Text";

const ReleaseAddPageStyle = styled.div`
  display: flex;
  position: relative;
  .tempSave {
    height: 40px;

    position: absolute;
    top: -40px;
    left: calc(58% - 110px);
    display: flex;
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`;
const FormSectionStyle = styled.div`
  width: 58%;
  height: calc(100vh - 230px);
  overflow: scroll;
  padding-left: 18%;
  padding-right: 1.5%;
  padding-top: 20px;
  .gap {
    height: 45px;
  }
  ::-webkit-scrollbar {
    height: 7px;
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
  .delete {
    cursor: pointer;
  }
`;
const SearchBoxSectionStyle = styled.div`
  width: 42%;
`;

const ReleaseFromStyle = styled.div``;
function ReleaseAddPage() {
  const { tokenAxios } = commonStore((state) => ({
    tokenAxios: state.tokenAxios,
  }));

  const {
    initreleaseModule,
    // releaseInfo
    releaseId,
    setreleaseId,
    releaseNumber,
    releaseItemId, // {id,name}
    setreleaseItemId,
    releaseTitle,
    releaseType,
    releaseContent,
    releaseCoId, // {id,name}
    setreleaseCoId,
    releaseOrganizationId,
    setreleaseOrganizationId,
    setreleaseType,
    // attachment
    attachmentFileArray,
    attachmentTagArray,
    attachmentCommentArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    // seaechbox
    searchBoxType,
    setsearchBoxType,
    setsearchBoxProperty,
    deletemember,
    // route
    routeNumber,
    routeData,
    setrouteData,
    setrouteNumber,
    members,
    targetMember,
    settargetMember,
    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // etc

    setcurrentCategory,
  } = releaseModuleStore();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [isRouteActivate, setisRouteActivate] = useState(false);
  const [isFirstTempId, setisFirstTempId] = useState(false);
  const formRef = useRef();
  const fromRef = useRef();

  const appendReleaseFormData = () => {
    const formData = new FormData();
    formData.append("releaseType", releaseType); // 보낼때는 releaseType:1, 받을떄, 보여줄때는 {id,name}
    if (releaseCoId.coId || releaseCoId.id) {
      formData.append("releaseCoId", releaseCoId.coId || releaseCoId.id);
    }
    formData.append(
      "releaseItemId",
      releaseItemId.map((item) => item.id)
    );
    formData.append("releaseTitle", releaseTitle);
    formData.append("releaseContent", releaseContent);
    formData.append(
      "releaseOrganizationId",
      releaseOrganizationId.map((item) => item.id)
    );
    // attach
    attachmentFileArray.forEach((file) => {
      formData.append("attachments", file);
    });

    formData.append("attachmentComment", attachmentCommentArray);
    formData.append("tag", attachmentTagArray);
    return formData;
  };

  const saveRelease = async () => {
    setisLoading(true);
    const formData = appendReleaseFormData();
    try {
      let response = "";
      if (!isFirstTempId) {
        response = await tokenAxios.post("/release", formData);
      } else {
        response = await tokenAxios.put(
          `/release/temp/end/${isFirstTempId}`,
          formData
        );
      }
      // const response = await tokenAxios.post("/release", formData);
      setisRouteActivate(true);
      setreleaseId(response.data.result.data.id);
      // route부분으로 스크롤
      setrouteNumber(response.data.result.data.routeId);
      setisLoading(false);
    } catch (err) {
      if (err.response.data.result) {
        console.log(err.response);
        alert(err.response.data.result.msg);
      } else {
        console.log(err.response);
        navigate("/notFound");
      }
      setisRouteActivate(false);
    }
  };
  const tempSave = async () => {
    setisLoading(true);
    const formData = appendReleaseFormData();
    try {
      if (isFirstTempId) {
        const response = await tokenAxios.put(
          `/release/temp/${isFirstTempId}`,
          formData
        );
      } else {
        const response = await tokenAxios.post("/release/temp", formData);
        setisFirstTempId(response.data.result.data.id);
      }
      alert(txt.approveDone);

      setisLoading(false);
    } catch (err) {
      if (err.response.data.result) {
        console.log(err.response);
        alert(err.response.data.result.msg);
      } else {
        console.log(err.response);
        navigate("/notFound");
      }
    }
  };

  const params = useParams();
  const getItemCo = async (id) => {
    const url = releaseType === "1" ? "item" : "co";
    // const url = params.type&&params === "1" ? "item" : "co";
    if (releaseType && releaseType === "1") {
      setreleaseType("1");
    } else {
      setreleaseType("2");
    }
    const response = await tokenAxios.get(`${url}/${id}`);

    if (releaseType === "1") {
      setreleaseItemId([response.data.result.data]);
    } else if (releaseType === "2") {
      setreleaseCoId([response.data.result.data]);
    }
  };
  useEffect(() => {
    setcurrentCategory("Add");
    if (params.id !== "new") {
      getItemCo(params.id);
    }
    return () => {
      initreleaseModule();
    };
  }, [setcurrentCategory]);

  return (
    <ReleaseAddPageStyle>
      <div className="tempSave">
        {!isRouteActivate && (
          <Button
            type="button"
            onClick={tempSave}
            backgroundColor="transparent"
            fontSize="12px"
            color="var(--textDarkGray)"
          >
            {txt.saveAsDraft}{" "}
          </Button>
        )}
        <Button
          type="button"
          backgroundColor="transparent"
          color="var(--textDarkGray)"
          fontSize="12px"
          onClick={() => {
            navigate("/release");
          }}
        >
          <span className="material-icons">close</span>
        </Button>
      </div>
      <LoadingBox isLoading={isLoading}>
        <LoadingArrowAnimation />
        <BoldText fontSize="26px" color="var(--eciBlue)">
          S a v i n g
        </BoldText>
        <br />
        <br />
        Please wait while we set
        <br />
        thing up for you!
      </LoadingBox>
      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, txt.rel.relInfo],
            [attachmentRef, txt.rel.relAttachment],
            [fromRef, txt.rel.relFrom],
            [routeRef, txt.rel.routeInfo],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>
      <FormSectionStyle ref={formRef}>
        <ReleaseInformationSection readOnly={isRouteActivate} />

        <AttachmentSection
          readOnly={isRouteActivate}
          attachmentFileArray={attachmentFileArray}
          setattachmentFileArray={setattachmentFileArray}
          attachmentTagArray={attachmentTagArray}
          setattachmentTagArray={setattachmentTagArray}
          attachmentCommentArray={attachmentCommentArray}
          setattachmentCommentArray={setattachmentCommentArray}
          setattachmentRef={setattachmentRef}
          title={txt.rel.relAttachment}
          tagOptionList={txt.rel.tagOptionList}
          deletedFileIds={[]}
          attachmentIdArray={[]}
          attachmentOriginArray={[]}
          setattachmentIdArray={() => {}}
          setattachmentOriginArray={() => {}}
          attachmentUploaderArray={[]}
          setattachmentUploaderArray={() => {}}
          attachmentDateArray={[]}
          setattachmentDateArray={() => {}}
        />
        <br />
        <ReleaseFromStyle ref={fromRef}>
          <BoldText fontSize="14px" color="var(--eciBlue)">
            {txt.rel.relFrom}{" "}
          </BoldText>
          <br />
          <SearchInput
            required
            width="100%"
            height="40px"
            placeholder={txt.rel.relPart}
            onClick={() => {
              setsearchBoxType("releaseOrganizationId");
            }}
            backgroundColor="var(--eciBlue)"
          />
          <Table width="100%" minHeight="120px">
            <TableIndexRow widthArray={[1]} itemArray={txt.rel.relIndexArray} />
            {releaseOrganizationId &&
              [...new Set(releaseOrganizationId)].map((item, i) => {
                const onDelete = () => {
                  const tmpArr = [...releaseOrganizationId];
                  tmpArr.splice(i, 1);
                  setreleaseOrganizationId(tmpArr);
                };
                return (
                  <TableRow
                    key={i}
                    widthArray={[9, 1]}
                    itemArray={[
                      item.name,
                      <span
                        className="material-icons delete"
                        onClick={onDelete}
                      >
                        delete
                      </span>,
                    ]}
                  />
                );
              })}
          </Table>
        </ReleaseFromStyle>
        <div className="gap" />
        {isRouteActivate ? (
          ""
        ) : (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveRelease}
            condition={
              !!releaseType &&
              !!releaseTitle &&
              !!releaseContent &&
              (releaseType === "1" ? !!releaseCoId : !!releaseItemId) &&
              releaseOrganizationId &&
              releaseOrganizationId.length > 0
            }
          >
            {txt.saveAndRoute}{" "}
          </Button>
        )}

        <RouteInitSection
          activate={isRouteActivate}
          itemType="release"
          setrouteRef={setrouteRef}
          itemId={releaseId}
          setsearchBoxType={setsearchBoxType}
          targetMember={targetMember}
          settargetMember={settargetMember}
          members={members}
          url="/route/release"
        />
      </FormSectionStyle>
      <SearchBoxSectionStyle>
        <SearchBox
          width="100%"
          height="100vh - 218px"
          type={searchBoxType}
          setproperty={setsearchBoxProperty}
          propertyIndex={targetMember}
          members={members}
          deletememberArray={deletemember}
        />
      </SearchBoxSectionStyle>
    </ReleaseAddPageStyle>
  );
}
export default React.memo(ReleaseAddPage);
