import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import BoldText from "../../atom/BoldText";
import Button from "../../atom/Button";

import LoadingArrowAnimation from "../../molecule/LoadingArrowAnimation";
import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";
import designModuleStore from "../../store/DesignModuleStore";
import AttachmentSection from "../AttachmentSection";
import DesignInformationSection from "../DesignInformationSection";
import LoadingBox from "../LoadingBox";
import RouteSection from "../RouteSection";
import resultText from "../../Text";

const DesignAddPageStyle = styled.div`
  display: flex;
  position: relative;
  .tempSave {
    height: 40px;

    position: absolute;
    top: -40px;
    left: calc(58% - 110px);
    display: flex;
  }
  .retry {
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
    text-decoration: none;
    color: var(--textDarkGray);
  }
  padding-top: 20px;
  .x {
    width: 100%;
    cursor: pointer;
    font-size: 24px;
    text-align: end;
    color: black;
    padding-top: 12px;
    padding-right: 18px;
    font-weight: 600;
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  left: 0;
`;
const FormSectionStyle = styled.div`
  padding-left: 18%;
  padding-right: 1.5%;

  width: 100%;
  height: calc(100vh - 280px);
  overflow-y: scroll;
  overflow-x: visible;
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }

  .gap {
    height: 45px;
  }
  .att {
    width: 50%;
  }
`;

export default function DesignAddPage() {
  const { tokenAxios } = commonStore();

  const {
    // item
    designItemId,
    designId,
    setdesignId,
    designItemName,
    cadFileList,
    setdesignItemInfo,
    uploaderType,
    // attachment
    attachmentFileArray,
    attachmentTagArray,
    attachmentCommentArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    // route
    routeNumber,
    routeData,
    setrouteNumber,
    setrouteData,
    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // etc
    isMatchConfirmed,
    initdesignModule,
    roomId,
    setcadFileList,
    setcadFile,
    cadFile,
    choiceDesignId,
  } = designModuleStore();
  const [isRouteActivate, setisRouteActivate] = useState(false);
  const formRef = useRef();
  const navigate = useNavigate();
  const params = useParams();
  const [isTempLoading, setisTempLoading] = useState(false);
  const [isFirstTempId, setisFirstTempId] = useState(false);

  const setItemInfo = useCallback(
    async (itemID) => {
      const response = await tokenAxios.get(`item/${itemID}`);

      const { data } = response.data.result;
      setdesignItemInfo({
        designItemId: data.id,
        designItemName: data.name,
        designItemNumber: data.itemNumber,
        designItemImageAddress:
          data.thumbnail.imageAdress &&
          `${process.env.REACT_APP_BASE_URL}/${data.thumbnail.imageAdress}`,
      });
    },
    [tokenAxios, setdesignItemInfo]
  );
  const saveDesign = async () => {
    setisTempLoading(true);

    const formData = new FormData();
    formData.append("itemId", designItemId);
    formData.append("designId", designId);
    formData.append("designContent", JSON.stringify(cadFileList));

    let status;
    if (uploaderType === "Catia") {
      status = "cad";
    } else if (uploaderType === "Cadian") {
      status = "cadian";
    } else {
      status = "link";
    }
    formData.append("status", status);
    if (uploaderType === "Link") {
      formData.append("choiceDesignId", choiceDesignId);
    }

    attachmentFileArray.forEach((file) => {
      formData.append("addedAttachments", file);
    });
    formData.append("addedAttachmentComment", attachmentCommentArray);
    formData.append(
      "addedTag",
      attachmentTagArray.map((item) => (item.id ? item.id : item))
    );
    formData.append("deletedAttachments", "");
    try {
      let response = "";
      if (!isFirstTempId) {
        response = await tokenAxios.put("/design", formData);
      } else {
        response = await tokenAxios.put(
          `/design/temp/end/${isFirstTempId}`,
          formData
        );
      }
      // const response = await tokenAxios.post("/design", formData);
      setisRouteActivate(true);
      setrouteNumber(response.data.result.data.routeId);
      setisTempLoading(false);
    } catch (error) {
      if (error.response.data.result) {
        console.log(error.response);
        alert(error.response.data.result.msg);
      } else {
        console.log(error);
        navigate("/notFound");
      }
      setisRouteActivate(false);
    }
  };
  const tempSave = async () => {
    setisTempLoading(true);
    const formData = new FormData();
    formData.append("itemId", designItemId);
    formData.append("designId", designId);
    let status;
    if (uploaderType === "Catia") {
      status = "cad";
    } else if (uploaderType === "Cadian") {
      status = "cadian";
    } else {
      status = "link";
    }
    formData.append("status", status);
    if (uploaderType === "Link") {
      formData.append("choiceDesignId", choiceDesignId);
    }
    formData.append("designContent", JSON.stringify(cadFileList));
    attachmentFileArray.forEach((file) => {
      formData.append("addedAttachments", file);
    });
    formData.append("addedAttachmentComment", attachmentCommentArray);
    formData.append(
      "addedTag",
      attachmentTagArray.map((item) => (item.id ? item.id : item))
    );
    formData.append("deletedAttachments", "");
    try {
      if (isFirstTempId) {
        const response = await tokenAxios.put(
          `/design/temp/${isFirstTempId}`,
          formData
        );
      } else {
        const response = await tokenAxios.put("/design/temp", formData);
        setisFirstTempId(response.data.result.data.id);
      }
      alert(resultText.approveDone);

      setisTempLoading(false);
    } catch (error) {
      if (error.response.data.result) {
        console.log(error.response);
        alert(error.response.data.result.msg);
      } else {
        console.log(error.response);
        navigate("/notFound");
      }
    }
  };

  useEffect(() => {
    setItemInfo(params.itemId);
    return () => {
      initdesignModule();
    };
  }, [initdesignModule, setItemInfo]);

  // const getSocket = async () => {
  //   const response = await tokenAxios.get(`/design-content/${designItemId}`);
  //   const message = JSON.parse(response.data.result.data);
  //   if (message.title === " ") {
  //     setisTempLoading(false);
  //     alert(resultText.design.checkNumber);
  //   } else {
  //     setcadFile(message.title);
  //     setcadFileList(message);
  //     setdesignId(message.desginId);
  //     setisTempLoading(false);
  //   }
  // };
  return (
    <DesignAddPageStyle>
      <div className="tempSave">
        {!isRouteActivate && (
          <Button
            type="button"
            onClick={tempSave}
            backgroundColor="transparent"
            fontSize="12px"
            color="var(--textDarkGray)"
          >
            {resultText.saveAsDraft}
          </Button>
        )}
      </div>
      <LoadingBox isLoading={isTempLoading}>
        <div
          className="x"
          onClick={() => {
            setisTempLoading(false);
          }}
        >
          x
        </div>
        <LoadingArrowAnimation />
        <BoldText fontSize="26px" color="var(--eciBlue)">
          S a v i n g
        </BoldText>{" "}
        <br />
        <br />
        {isTempLoading === "catia" ? (
          <a
            href={
              uploaderType === "Catia"
                ? `catiauploader:${designItemId}/${roomId}/${
                    process.env.REACT_APP_BASE_URL.split("//")[1].split(":")[0]
                  }`
                : `dwgUploader:${designItemId}/${roomId}`
            }
          >
            <div className="retry">retry</div>
          </a>
        ) : (
          <>
            Please wait while we set
            <br />
            thing up for you!
          </>
        )}
      </LoadingBox>
      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, resultText.design.designInformation],
            [attachmentRef, resultText.design.designAttachment],
            [routeRef, resultText.design.routeInformation],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>

      <FormSectionStyle ref={formRef}>
        <DesignInformationSection
          readOnly={isRouteActivate}
          setisTempLoading={setisTempLoading}
          designItemId={designItemId || params.itemId || params.designId}
        />
        <div className="att">
          <AttachmentSection
            readOnly={isRouteActivate}
            attachmentFileArray={attachmentFileArray}
            setattachmentFileArray={setattachmentFileArray}
            attachmentTagArray={attachmentTagArray}
            setattachmentTagArray={setattachmentTagArray}
            attachmentCommentArray={attachmentCommentArray}
            setattachmentCommentArray={setattachmentCommentArray}
            setattachmentRef={setattachmentRef}
            title={resultText.design.designAttachment}
            tagOptionList={[
              { id: "6", name: resultText.design.drReport },
              { id: "7", name: resultText.design.drProceedings },
              { id: "8", name: resultText.design.change },
            ]}
            deletedFileIds={[]}
            attachmentIdArray={[]}
            attachmentOriginArray={[]}
            setattachmentIdArray={() => {}}
            setattachmentOriginArray={() => {}}
            attachmentUploaderArray={[]}
            setattachmentUploaderArray={() => {}}
            attachmentDateArray={[]}
            setattachmentDateArray={() => {}}
          />
          <div className="gap" />
          {isRouteActivate ? (
            ""
          ) : (
            <Button
              backgroundColor="var(--eciBlue)"
              width="100%"
              height="30px"
              color="white"
              onClick={saveDesign}
              condition={
                uploaderType === "Catia" ? isMatchConfirmed : !!cadFile
              }
            >
              {resultText.saveAndRoute}
            </Button>
          )}
          <RouteSection
            activate={isRouteActivate}
            routeNumber={routeNumber}
            setrouteRef={setrouteRef}
            routeData={routeData}
            setrouteData={setrouteData}
          />{" "}
        </div>
      </FormSectionStyle>
    </DesignAddPageStyle>
  );
}
