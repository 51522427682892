import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import TableIndexRow from "../atom/TableIndexRow";
import TableRow from "../atom/TableRow";
import commonStore from "../store/commonStore";
import PaginationTable from "../molecule/PaginationTable";
import SearchBar from "../molecule/SearchBar";
import Input from "../atom/Input";
import DeleteButton from "../atom/DeleteButton";

const PropChangeSearchBoxStyle = styled.div`
  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height});
  padding: 5% 10%;
  .tableSection {
    height: 80%;
  }
  .blueBackground {
    background-color: #f2f4f8;
    height: 30px;
    display: flex;
    align-items: center;
  }
  .addContainer {
    display: flex;
    align-items: center;
  }
  border: solid var(--eciBlue) 1px;
  border-radius: 10px;
  background-color: white;
`;

export default function PropChangeSearchBox({ width, height, type }) {
  const { tokenAxios } = commonStore();
  const [searchText, setSearchText] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [pageNum, setPageNum] = useState("0");
  const [rowsPerPage, setrowsPerPage] = useState(7);
  const [dataList, setdataList] = useState([]);
  const [index, setindex] = useState([]);
  const [code, setcode] = useState("");
  const [name, setname] = useState("");
  const [renderFlag, setrenderFlag] = useState(0);
  const widthArray = [5, 5, 3];

  const indexRow = (
    <TableIndexRow
      widthArray={widthArray}
      itemArray={index && index.length > 2 ? ["code", "name"] : ["name"]}
    />
  );
  const deleteProp = async (propId) => {
    const response = await tokenAxios.delete(`${type}/${propId}`);
    // rerender
    setrenderFlag(renderFlag + 1);
  };
  const addProp = async () => {
    const formdata = new FormData();
    if (code) {
      formdata.append("code", code);
    }
    formdata.append("name", name);

    const response = await tokenAxios.post(`${type}`, formdata);
    // rerender
    setrenderFlag(renderFlag + 1);
  };
  const itemRowList =
    dataList &&
    dataList.map((item, i) => {
      const itemArray = Object.values(item).map((itm) => {
        if (typeof itm === "object" && itm !== null) {
          return itm.value ? itm.value : itm.name;
        }

        return itm;
      });
      itemArray.shift();
      if (index && index.length <= 2) {
        itemArray.push("");
      }
      itemArray.push(
        <DeleteButton
          onDelete={() => {
            deleteProp(item.id); // 이 부분 확인
          }}
        />
      );
      return <TableRow key={i} widthArray={widthArray} itemArray={itemArray} />;
    });
  itemRowList.push(
    <TableRow
      onClick={() => {}}
      widthArray={widthArray}
      itemArray={[
        index && index.length > 2 ? (
          <div className="blueBackground">
            <Input width="100%" height="30px" state={code} setState={setcode} />
          </div>
        ) : (
          <div className="blueBackground">
            <Input width="100%" height="30px" state={name} setState={setname} />
          </div>
        ),
        index && index.length > 2 ? (
          <div className="blueBackground">
            <Input width="100%" height="30px" state={name} setState={setname} />
          </div>
        ) : (
          ""
        ),
        <div className="addContainer">
          <span className="material-icons plus" onClick={addProp}>
            add
          </span>
        </div>,
      ]}
    />
  );

  useEffect(() => {
    setdataList([]);
    setname("");
    setcode("");
    if (type) {
      const newType = type === "supplierId" ? "supplierOrganizationId" : type;

      tokenAxios
        .get(
          `${newType}${
            newType === "members" ? "/page" : ""
          }?size=${rowsPerPage}&page=${pageNum}&name=${searchText}`
        )
        .then((res) => {
          if (newType === "releaseCoId" || newType === "members") {
            setdataList([...res.data.contents]);
            setTotalPage(res.data.totalPages);
            if (newType === "members") {
              setindex(["", ...res.data.indexes]);
            } else {
              setindex(res.data.indexes);
            }
          } else if (newType === "item-candidates") {
            setdataList([...res.data.content]);
            setTotalPage(res.data.totalPages);
            setindex(["itemNumber", "itemName"]);
          } else {
            setdataList([...res.data.result.data.content]);
            setTotalPage(res.data.result.data.totalPages);
            setindex(res.data.result.data.indexes);
          }
        });
    }
  }, [
    setdataList,
    tokenAxios,
    type,
    pageNum,
    rowsPerPage,
    searchText,
    renderFlag,
  ]);
  useEffect(() => {
    setPageNum("0");
  }, [searchText]);

  return (
    <PropChangeSearchBoxStyle width={width} height={height} type={type}>
      <div className="searchSection">
        <SearchBar
          width="170px"
          height="30px"
          placeholder="Search Items"
          state={searchText}
          setState={setSearchText}
        />
        <br />
        <br />
      </div>
      <div className="tableSection">
        <PaginationTable
          height="100%"
          noshadow
          totalPage={totalPage}
          setRowsPerPage={setrowsPerPage}
          rowsPerPageDefault={7}
          pageNum={pageNum}
          rowsPerPage={rowsPerPage}
          setpageNum={setPageNum}
        >
          {indexRow}
          {itemRowList}
        </PaginationTable>
      </div>
    </PropChangeSearchBoxStyle>
  );
}
