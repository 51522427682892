import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { NavLink, Outlet } from "react-router-dom";
import BoldText from "../atom/BoldText";
import commonStore from "../store/commonStore";
import txt from "../Text";

const AdminModuleStyle = styled.div`
  margin-top: 80px;
  width: 100%;
  min-width: 1000px;
  min-height: 580px;
  height: calc(100vh - 80px);

  background-color: var(--lightGray);
  overflow: scroll;

  ::-webkit-scrollbar {
    height: 7px;
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
  position: relative;
`;
const AdminModulePageStyle = styled.div`
  padding-right: 4%;
  width: 100%;
  min-width: 1000px;
  min-height: calc(100vh - 100px);
  //80px는 헤더 높이

  .cont {
    display: flex;
    justify-content: space-between;
    .nav {
      width: 10%;
      padding: 20px;
      .act {
        color: var(--eciBlue);
      }
    }
    .out {
      width: 90%;
    }
  }
`;

const CategoryBar = styled.div`
  width: 100%;
  min-width: 1000px;
  border-bottom: solid 1px var(--eciBlue);

  display: flex;

  .category {
    height: 40px;
    width: 100px;

    font-size: 12px;

    line-height: 40px;
    text-decoration: none;
    transform: translate(0, 1.5px);
    padding-left: 4px;
    border-bottom: solid 3px var(--eciBlue);
    .plus {
      font-size: 14px;
      color: black;
      color: var(--eciBlue);
      margin-right: 10px;
    }
  }
`;

function AdminModule() {
  const { setCurrentModule } = commonStore();

  useEffect(() => {
    setCurrentModule("Admin");
  }, [setCurrentModule]);
  return (
    <AdminModuleStyle>
      <AdminModulePageStyle>
        <br />
        <br />
        <BoldText fontSize="30px" color="var(--eciBlue)">
          {txt.admin.admin}
        </BoldText>
        <br />
        <br />

        <CategoryBar>
          <div className="category">
            <BoldText fontSize="14px" color="var(--eciBlue)">
              {txt.admin.member}
            </BoldText>
          </div>
        </CategoryBar>
        <div className="cont">
          <div className="nav">
            <NavLink
              to="/admin/"
              style={({ isActive }) => ({
                textDecoration: "none",
                fontSize: "12px",
                fontWeight: 700,
                color: isActive ? "var(--eciBlue)" : "var(--textDarkGray)",
                lineHeight: "20px",
              })}
            >
              {txt.admin.memberControll}
            </NavLink>
            <br />

            <NavLink
              to="/admin/add"
              style={({ isActive }) => ({
                textDecoration: "none",
                fontSize: "12px",
                fontWeight: 700,
                color: isActive ? "var(--eciBlue)" : "var(--textDarkGray)",
                lineHeight: "20px",
              })}
            >
              {txt.admin.authorizationChange}
            </NavLink>
            <br />
            <NavLink
              to="/admin/prop"
              style={({ isActive }) => ({
                textDecoration: "none",
                fontSize: "12px",
                fontWeight: 700,
                color: isActive ? "var(--eciBlue)" : "var(--textDarkGray)",
                lineHeight: "20px",
              })}
            >
              {txt.admin.propChange}
            </NavLink>
          </div>
          <div className="out">
            <Outlet />
          </div>
        </div>
      </AdminModulePageStyle>
    </AdminModuleStyle>
  );
}
export default React.memo(AdminModule);
