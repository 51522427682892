import React from "react";
import styled from "styled-components";

const TextBoxStyle = styled.div`
  pointer-events: ${(props) => props.readOnly && "none"};

  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height});
`;
const TextAreaStyle = styled.textarea`
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  resize: none;
  border: none;
  border-radius: 10px;
  padding: 10px;
  outline: var(--eciBlue) 1px solid;
`;

export default function TextBox({
  width,
  height,
  state,
  setState,
  requiredError,
  readOnly,
  required,
  placeholder,
  backgroundColor,
}) {
  const handleText = (e) => {
    e.preventDefault();
    if (setState) {
      setState(e.target.value);
    }
  };
  return (
    <TextBoxStyle width={width} height={height} readOnly={readOnly}>
      <TextAreaStyle
        onChange={handleText}
        placeholder={required && !readOnly ? `${placeholder} *` : placeholder}
        value={state}
        backgroundColor={backgroundColor}
      />
      {requiredError ? <div className="fillIn">fill in</div> : ""}
    </TextBoxStyle>
  );
}
