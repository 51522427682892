import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import PlusButton from "../../atom/PlusButton";
import StatusTag from "../../atom/StatusTag";
import TableIndexRow from "../../atom/TableIndexRow";
import TableRow from "../../atom/TableRow";
import PaginationTable from "../../molecule/PaginationTable";
import SearchBar from "../../molecule/SearchBar";
import commonStore from "../../store/commonStore";
import CRCOModuleStore from "../../store/CRCOModuleStore";
import txt from "../../Text";

const COListSectionStyle = styled.div`
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      border-radius: 100px;
    }
  }
`;
const SearchSectionStyle = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function COListSection() {
  const { tokenAxios } = commonStore((state) => ({
    tokenAxios: state.tokenAxios,
  }));

  const { setcurrentCategory, setCRCONumber } = CRCOModuleStore();

  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [pageNum, setPageNum] = useState("0");
  const [COArray, setCOArray] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [searchText, setsearchText] = useState("");
  const navigate = useNavigate();
  const CRCORowList =
    COArray &&
    COArray.map((item, i) => {
      return (
        <Link
          to={`detail/${item.crId}`}
          key={i}
          style={{ textDecoration: "none" }}
        >
          <TableRow
            key={i}
            itemArray={[
              <div className="img">
                <img
                  src={
                    item.imageAddress &&
                    `${process.env.REACT_APP_BASE_URL}/${item.imageAddress}`
                  }
                  alt=""
                />
              </div>,
              item.crNumber,
              item.crReason && item.crReason.name,
              item.itemNumber,
              item.itemName,
              item.itemClassification && item.itemClassification.value,
              item.itemRevision,
              <StatusTag height="30px" width="90%">
                {item.itemStatus}
              </StatusTag>,
            ]}
            widthArray={[1, 1, 1, 2, 3, 3, 1, 1]}
          />
        </Link>
      );
    });

  useEffect(() => {
    setcurrentCategory("CO");
    try {
      tokenAxios
        .get(`/co/page?size=${rowsPerPage}&page=${pageNum}&name=${searchText}`)
        .then((res) => {
          setCOArray(res.data.content);
          setTotalPage(res.data.totalPages);
        })
        .catch((err) => {
          //
        });
    } catch (err) {
      // console.log(err);
    }
  }, [setcurrentCategory, pageNum, rowsPerPage, searchText]);
  useEffect(() => {
    setPageNum("0");
  }, [searchText]);
  return (
    <COListSectionStyle>
      <SearchSectionStyle>
        <SearchBar
          width="170px"
          height="30px"
          placeholder="Search Items"
          state={searchText}
          setState={setsearchText}
        />
        <div className="buttons">
          {" "}
          <PlusButton
            // width="140px"
            height="30px"
            onClick={() => {
              navigate("add/new");
            }}
          >
            {txt.crco.createNewCo}{" "}
          </PlusButton>
        </div>
      </SearchSectionStyle>
      <PaginationTable
        width="100%"
        minHeight="300px"
        totalPage={totalPage}
        rowsPerPageDefault={10}
        pageNum={pageNum}
        setRowsPerPage={setRowsPerPage}
        setpageNum={setPageNum}
        rowsPerPage={rowsPerPage}
        noshadow
      >
        <TableIndexRow
          itemArray={txt.crco.coListIndexArray}
          widthArray={[1, 1, 1, 2, 3, 3, 1, 1]}
        />
        {CRCORowList}
      </PaginationTable>
    </COListSectionStyle>
  );
}
