import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useParams, Link, useNavigate } from "react-router-dom";
/* eslint-disable */
import { unstable_batchedUpdates } from "react-dom";
import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";

import BoldText from "../../atom/BoldText";
import itemModuleStore from "../../store/itemModuleStore";
import RouteSection from "../RouteSection";
import AttachmentSection from "../AttachmentSection";
import ItemInformationSection from "../ItemInformationSection";
import BOMModuleStore from "../../store/BOMModuleStore";
import Button from "../../atom/Button";
import text from "../../Text";
import Table from "../../molecule/Table";
import TableIndexRow from "../../atom/TableIndexRow";
import TableRow from "../../atom/TableRow";

const ItemDetailPageStyle = styled.div`
  display: flex;
  position: relative;
  .tempSave {
    height: 40px;

    position: absolute;
    top: -40px;
    left: calc(58% - 110px);
    display: flex;
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`;
const FormSectionStyle = styled.div`
  width: 58%;
  height: calc(100vh - 200px);
  overflow: scroll;
  padding-left: 18%;
  padding-right: 1.5%;
  padding-top: 20px;
  .gap {
    height: 45px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
`;
const SearchBoxSectionStyle = styled.div`
  width: 42%;
  position: relative;
  .searchBox {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
const BOMDesignSectionStyle = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  .aBlock {
    width: 50%;
  }
`;
const WhereUseStyle = styled.div``;
export default function ItemDetailPage() {
  const tokenAxios = commonStore((state) => state.tokenAxios);
  const {
    // item
    setitemInfo,
    setitemNumber,
    setitemClassification,
    setitemRevision,

    /// attachment
    attachmentFileArray,
    attachmentIdArray,
    attachmentTagArray,
    attachmentCommentArray,
    attachmentOriginArray,
    attachmentDateArray,
    attachmentUploaderArray,
    attachmentModifiedAtArray,
    setattachmentModifiedAtArray,
    setattachmentDateArray,
    setattachmentUploaderArray,
    setattachmentIdArray,
    setattachmentOriginArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    deletedFileIdArray,
    setdeletedFileIdArray,
    // route
    routeNumber,
    routeData,
    setrouteNumber,
    setrouteData,
    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // etc
    initItemModule,
    isRouteActivate,
    setcurrentCategory,
    whereUse,
  } = itemModuleStore();
  const [isClassificationBox, setisClassificationBox] = useState(false);
  const [bomData, setbomData] = useState({});
  const [designData, setdesignData] = useState({});
  const { setpageType } = BOMModuleStore();
  const formRef = useRef();
  const params = useParams();
  const getData = async (itemId) => {
    const response = await tokenAxios.get(`item/${itemId}`);
    const { data } = response.data.result; // 확인필
    unstable_batchedUpdates(() => {
      setitemInfo({
        itemName: data.name,
        itemNumber: data.itemNumber,
        customItemNumber: data.customItemNumber,
        itemRevision: data.itemRevision,
        typeId: data.itemTypes.id,
        thumbnail: data.thumbnail,
        sharing: data.sharing ? "true" : "false",
        carTypeId: data.carTypeId,
        forming: data.forming,
        curve: data.curve,
        integrate: data.integrate,
        width: data.width,
        height: data.height,
        thickness: data.thickness,
        weight: data.weight,
        importance: data.importance,
        colorId: data.colorId,
        loadQuantity: data.loadQuantity,
        coatingWayId: data.coatingWayId,
        coatingTypeId: data.coatingTypeId,
        modulus: data.modulus,
        screw: data.screw,
        cuttingType: data.cuttingType,
        lcd: data.lcd,
        displaySize: data.displaySize,
        screwHeight: data.screwHeight,
        clientOrganizationId: data.clientOrganizationId,
        supplierOrganizationId: data.supplierOrganizationId,
        makersId: data.makersId,
        partnumbers: data.partnumbers,
        // routeNumbers
        routeNumber: data.routeId > 0 ? data.routeId : "",
        whereUse: data.whereUse,
      });
      setitemClassification(data.classification);
      // attachment
      const tempfileArray = [];
      const temptagArray = [];
      const tempcommentArray = [];
      const temporiginArray = [];
      const tempuploaderArray = [];
      const tempdateArray = [];
      const tempidArray = [];
      const tempdeletedFileArray = [];
      const tempModifiedAtArray = [];

      data.attachments.forEach((item) => {
        tempfileArray.push(item.originName);
        tempcommentArray.push(item.attach_comment);
        temptagArray.push(item.tag);
        temporiginArray.push(item.attachmentaddress);
        tempuploaderArray.push(item.upload);
        tempdateArray.push(item.date);
        tempidArray.push(item.id);
        if (item.deleted) {
          tempdeletedFileArray.push(item.id);
        }
        tempModifiedAtArray.push(item.modifiedAt ? item.modifiedAt : "");
      });
      setattachmentFileArray(tempfileArray);
      setattachmentIdArray(tempidArray);
      setattachmentCommentArray(tempcommentArray);
      setattachmentTagArray(temptagArray);
      setattachmentOriginArray(temporiginArray);
      setattachmentDateArray(tempdateArray);
      setattachmentUploaderArray(tempuploaderArray);
      setdeletedFileIdArray(tempdeletedFileArray);
      setattachmentModifiedAtArray(tempModifiedAtArray);
      // bom, design link
      setbomData(data.bomId);
      setdesignData(data.design);
    });
  };

  const isInit = useRef(false);
  const navigate = useNavigate();
  const whereUseList =
    whereUse &&
    whereUse.map((item) => {
      return (
        <TableRow
          key={item.itemId}
          widthArray={[1, 3, 5, 4]}
          itemArray={[
            "",
            item.itemNumber,
            item.itemName,
            item.itemClassification.value,
          ]}
        />
      );
    });
  useEffect(() => {
    setcurrentCategory("Detail");
    isInit.current = true;

    getData(params.itemId);
    return () => {
      if (isInit.current) {
        initItemModule();
      } else {
        setitemNumber("");
      }
    };
  }, []);
  return (
    <ItemDetailPageStyle>
      <div className="tempSave">
        <Button
          type="button"
          onClick={() => {
            isInit.current = false;
            setitemRevision("A");
            navigate(`/item/add/${params.itemId}`);
          }}
          backgroundColor="transparent"
          fontSize="12px"
          color="var(--textDarkGray)"
        >
          {text.duplicate}
        </Button>
      </div>
      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, text.item.itemInformation],
            [attachmentRef, text.item.itemAttachment],
            [routeRef, text.item.routeInformation],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>
      <FormSectionStyle ref={formRef}>
        <ItemInformationSection
          setisClassificationBox={setisClassificationBox}
          isClassificationBox={isClassificationBox}
          readOnly
        />
        <AttachmentSection
          readOnly
          attachmentFileArray={attachmentFileArray}
          setattachmentFileArray={setattachmentFileArray}
          attachmentTagArray={attachmentTagArray}
          setattachmentTagArray={setattachmentTagArray}
          attachmentCommentArray={attachmentCommentArray}
          attachmentOriginArray={attachmentOriginArray}
          attachmentModifiedAtArray={attachmentModifiedAtArray}
          setattachmentOriginArray={setattachmentOriginArray}
          setattachmentCommentArray={setattachmentCommentArray}
          attachmentDateArray={attachmentDateArray}
          attachmentUploaderArray={attachmentUploaderArray}
          setattachmentDateArray={setattachmentDateArray}
          setattachmentUploaderArray={setattachmentUploaderArray}
          setattachmentRef={setattachmentRef}
          title={text.item.itemAttachment}
          // edit
          attachmentIdArray={attachmentIdArray}
          setattachmentIdArray={setattachmentIdArray}
          deletedFileIds={deletedFileIdArray}
        />

        <div className="gap" />

        <BOMDesignSectionStyle>
          {bomData * 1 > 0 ? (
            <Link to={`/BOM/${bomData}`} className="aBlock" onClick={() => {}}>
              <BoldText color="var(--eciBlue)" fontSize="20px">
                BOM
              </BoldText>
            </Link>
          ) : (
            <div className="aBlock">
              <BoldText
                color={
                  designData.color === "none"
                    ? "transparent"
                    : "var(--disableGray)"
                }
                fontSize="20px"
              >
                BOM{" "}
              </BoldText>
            </div>
          )}

          {!designData || designData.id * 1 > 0 ? (
            <Link
              to={`/design/${designData.responsibleList[0].range}/${designData.id}`}
              className="aBlock"
            >
              <BoldText color="var(--eciBlue)" fontSize="20px">
                Design
              </BoldText>
            </Link>
          ) : (
            <BoldText
              color={
                designData.color === "none"
                  ? "transparent"
                  : "var(--disableGray)"
              }
              fontSize="20px"
            >
              Design
            </BoldText>
          )}
        </BOMDesignSectionStyle>

        <div className="gap" />
        {whereUse && whereUse.length > 0 && (
          <WhereUseStyle>
            <BoldText color="var(--eciBlue)" fontSize="20px">
              {text.item.whereUse}
            </BoldText>
            <br />
            <br />
            <Table>
              <TableIndexRow
                itemArray={text.item.whereUseIndex}
                widthArray={[1, 3, 5, 4]}
              />
              {whereUseList}
            </Table>
          </WhereUseStyle>
        )}

        <div className="gap" />
        {routeNumber * 1 > 0 && (
          <RouteSection
            activate
            routeNumber={routeNumber}
            setrouteRef={setrouteRef}
            routeData={routeData}
            setrouteData={setrouteData}
            readOnly
          />
        )}
      </FormSectionStyle>
    </ItemDetailPageStyle>
  );
}
