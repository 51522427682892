import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import projectModuleStore from "../../store/projectModuleStore";
import ProjectInformationSection from "../ProjectInformationSection";
import RoundTextBox from "../../atom/RoundTextBox";
import Input from "../../atom/Input";

import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";
import RouteSection from "../RouteSection";
import AttachmentSection from "../AttachmentSection";
import RoundImgBox from "../../atom/RoundImgBox";
import resultText from "../../Text";

const ProjectDetailPageStyle = styled.div`
  display: flex;
  position: relative;
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`;
const FormSectionStyle = styled.div`
  width: 58%;
  height: calc(100vh - 230px);
  overflow: scroll;
  padding-left: 18%;
  padding-right: 1.5%;
  padding-top: 20px;
  .gap {
    height: 45px;
  }
  ::-webkit-scrollbar {
    height: 7px;
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
  .member {
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    height: 50px;
  }
  .profile {
    width: 20px;
    height: 20px;
    border-radius: 100px;
    margin-right: 5px;
  }
`;
export default function ProjectDetailPage() {
  const { tokenAxios } = commonStore((state) => ({
    tokenAxios: state.tokenAxios,
  }));
  const {
    // project
    projectName,
    setprojectInfo,
    // item
    setlinkedItemList,
    // attachment
    attachmentFileArray,
    attachmentTagArray,
    attachmentCommentArray,
    attachmentOriginArray,
    attachmentDateArray,
    attachmentUploaderArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    setattachmentOriginArray,
    setattachmentDateArray,
    setattachmentUploaderArray,
    attachmentModifiedAtArray,
    setattachmentModifiedAtArray,
    attachmentIdArray,
    setattachmentIdArray,
    deletedFileIdArray,
    setdeletedFileIdArray,
    // route
    routeNumber,
    routeData,
    setrouteNumber,
    setrouteData,
    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // ect
    initprojectModule,
    setcurrentCategory,
  } = projectModuleStore();
  const formRef = useRef();
  const params = useParams();
  const [creator, setcreator] = useState("");
  const [createdAt, setcreatedAt] = useState("");
  const [modifier, setmodifier] = useState("");
  const [modifiedAt, setmodifiedAt] = useState("");

  const getProjectData = useCallback(
    async (projectId) => {
      const response = await tokenAxios.get(`project/${projectId}`);
      const { data } = response.data.result;
      setprojectInfo({
        projectName: data.name,
        protoStartPeriod: data.protoStartPeriod ? data.protoStartPeriod : "",
        protoOverPeriod: data.protoOverPeriod ? data.protoOverPeriod : "",
        p1StartPeriod: data.p1StartPeriod ? data.p1StartPeriod : "",
        p1OverPeriod: data.p1OverPeriod ? data.p1OverPeriod : "",
        p2StartPeriod: data.p2StartPeriod ? data.p2StartPeriod : "",
        p2OverPeriod: data.p2OverPeriod ? data.p2OverPeriod : "",
        sopStartPeriod: data.sopStartPeriod ? data.sopStartPeriod : "",
        sopOverPeriod: data.sopOverPeriod ? data.sopOverPeriod : "",
        projectType: data.projectType,
        projectLevel: {
          id: data.projectLevel && data.projectLevel.id,
          name: data.projectLevel && data.projectLevel.name,
        },
        clientOrganizationId: {
          id: data.clientOrganization && data.clientOrganization.id,
          name: data.clientOrganization && data.clientOrganization.name,
        },
        supplierId: {
          id: data.supplierOrganizationId && data.supplierOrganizationId.id,
          name: data.supplierOrganizationId && data.supplierOrganizationId.name,
        },
        carTypeId: {
          id: data.carType && data.carType.id,
          name: data.carType && data.carType.name,
        },
        clientItemNumber: data.clientItemNumber,
        linkedItemList: data.item,
        routeNumber: data.routeId,
        // creator: data.creator,
        // createdAt: data.createdAt,
        // modifier: data.modifier,
        // modifiedAt: data.modifiedAt,
      });
      setcreatedAt(data.createdAt);
      setcreator(data.creator);
      setmodifiedAt(data.modifiedAt);
      setmodifier(data.modifier);
      // attachment
      const tempfileArray = [];
      const temptagArray = [];
      const tempcommentArray = [];
      const temporiginArray = [];
      const tempuploaderArray = [];
      const tempdateArray = [];
      const tempidArray = [];
      const tempdeletedFileArray = [];
      const tempModifiedAtArray = [];

      if (data.projectAttachments) {
        data.projectAttachments.forEach((item) => {
          tempfileArray.push(item.originName);
          tempcommentArray.push(item.attach_comment);
          temptagArray.push(item.tag);
          temporiginArray.push(item.attachmentaddress);
          tempuploaderArray.push(item.upload);
          tempdateArray.push(item.date);
          tempidArray.push(item.id);
          if (item.deleted) {
            tempdeletedFileArray.push(item.id);
          }
          tempModifiedAtArray.push(item.modifiedAt ? item.modifiedAt : "");
        });
        setattachmentFileArray(tempfileArray);
        setattachmentIdArray(tempidArray);
        setattachmentCommentArray(tempcommentArray);
        setattachmentTagArray(temptagArray);
        setattachmentOriginArray(temporiginArray);
        setattachmentDateArray(tempdateArray);
        setattachmentUploaderArray(tempuploaderArray);
        setdeletedFileIdArray(tempdeletedFileArray);
        setattachmentModifiedAtArray(tempModifiedAtArray);
      }
    },
    [tokenAxios, setprojectInfo]
  );
  useEffect(() => {
    setcurrentCategory("List");
    getProjectData(params.projectId);
    return () => {
      initprojectModule();
    };
  }, [setcurrentCategory, initprojectModule, getProjectData]);
  return (
    <ProjectDetailPageStyle>
      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, resultText.project.projectInformation],
            [attachmentRef, resultText.project.projectAttachment],
            [routeRef, resultText.project.routeInformation],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>
      <FormSectionStyle ref={formRef}>
        <div className="member">
          <RoundImgBox
            width="30px"
            height="30px"
            src={
              creator.profileImage &&
              `${process.env.REACT_APP_BASE_URL}/${creator.profileImage}`
            }
          />
          {resultText.project.createdAt(creator.username, createdAt)}
        </div>
        {modifier && (
          <div className="member">
            <RoundImgBox
              width="30px"
              height="30px"
              src={
                modifier.profileImage &&
                `${process.env.REACT_APP_BASE_URL}/${modifier.profileImage}`
              }
            />
            {resultText.project.modifiedAt(modifier.username, modifiedAt)}
          </div>
        )}

        <Input height="40px" state={projectName} />
        <ProjectInformationSection readOnly />
        <AttachmentSection
          readOnly
          attachmentModifiedAtArray={attachmentModifiedAtArray}
          attachmentFileArray={attachmentFileArray}
          setattachmentFileArray={setattachmentFileArray}
          attachmentTagArray={attachmentTagArray}
          setattachmentTagArray={setattachmentTagArray}
          attachmentCommentArray={attachmentCommentArray}
          attachmentOriginArray={attachmentOriginArray}
          setattachmentOriginArray={setattachmentOriginArray}
          setattachmentCommentArray={setattachmentCommentArray}
          attachmentDateArray={attachmentDateArray}
          attachmentUploaderArray={attachmentUploaderArray}
          setattachmentDateArray={setattachmentDateArray}
          setattachmentUploaderArray={setattachmentUploaderArray}
          setattachmentRef={setattachmentRef}
          title={resultText.project.projectAttachment}
          // edit
          attachmentIdArray={attachmentIdArray}
          setattachmentIdArray={setattachmentIdArray}
          deletedFileIds={deletedFileIdArray}
        />
        <div className="gap" />
        <RouteSection
          activate
          readOnly
          routeNumber={routeNumber}
          setrouteRef={setrouteRef}
          routeData={routeData}
          setrouteData={setrouteData}
        />
      </FormSectionStyle>
    </ProjectDetailPageStyle>
  );
}
