import React, { useEffect, useRef, useState, useMemo } from "react";
import styled from "styled-components";
import LineTitle from "../atom/LineTitle";
import RoundImgBox from "../atom/RoundImgBox";
import RoundTextBox from "../atom/RoundTextBox";
import TableIndexRow from "../atom/TableIndexRow";
import TableRow from "../atom/TableRow";
import AnimationInput from "../molecule/AnimationInput";
import AnimationSelectBox from "../molecule/AnimationSelectBox";
import PaginationTable from "../molecule/PaginationTable";
import SearchBar from "../molecule/SearchBar";
import SearchInput from "../molecule/SearchInput";
import TextBox from "../molecule/TextBox";
import commonStore from "../store/commonStore";
import releaseModuleStore from "../store/releaseModuleStore";
import ModalBox from "./ModalBox";
import txt from "../Text";
import DeleteButton from "../atom/DeleteButton";
import ClassificationSelectTree from "../molecule/ClassificationSelectTree";
import Table from "../molecule/Table";

const ModalStyle = styled.div`
  display: flex;
  width: 80vw;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 1px solid var(--deemGray);
    width: 5px;
    background: white;
    border-radius: 8px;
  }
  .gap {
    width: 40px;
    flex-shrink: 0;
  }

  .treeScrollContainer {
    border-radius: 12px 12px 0 0;
    padding-top: 40px;
    background-color: white;
    height: calc(100% - 59px);
    overflow: scroll;
    ::-webkit-scrollbar {
      width: 5px;
      height: 7px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border: 15px solid transparent;
      width: 5px;
      background: rgba(4, 8, 15, 0.3);
      border-radius: 8px;
    }
  }
`;
const PropModalStyle = styled.div`
  min-width: 300px;
  height: calc(${(props) => (props.height ? props.height : "50vh + 30px")});
  background-color: var(--eciBlue);
  border-radius: 10px;
  position: relative;
  width: calc(${(props) => (props.width ? props.width : "40%")});
  flex-shrink: 0;
  .title {
    color: white;
    font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
    font-weight: 600;
    height: 10%;
    min-height: 40px;
    line-height: 40px;
    padding-left: 16px;
    border-radius: 10px 10px 0 0;
    background-color: var(--eciBlue);
  }

  .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: 81%;
    border-radius: 8px;
    padding: 16px;
    overflow-y: scroll;
    overflow: scroll;
    ::-webkit-scrollbar {
      width: 5px;
      height: 7px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border: 15px solid transparent;
      width: 5px;
      background: rgba(4, 8, 15, 0.3);
      border-radius: 8px;
    }
  }
  .buttonPart {
    position: absolute;
    bottom: 0px;
    left: 0px;
    border-top: solid var(--textGray) 1px;
    height: 10%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    .actionButton {
      cursor: pointer;
      padding: 4px 20px;
      font-weight: 600;
      color: var(--textDarkGray);
    }
    .cancel {
      border-right: 3px solid var(--textGray);
    }
    .apply {
      color: var(--eciBlue);
    }
  }
`;
const ReleaseInformationSectionStyle = styled.div`
  pointer-events: ${(props) => (props.readOnly ? "none" : "")};

  .gridContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 60px;
    grid-column-gap: 15px;
    grid-row-gap: 10px;
  }
  .flexContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
  .blueBox {
    grid-column: 1/3;
    padding: 15px;
    background-color: var(--eciBlue);
    border-radius: 10px;
    margin-bottom: 10px;
  }
`;
const ReleaseItemStyle = styled.div`
  width: calc(50% - 7px);
  background-color: white;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 10px;
  margin: 9px 0;
`;
export default function ReleaseInformationSection({ readOnly }) {
  const {
    // release
    releaseNumber,
    setreleaseNumber,
    releaseItemId, // {[id,name}]
    setreleaseItemId,
    releaseTitle,
    setreleaseTitle,
    releaseType,
    setreleaseType,
    releaseContent,
    setreleaseContent,
    releaseCoId, // {id,name}
    setreleaseCoId,
    releaseOrganizationId, // [{id,na{}]
    setreleaseOrganizationId,
    // date
    setsearchBoxType,
    setinformationRef,
  } = releaseModuleStore();
  const [isModalBox, setisModalBox] = useState(false);
  const tokenAxios = commonStore((state) => state.tokenAxios);
  const informationRef = useRef();
  const store = releaseModuleStore();
  // Modal
  const [searchText, setsearchText] = useState("");
  const [itemName, setitemName] = useState("");
  const [itemNumber, setitemNumber] = useState("");
  const [carType, setcarType] = useState("");
  const [carTypeArr, setcarTypeArr] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [pageNum, setpageNum] = useState("0");
  const [rowsPerPage, setrowsPerPage] = useState(10);

  const itemBoxes = useMemo(
    () =>
      releaseItemId &&
      releaseItemId.map((item, i) => {
        return (
          <ReleaseItemStyle key={i}>
            {item.itemName || item.name}
            {!readOnly && (
              <DeleteButton
                onDelete={() => {
                  const tmpArr = [...releaseItemId];
                  tmpArr.splice(i, 1);
                  setreleaseItemId(tmpArr);
                }}
              />
            )}
          </ReleaseItemStyle>
        );
      }),
    [releaseItemId]
  );
  const getTreeData = async () => {
    const response = await tokenAxios.get(`/classification1`);
    store.setmodalClassificationTree(response.data.result.data);
  };

  // 모달 끝
  useEffect(() => {
    setinformationRef(informationRef);
    setpageNum("0");
    getTreeData();
    // getCarTypeList()
  }, [setinformationRef]);

  // const getModalData = async () => {
  //   const response = await tokenAxios.get(
  //     `releaseItemId?size=${rowsPerPage}&page=${pageNum}&name=${searchText}&itemName=${itemName}&itemNumber=${itemNumber}&carTypeId=${carType}`
  //   );
  //   setdataList([response.data.content]);
  //   setTotalPage(response.data.totalPages);
  // };
  const getModalList = async () => {
    // 여기 추가
    const response = await tokenAxios.get(
      `releaseItemId/${store.modalSelectedItemClassification.classification}?size=${rowsPerPage}&page=${pageNum}&name=${searchText}`
    );
    store.setmodalItemList(response.data.content);
    setTotalPage(response.data.totalPages || 1);
  };
  useEffect(() => {
    if (store.modalSelectedItemClassification.id) getModalList();
  }, [store.modalSelectedItemClassification, pageNum, rowsPerPage, searchText]);
  return (
    <ReleaseInformationSectionStyle ref={informationRef} readOnly={readOnly}>
      <ModalBox
        isActivate={isModalBox}
        onClick={(val) => {}}
        setisActivate={setisModalBox}
      >
        <ModalStyle>
          <PropModalStyle width="240px" height="70vh" fontSize="20px">
            <div className="title">Choose Item Classification</div>
            <div className="treeScrollContainer">
              {store.modalClassificationTree.map((item, i) => {
                return (
                  <ClassificationSelectTree
                    key={i}
                    child={
                      item.children || item.c2SelectDtos || item.c3SelectDtos
                    }
                    title={item.name}
                    last={item.last}
                    id={item.id}
                    data={item}
                    depth={0}
                    treeId={i}
                    backgroundColor="var(--eciBlue)"
                    boxWidth="100px"
                    onClick={(val) => {
                      if (val.last === 1) {
                        store.setmodalSelectedItemClassification(val);
                      }
                    }}
                    currentState={store.modalSelectedItemClassification}
                  />
                );
              })}
            </div>
          </PropModalStyle>
          <div className="gap" />
          <PropModalStyle width="680px" height="70vh" fontSize="20px">
            <div className="title">Select the Item</div>
            <SearchBar
              state={searchText}
              setState={setsearchText}
              height="32px"
              cssStyle="margin-bottom:12px; "
              width="100% - 62px"
            />

            <div className="modal">
              <PaginationTable
                width="100%"
                minHeight="90%"
                totalPage={totalPage}
                rowsPerPageDefault={10}
                pageNum={pageNum}
                setRowsPerPage={setrowsPerPage}
                rowsPerPage={rowsPerPage}
                setpageNum={setpageNum}
                noshadow
              >
                <TableIndexRow
                  widthArray={[1, 4, 4, 4, 5, 5]}
                  itemArray={txt.rel.modalIndexArray}
                />

                {store.modalItemList.map((itemObj, i) => (
                  <TableRow
                    key={i}
                    widthArray={[1, 4, 4, 4, 5, 5]}
                    itemArray={[
                      <input
                        type="checkbox"
                        onChange={() => {
                          store.onCheckModalItem(itemObj);
                        }}
                        checked={
                          store.modalTempCheckedList.findIndex(
                            (item) => item.cardId === itemObj.cardId
                          ) > -1
                        }
                      />,
                      itemObj && itemObj.cardNumber,
                      itemObj && itemObj.cardName,
                      itemObj && itemObj.classification,
                      itemObj && itemObj.cardType,
                      itemObj &&
                      itemObj.sharing &&
                      itemObj.sharing === "공용" ? (
                        <RoundTextBox
                          width="50%"
                          backgroundColor="var(--lightGray)"
                          color="var(--eciBlue)"
                          height="30px"
                        >
                          {txt.item.sharing}{" "}
                        </RoundTextBox>
                      ) : (
                        <RoundTextBox
                          width="50%"
                          backgroundColor="var(--lightGray)"
                          color="var(--eciBlue)"
                          height="30px"
                        >
                          {txt.item.notSharing}{" "}
                        </RoundTextBox>
                      ),
                    ]}
                  />
                ))}
              </PaginationTable>
            </div>

            <div className="buttonPart">
              <div className="actionButton cancel" onClick={store.cancelModal}>
                CANCEL
              </div>
              <div
                className="actionButton apply"
                onClick={() => {
                  store.applyModalLv2();
                }}
              >
                APPLY
              </div>
            </div>
          </PropModalStyle>
          <div className="gap" />

          <PropModalStyle width="500px" height="70vh" fontSize="20px">
            <div className="title">Added Items</div>
            <div className="modal">
              <Table width="100%" noshadow>
                <TableIndexRow
                  widthArray={[4, 7, 7]}
                  itemArray={["No.", "Part Number", "부품명"]}
                />

                {store.modalCheckedItemList.map((itemObj, i) => (
                  <TableRow
                    key={i}
                    widthArray={[4, 7, 6, 1]}
                    itemArray={[
                      itemObj.cardNumber,
                      itemObj.cardName,
                      <span
                        className="material-icons delete"
                        onClick={() =>
                          store.onDeleteModalAddedItems(itemObj.cardId)
                        }
                      >
                        delete
                      </span>,
                    ]}
                  />
                ))}
              </Table>
            </div>

            <div className="buttonPart">
              <div className="actionButton cancel" onClick={store.cancelModal}>
                CANCEL
              </div>
              <div
                className="actionButton apply"
                onClick={() => {
                  setisModalBox(false);
                  store.applyModalLv3();
                }}
              >
                APPLY
              </div>
            </div>
          </PropModalStyle>
        </ModalStyle>
        {/* <FilterSearchBox
          searchText={searchText}
          setsearchText={setsearchText}
          setitemName={setitemName}
          setitemNumber={setitemNumber}
          setcarType={setcarType}
        />
        <br />
        <br />
        <PaginationTable
          minHeight="480px"
          totalPage={totalPage}
          setRowsPerPage={setrowsPerPage}
          rowsPerPageDefault={7}
          pageNum={pageNum}
          rowsPerPage={rowsPerPage}
          setpageNum={setpageNum}
        >
          <TableIndexRow
            widthArray={[1, 4, 4, 4, 5, 5]}
            itemArray={txt.rel.modalIndexArray}
          />
          {rows}
        </PaginationTable> */}
      </ModalBox>

      <LineTitle
        fontSize="16px"
        color="var(--eciBlue)"
        width="100%"
        height="60px"
      >
        {txt.rel.relInfo}{" "}
      </LineTitle>
      <div className="gridContainer">
        <AnimationInput
          width="100%"
          height="40px"
          placeholder={txt.rel.relNum}
          state={releaseNumber}
          setState={setreleaseNumber}
          readOnly
          backgroundColor="var(--textDarkGray)"
        />
        <div />
        <AnimationSelectBox
          width="100%"
          height="40px"
          placeholder={txt.rel.relType}
          state={releaseType}
          setState={setreleaseType}
          required
          readOnly={readOnly}
        >
          <option value="1">{txt.rel.originalRel}</option>
          <option value="2">{txt.rel.coRel}</option>
        </AnimationSelectBox>
        {releaseType.toString() === "1" ? (
          <div />
        ) : (
          <SearchInput
            required
            width="100%"
            height="40px"
            placeholder={txt.rel.co}
            state={releaseCoId.coNumber}
            onClick={() => {
              setsearchBoxType("releaseCoId");
            }}
            readOnly={readOnly}
          />
        )}
      </div>

      {releaseType.toString() === "1" && (
        <>
          <SearchInput
            required
            width="100%"
            height="40px"
            placeholder={txt.rel.relItem}
            // state={releaseItemId.itemName || releaseItemId.name}
            backgroundColor="var(--eciBlue)"
            onClick={() => {
              setisModalBox(true);
            }}
            readOnly={readOnly}
          />
          <div className="flexContainer">{itemBoxes}</div>
        </>
      )}

      <div className="blueBox">
        <AnimationInput
          width="50%"
          height="40px"
          placeholder={txt.rel.title}
          state={releaseTitle}
          setState={setreleaseTitle}
          readOnly={readOnly}
          required
        />
        <TextBox
          width="100%"
          height="100px"
          placeholder={txt.rel.content}
          required
          state={releaseContent}
          setState={setreleaseContent}
          readOnly={readOnly}
        />
      </div>
    </ReleaseInformationSectionStyle>
  );
}
