// get language

const textList = {
  eng: {
    recover: "복구",
    delete: "삭제",
    selectAll: "전체 선택",
    duplicate: "Duplicate",
    saveAsDraft: "Save as Draft",
    saveAndRoute: "Save and Route",
    routeInformation: "Route Information",
    approveDone: "등록이 완료되었습니다",
    refuseDone: "거절이 완료되었습니다",
    revise: "Revise",
    etc: "ETC",
    high: "상",
    mid: "중",
    low: "하",
    common: {
      NAVBAR: {
        Home: "Home",
        Project: "Project",
        Design: "Design",
        BOM: "BOM",
        Design_Change: "Design Change",
        Item: "Item",
        Document: "Document",
        Release: "Release",
        Admin: "Admin",
      },
      coRouteInitArr: ["CO 신청", "CO 승인", "CO 실행", "CO 실행 확정"],
      crRouteInitArr: ["CR 신청", "CR 승인", "CR 요청접수", "CR Review"],
      relRouteInitArr: ["배포 신청", "배포 승인"],
      docRouteInitArr: ["문서 신청", "문서 승인"],
      projectRouteInitArr: ["Project 등록"],
      loginErrorMessage:
        "최소 8자리이면서 1개 이상의 알파벳, 숫자, 특수문자를 포함",
      phoneNumberErrorMessage: "전화번호는 - 없이 작성해주세요.",
    },
    home: {
      icons: {
        Project: "lightbulb",
        "CR / CO": "published_with_changes",
        Item: "build",
        Document: "description",
        Release: "exit_to_app",
        Bom: "mediation",
        Design: "design_services",
      },
      ToDo: "To Do",
      todoCategoryArray: [
        "Project",
        "Design",
        "BOM",
        "Item",
        "CR/CO",
        "Release",
        "Document",
      ],
      myProjectStatus: "My Project Status",
      projectIndexArray: [
        "Project Number",
        "Project Name",
        "개발유형",
        "개발등급",
        "생산조직",
        "고객사",
        "차종",
        "고객제품번호",
        "제품번호",
        "제품명",
        "phase",
        "status",
      ],
      stat: {
        working: "WORKING",
        complete: "COMPLETE",
        release: "RELEASE",
        pending: "PENDING",
        drop: "DROP",
        total: "Project Total",
      },
    },
    project: {
      project: "Project",
      projectDescription: "",
      list: "List",
      addNewProject: "Add New Project",
      projectListIndexArray: [
        "#",
        "Project Name",
        "개발유형",
        "개발등급",
        "생산조직",
        "고객사",
        "차종",
        "고객제품번호",
        "제품번호",
        "제품명",
        "phase",
        "status",
      ],
      projectListManageIndexArray: [
        "Project Number",
        "Project Name",
        "개발유형",
        "개발등급",
        "생산조직",
        "고객사",
        "차종",
        "고객제품번호",
        "제품번호",
        "제품명",
        "phase",
        "status",
        "삭제 여부",
        "Drop 여부",
        "Pending 여부",
      ],
      projectInformation: "Project Information",
      projectAttachment: "Project Attachment",
      routeInformation: "Route Information",
      typeProjectName: "Type Project Name",
      clientData: "고객설계데이터",
      devStat: "개발사양서",
      createdAt: (user, date) => `${user}님이 ${date}에 작성했습니다`,
      modifiedAt: (user, date) =>
        `${user}님이 ${date}에 마지막으로 수정했습니다`,
      projectType: "개발 유형",
      projectLevel: "개발 등급",
      supplier: "생산조직",
      client: "고객사",
      carType: "차종",
      clientItemNumber: "고객제품번호",
      itemNumber: "제품 번호",
      itemName: "제품명",
      revision: "Revision",
      classification: "Classification",
      status: "Status",
      yangsan: "양산 개발",
      preDev: "선행 개발",
      itemIndexArray: [
        "ItemNumber",
        "Name",
        "Revision",
        "Classification",
        "Status",
      ],
      protoError: "check start date",
    },
    design: {
      cadFileAlert: "디자인 파일 우측의 휴지통을 눌러 다시 업로드 하세요.",
      design: "Design",
      addNewDesign: "Add new design",
      designInformation: "Design Information",
      designAttachment: "Design Attachment",
      routeInformation: "Route Information",
      drReport: "기구DR보고서",
      drProceedings: "기구DR회의록",
      change: "신규점 변환 검토서",
      cadParts: "CAD Parts",
      itemParts: "Item Parts",
      checkNumber: "아이템 number를 확인해주세요.", // check
    },
    item: {
      item: "Item",
      list: "List",
      addNewItem: "Add New Item",
      selectMajor: "Major 선택",
      itemListIndexArray: [
        "",
        "Item Number",
        "carType",
        "Item Name",
        "Item Classification",
        "Revision",
        "Status",
        "ItemType",
        "공용 유무",
      ],
      sharing: "공용",
      notSharing: "전용",
      itemInformation: "Item Information",
      itemAttachment: "Item Attachment",
      routeInformation: "Route Information",
      whereUse: "Where Use",
      affectedItem: "Affected Item",
      classification: "Classification",
      itemNumber: "Item Number",
      itemName: "Item Name",
      customerItemNumber: "Customer Item Number",
      thumbnail: "Thumbnail",
      isSharing: "공유 여부",
      carType: "차종",
      itemType: "개발 유형",
      whereUseIndex: ["", "Item Number", "Item Name", "Item Classification"],
    },
    bom: {
      prelIndexArray: [
        "ItemNumber",
        "Item Classificaton",
        "Item Name",
        "Item Type",
        "공용유뮤",
      ],
      devIndexArray: [
        "ItemNumber",
        "Item Classificaton",
        "Item Name",
        "Item Type",
        "공용유뮤",
      ],
      compModalIndexArray: [
        "ItemNumber",
        "Item Classificaton",
        "Item Name",
        "Item Type",
        "공용유뮤",
      ],
      compIndexArray: [
        "",
        "Item Number",
        "Item Name",
        "Item Classification",
        "Item Type",
        "공용우뮤",
      ],
      bomList: "BOM List",
      bomInfo: "BOM Information",
      routeInfo: "Route Information",
      compare: "Compare",
    },
    doc: {
      searchBoxIndexArray: ["1Lv", "2Lv", "3Lv"],

      document: "Document",
      list: "List",
      addNewDoc: "Add New Document",
      docAttachment: "Document Attachment",
      docInfo: "Document Information",
      routeInfo: "Route Information",
      docListIndexArray: [
        "Document Number",
        "Attachment Title",
        "Revision",
        "Tag",
        "Status",
        "Originated",
        "Owner",
      ],
      revision: "Revision",
      docClassification: "DocumentClassification",
      docNumber: "Document Number",
      docTag: "Document Tag",
      title: "Title",
      description: "Description",
    },
    rel: {
      list: "List",
      addNewRelease: "Add New Release",
      modalIndexArray: [
        "",
        "ItemNumber",
        "Item Name",
        "Item Classificaton",
        "Item Type",
        "공용유뮤",
      ],
      relInfo: "Release Information",
      relAttachment: "Release Attachment",
      relFrom: "Releasing From",
      routeInfo: "Route Information",
      relNum: "Release Number",
      relType: "Release Type",
      coRel: "CO 배포",
      originalRel: "초도 배포",
      relItem: "배포 item",
      co: "배포 CO",
      title: "배포 제목",
      content: "배포 내용",
      tagOptionList: [
        { id: "1", name: "ETC" },
        { id: "11", name: "배포관련문서" },
      ],
      relIndexArray: ["배포처"],
      relPart: "배포처",
      listIndexArray: [
        "ReleaseNumber",
        "Release Title",
        "Release Type",
        "Item Number",
        "CO Number",
      ],
    },
    crco: {
      crco: "CR/CO",
      // cr
      cr: "Change Request",
      createNewCr: "CreateNew CR",
      crListIndexArray: [
        "CR Number",
        "CR 이유",
        "Item Number",
        "Item Name",
        "Item Classification",
        "Revision",
        "Status",
      ],
      crAttachment: "Change Request Attahcment",
      CRInformation: "CR Information",
      CRAttachment: "CR Attachment",
      crTagOptionList: [
        { id: "1", name: "ETC" },
        { id: "9", name: "설계변경요청서" },
      ],
      crModalIndexArray: [
        "Item Number",
        "Item Classificaton",
        "Item Name",
        "Item Type",
        "공용유뮤",
      ],
      crInfo: "Change Request Information",
      crNum: "CR Number",
      crReason: "CR이유",
      crImportance: "CR 중요도",
      crSource: "CR 출처",
      crTitle: "CR 제목",
      crContent: "CR내용",
      proposedSol: "Proposed Solution",
      affectedItem: "Affected Item",
      // co
      coTitle: "CO 제목",
      coContent: "CO 내용",
      co: "Change Order",
      coInfo: "Change Order Information",
      coNum: "CO Number",
      client: "고객",
      clientCoNum: "고객 CO Number",
      startDate: "고객 CO 발행일",
      endDate: "고객 CO 접수일",
      isPastCar: "과거차 발생 여부",
      carType: "과거차 발생 차종",
      isCost: "COST 변동 여부",
      true: "유",
      false: "무",
      costChange: "COST 변동 금액",
      costLevel: "COST 적용단계",
      coTiming: "CO 적용시점",
      coAffect: "CO 영향",
      coCheckList: [
        { id: 1, name: "도면수정" },
        { id: 2, name: "금형수정" },
        { id: 3, name: "설비변경" },
        { id: 4, name: "공정변경" },
        { id: 5, name: "부품변경" },
        { id: 6, name: "차공구변경" },
        { id: 7, name: "DFMEA변경" },
        { id: 8, name: "도면 합리화" },
      ],
      coReason: "CO 이유",
      COInfo: "CO Information",
      COAttachment: "CO Attachment",
      routeInfo: "Route Information",
      createNewCo: "Create New CO",
      coListIndexArray: [
        // excel파일과 다름
        "",
        "CO Number",
        "CO 이유",
        "Item Number",
        "Item Name",
        "Item Classification",
        "Revision",
        "Status",
      ],
      coImportance: "CO 중요도",

      coTagOption: [{ id: "1", name: "ETC" }],
      relCrIndexArray: [
        "CRNumber",
        "이유",
        "ItemNumber",
        "Item Name",
        "Item Classification",
        "Revision",
        "Status",
      ],
      aiIndexArray: [
        "ItemNumber",
        "Item Name",
        "Item Classificaton",
        "Item Type",
        "공용유뮤",
      ],
      relCRResultIndexArray: [
        "CRNumber",
        "Type",
        "이유",
        "중요도",
        "출처",
        "제목",
      ],
      aiResultIndexArray: [
        "CRNumber",
        "ItemNumber",
        "Item Name",
        "Item Classificaton",
        "Revision",
        "Status",
        // "변경내용",
        // "변경항목",
      ],
    },
    admin: {
      admin: "Admin",
      member: "Member",
      listIndexArray: [
        "이름",
        "소속",
        "직위/권한",
        "Admin 권한",
        "ID",
        "계정생성일",
      ],
      addNewMember: "Add New Member",
      positionCheckListKor: [
        // check
        { name: "CEO", id: 1 },
        { name: "Vice CEO", id: 2 },
        { name: "Executive director", id: 10 },
        { name: "Managing director", id: 11 },
        { name: "Director", id: 12 },
        { name: "Team manager", id: 3 },
        { name: "Manager", id: 4 },
        { name: "Senior manager", id: 5 },
        { name: "Pro", id: 7 },
      ],

      positionCheckListChn: [
        // check
        { name: "CEO", id: 8 },
        { name: "Vice CEO", id: 9 },
        { name: "Executive director", id: 10 },
        { name: "Managing director", id: 11 },
        { name: "Director", id: 12 },
        { name: "Manager", id: 13 },
        { name: "Manager", id: 14 },
        { name: "Senior manager", id: 15 },
        { name: "Senior manager", id: 16 },
        { name: "Pro", id: 17 },
      ],
      currentPosition: "현재 권한",
      memberControll: "멤버 관리",
      authorizationChange: "권한 이양",
      propChange: "속성 관리",
      memberSearchIndexArr: ["", "이름", "소속", "직위/권한"],
      permissionSearchIndexArr: [
        "Item Number",
        "Item Name",
        "Revision",
        "Route",
      ],
      addPermission: "권한 추가하기",
      isAdminCheck: "Admin 권한 부여",
      propList: "속성 종류",
    },
    backTo: "이전 페이지로 돌아가기",
    toHome: "홈으로 돌아가기",
    cantFindPage: "에러가 발생했습니다.",
  },

  chn: {
    recover: "复原", //  추가
    delete: "删除", // 추가
    selectAll: "全部选择",
    duplicate: "复制",
    saveAsDraft: "另存为",
    saveAndRoute: "保存及线路",
    routeInformation: "线路信息",
    approveDone: "完成登录",
    refuseDone: "完成拒绝",
    revise: "修订",
    etc: "其它",
    high: "上",
    mid: "中",
    low: "下",
    common: {
      NAVBAR: {
        Home: "主页",
        Project: "项目",
        Design: "设计",
        BOM: "材料清单",
        Design_Change: "设计变更",
        Item: "型号",
        Document: "文件",
        Release: "解除",
        Admin: "管理者",
      },
      coRouteInitArr: ["CO 申请", "CO 承认", "CO 实行", "CO 确定实行"],
      crRouteInitArr: ["CR 申请", "CR 承认", "CR 接受邀请", "CR 检讨"],
      relRouteInitArr: ["发放申请", "发放承认"],
      docRouteInitArr: ["文件申请", "文件承认"],
      projectRouteInitArr: ["项目登入"],
      loginErrorMessage: "最少8位数,需包含1个以上的字母、数字、特殊文字",
      phoneNumberErrorMessage: "只输入电话号码(不要包含-,+)",
    },
    home: {
      // 알람 아이콘 추가
      icons: {
        Project: "lightbulb",
        "CR / CO": "published_with_changes",
        Item: "build",
        Document: "description",
        Release: "exit_to_app",
        Bom: "mediation", // 이거 봄으로 바꿔
        Design: "design_services",
      },
      ToDo: "To Do",
      todoCategoryArray: [
        "Project",
        "Design",
        "BOM",
        "Item",
        "CR/CO",
        "Release",
        "Document",
      ],
      myProjectStatus: "My Project Status",
      projectIndexArray: [
        "项目编号",
        "项目名称",
        "开发类型",
        "开发等级",
        "生产组织",
        "客户",
        "车种",
        "客户产品编号",
        "产品编号",
        "产品名称",
        "阶段",
        "状态",
      ],
      stat: {
        working: "WORKING",
        complete: "COMPLETE",
        release: "RELEASE",
        pending: "PENDING",
        drop: "DROP",
        total: "Project Total",
      },
    },
    project: {
      project: "Project",
      projectDescription: "",
      list: "List",
      addNewProject: "Add New Project",
      projectListIndexArray: [
        "项目名称",
        "开发类型",
        "开发等级",
        "生产组织",
        "客户",
        "车种",
        "客户产品编号",
        "产品编号",
        "产品名称",
        "阶段",
        "状态",
      ],
      projectListManageIndexArray: [
        "项目编号",
        "项目名称",
        "开发类型",
        "开发等级",
        "生产组织",
        "客户",
        "车种",
        "客户产品编号",
        "产品编号",
        "产品名称",
        "阶段",
        "状态",
        "是否删除",
        "Drop与否",
        "Pending与否",
      ],
      projectInformation: "项目资料",
      projectAttachment: "项目附件",
      routeInformation: "路线信息",
      typeProjectName: "项目名称类型",
      clientData: "客户设计数据",
      devStat: "开发式样书",
      createdAt: (user, date) => `${user}在 ${date}制成`,
      modifiedAt: (user, date) => `${user}在 ${date}最终修改`,
      projectType: "开发类型",
      projectLevel: "开发等级",
      supplier: "生产组织",
      client: "客户",
      carType: "车种",
      clientItemNumber: "客户产品编号",
      itemNumber: "产品编号",
      itemName: "产品名称",
      revision: "修订",
      classification: "分类",
      status: "状态",
      yangsan: "量产开发",
      preDev: "先行开发",
      itemIndexArray: ["产品型号", "名称", "修订", "分类", "状态"],
      protoError: "检查起始日期",
    },
    design: {
      cadFileAlert: "디자인 파일 우측의 휴지통을 눌러 다시 업로드 하세요.",
      design: "设计",
      addNewDesign: "增加新设计",
      designInformation: "设计资料",
      designAttachment: "设计附件",
      routeInformation: "线路信息",
      drReport: "治具DR报告书",
      drProceedings: "治具DR会议录",
      change: "新规点 变换检讨书",
      cadParts: "CAD 部分",
      itemParts: "型号部分",
      checkNumber: "請核對商品編號",
    },
    item: {
      item: "Item",
      list: "List",
      addNewItem: "Add New Item",
      selectMajor: "Major 選擇",
      itemListIndexArray: [
        "",
        "型号编号",
        "carType",
        "型号名称",
        "型号分类",
        "修订",
        "Status",
        "型号类型",
        "是否共用",
      ],
      sharing: "共用",
      notSharing: "专用",
      itemInformation: "型号信息",
      itemAttachment: "型号附件",
      routeInformation: "线路信息",
      whereUse: "使用者",
      affectedItem: "受影响型号",
      classification: "分类",
      itemNumber: "型号编号",
      itemName: "型号名称",
      customerItemNumber: "Customer Item Number",
      thumbnail: "缩略图",
      isSharing: "是否共享",
      carType: "车种",
      itemType: "开发类型",
      whereUseIndex: ["", "型号编号", "型号名称", "型号 分类"],
    },
    bom: {
      prelIndexArray: ["", "型号编号", "型号名称", "型号 分类"],
      devIndexArray: [
        "型号编号",
        "型号分类",
        "型号名称",
        "型号类型",
        "是否共用",
      ],
      compModalIndexArray: [
        "型号编号",
        "型号分类",
        "型号名称",
        "型号类型",
        "是否共用",
      ],
      compIndexArray: [
        "",
        "型号编号",
        "型号分类",
        "型号名称",
        "型号类型",
        "是否共用",
      ],
      bomList: "BOM List",
      bomInfo: "BOM Information",
      routeInfo: "Route Information",
      compare: "Compare",
    },
    doc: {
      searchBoxIndexArray: ["1Lv", "2Lv", "3Lv"],

      document: "Document",
      list: "List",
      addNewDoc: "Add New Document",
      docAttachment: "Document Attachment",
      docInfo: "Document Information",
      routeInfo: "Route Information",
      docListIndexArray: [
        "Document Number",
        "Attachment Title",
        "Revision",
        "Tag",
        "Status",
        "Originated",
        "Owner",
      ],
      revision: "Revision",
      docClassification: "DocumentClassification",
      docNumber: "Document Number",
      docTag: "Document Tag",
      title: "Title",
      description: "Description",
    },
    rel: {
      list: "List",
      addNewRelease: "Add New Release",
      modalIndexArray: [
        "",
        "型号编号",
        "型号名称",
        "型号分类",
        "型号类型",
        "是否共用",
      ],
      relInfo: "Release Information",
      relAttachment: "Release Attachment",
      relFrom: "Releasing From",
      routeInfo: "Route Information",
      relNum: "Release Number",
      relType: "Release Type",
      coRel: "CO 发放",
      originalRel: "初图 发放",
      relItem: "发放型号",
      co: "发放 CO",
      title: "发放标题",
      content: "发放内容",
      tagOptionList: [
        { id: "1", name: "其它" },
        { id: "11", name: "发放相关文件" },
      ],
      relIndexArray: ["发放处"],
      relPart: "发放处",
      listIndexArray: [
        "ReleaseNumber",
        "Release Title",
        "Release Type",
        "Item Number",
        "CO Number",
      ],
    },
    crco: {
      crco: "CR/CO",
      // cr
      cr: "Change Request",
      createNewCr: "CreateNew CR",
      crListIndexArray: [
        "CR 编号",
        "事由",
        "型号编号",
        "型号名称",
        "型号分类",
        "更新",
        "状态",
      ],
      crAttachment: "Change Request Attahcment",
      CRInformation: "CR Information",
      CRAttachment: "CR Attachment",
      crTagOptionList: [
        { id: "1", name: "其它" },
        { id: "9", name: "设计变更邀请书" },
      ],
      crModalIndexArray: [
        "型号编号",
        "型号分类",
        "型号名称",
        "型号类型",
        "是否共用",
      ],
      crInfo: "Change Request Information",
      crNum: "CR Number",
      crReason: "CR事由",
      crImportance: "CR 重要度",
      crSource: "CR 来源",
      crTitle: "CR 标题",
      crContent: "CR 内容",
      proposedSol: "Proposed Solution",
      affectedItem: "Affected Item",
      // co
      coTitle: "CO 标题",
      coContent: "CO 内容",
      co: "Change Order",
      coInfo: "Change Order Information",
      coNum: "CO Number",
      client: "客户",
      clientCoNum: "客户 CO 编号",
      startDate: "客户 CO 发行日",
      endDate: "客户 CO 接受日",
      isPastCar: "以往车 发生与否",
      carType: "以往车 发生车种",
      isCost: "COST 变动与否",
      true: "与",
      false: "否",
      costChange: "COST 变动金额",
      costLevel: "COST 适用阶段",
      coTiming: "CO 适用时间点",
      coAffect: "CO 影响",
      coCheckList: [
        { id: 1, name: "图纸修改" },
        { id: 2, name: "模具修改" },
        { id: 3, name: "设备修改" },
        { id: 4, name: "工程变更" },
        { id: 5, name: "部品变更" },
        { id: 6, name: "治工具变更" },
        { id: 7, name: "DFMEA变更" },
        { id: 8, name: "도면 합리화" },
      ],
      coReason: "CO 事由",
      COInfo: "CO 资料",
      COAttachment: "CO Attachment",
      routeInfo: "Route Information",
      createNewCo: "Create New CO",
      coListIndexArray: [
        "",
        "编号",
        "事由",
        "型号编号",
        "型号名称",
        "型号分类",
        "更新",
        "状态",
      ],
      coImportance: "CO 重要度",

      coTagOption: [{ id: "1", name: "ETC" }],
      relCrIndexArray: [
        "CR 编号",
        "事由",
        "型号编号",
        "型号名称",
        "型号分类",
        "更新",
        "状态",
      ],
      aiIndexArray: [
        "型号编号",
        "型号名称",
        "型号分类",
        "型号类型",
        "是否共用",
      ],
      relCRResultIndexArray: [
        "型号编号",
        "型号名称",
        "型号分类",
        "型号类型",
        "是否共用",
      ],
      aiResultIndexArray: [
        "CR 编号",
        "型号编号",
        "型号名称",
        "型号分类",
        "更新",
        "状态",
        // "变更内容",
        // "变更项目",
      ],
    },
    admin: {
      admin: "Admin",
      member: "Member",
      listIndexArray: [
        "名字",
        "所属",
        "职位/权限",
        "Admin 权限",
        "ID",
        "账户生成日",
      ],
      addNewMember: "Add New Member",
      positionCheckListKor: [
        // check
        { name: "总经理", id: 1 },
        { name: "副总经理", id: 2 },
        { name: "专务理事", id: 10 },
        { name: "常务理事", id: 11 },
        { name: "理事", id: 12 },
        { name: "部长", id: 3 },
        { name: "副总监", id: 4 },
        { name: "副经理", id: 5 },
        { name: "职工", id: 7 },
      ],
      positionCheckListChn: [
        // check
        { name: "总经理", id: 8 },
        { name: "总经理", id: 9 },
        { name: "专务理事", id: 10 },
        { name: "常务理事", id: 11 },
        { name: "理事", id: 12 },
        { name: "部长", id: 13 },
        { name: "副总监", id: 14 },
        { name: "经理", id: 15 },
        { name: "副经理", id: 16 },
        { name: "职工", id: 17 },
      ],
      currentPosition: "目前权限",
      memberControll: "成员管理",
      authorizationChange: "权限转让",
      propChange: "属性管理",
      memberSearchIndexArr: ["", "名字", "所属", "职位/权限"],
      permissionSearchIndexArr: [
        "Item Number",
        "Item Name",
        "Revision",
        "Route",
      ],
      addPermission: "追加权限",
      isAdminCheck: "赋予Admin权限",
      propList: "속성 종류",
    },
    backTo: "返回上一页",
    toHome: "返回主页",
    cantFindPage: "发生异常.",
  },

  kor: {
    recover: "복구", //  추가
    delete: "삭제", // 추가
    selectAll: "전체 선택",
    duplicate: "Duplicate",
    saveAsDraft: "Save as Draft",
    saveAndRoute: "Save and Route",
    routeInformation: "Route Information",
    approveDone: "등록이 완료되었습니다",
    refuseDone: "거절이 완료되었습니다",
    revise: "Revise",
    etc: "ETC",
    high: "상",
    mid: "중",
    low: "하",
    common: {
      NAVBAR: {
        Home: "Home",
        Project: "Project",
        Design: "Design",
        BOM: "BOM",
        Design_Change: "Design Change",
        Item: "Item",
        Document: "Document",
        Release: "Release",
        Admin: "Admin",
      },
      coRouteInitArr: ["CO 신청", "CO 승인", "CO 실행", "CO 실행 확정"],
      crRouteInitArr: ["CR 신청", "CR 승인", "CR 요청접수", "CR Review"],
      relRouteInitArr: ["배포 신청", "배포 승인"],
      docRouteInitArr: ["문서 신청", "문서 승인"],
      projectRouteInitArr: ["Project 등록"],
      loginErrorMessage:
        "최소 8자리이면서 1개 이상의 알파벳, 숫자, 특수문자를 포함",
      phoneNumberErrorMessage: "전화번호는 기호(+,-) 없이 작성해주세요.",
    },
    home: {
      // 알람 아이콘 추가
      icons: {
        Project: "lightbulb",
        "CR / CO": "published_with_changes",
        Item: "build",
        Document: "description",
        Release: "exit_to_app",
        Bom: "mediation", // 이거 봄으로 바꿔
        Design: "design_services",
      },
      ToDo: "To Do",
      todoCategoryArray: [
        "Project",
        "Design",
        "BOM",
        "Item",
        "CR/CO",
        "Release",
        "Document",
      ],
      myProjectStatus: "My Project Status",
      projectIndexArray: [
        "Project Number",
        "Project Name",
        "개발유형",
        "개발등급",
        "생산조직",
        "고객사",
        "차종",
        "고객제품번호",
        "제품번호",
        "제품명",
        "phase",
        "status",
      ],
      stat: {
        working: "WORKING",
        complete: "COMPLETE",
        release: "RELEASE",
        pending: "PENDING",
        drop: "DROP",
        total: "Project Total",
      },
    },
    project: {
      project: "Project",
      projectDescription: "",
      list: "List",
      addNewProject: "Add New Project",
      projectListIndexArray: [
        "Project Number",
        "Project Name",
        "개발유형",
        "개발등급",
        "생산조직",
        "고객사",
        "차종",
        "고객제품번호",
        "제품번호",
        "제품명",
        "phase",
        "status",
      ],
      projectListManageIndexArray: [
        "Project Number",
        "Project Name",
        "개발유형",
        "개발등급",
        "생산조직",
        "고객사",
        "차종",
        "고객제품번호",
        "제품번호",
        "제품명",
        "phase",
        "status",
        "삭제 여부",
        "Drop 여부",
        "Pending 여부",
      ],
      projectInformation: "Project Information",
      projectAttachment: "Project Attachment",
      routeInformation: "Route Information",
      typeProjectName: "Type Project Name",
      clientData: "고객설계데이터",
      devStat: "개발사양서",
      createdAt: (user, date) => `${user}님이 ${date}에 작성했습니다`,
      modifiedAt: (user, date) =>
        `${user}님이 ${date}에 마지막으로 수정했습니다`,
      projectType: "개발 유형",
      projectLevel: "개발 등급",
      supplier: "생산조직",
      client: "고객사",
      carType: "차종",
      clientItemNumber: "고객제품번호",
      itemNumber: "제품 번호",
      itemName: "제품명",
      revision: "Revision",
      classification: "Classification",
      status: "Status",
      yangsan: "양산 개발",
      preDev: "선행 개발",
      itemIndexArray: [
        "ItemNumber",
        "Name",
        "Revision",
        "Classification",
        "Status",
      ],
      protoError: "check start date",
    },
    design: {
      cadFileAlert: "디자인 파일 우측의 휴지통을 눌러 다시 업로드 하세요.",
      design: "Design",
      addNewDesign: "Add new design",
      designInformation: "Design Information",
      designAttachment: "Design Attachment",
      routeInformation: "Route Information",
      drReport: "기구DR보고서",
      drProceedings: "기구DR회의록",
      change: "신규점 변환 검토서",
      cadParts: "CAD Parts",
      itemParts: "Item Parts",
      checkNumber: "아이템 number를 확인해주세요.",
    },
    item: {
      item: "Item",
      list: "List",
      addNewItem: "Add New Item",
      selectMajor: "Major 선택",
      itemListIndexArray: [
        "",
        "Item Number",
        "carType",
        "Item Name",
        "Item Classification",
        "Revision",
        "Status",
        "ItemType",
        "공용 유무",
      ],
      sharing: "공용",
      notSharing: "전용",
      itemInformation: "Item Information",
      itemAttachment: "Item Attachment",
      routeInformation: "Route Information",
      whereUse: "Where Use",
      affectedItem: "Affected Item",
      classification: "Classification",
      itemNumber: "Item Number",
      itemName: "Item Name",
      customerItemNumber: "Customer Item Number",
      thumbnail: "Thumbnail",
      isSharing: "공유 여부",
      carType: "차종",
      itemType: "개발 유형",
      whereUseIndex: ["", "Item Number", "Item Name", "Item Classification"],
    },
    bom: {
      prelIndexArray: [
        "ItemNumber",
        "Item Classificaton",
        "Item Name",
        "Item Type",
        "공용유뮤",
      ],
      devIndexArray: [
        "ItemNumber",
        "Item Classificaton",
        "Item Name",
        "Item Type",
        "공용유뮤",
      ],
      compModalIndexArray: [
        "ItemNumber",
        "Item Classificaton",
        "Item Name",
        "Item Type",
        "공용유뮤",
      ],
      compIndexArray: [
        "",
        "Item Number",
        "Item Name",
        "Item Classification",
        "Item Type",
        "공용우뮤",
      ],
      bomList: "BOM List",
      bomInfo: "BOM Information",
      routeInfo: "Route Information",
      compare: "Compare",
    },
    doc: {
      searchBoxIndexArray: ["1Lv", "2Lv", "3Lv"],

      document: "Document",
      list: "List",
      addNewDoc: "Add New Document",
      docAttachment: "Document Attachment",
      docInfo: "Document Information",
      routeInfo: "Route Information",
      docListIndexArray: [
        "Document Number",
        "Attachment Title",
        "Revision",
        "Tag",
        "Status",
        "Originated",
        "Owner",
      ],
      revision: "Revision",
      docClassification: "DocumentClassification",
      docNumber: "Document Number",
      docTag: "Document Tag",
      title: "Title",
      description: "Description",
    },
    rel: {
      list: "List",
      addNewRelease: "Add New Release",
      modalIndexArray: [
        "",
        "ItemNumber",
        "Item Name",
        "Item Classificaton",
        "Item Type",
        "공용유뮤",
      ],
      relInfo: "Release Information",
      relAttachment: "Release Attachment",
      relFrom: "Releasing From",
      routeInfo: "Route Information",
      relNum: "Release Number",
      relType: "Release Type",
      coRel: "CO 배포",
      originalRel: "초도 배포",
      relItem: "배포 item",
      co: "배포 CO",
      title: "배포 제목",
      content: "배포 내용",
      tagOptionList: [
        { id: "1", name: "ETC" },
        { id: "11", name: "배포관련문서" },
      ],
      relIndexArray: ["배포처"],
      relPart: "배포처",
      listIndexArray: [
        "ReleaseNumber",
        "Release Title",
        "Release Type",
        "Item Number",
        "CO Number",
      ],
    },
    crco: {
      crco: "CR/CO",
      // cr
      cr: "Change Request",
      createNewCr: "CreateNew CR",
      crListIndexArray: [
        "CR Number",
        "CR 이유",
        "Item Number",
        "Item Name",
        "Item Classification",
        "Revision",
        "Status",
      ],
      crAttachment: "Change Request Attahcment",
      CRInformation: "CR Information",
      CRAttachment: "CR Attachment",
      crTagOptionList: [
        { id: "1", name: "ETC" },
        { id: "9", name: "설계변경요청서" },
      ],
      crModalIndexArray: [
        "Item Number",
        "Item Classificaton",
        "Item Name",
        "Item Type",
        "공용유뮤",
      ],
      crInfo: "Change Request Information",
      crNum: "CR Number",
      crReason: "CR이유",
      crImportance: "CR 중요도",
      crSource: "CR 출처",
      crTitle: "CR 제목",
      crContent: "CR내용",
      proposedSol: "Proposed Solution",
      affectedItem: "Affected Item",
      // co
      coTitle: "CO 제목",
      coContent: "CO 내용",
      co: "Change Order",
      coInfo: "Change Order Information",
      coNum: "CO Number",
      client: "고객",
      clientCoNum: "고객 CO Number",
      startDate: "고객 CO 발행일",
      endDate: "고객 CO 접수일",
      isPastCar: "과거차 발생 여부",
      carType: "과거차 발생 차종",
      isCost: "COST 변동 여부",
      true: "유",
      false: "무",
      costChange: "COST 변동 금액",
      costLevel: "COST 적용단계",
      coTiming: "CO 적용시점",
      coAffect: "CO 영향",
      coCheckList: [
        { id: 1, name: "도면수정" },
        { id: 2, name: "금형수정" },
        { id: 3, name: "설비변경" },
        { id: 4, name: "공정변경" },
        { id: 5, name: "부품변경" },
        { id: 6, name: "차공구변경" },
        { id: 7, name: "DFMEA변경" },
        { id: 8, name: "도면 합리화" },
      ],
      coReason: "CO 이유",
      COInfo: "CO Information",
      COAttachment: "CO Attachment",
      routeInfo: "Route Information",
      createNewCo: "Create New CO",
      coListIndexArray: [
        "",
        "CO Number",
        "CO 이유",
        "Item Number",
        "Item Name",
        "Item Classification",
        "Revision",
        "Status",
      ],
      coImportance: "CO 중요도",

      coTagOption: [{ id: "1", name: "ETC" }],
      relCrIndexArray: [
        "CRNumber",
        "이유",
        "ItemNumber",
        "Item Name",
        "Item Classification",
        "Revision",
        "Status",
      ],
      aiIndexArray: [
        "ItemNumber",
        "Item Name",
        "Item Classificaton",
        "Item Type",
        "공용유뮤",
      ],
      relCRResultIndexArray: [
        "CRNumber",
        "Type",
        "이유",
        "중요도",
        "출처",
        "제목",
      ],
      aiResultIndexArray: [
        "CRNumber",
        "ItemNumber",
        "Item Name",
        "Item Classificaton",
        "Revision",
        "Status",
        // "변경내용",
        // "변경항목",
      ],
    },
    admin: {
      admin: "Admin",
      member: "Member",
      listIndexArray: [
        "이름",
        "소속",
        "직위/권한",
        "Admin 권한",
        "ID",
        "계정생성일",
      ],
      addNewMember: "Add New Member",
      positionCheckListKor: [
        // check
        { name: "대표", id: 1 },
        { name: "부사장", id: 2 },
        { name: "전무이사", id: 10 },
        { name: "상무이사", id: 11 },
        { name: "이사", id: 12 },
        { name: "팀장", id: 3 },
        { name: "책임", id: 4 },
        { name: "선임", id: 5 },
        { name: "프로", id: 7 },
      ],
      positionCheckListChn: [
        // check
        { name: "총경리", id: 8 },
        { name: "부총경리", id: 9 },
        { name: "전무이사", id: 10 },
        { name: "상무이사", id: 11 },
        { name: "이사", id: 12 },
        { name: "부장", id: 13 },
        { name: "차장", id: 14 },
        { name: "과장", id: 15 },
        { name: "대리", id: 16 },
        { name: "사원", id: 17 },
      ],
      currentPosition: "현재 권한",
      memberControll: "멤버 관리",
      authorizationChange: "권한 이양",
      propChange: "속성 관리",
      memberSearchIndexArr: ["", "이름", "소속", "직위/권한"],
      permissionSearchIndexArr: [
        "Item Number",
        "Item Name",
        "Revision",
        "Route",
      ],
      addPermission: "권한 추가하기",
      isAdminCheck: "Admin 권한 부여",
      propList: "속성 종류",
    },
    backTo: "이전 페이지로 돌아가기",
    toHome: "홈으로 돌아가기",
    cantFindPage: "에러가 발생했습니다.",
  },
};

const resultText = (currentLang) => {
  if (!currentLang) {
    localStorage.setItem("lang", "eng");
    return textList.eng;
  }
  return textList[currentLang];
};
export default resultText(localStorage.getItem("lang"));
