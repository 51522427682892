import create from "zustand";

const projectModuleStore = create((set, get) => ({
  isRouteActivate: false,
  setisRouteActivate: (val) => {
    set(() => ({ isRouteActivate: val }));
  },
  // 카테고리 설정
  currentCategory: "List",
  setcurrentCategory: (val) => {
    if (val !== get().currentCategory) {
      set(() => ({ currentCategory: val }));
    }
  },

  // 스크롤 ref 모음
  informationRef: "",
  setinformationRef: (val) => {
    set(() => ({ informationRef: val }));
  },
  attachmentRef: "",
  setattachmentRef: (val) => {
    set(() => ({ attachmentRef: val }));
  },
  routeRef: "",
  setrouteRef: (val) => {
    set(() => ({ routeRef: val }));
  },
  // iteminfo
  linkedItemList: [],
  setlinkedItemList: (val) => {
    set(() => ({ linkedItemList: val }));
  },
  deletedLinkedItemList: [],
  setdeletedLinkedItemList: (val) => {
    set(() => ({ deletedLinkedItemList: val }));
  },
  // 우측 검색창 관리
  searchBoxType: "",
  setsearchBoxType: (val) => {
    set(() => ({ searchBoxType: val }));
  },
  setsearchBoxProperty: (target, val) => {
    if (target === "item-candidates") {
      const list = get().linkedItemList;
      const tmpSet = new Set(list);
      tmpSet.add(val);
      const tmpArr = Array.from(tmpSet);

      set(() => ({
        linkedItemList: tmpArr, // 중복은 어쩔겨
      }));
    } else {
      set(() => ({ [target]: val }));
    }
  },

  // project state
  projectId: "",
  setprojectId: (val) => {
    set(() => ({ projectId: val }));
  },
  projectNumber: "",
  setprojectNumber: (val) => {
    set(() => ({ projectNumber: val }));
  },
  projectName: "",
  setprojectName: (val) => {
    set(() => ({ projectName: val }));
  },

  protoStartPeriod: "",
  setprotoStartPeriod: (val) => {
    set(() => ({ protoStartPeriod: val }));
  },
  protoOverPeriod: "",
  setprotoOverPeriod: (val) => {
    set(() => ({ protoOverPeriod: val }));
  },
  p1StartPeriod: "",
  setp1StartPeriod: (val) => {
    set(() => ({ p1StartPeriod: val }));
  },
  p1OverPeriod: "",
  setp1OverPeriod: (val) => {
    set(() => ({ p1OverPeriod: val }));
  },
  p2StartPeriod: "",
  setp2StartPeriod: (val) => {
    set(() => ({ p2StartPeriod: val }));
  },
  p2OverPeriod: "",
  setp2OverPeriod: (val) => {
    set(() => ({ p2OverPeriod: val }));
  },
  sopStartPeriod: "",
  setsopStartPeriod: (val) => {
    set(() => ({ sopStartPeriod: val }));
  },
  sopOverPeriod: "",
  setsopOverPeriod: (val) => {
    set(() => ({ sopOverPeriod: val }));
  },
  projectType: "",
  setprojectType: (val) => {
    set(() => ({ projectType: val }));
  },
  projectLevel: [],
  setprojectLevel: (val) => {
    set(() => ({ projectLevel: val }));
  },
  supplierId: {},
  setsupplierId: (val) => {
    set(() => ({ supplierId: val }));
  },
  clientOrganizationId: {},
  setclientOrganizationId: (val) => {
    set(() => ({ clientOrganizationId: val }));
  },
  carTypeId: {},
  setcarTypeId: (val) => {
    set(() => ({ carTypeId: val }));
  },
  clientItemNumber: "",
  setclientItemNumber: (val) => {
    set(() => ({ clientItemNumber: val }));
  },
  // supplierId: {},
  // setsupplierId: (val) => {
  //   set(() => ({ supplierId: val }));
  // },
  setprojectInfo: (val) => {
    set(() => val);
  },

  // itemId: "",
  // setitemId: (val) => {
  //   set(() => ({ itemId: val }));
  // },
  // itemName: "",
  // setitemName: (val) => {
  //   set(() => ({ itemName: val }));
  // },
  // itemRevision: "",
  // setitemRevision: (val) => {
  //   set(() => ({ itemRevision: val }));
  // },
  // itemNumber: "",
  // setitemNumber: (val) => {
  //   set(() => ({ itemNumber: val }));
  // },
  // itemClassification: "",
  // setitemClassification: (val) => {
  //   set(() => ({ itemClassification: val }));
  // },
  // itemStatus: "",
  // setitemStatus: (val) => {
  //   set(() => ({ itemStatus: val }));
  // },

  // attachment
  attachmentFileArray: [],
  setattachmentFileArray: (val) => {
    set(() => ({ attachmentFileArray: val }));
  },
  attachmentOriginArray: [],
  setattachmentOriginArray: (val) => {
    const newVal =
      val &&
      val.map((item) => {
        return `${process.env.REACT_APP_BASE_URL}/${item.toString()}`;
      });
    set(() => ({
      attachmentOriginArray: newVal,
    }));
  },
  attachmentTagArray: [],
  setattachmentTagArray: (val) => {
    set(() => ({ attachmentTagArray: val }));
  },

  attachmentCommentArray: [],
  setattachmentCommentArray: (val) => {
    set(() => ({ attachmentCommentArray: val }));
  },
  attachmentUploaderArray: [],
  setattachmentUploaderArray: (val) => {
    set(() => ({ attachmentUploaderArray: val }));
  },
  attachmentDateArray: [],
  setattachmentDateArray: (val) => {
    set(() => ({ attachmentDateArray: val }));
  },
  attachmentModifiedAtArray: [],
  setattachmentModifiedAtArray: (val) => {
    set(() => ({ attachmentModifiedAtArray: val }));
  },
  setattachmentInfo: (val) => {
    set(() => val);
  },
  // edit attachment
  // edit attachment
  deletedFileIdArray: [],
  setdeletedFileIdArray: (val) => {
    set(() => ({ deletedAttachmentArray: val }));
  },
  attachmentIdArray: [],
  setattachmentIdArray: (val) => {
    set(() => ({ attachmentIdArray: val }));
  },
  addedAttachmentArray: [],
  setaddedAttachmentArray: (val) => {
    set(() => ({ addedAttachmentArray: val }));
  },
  addedAttachmentTagArray: [],
  setaddedAttachmentTagArray: (val) => {
    set(() => ({ addedAttachmentTagArray: val }));
  },
  addedAttachmentCommentArray: [],
  setaddedAttachmentCommentArray: (val) => {
    set(() => ({ addedAttachmentCommentArray: val }));
  },
  deletedAttachmentArray: [],
  setdeletedAttachmentArray: (val) => {
    set(() => ({ deletedAttachmentArray: val }));
  },
  // route state
  routeNumber: "",
  setrouteNumber: (val) => {
    set(() => ({ routeNumber: val }));
  },
  routeData: [],
  setrouteData: (val) => {
    set(() => ({ routeData: val }));
  },
  // state초기화 함수
  initprojectModule: () => {
    set(() => ({
      isRouteActivate: false,
      protoStartPeriod: "",
      protoOverPeriod: "",
      p1StartPeriod: "",
      p1OverPeriod: "",
      p2StartPeriod: "",
      p2OverPeriod: "",
      sopStartPeriod: "",
      sopOverPeriod: "",
      projectType: "",
      projectName: "",
      projectLevel: "",
      clientOrganizationId: "",
      carTypeId: "",
      clientItemNumber: "",
      supplierId: "",
      linkedItemList: [],
      deletedLinkedItemList: [],

      // attachment
      attachmentTagOptionList: [],
      attachmentFileArray: [],
      attachmentOriginArray: [],
      attachmentTagArray: [],
      attachmentCommentArray: [],
      attachmentUploaderArray: [],
      attachmentModifiedAtArray: [],
      // edit attachment
      deletedFileIdArray: [],
      attachmentIdArray: [],
      addedAttachmentArray: [],
      addedAttachmentTagArray: [],
      addedAttachmentCommentArray: [],
      deletedAttachmentArray: [],
      routeNumber: "",
      routeData: [],
      // 우측 검색창
      searchBoxType: "",
    }));
  },
}));
export default projectModuleStore;
