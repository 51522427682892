import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import CRCOModuleStore from "../../store/CRCOModuleStore";
import CRInformationSection from "../CRInformationSection";

import Button from "../../atom/Button";
import SearchBox from "../../molecule/SearchBox";
import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";
import LoadingBox from "../LoadingBox";
import LoadingArrowAnimation from "../../molecule/LoadingArrowAnimation";
import BoldText from "../../atom/BoldText";
import RouteSection from "../RouteSection";
import AttachmentSection from "../AttachmentSection";
import txt from "../../Text";

const CRCOAddSectionStyle = styled.div`
  display: flex;
  position: relative;
  .tempSave {
    height: 40px;

    position: absolute;
    top: -40px;
    left: calc(58% - 110px);
    display: flex;
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`;
const FormSectionStyle = styled.div`
  width: 58%;
  height: calc(100vh - 230px);
  overflow: scroll;
  padding-left: 18%;
  padding-right: 1.5%;
  padding-top: 20px;
  .gap {
    height: 45px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
`;
const SearchBoxSectionStyle = styled.div`
  width: 42%;
`;
function CRReviewPage() {
  const { tokenAxios } = commonStore((state) => ({
    tokenAxios: state.tokenAxios,
  }));

  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const formRef = useRef();
  const {
    // crinfo
    CRNumber,
    crReasonId,
    CRReasonText,
    crImportanceId,
    crSourceId,
    CRTitle,
    CRBody,
    CRSolution,
    CRAffectedItem,
    setCRInfo,
    /// attachment
    attachmentFileArray,
    attachmentIdArray,
    attachmentTagArray,
    attachmentCommentArray,
    attachmentOriginArray,
    attachmentDateArray,
    attachmentUploaderArray,
    attachmentModifiedAtArray,
    setattachmentModifiedAtArray,
    setattachmentDateArray,
    setattachmentUploaderArray,
    setattachmentIdArray,
    setattachmentOriginArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    deletedFileIdArray,
    setdeletedFileIdArray,
    // route
    routeNumber,
    routeData,
    setrouteData,
    setrouteNumber,
    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // searchBox
    searchBoxType,
    setsearchBoxProperty,
    // etc
    isRouteActivate,
    setisRouteActivate,
    setcurrentCategory,
    initCRModule,
  } = CRCOModuleStore();

  const params = useParams();
  const getData = async (id) => {
    const response = await tokenAxios.get(`cr/${id}`);
    const { data } = response.data.result; // 확인필
    // setCRInfo
    setCRInfo({
      CRNumber: data.crNumber,
      crReasonId: data.crReasonId,
      CRReasonText: data.crReason,
      crImportanceId: data.crImportanceId,
      crSourceId: data.crSourceId,
      CRTitle: data.name,
      CRBody: data.content,
      CRSolution: data.solution,
      CRAffectedItem: data.item,
    });
    // route
    setrouteNumber(data.routeId);
    // attachment
    const tempfileArray = [];
    const temptagArray = [];
    const tempcommentArray = [];
    const temporiginArray = [];
    const tempuploaderArray = [];
    const tempdateArray = [];
    const tempidArray = [];
    const tempdeletedFileArray = [];
    const tempModifiedAtArray = [];

    data.attachments.forEach((item) => {
      tempfileArray.push(item.originName);
      tempcommentArray.push(item.attach_comment);
      temptagArray.push(item.tag);
      temporiginArray.push(item.attachmentaddress);
      tempuploaderArray.push(item.upload);
      tempdateArray.push(item.date);
      tempidArray.push(item.id);
      if (item.deleted) {
        tempdeletedFileArray.push(item.id);
      }
      tempModifiedAtArray.push(item.modifiedAt ? item.modifiedAt : "");
    });
    setattachmentFileArray(tempfileArray);
    setattachmentIdArray(tempidArray);
    setattachmentCommentArray(tempcommentArray);
    setattachmentTagArray(temptagArray);
    setattachmentOriginArray(temporiginArray);
    setattachmentDateArray(tempdateArray);
    setattachmentUploaderArray(tempuploaderArray);
    setdeletedFileIdArray(tempdeletedFileArray);
    console.log(1);

    setattachmentModifiedAtArray(tempModifiedAtArray);
  };
  useEffect(() => {
    setcurrentCategory("CR");
    getData(params.id);

    return () => {
      initCRModule();
    };
  }, []);

  return (
    <CRCOAddSectionStyle>
      <LoadingBox isLoading={isLoading}>
        <LoadingArrowAnimation />
        <BoldText fontSize="26px" color="var(--eciBlue)">
          S a v i n g
        </BoldText>
        <br />
        <br />
        Please wait while we set
        <br />
        thing up for you!
      </LoadingBox>
      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, txt.crco.CRInformation],
            [attachmentRef, txt.crco.CRAttachment],
            [routeRef, txt.crco.routeInfo],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>
      <FormSectionStyle ref={formRef}>
        <CRInformationSection readOnly />
        <AttachmentSection
          readOnly
          attachmentFileArray={attachmentFileArray}
          setattachmentFileArray={setattachmentFileArray}
          attachmentTagArray={attachmentTagArray}
          setattachmentTagArray={setattachmentTagArray}
          attachmentCommentArray={attachmentCommentArray}
          attachmentOriginArray={attachmentOriginArray}
          attachmentModifiedAtArray={attachmentModifiedAtArray}
          setattachmentOriginArray={setattachmentOriginArray}
          setattachmentCommentArray={setattachmentCommentArray}
          attachmentDateArray={attachmentDateArray}
          attachmentUploaderArray={attachmentUploaderArray}
          setattachmentDateArray={setattachmentDateArray}
          setattachmentUploaderArray={setattachmentUploaderArray}
          setattachmentRef={setattachmentRef}
          title={txt.crco.crAttachment}
          // edit
          attachmentIdArray={attachmentIdArray}
          setattachmentIdArray={setattachmentIdArray}
          deletedFileIds={deletedFileIdArray}
        />

        <div className="gap" />

        <RouteSection
          activate
          routeNumber={routeNumber}
          setrouteRef={setrouteRef}
          routeData={routeData}
          setrouteData={setrouteData}
        />
      </FormSectionStyle>
    </CRCOAddSectionStyle>
  );
}
export default React.memo(CRReviewPage);
