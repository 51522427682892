import create from "zustand";

const itemModuleStore = create((set, get) => ({
  currentCategory: "List",
  setcurrentCategory: (val) => {
    set(() => ({ currentCategory: val }));
  },
  // item
  // condition
  requiredPropertyList: ["typeId", "itemName", "sharing"],
  setrequiredPropertyList: (val) => {
    set(() => ({ requiredPropertyList: val }));
  },
  addrequiredPropertyList: (val) => {
    const newarr = [...get().requiredPropertyList, val];
    set(() => ({ requiredPropertyList: newarr }));
  },
  // id
  itemId: "",
  setitemId: (val) => {
    set(() => ({ itemId: val }));
  },
  // 공용
  itemRevision: "A",
  setitemRevision: (val) => {
    set(() => ({ itemRevision: val }));
  },
  itemClassification: "",
  // {value: , classification:}
  setitemClassification: (val) => {
    set(() => ({
      itemClassification: val,
      classification1: val.classification && val.classification.split("/")[0],
      classification2: val.classification && val.classification.split("/")[1],
      classification3: val.classification && val.classification.split("/")[2],
    }));
  },
  classification1: "",
  classification2: "",
  classification3: "",

  itemNumber: "",
  setitemNumber: (val) => {
    set(() => ({ itemNumber: val }));
  },
  itemName: "",
  setitemName: (val) => {
    set(() => ({ itemName: val }));
  },
  customItemNumber: "",
  setcustomItemNumber: (val) => {
    set(() => ({ customItemNumber: val }));
  },
  isMajor: "0",
  setisMajor: (val) => {
    set(() => ({ isMajor: val }));
  },
  typeId: "",
  settypeId: (val) => {
    set(() => ({ typeId: val }));
  },
  sharing: "true",
  setsharing: (val) => {
    set(() => ({ sharing: val }));
  },
  thumbnail: "",
  setthumbnail: (val) => {
    set(() => ({ thumbnail: val }));
  },

  carTypeId: "",
  setcarTypeId: (val) => {
    set(() => ({ carTypeId: val }));
  },

  // class별
  forming: "",
  setforming: (val) => {
    set(() => ({ forming: val }));
  },
  curve: "",
  setcurve: (val) => {
    set(() => ({ curve: val }));
  },
  integrate: "",
  setintegrate: (val) => {
    set(() => ({ integrate: val }));
  },
  width: "",
  setwidth: (val) => {
    set(() => ({ width: val }));
  },
  height: "",
  setheight: (val) => {
    set(() => ({ height: val }));
  },

  thickness: "",
  setthickness: (val) => {
    set(() => ({ thickness: val }));
  },
  weight: "",
  setweight: (val) => {
    set(() => ({ weight: val }));
  },
  importance: "",
  setimportance: (val) => {
    set(() => ({ importance: val }));
  },
  colorId: "",
  setcolorId: (val) => {
    set(() => ({ colorId: val }));
  },
  loadQuantity: "",
  setloadQuantity: (val) => {
    set(() => ({ loadQuantity: val }));
  },
  coatingWayId: "",
  setcoatingWayId: (val) => {
    set(() => ({ coatingWayId: val }));
  },
  coatingTypeId: "",
  setcoatingTypeId: (val) => {
    set(() => ({ coatingTypeId: val }));
  },
  modulus: "",
  setmodulus: (val) => {
    set(() => ({ modulus: val }));
  },
  screw: "",
  setscrew: (val) => {
    set(() => ({ screw: val }));
  },
  cuttingWay: "",
  setcuttingWay: (val) => {
    set(() => ({ cuttingWay: val }));
  },
  lcd: "",
  setlcd: (val) => {
    set(() => ({ lcd: val }));
  },
  displaySize: "",
  setdisplaySize: (val) => {
    set(() => ({ displaySize: val }));
  },
  screwHeight: "",
  setscrewHeight: (val) => {
    set(() => ({ screwHeight: val }));
  },
  clientOrganizationId: "", // 확인필
  setclientOrganizationId: (val) => {
    set(() => ({ clientOrganizationId: val }));
  },
  supplierOrganizationId: "", // 확인필
  setsupplierOrganizationId: (val) => {
    set(() => ({ supplierOrganizationId: val }));
  },
  makersId: "", // 확인필
  setmakersId: (val) => {
    set(() => ({ makersId: val }));
  },
  partnumbers: "",
  setpartnumbers: (val) => {
    set(() => ({ partnumbers: val }));
  },
  pitch: "",
  setpitch: (val) => {
    set(() => ({ pitch: val }));
  },
  specificGravity: "",
  setspecificGravity: (val) => {
    set(() => ({ specificGravity: val }));
  },
  // whereUse
  whereUse: [],
  setwhereUse: (val) => {
    set(() => ({ whereUse: val }));
  },
  // searchbox
  searchBoxType: "",
  setsearchBoxType: (val) => {
    set(() => ({ searchBoxType: val }));
  },
  setsearchBoxProperty: (target, val, index) => {
    if (target) {
      if (index) {
        if (target === "members") {
          const tmpmember = get().members;
          if (tmpmember[index]) {
            tmpmember[index] = [...tmpmember[index], val];
          } else {
            tmpmember[index] = [val];
          }
          set(() => ({ members: tmpmember }));
        } else {
          const targetArray = get()[`${target}`];
          if (Array.isArray(targetArray)) {
            targetArray[index] = val;
          }
          set(() => ({ [target]: targetArray }));
        }
      } else {
        set(() => ({ [target]: val }));
      }
    }
  },
  deletemember: (id, index) => {
    const tmpmemberArr = get().members;
    const newArr =
      tmpmemberArr[index] && tmpmemberArr[index].filter((mem) => mem.id !== id);
    if (newArr[0]) {
      tmpmemberArr[index] = newArr;
    } else {
      tmpmemberArr.splice(index, 1);
    }
    set(() => ({ member: tmpmemberArr }));
  },
  // attachment
  attachmentTagOptionList: [{ id: -1, name: "default" }],
  setattachmentTagOptionList: (val) => {
    set(() => ({ attachmentTagOptionList: val }));
  },
  attachmentFileArray: [],
  setattachmentFileArray: (val) => {
    set(() => ({ attachmentFileArray: val }));
  },
  attachmentOriginArray: [],
  setattachmentOriginArray: (val) => {
    set(() => ({
      attachmentOriginArray: val,
    }));
  },
  attachmentTagArray: [],
  setattachmentTagArray: (val) => {
    set(() => ({ attachmentTagArray: val }));
  },

  attachmentCommentArray: [],
  setattachmentCommentArray: (val) => {
    set(() => ({ attachmentCommentArray: val }));
  },
  attachmentUploaderArray: [],
  setattachmentUploaderArray: (val) => {
    set(() => ({ attachmentUploaderArray: val }));
  },
  attachmentDateArray: [],
  setattachmentDateArray: (val) => {
    set(() => ({ attachmentDateArray: val }));
  },
  // edit attachment
  deletedFileIdArray: [],
  setdeletedFileIdArray: (val) => {
    set(() => ({ deletedFileIdArray: val }));
  },
  attachmentIdArray: [],
  setattachmentIdArray: (val) => {
    set(() => ({ attachmentIdArray: val }));
  },
  attachmentModifiedAtArray: [],
  setattachmentModifiedAtArray: (val) => {
    set(() => ({ attachmentModifiedAtArray: val }));
  },
  addedAttachmentArray: [],
  setaddedAttachmentArray: (val) => {
    set(() => ({ addedAttachmentArray: val }));
  },
  addedAttachmentTagArray: [],
  setaddedAttachmentTagArray: (val) => {
    set(() => ({ addedAttachmentTagArray: val }));
  },
  addedAttachmentCommentArray: [],
  setaddedAttachmentCommentArray: (val) => {
    set(() => ({ addedAttachmentCommentArray: val }));
  },
  deletedAttachmentArray: [],
  setdeletedAttachmentArray: (val) => {
    set(() => ({ deletedAttachmentArray: val }));
  },

  // route
  routeNumber: "",
  setrouteNumber: (val) => {
    set(() => ({ routeNumber: val }));
  },
  routeData: [],
  setrouteData: (val) => {
    set(() => ({ routeData: val }));
  },
  isRouteActivate: false,
  setisRouteActivate: (val) => {
    set(() => ({ isRouteActivate: val }));
  },
  // route init
  members: [],
  setmembers: (val) => {
    set(() => ({ members: val }));
  },
  targetMember: "",
  settargetMember: (val) => {
    set(() => ({ targetMember: val }));
  },
  // ref
  informationRef: "",
  setinformationRef: (val) => {
    set(() => ({ informationRef: val }));
  },
  attachmentRef: "",
  setattachmentRef: (val) => {
    set(() => ({ attachmentRef: val }));
  },
  routeRef: "",
  setrouteRef: (val) => {
    set(() => ({ routeRef: val }));
  },
  // revising(co)
  isRevising: false,
  setisRevising: (val) => {
    set(() => ({ isRevising: val }));
  },
  /// 초기화
  miniInit: () => {
    set(() => ({
      // item 필수
      typeId: "",
      // item classification별
      forming: "",
      curve: "",
      integrate: "",
      width: "",
      height: "",
      thickness: "",
      weight: "",
      importance: "",
      colorId: "",
      loadQuantity: "",
      coatingWayId: "",
      coatingTypeId: "",
      modulus: "",
      screw: "",
      isMajor: "0",
      cuttingWay: "",
      lcd: "",
      displaySize: "",
      screwHeight: "",
      clientOrganizationId: "",
      supplierOrganizationId: "",
      makersId: "",
      partnumbers: "",
      pitch: "",
      specificGravity: "",
    }));
  },
  initItemModule: () => {
    set(() => ({
      whereUse: [],
      searchBoxType: "",
      // item 필수
      itemId: "",
      itemRevision: "A",
      itemNumber: "",
      itemName: "",
      itemClassification: "",
      classification1: "",
      classification2: "",
      classification3: "",
      name: "",
      customItemNumber: "",
      typeId: "",
      requiredPropertyList: ["typeId", "itemName", "sharing"],
      thumbnail: "",
      sharing: "true",
      carTypeId: "",
      // item classification별
      forming: "",
      curve: "",
      integrate: "",
      width: "",
      height: "",
      thickness: "",
      weight: "",
      importance: "",
      colorId: "",
      loadQuantity: "",
      coatingWayId: "",
      coatingTypeId: "",
      modulus: "",
      screw: "",
      cuttingWay: "",
      lcd: "",
      displaySize: "",
      screwHeight: "",
      clientOrganizationId: "",
      supplierOrganizationId: "",
      makersId: "",
      partnumbers: "",
      pitch: "",
      specificGravity: "",
      // attachment
      attachmentTagOptionList: [],
      attachmentFileArray: [],
      attachmentOriginArray: [],
      attachmentTagArray: [],
      attachmentCommentArray: [],
      attachmentUploaderArray: [],
      attachmentModifiedAtArray: [],
      // edit attachment
      deletedFileIdArray: [],
      attachmentIdArray: [],
      addedAttachmentArray: [],
      addedAttachmentTagArray: [],
      addedAttachmentCommentArray: [],
      deletedAttachmentArray: [],
      // route
      routeNumber: "",
      routeData: [],
      members: [],
      targetMember: "",
      // revise
      isRevising: false,
    }));
  },
  //
  setitemInfo: (val) => {
    set(() => val);
  },
}));
export default itemModuleStore;
