import React from "react";
import styled from "styled-components";

import BoldText from "../atom/BoldText";
import RoundTextBox from "../atom/RoundTextBox";

const ToDoCardStyle = styled.div`
  //size property는 props기반으로 수정?
  width: 90%;
  min-width: 200px;
  height: 65px;
  margin-bottom: 12px;

  display: flex;

  border-radius: 10px;
  background-image: linear-gradient(to right, white, white 60%, white 100%);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);

  font-size: 12px;
  color: var(--deemGray);
  transition: 0.5s linear;
  cursor: pointer;

  .flexContainer {
    width: 100%;
  }
  .topPart {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 28px;
    width: 95%;
    min-width: 160px;
    margin-bottom: 6px;
  }
  .textBox {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 180px;
  }

  ::before {
    content: " ";
    width: 12px;
    height: 100%;
    margin-right: 10px;
    border-radius: 10px 0 0 10px;
    background-color: ${(props) => props.color};
  }
`;

export default function ToDoCard({
  color,
  itemName,
  itemNumber,
  itemType,
  onClick,
}) {
  return (
    <ToDoCardStyle color={color} onClick={onClick}>
      <div className="flexContainer">
        <div className="topPart">
          <div> {itemNumber}</div>
          <RoundTextBox
            color="var(--textGray)"
            width="60px"
            height="18px"
            fontSize="11px"
            backgroundColor="var(--lightGray)"
          >
            {itemType}
          </RoundTextBox>
        </div>
        <div className="textBox">
          <BoldText fontSize="16px" color="var(--eciBlue)">
            {itemName}
          </BoldText>
        </div>
      </div>
    </ToDoCardStyle>
  );
}
