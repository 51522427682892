import React, { useCallback, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";

import styled from "styled-components";
import BoldText from "../atom/BoldText";
import commonStore from "../store/commonStore";
import CRCOModuleStore from "../store/CRCOModuleStore";
import txt from "../Text";

const CRCOModuleStyle = styled.div`
  padding-top: 80px;
  width: 100%;
  min-width: 1000px;
  height: 100vh;
  min-height: 580px;

  position: relative;
`;
const CRCOModulePageStyle = styled.div`
  padding-top: 24px;
  padding-right: 4%;
  width: 100%;
  min-width: 1000px;
  min-height: calc(100vh - 80px);
  //80px는 헤더 높이

  background-color: var(--lightGray);

  .description {
    height: 40px;

    font-size: 12px;
    line-height: 40px;
  }
`;

const CategoryBar = styled.div`
  width: 100%;
  border-bottom: solid 1px var(--eciBlue);

  display: flex;

  .category {
    height: 40px;
    width: 18%;

    font-size: 12px;

    line-height: 40px;
    text-decoration: none;
    transform: translate(0, 1.5px);
  }
  .List {
    border-bottom: ${(props) =>
      props.currentCategory === "CR" ? "solid 3px var(--eciBlue)" : "none"};
  }
  .Add {
    padding-left: 4px;
    border-bottom: ${(props) =>
      props.currentCategory === "CO" ? "solid 3px var(--eciBlue)" : "none"};
    .plus {
      font-size: 14px;
      color: black;
      color: ${(props) =>
        props.currentCategory === "Add"
          ? " var(--eciBlue)"
          : " var(--darkGray)"};
      margin-right: 10px;
    }
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: var(--eciBlueHover);
`;
function CRCOModule() {
  const setCurrentModule = commonStore((state) => state.setCurrentModule);

  const { currentCategory, initCRCOModule } = CRCOModuleStore((state) => ({
    currentCategory: state.currentCategory,
    initCRCOModule: state.initCRCOModule,
  }));
  useEffect(() => {
    setCurrentModule("Design Change");
  }, [setCurrentModule]);
  return (
    <CRCOModuleStyle>
      <CRCOModulePageStyle>
        <BoldText fontSize="30px" color="var(--eciBlue)">
          {txt.crco.crco}{" "}
        </BoldText>

        <CategoryBar currentCategory={currentCategory}>
          <div className="List category">
            <StyledLink to="/C/R">
              <BoldText
                fontSize="14px"
                color={
                  currentCategory === "CR"
                    ? "var(--eciBlue)"
                    : "var(--darkGray)"
                }
              >
                {txt.crco.cr}{" "}
              </BoldText>
            </StyledLink>
          </div>
          <div className="Add category">
            <StyledLink to="/C/O" onClick={initCRCOModule}>
              <BoldText
                fontSize="14px"
                color={
                  currentCategory === "CO"
                    ? "var(--eciBlue)"
                    : "var(--darkGray)"
                }
              >
                {txt.crco.co}{" "}
              </BoldText>
            </StyledLink>
          </div>
        </CategoryBar>
        <Outlet />
      </CRCOModulePageStyle>
    </CRCOModuleStyle>
  );
}
export default React.memo(CRCOModule);
