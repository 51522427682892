import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";

import documentModuleStore from "../../store/DocumentModuleStore";
import AttachmentSection from "../AttachmentSection";
import DocumentInformationSection from "../DocumentInformationSection";

import RouteSection from "../RouteSection";
import txt from "../../Text";

const DocumentReviewPageStyle = styled.div`
  display: flex;
  position: relative;
  .tempSave {
    height: 40px;

    position: absolute;
    top: -40px;
    left: calc(58% - 110px);
    display: flex;
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`;
const FormSectionStyle = styled.div`
  width: 58%;
  height: ${(props) =>
    props.isClassificationBox ? "760px" : "calc(100vh - 200px)"};
  overflow: scroll;
  padding-left: 18%;
  padding-right: 1.5%;
  padding-top: 20px;
  .gap {
    height: 45px;
  }
  ::-webkit-scrollbar {
    height: 7px;
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
`;
const SearchBoxSectionStyle = styled.div`
  width: 42%;
  position: relative;
  padding-bottom: 30px;
  .searchBox {
    width: 100%;
  }
`;
export default function DocumentReviewPage() {
  const tokenAxios = commonStore((state) => state.tokenAxios);
  const {
    // dcument
    setdocumentInfo,
    documentId,
    setdocumentId,
    documentTag,
    documentContent,
    documentTitle,
    documentClassification,
    setdocumentClassification,
    classification1,
    classification2,
    // attachment
    attachmentFileArray,
    attachmentTagArray,
    attachmentCommentArray,
    attachmentOriginArray,
    attachmentDateArray,
    attachmentUploaderArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    setattachmentOriginArray,
    setattachmentDateArray,
    setattachmentUploaderArray,
    attachmentModifiedAtArray,
    setattachmentModifiedAtArray,
    attachmentIdArray,
    setattachmentIdArray,
    deletedFileIdArray,
    setdeletedFileIdArray,

    // route
    routeNumber,
    routeData,
    setrouteData,
    setrouteNumber,

    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // searchBox
    searchBoxType,
    setsearchBoxType,
    setsearchBoxProperty,
    // etc
    initdocumentModule,
    setcurrentCategory,
  } = documentModuleStore();
  const navigate = useNavigate();
  const [isClassificationBox, setisClassificationBox] = useState(false);
  const formRef = useRef();
  const [isRouteActivate, setisRouteActivate] = useState(false);
  const getdocumentData = useCallback(
    async (Id) => {
      const response = await tokenAxios.get(`doc/${Id}`);
      const { data } = response.data.result;
      setdocumentInfo({
        documentTag: data.tag, // {id,name}
        documentContent: data.documentContent,
        documentNumber: data.documentNumber,
        revision: data.revision,
        documentTitle: data.documentTitle,
        routeNumber: data.routeId,
      });
      setdocumentClassification(data.classification);

      // attachment
      const tempfileArray = [];
      const temptagArray = [];
      const tempcommentArray = [];
      const temporiginArray = [];
      const tempuploaderArray = [];
      const tempdateArray = [];
      const tempidArray = [];
      const tempdeletedFileArray = [];
      const tempModifiedAtArray = [];

      if (data.documentAttachmentDtos) {
        data.documentAttachmentDtos.forEach((item) => {
          tempfileArray.push(item.originName);
          tempcommentArray.push(item.attach_comment);
          temptagArray.push(item.tag);
          temporiginArray.push(item.attachmentaddress);
          tempuploaderArray.push(item.upload);
          tempdateArray.push(item.date);
          tempidArray.push(item.id);
          if (item.deleted) {
            tempdeletedFileArray.push(item.id);
          }
          tempModifiedAtArray.push(item.modifiedAt ? item.modifiedAt : "");
        });
        setattachmentFileArray(tempfileArray);
        setattachmentIdArray(tempidArray);
        setattachmentCommentArray(tempcommentArray);
        setattachmentTagArray(temptagArray);
        setattachmentOriginArray(temporiginArray);
        setattachmentDateArray(tempdateArray);
        setattachmentUploaderArray(tempuploaderArray);
        setdeletedFileIdArray(tempdeletedFileArray);
        setattachmentModifiedAtArray(tempModifiedAtArray);
      }
    },
    [tokenAxios]
  );
  const params = useParams();

  useEffect(() => {
    setcurrentCategory("List");
    getdocumentData(params.documentId);
    return () => {
      initdocumentModule();
    };
  }, []);
  return (
    <DocumentReviewPageStyle>
      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, txt.doc.docInfo],
            [routeRef, txt.doc.routeInfo],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>
      <FormSectionStyle ref={formRef} isClassificationBox={isClassificationBox}>
        <DocumentInformationSection readOnly />
        <AttachmentSection
          oneLine
          readOnly
          attachmentModifiedAtArray={attachmentModifiedAtArray}
          attachmentFileArray={attachmentFileArray}
          setattachmentFileArray={setattachmentFileArray}
          attachmentTagArray={attachmentTagArray}
          setattachmentTagArray={setattachmentTagArray}
          attachmentCommentArray={attachmentCommentArray}
          attachmentOriginArray={attachmentOriginArray}
          setattachmentOriginArray={setattachmentOriginArray}
          setattachmentCommentArray={setattachmentCommentArray}
          attachmentDateArray={attachmentDateArray}
          attachmentUploaderArray={attachmentUploaderArray}
          setattachmentDateArray={setattachmentDateArray}
          setattachmentUploaderArray={setattachmentUploaderArray}
          setattachmentRef={setattachmentRef}
          title={txt.doc.docAttachment}
          // edit
          attachmentIdArray={attachmentIdArray}
          setattachmentIdArray={setattachmentIdArray}
          deletedFileIds={deletedFileIdArray}
        />
        <div className="gap" />
        <RouteSection
          activate
          routeNumber={routeNumber}
          setrouteRef={setrouteRef}
          routeData={routeData}
          setrouteData={setrouteData}
        />
      </FormSectionStyle>
    </DocumentReviewPageStyle>
  );
}
