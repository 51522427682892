import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { Link, Outlet } from "react-router-dom";
import BoldText from "../atom/BoldText";
import commonStore from "../store/commonStore";
import releaseModuleStore from "../store/releaseModuleStore";
import txt from "../Text";

const ReleaseModuleStyle = styled.div`
  margin-top: 80px;
  width: 100%;
  min-width: 1000px;
  min-height: 580px;
  height: calc(100vh - 80px);

  background-color: var(--lightGray);
  overflow: scroll;

  ::-webkit-scrollbar {
    height: 7px;
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
  position: relative;
`;
const ReleaseModulePageStyle = styled.div`
  padding-top: 24px;
  padding-right: 4%;
  width: 100%;
  min-width: 1000px;
  min-height: calc(100vh - 80px);
  //80px는 헤더 높이

  .itemInfo {
    height: 80px;
    display: flex;
    align-items: center;
    line-height: 10px;
    img {
      width: 50px;
      height: 50px;
      border-radius: 100px;
      background-color: var(--textDarkGray);
      margin-right: 5px;
    }
    .itemNumber {
      font-size: 12px;
      color: var(--textDarkGray);
      font-weight: 700;
    }
    .itemName {
      font-size: 16px;
    }
  }
`;

const CategoryBar = styled.div`
  width: 100%;
  min-width: 1000px;
  border-bottom: solid 1px var(--eciBlue);

  display: flex;

  .category {
    height: 40px;
    width: 18%;

    font-size: 12px;

    line-height: 40px;
    text-decoration: none;
    transform: translate(0, 1.5px);
  }
  .List {
    border-bottom: ${(props) =>
      props.currentCategory === "List" ? "solid 3px var(--eciBlue)" : "none"};
  }
  .Add {
    padding-left: 4px;
    border-bottom: ${(props) =>
      props.currentCategory === "Add" ? "solid 3px var(--eciBlue)" : "none"};
    .plus {
      font-size: 14px;
      color: black;
      color: ${(props) =>
        props.currentCategory === "Add"
          ? " var(--eciBlue)"
          : " var(--darkGray)"};
      margin-right: 10px;
    }
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: var(--eciBlueHover);
`;
function ReleaseModule() {
  const { setCurrentModule } = commonStore();
  const { currentCategory } = releaseModuleStore();

  useEffect(() => {
    setCurrentModule("Release");
  }, [setCurrentModule]);
  return (
    <ReleaseModuleStyle>
      {/* <LoadingBox isLoading={isLoading()}>
        <LoadingFileAnimation />
        <BoldText fontSize="26px" color="var(--eciBlue)">
          P R O C E S S I N G
        </BoldText>
        <br />
        <br />
        Please wait while we set
        <br />
        thing up for you !
      </LoadingBox> */}
      <ReleaseModulePageStyle>
        <BoldText fontSize="30px" color="var(--eciBlue)">
          Release
        </BoldText>
        <br />

        <CategoryBar>
          <div className="List category">
            <StyledLink to="/release">
              <BoldText
                fontSize="14px"
                color={
                  currentCategory === "List"
                    ? "var(--eciBlue)"
                    : "var(--darkGray)"
                }
              >
                {txt.rel.list}{" "}
              </BoldText>
            </StyledLink>
          </div>
          <div className="Add category">
            <StyledLink to="/release/add/new">
              <span className="material-icons plus">add</span>
              <BoldText
                fontSize="14px"
                color={
                  currentCategory === "Add"
                    ? "var(--eciBlue)"
                    : "var(--darkGray)"
                }
              >
                {txt.rel.addNewRelease}{" "}
              </BoldText>
            </StyledLink>
          </div>
        </CategoryBar>
        <Outlet />
      </ReleaseModulePageStyle>
    </ReleaseModuleStyle>
  );
}
export default React.memo(ReleaseModule);
