import React from "react";
import styled from "styled-components";
import RoundImgBox from "../atom/RoundImgBox";

const BOMCardStyle = styled.div`
  padding: 10px;
  margin: 10px;
  z-index: -1;
  .width {
    width: 180px;
  }
  height: 80px;
  background-color: ${(props) => (props.blue ? "var(--eciBlue)" : "white")};
  border-radius: 10px;
  border: solid 1px var(--eciBlue);
  opacity: ${(props) => (props.collapsed ? "0.6" : "1")};

  .itemName {
    margin-left: 8px;
    font-size: 12px;
    color: ${(props) => (props.blue ? "white" : "var(--eciBlue)")};
    opacity: 0.7;
    height: 20px;
  }
  .classification {
    font-size: 11px;
    color: ${(props) => (props.blue ? "white" : "var(--eciBlue)")};
  }
  .right {
    margin-left: 10px;
  }
  .name {
    font-size: 12px;
    font-weight: 700;
    color: ${(props) => (props.blue ? "white" : "var(--eciBlue)")};
  }
  .box {
    display: flex;
    align-items: center;
  }
`;
export default function BOMCard({ data, collapsed }) {
  return (
    <BOMCardStyle collapsed={collapsed} blue={data.top}>
      <div className="itemName">{data.itemName}</div>
      <div className="width" />
      <div className="box">
        <RoundImgBox
          src={
            data.thumbNail &&
            `${process.env.REACT_APP_BASE_URL}/${data.thumbNail}`
          }
        />
        <div className="right">
          <span className="classification">{data.itemClassification}</span>
          <br />
          <span className="name">{data.name}</span>{" "}
        </div>
      </div>
    </BOMCardStyle>
  );
}
