import React from "react";
import styled from "styled-components";

const RoundTextBoxStyle = styled.div`
  ${(props) => (props.width ? `width: ${props.width}` : "")};
  height: ${(props) => props.height};

  background-color: ${(props) => props.backgroundColor};
  border-radius: 10px;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.height};
  color: ${(props) => props.color};
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export default function RoundTextBox({
  children,
  backgroundColor,
  width,
  height,
  fontSize,
  color,
  center,
}) {
  return (
    <RoundTextBoxStyle
      backgroundColor={backgroundColor}
      width={width}
      height={height}
      fontSize={fontSize}
      color={color}
      center
    >
      {children}
    </RoundTextBoxStyle>
  );
}
