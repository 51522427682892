import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import PlusButton from "../../atom/PlusButton";
import TableIndexRow from "../../atom/TableIndexRow";
import TableRow from "../../atom/TableRow";
import PaginationTable from "../../molecule/PaginationTable";
import SearchBar from "../../molecule/SearchBar";
import commonStore from "../../store/commonStore";
import adminModuleStore from "../../store/AdminModuleStore";
import txt from "../../Text";
import ModalBox from "../ModalBox";
import SignUp from "../SignUp";
import BoldText from "../../atom/BoldText";
import TeamSearchUi from "../../molecule/TeamSearchUi";

const AdminListPageStyle = styled.div`
  display: flex;
  justify-content: space-between;

  .right {
    width: 77%;
  }
  .memberName {
    color: var(--eciBlue);
    text-decoration: underline;
    cursor: pointer;
  }
`;
const SearchSectionStyle = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const PositionModalStyle = styled.div`
  width: 200px;
  min-height: 240px;
  position: absolute;
  right: calc(50% - 420px);
  bottom: calc(30vh - 130px);

  background-color: white;
  border-radius: 10px;
  padding: 22px 10px;
  color: var(--textGray);
  .check {
    display: flex;
    border-bottom: solid 1px var(--textDarkGray);
    height: 40px;
    line-height: 40px;
    input,
    div {
      font-size: 12px;
      color: var(--textDarkGray);
    }
  }
`;
const TeamModalStyle = styled.div`
  width: 200px;
  height: 500px;
  position: absolute;
  right: calc(50% - 420px);
  bottom: calc(30vh - 130px);

  background-color: white;
  border-radius: 10px;
  padding: 10px 0;
  color: var(--textGray);
`;

export default function AdminListPage() {
  const { tokenAxios } = commonStore((state) => ({
    tokenAxios: state.tokenAxios,
  }));

  const {
    position,
    setposition,
    setcurrentCategory,
    addListPageUnfoldedRowIndex,
    deleteListPageUnfoldedRowIndex,
    signUpSearchBoxType,
    isModalActivate,
    setisModalActivate,
    team,
    setteam,
    signUpTeam,
    setsignUpTeam,
    initadminModule,
    infoObj,
    setinfoObj,
    isModalReadOnly,
    setisModalReadOnly,
  } = adminModuleStore();

  const [teamTreeData, setteamTreeData] = useState([]);
  const [searchWord, setsearchWord] = useState("");
  const [teamSearchWord, setteamSearchWord] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [pageNum, setpageNum] = useState("0");
  const [memberData, setmemberData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const navigate = useNavigate();
  const memberRowList =
    memberData &&
    memberData.map((item, i) => {
      return (
        <TableRow
          key={i}
          itemArray={[
            <span
              className="memberName"
              onClick={() => {
                setisModalActivate(true);
                setisModalReadOnly(true);
                //  멤버 정보 띄우기
                setinfoObj({
                  name: item.username,
                  email: item.email,
                  password: "",
                  phoneNumber: item.contact,
                  profileImage: item.profileImage,
                  ImagePreview: "",
                  isAdmin: item.admin ? "Y" : "N",
                  id: item.id,
                  position: item.positionId,
                  signUpTeam: item.departmentId,
                });
              }}
            >
              {item.username}
            </span>,
            item.department,
            item.position,
            item.admin ? "Y" : "N",
            item.email,
            item.createdAt,
          ]}
          widthArray={[2, 2, 3, 3, 4, 2]}
        />
      );
    });
  const checkList =
    signUpTeam && signUpTeam.value.split("/")[0] === "ECI-Korea"
      ? txt.admin.positionCheckListKor
      : txt.admin.positionCheckListChn;
  const checkBoxList =
    checkList &&
    checkList.map((item, index) => {
      return (
        <div className="check" key={index}>
          {/* <label htmlFor={`cb${index}`}> */}
          <input
            type="checkbox"
            id={`cb${index}`}
            checked={item === position}
            onChange={(e) => {
              if (e.target.checked) {
                setposition("");
                setposition(item);
              } else if (!e.target.checked) {
                setposition("");
              }
            }}
          />
          {/* </label> */}
          <div>{item.name}</div>
        </div>
      );
    });
  useEffect(async () => {
    const response = await tokenAxios.get("/departments");
    setteamTreeData(response.data.result.data);
  }, []);
  useEffect(() => {
    try {
      tokenAxios
        .get(
          `members/page?size=${rowsPerPage}&page=${pageNum}&name=${searchWord}${
            teamSearchWord.classification ? "&keyWord=" : ""
          }${
            teamSearchWord.classification ? teamSearchWord.classification : ""
          }`
        )
        .then((res) => {
          setmemberData(res.data.contents);
          setTotalPage(res.data.totalPages);
        })
        .catch((err) => {
          //
        });
    } catch (err) {
      // console.log(err);
    }
    return initadminModule();
  }, [setcurrentCategory, pageNum, rowsPerPage, searchWord, teamSearchWord]);
  const miniModal =
    signUpSearchBoxType === "Position" ? (
      <PositionModalStyle>
        <BoldText fontSize="12px" color="var(--textDarkGray)">
          {txt.admin.currentPosition}
        </BoldText>
        <br />
        {checkBoxList}
      </PositionModalStyle>
    ) : (
      signUpSearchBoxType === "Team" && (
        <TeamModalStyle>
          <TeamSearchUi
            width="100%"
            height="100%"
            teamTreeData={teamTreeData}
            state={signUpTeam}
            setstate={setsignUpTeam}
          />
        </TeamModalStyle>
      )
    );
  return (
    <AdminListPageStyle>
      <ModalBox isActivate={isModalActivate} setisActivate={setisModalActivate}>
        {isModalActivate && <SignUp />}
        {miniModal}
      </ModalBox>

      <TeamSearchUi
        width="22%"
        height="58vh"
        teamTreeData={teamTreeData}
        state={teamSearchWord}
        setstate={setteamSearchWord}
      />

      <div className="right">
        <SearchSectionStyle>
          <SearchBar
            width="170px"
            height="30px"
            placeholder="Search Items"
            state={searchWord}
            setState={setsearchWord}
          />

          <div className="buttons">
            {" "}
            <PlusButton
              // width="140px"
              height="30px"
              onClick={() => {
                setisModalActivate(true);
              }}
            >
              {txt.admin.addNewMember}{" "}
            </PlusButton>
          </div>
        </SearchSectionStyle>
        <PaginationTable
          width="100%"
          minHeight="calc(58vh - 40px)"
          totalPage={totalPage}
          rowsPerPageDefault={10}
          pageNum={pageNum}
          setRowsPerPage={setRowsPerPage}
          setpageNum={setpageNum}
          rowsPerPage={rowsPerPage}
          noshadow
        >
          <TableIndexRow
            itemArray={txt.admin.listIndexArray}
            widthArray={[2, 2, 3, 3, 4, 2]}
          />
          {memberRowList}
        </PaginationTable>
      </div>
    </AdminListPageStyle>
  );
}
