import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import LineTitle from "../atom/LineTitle";

import AnimationInput from "../molecule/AnimationInput";
import AnimationSelectBox from "../molecule/AnimationSelectBox";
import SearchInput from "../molecule/SearchInput";

import TextBox from "../molecule/TextBox";
import commonStore from "../store/commonStore";
import documentModuleStore from "../store/DocumentModuleStore";
import txt from "../Text";

const DocumentInformationSectionStyle = styled.div`
  pointer-events: ${(props) => (props.readOnly ? "none" : "")};

  .gridContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: (60, 60px, 60px, 200px);
    grid-column-gap: 15px;
    grid-row-gap: 10px;
    .blueBox {
      grid-column: 1/3;
      padding: 15px;
      background-color: var(--eciBlue);
      border-radius: 10px;
      margin-bottom: 10px;
    }
  }
`;
export default function DocumentInformationSection({
  readOnly,
  setisClassificationBox,
}) {
  const {
    // release
    documentClassification,
    setdocumentClassification,
    documentNumber,
    setdocumentNumber,
    documentTag,
    setdocumentTag,
    documentTitle,
    setdocumentTitle,
    revision,
    setrevision,
    documentContent,
    setdocumentContent,
    // etc
    isRevise,
    // date
    setsearchBoxType,
    setinformationRef,
  } = documentModuleStore();
  const tokenAxios = commonStore((state) => state.tokenAxios);

  const informationRef = useRef();
  useEffect(() => {
    setinformationRef(informationRef);
  }, [setinformationRef]);
  return (
    <DocumentInformationSectionStyle ref={informationRef} readOnly={readOnly}>
      <LineTitle
        fontSize="16px"
        color="var(--eciBlue)"
        width="100%"
        height="60px"
      >
        {txt.doc.docInfo}{" "}
      </LineTitle>
      <div className="gridContainer">
        <AnimationInput
          width="100%"
          height="40px"
          placeholder={txt.doc.revision}
          state={revision}
          setState={setrevision}
          readOnly
          backgroundColor="var(--disableGray)"
        />
        <div />
        <SearchInput
          width="100%"
          height="40px"
          placeholder={txt.doc.docClassification}
          required
          state={documentClassification.value}
          onClick={() => {
            setisClassificationBox(true);
            setsearchBoxType("");
          }}
          readOnly={readOnly || isRevise}
          backgroundColor={isRevise && "var(--disableGray)"}
          blue
        />
        <AnimationInput
          width="100%"
          height="40px"
          placeholder={txt.doc.docNumber}
          state={documentNumber}
          setState={setdocumentNumber}
          readOnly
          backgroundColor="var(--disableGray)"
        />
        {/* <AnimationInput // selectbox로 바꿀수도
          width="100%"
          height="40px"
          placeholder={txt.doc.docTag}
          state={documentTag.name}
          setState={setdocumentTag}
          readOnly
          backgroundColor="var(--disableGray)"
        /> */}

        <div className="blueBox">
          <AnimationInput
            width="50%"
            height="40px"
            placeholder={txt.doc.title}
            state={documentTitle}
            setState={setdocumentTitle}
            readOnly={readOnly}
            required
          />
          <TextBox
            width="100%"
            height="100px"
            placeholder={txt.doc.description}
            required
            state={documentContent}
            setState={setdocumentContent}
            readOnly={readOnly}
          />
        </div>
      </div>
    </DocumentInformationSectionStyle>
  );
}
