import React from "react";
import styled from "styled-components";
import RoundTextBox from "../atom/RoundTextBox";
import TableRow from "../atom/TableRow";
import PencilInput from "./PencilInput";
import text from "../Text";

const BOMPropRowStyle = styled.div`
  width: calc(${(props) => props.width});
  height: 40px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  margin-bottom: 20px;
  .con {
    display: flex;
    align-items: center;
    position: relative;
    .delete {
      cursor: pointer;
      position: absolute;
      right: -30px;
      top: 10px;
      background-color: var(--lightGray);
    }
  }
`;
export default function BOMPropRow({
  width,
  readOnly,
  name,
  classification,
  type,
  sharing,
  setname,
  setclassification,
  settype,
  setsharing,
  onDelete,
  backgroundColor,
  searchOnly,
}) {
  return (
    <BOMPropRowStyle width={width} backgroundColor={backgroundColor}>
      {searchOnly || readOnly ? (
        <div className="con">
          <TableRow
            width="100%"
            widthArray={[1, 1, 1, 1]}
            itemArray={[
              classification,

              name,
              /*eslint-disable */
              type,
              sharing ? (
                sharing === "공용" ? (
                  <RoundTextBox
                    width="40%"
                    backgroundColor="var(--lightGray)"
                    color="var(--eciBlue)"
                    height="30px"
                  >
                    {text.item.sharing}
                  </RoundTextBox>
                ) : (
                  <RoundTextBox
                    width="40%"
                    backgroundColor="var(--lightGray)"
                    color="var(--eciBlue)"
                    height="30px"
                  >
                    {text.item.notSharing}{" "}
                  </RoundTextBox>
                )
              ) : (
                ""
              ),
            ]}
            noBorder
          />
          {!readOnly && (
            <span className="material-icons delete" onClick={onDelete}>
              delete
            </span>
          )}
        </div>
      ) : (
        <div className="con">
          <TableRow
            width="100%"
            widthArray={[1, 1, 1, 1]}
            noBorder
            itemArray={[
              <PencilInput
                width="100%"
                height="30px"
                state={classification}
                setState={setclassification}
                backgroundColor="var(--lightGray)"
              />,
              <PencilInput
                width="100%"
                height="30px"
                state={name}
                setState={setname}
                backgroundColor="var(--lightGray)"
              />,

              <PencilInput
                width="100%"
                height="30px"
                state={type}
                setState={settype}
                backgroundColor="var(--lightGray)"
              />,
              <PencilInput
                width="100%"
                height="30px"
                state={sharing}
                setState={setsharing}
                backgroundColor="var(--lightGray)" // check
              />,
            ]}
          />
          {
            //! readOnly && (
            <span className="material-icons delete" onClick={onDelete}>
              delete
            </span>
            // )
          }
        </div>
      )}
    </BOMPropRowStyle>
  );
}
