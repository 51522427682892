import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import RoundImgBox from "../../atom/RoundImgBox";
import RoundTextBox from "../../atom/RoundTextBox";
import StatusTag from "../../atom/StatusTag";
import TableIndexRow from "../../atom/TableIndexRow";
import TableRow from "../../atom/TableRow";
import PaginationTable from "../../molecule/PaginationTable";
import commonStore from "../../store/commonStore";
import itemModuleStore from "../../store/itemModuleStore";
import resultText from "../../Text";
import FilterSearchBox from "../../molecule/FilterSearchBox";
import RevisionItemRow from "../../molecule/RevisionItemRow";

const ItemListPageStyle = styled.div``;
const SearchSectionStyle = styled.div`
  margin: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export default function ItemListPage() {
  const { tokenAxios } = commonStore((state) => ({
    tokenAxios: state.tokenAxios,
  }));

  const { setcurrentCategory, setprojectNumber } = itemModuleStore();
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [pageNum, setpageNum] = useState("0");
  const [itemArray, setitemArray] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [searchText, setsearchText] = useState("");
  const [itemName, setitemName] = useState("");
  const [itemNumber, setitemNumber] = useState("");
  const [carType, setcarType] = useState("");

  const itemRowList =
    itemArray &&
    itemArray.map((item, i) => {
      return <RevisionItemRow dataObj={item} key={i} />;
    });
  useEffect(() => {
    setcurrentCategory("List");
    try {
      tokenAxios
        .get(
          `/items/page?size=${rowsPerPage}&page=${pageNum}&name=${searchText}&itemName=${itemName}&itemNumber=${itemNumber}&carTypeId=${carType}`
        )
        .then((res) => {
          setitemArray(res.data.content);
          setTotalPage(res.data.totalPages);
        })
        .catch((err) => {
          //
        });
    } catch (err) {
      // console.log(err);
    }
  }, [
    setcurrentCategory,
    pageNum,
    rowsPerPage,
    searchText,
    itemName,
    itemNumber,
    carType,
  ]);
  useEffect(() => {
    setpageNum("0");
  }, [searchText, itemName, itemNumber, carType]);
  return (
    <ItemListPageStyle>
      <SearchSectionStyle>
        {/* <SearchBar
          width="170px"
          height="30px"
          placeholder="Search Items"
          state={searchText}
          setState={setsearchText}
        /> */}
        <FilterSearchBox
          searchText={searchText}
          setsearchText={setsearchText}
          setitemName={setitemName}
          setitemNumber={setitemNumber}
          setcarType={setcarType}
        />
      </SearchSectionStyle>
      <PaginationTable
        width="100%"
        minHeight="300px"
        totalPage={totalPage}
        rowsPerPageDefault={10}
        pageNum={pageNum}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setpageNum={setpageNum}
        noshadow
      >
        <TableIndexRow
          itemArray={resultText.item.itemListIndexArray}
          widthArray={[1, 3, 3, 3, 3, 1, 2, 2, 2]}
        />
        {itemRowList}
      </PaginationTable>
    </ItemListPageStyle>
  );
}
