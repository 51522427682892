import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";
import designModuleStore from "../../store/DesignModuleStore";
import AttachmentSection from "../AttachmentSection";
import DesignInformationSection from "../DesignInformationSection";
import RouteSection from "../RouteSection";
import resultText from "../../Text";

const DesignDetailPageStyle = styled.div`
  display: flex;
  position: relative;
  padding-top: 10px;
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  left: 0;
`;
const FormSectionStyle = styled.div`
  padding-left: 18%;
  padding-right: 1.5%;
  width: 58%;
  height: calc(100vh - 280px);
  overflow-y: scroll;
  overflow-x: visible;
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }

  .gap {
    height: 45px;
  }
`;

export default function DesignDetailPage() {
  const { tokenAxios } = commonStore();

  const {
    setcadFile,
    setdesignItemInfo,
    setisMatchConfirmed,
    setcadFileList,
    setdesignId,
    setuploaderType,
    // attachment
    attachmentFileArray,
    attachmentTagArray,
    attachmentCommentArray,
    attachmentOriginArray,
    attachmentDateArray,
    attachmentUploaderArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    setattachmentOriginArray,
    setattachmentDateArray,
    setattachmentUploaderArray,
    attachmentModifiedAtArray,
    setattachmentModifiedAtArray,
    attachmentIdArray,
    setattachmentIdArray,
    deletedFileIdArray,
    setdeletedFileIdArray,
    // route
    routeNumber,
    routeData,
    setrouteData,
    setrouteNumber,
    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // etc
    initdesignModule,
  } = designModuleStore();
  const params = useParams();

  const getDesignData = useCallback(
    async (itemID) => {
      const response = await tokenAxios.get(`design/${itemID}`);
      const { data } = response.data.result;
      // design
      setcadFileList(JSON.parse(data.designContent));
      setcadFile({ name: JSON.parse(data.designContent).title });
      setisMatchConfirmed(true);

      setdesignId(itemID);
      let status;
      if (data.uploaderType === "cad") {
        status = "Catia";
      } else if (data.uploaderType === "cadian") {
        status = "Cadian";
      } else {
        status = "Link";
      }
      setuploaderType(status);

      setdesignItemInfo({
        designItemName: data.item.name,
        designItemId: data.item.id,
        designItemNumber: data.item.itemNumber,
        designItemImageAddress:
          data.item.imageAddress &&
          `${process.env.REACT_APP_BASE_URL}/${data.item.imageAddress}`,
      });

      // attachment
      const tempfileArray = [];
      const temptagArray = [];
      const tempcommentArray = [];
      const temporiginArray = [];
      const tempuploaderArray = [];
      const tempdateArray = [];
      const tempidArray = [];
      const tempdeletedFileArray = [];
      const tempModifiedAtArray = [];

      if (data.designAttachments) {
        data.designAttachments.forEach((item) => {
          tempfileArray.push(item.originName);
          tempcommentArray.push(item.attach_comment);
          temptagArray.push(item.tag);
          temporiginArray.push(item.attachmentaddress);
          tempuploaderArray.push(item.upload);
          tempdateArray.push(item.date);
          tempidArray.push(item.id);
          if (item.deleted) {
            tempdeletedFileArray.push(item.id);
          }
          tempModifiedAtArray.push(item.modifiedAt ? item.modifiedAt : "");
        });
      }

      setattachmentFileArray(tempfileArray);
      setattachmentIdArray(tempidArray);
      setattachmentCommentArray(tempcommentArray);
      setattachmentTagArray(temptagArray);
      setattachmentOriginArray(temporiginArray);
      setattachmentDateArray(tempdateArray);
      setdeletedFileIdArray(tempdeletedFileArray);
      setattachmentUploaderArray(tempuploaderArray);
      setattachmentModifiedAtArray(tempModifiedAtArray);

      setrouteNumber(data.routeId);
    },
    [tokenAxios, setdesignItemInfo, setrouteNumber]
  );
  useEffect(() => {
    getDesignData(params.designId);
    return () => {
      initdesignModule();
    };
  }, [getDesignData, initdesignModule]);

  const formRef = useRef();
  return (
    <DesignDetailPageStyle>
      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, resultText.design.designInformation],
            [attachmentRef, resultText.design.designAttachment],
            [routeRef, resultText.design.routeInformation],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>

      <FormSectionStyle ref={formRef}>
        <DesignInformationSection readOnly />
        <AttachmentSection
          attachmentModifiedAtArray={attachmentModifiedAtArray}
          readOnly
          attachmentFileArray={attachmentFileArray}
          setattachmentFileArray={setattachmentFileArray}
          attachmentTagArray={attachmentTagArray}
          setattachmentTagArray={setattachmentTagArray}
          attachmentCommentArray={attachmentCommentArray}
          attachmentOriginArray={attachmentOriginArray}
          setattachmentOriginArray={setattachmentOriginArray}
          setattachmentCommentArray={setattachmentCommentArray}
          attachmentDateArray={attachmentDateArray}
          attachmentUploaderArray={attachmentUploaderArray}
          setattachmentDateArray={setattachmentDateArray}
          setattachmentUploaderArray={setattachmentUploaderArray}
          setattachmentRef={setattachmentRef}
          title={resultText.design.designAttachment}
          // edit
          attachmentIdArray={attachmentIdArray}
          setattachmentIdArray={setattachmentIdArray}
          deletedFileIds={deletedFileIdArray}
        />
        <div className="gap" />
        <RouteSection
          activate
          readOnly
          routeNumber={routeNumber}
          setrouteRef={setrouteRef}
          routeData={routeData}
          setrouteData={setrouteData}
        />{" "}
      </FormSectionStyle>
    </DesignDetailPageStyle>
  );
}
