import React from "react";
import styled from "styled-components";
import PencilInput from "./PencilInput";

const BOMNumberRowStyle = styled.div`
  width: calc(${(props) => props.width});
  height: 40px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 16px;
  font-size: 13px;
  font-family: "Helvetica";
  color: var(--deemGray);
  .inputContainer {
    display: flex;
    align-items: center;
    height: 35px;
  }
`;
export default function BOMNumberRow({
  readOnly,
  state,
  setState,
  openSearchBox,
  width,
  searchOnly,
  backgroundColor,
}) {
  return (
    <BOMNumberRowStyle width={width} backgroundColor={backgroundColor}>
      {readOnly ? (
        <div>{state}</div>
      ) : (
        <div className="inputContainer">
          <span className="material-icons icon" onClick={openSearchBox}>
            search
          </span>
          {searchOnly ? (
            <div>{state}</div>
          ) : (
            <PencilInput
              width="80%"
              height="30px"
              state={state}
              setState={setState}
              backgroundColor="#F2F4F8"
            />
          )}
        </div>
      )}
    </BOMNumberRowStyle>
  );
}
