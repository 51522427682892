import React, { useState } from "react";
import styled from "styled-components";
import resultText from "../Text";

const ProjectManageButtonStyle = styled.div`
  width: 80%;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 4px;
  border: solid 1px var(--eciBlue);
  background-color: #f2f4f8;
  color: var(--eciBlue);
  font-weight: 600;
  opacity: ${(props) => (props.disable ? 0.4 : 1)};
  cursor: ${(props) => (props.disable ? "" : "pointer")};
`;
export default function ProjectManageButton({
  disable,
  onClick,
  children,
  isactive,
}) {
  const [isActive, setisActive] = useState(isactive);
  const onButtonClick = async (e) => {
    await onClick(e);
    setisActive(!isActive);
  };
  return (
    <ProjectManageButtonStyle disable={disable} onClick={onButtonClick}>
      {children}

      {!disable && isActive && resultText.recover}
    </ProjectManageButtonStyle>
  );
}
