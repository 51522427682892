import create from "zustand";

const documentModuleStore = create((set, get) => ({
  currentCategory: "List",
  setcurrentCategory: (val) => {
    set(() => ({ currentCategory: val }));
  },
  // searchBox
  searchBoxType: "",
  setsearchBoxType: (val) => {
    set(() => ({ searchBoxType: val }));
  },
  setsearchBoxProperty: (target, val, index) => {
    if (target === "releaseOrganizationId") {
      const targetArray = get()[`${target}`];
      set(() => ({ [target]: [...targetArray, val] }));
    } else if (index) {
      if (target === "members") {
        const tmpmember = get().members;
        if (tmpmember[index]) {
          tmpmember[index] = [...tmpmember[index], val];
        } else {
          tmpmember[index] = [val];
        }
        set(() => ({ members: tmpmember }));
      } else {
        const targetArray = get()[`${target}`];
        if (Array.isArray(targetArray)) {
          targetArray[index] = val;
        }
        set(() => ({ [target]: targetArray }));
      }
    } else {
      set(() => ({ [target]: val }));
    }
  },
  deletemember: (id, index) => {
    const tmpmemberArr = get().members;
    const newArr =
      tmpmemberArr[index] && tmpmemberArr[index].filter((mem) => mem.id !== id);

    if (newArr[0]) {
      tmpmemberArr[index] = newArr;
    } else {
      tmpmemberArr.splice(index, 1);
    }
    set(() => ({ member: tmpmemberArr }));
  },
  // document
  setdocumentInfo: (val) => {
    set(() => val);
  },
  documentId: "",
  setdocumentId: (val) => {
    set(() => ({ documentId: val }));
  },
  documentClassification: "",
  setdocumentClassification: (val) => {
    set(() => ({
      documentClassification: val,
      classification1: val.classification && val.classification.split("/")[0],
      classification2: val.classification && val.classification.split("/")[1],
    }));
    if (val.tag) {
      set(() => ({
        documentTag: val.tag,
      }));
    }
  },
  classification1: "",
  classification2: "",

  documentNumber: "",
  setdocumentNumber: (val) => {
    set(() => ({ documentNumber: val }));
  },
  documentTag: {}, // id,name,last,value,classification
  setdocumentTag: (val) => {
    set(() => ({ documentTag: val }));
  },
  documentTitle: "",
  setdocumentTitle: (val) => {
    set(() => ({ documentTitle: val }));
  },
  revision: "A",
  setrevision: (val) => {
    set(() => ({ revision: val }));
  },
  documentContent: "",
  setdocumentContent: (val) => {
    set(() => ({ documentContent: val }));
  },
  // duplicate, revise

  isRevise: false,
  setisRevise: (val) => {
    set(() => ({ isRevise: val }));
  },
  // attachment
  attachmentFileArray: [],
  setattachmentFileArray: (val) => {
    set(() => ({ attachmentFileArray: val }));
  },
  attachmentOriginArray: [],
  setattachmentOriginArray: (val) => {
    const newVal =
      val &&
      val.map((item) => {
        return item.toString();
      });
    set(() => ({
      attachmentOriginArray: newVal,
    }));
  },
  attachmentTagArray: [],
  setattachmentTagArray: (val) => {
    set(() => ({ attachmentTagArray: val }));
  },

  attachmentCommentArray: [],
  setattachmentCommentArray: (val) => {
    set(() => ({ attachmentCommentArray: val }));
  },
  attachmentUploaderArray: [],
  setattachmentUploaderArray: (val) => {
    set(() => ({ attachmentUploaderArray: val }));
  },
  attachmentDateArray: [],
  setattachmentDateArray: (val) => {
    set(() => ({ attachmentDateArray: val }));
  },
  setattachmentInfo: (val) => {
    set(() => val);
  },
  // edit attachment
  // edit attachment
  deletedFileIdArray: [],
  setdeletedFileIdArray: (val) => {
    set(() => ({ deletedFileIdArray: val }));
  },
  attachmentIdArray: [],
  setattachmentIdArray: (val) => {
    set(() => ({ attachmentIdArray: val }));
  },
  attachmentModifiedAtArray: [],
  setattachmentModifiedAtArray: (val) => {
    set(() => ({ attachmentModifiedAtArray: val }));
  },
  addedAttachmentArray: [],
  setaddedAttachmentArray: (val) => {
    set(() => ({ addedAttachmentArray: val }));
  },
  addedAttachmentTagArray: [],
  setaddedAttachmentTagArray: (val) => {
    set(() => ({ addedAttachmentTagArray: val }));
  },
  addedAttachmentCommentArray: [],
  setaddedAttachmentCommentArray: (val) => {
    set(() => ({ addedAttachmentCommentArray: val }));
  },
  deletedAttachmentArray: [],
  setdeletedAttachmentArray: (val) => {
    set(() => ({ deletedAttachmentArray: val }));
  },
  // route
  routeNumber: "",
  setrouteNumber: (val) => {
    set(() => ({ routeNumber: val }));
  },
  routeData: [],
  setrouteData: (val) => {
    set(() => ({ routeData: val }));
  },
  // routeinit
  members: [],
  setmembers: (val) => {
    set(() => ({ members: val }));
  },
  targetMember: "",
  settargetMember: (val) => {
    set(() => ({ targetMember: val }));
  },
  // 스크롤 ref 모음
  informationRef: "",
  setinformationRef: (val) => {
    set(() => ({ informationRef: val }));
  },
  attachmentRef: "",
  setattachmentRef: (val) => {
    set(() => ({ attachmentRef: val }));
  },
  routeRef: "",
  setrouteRef: (val) => {
    set(() => ({ routeRef: val }));
  },

  // 초기화 함수
  initdocumentModule: () => {
    set(() => ({
      // release
      documentId: "",
      documentTag: "",
      documentContent: "",
      revision: "A",
      documentClassification: "",
      classification1: "",
      classification2: "",
      documentNumber: "",
      documentTitle: "",
      isRevise: false,
      // atta
      attachmentTagOptionList: [],
      attachmentFileArray: [],
      attachmentOriginArray: [],
      attachmentTagArray: [],
      attachmentCommentArray: [],
      attachmentUploaderArray: [],
      attachmentModifiedAtArray: [],

      addedAttachmentArray: [],
      addedAttachmentTagArray: [],
      addedAttachmentCommentArray: [],
      deletedAttachmentArray: [],
      searchBoxType: "",
      routeNumber: "",
      routeData: [],
      members: [],
      targetMember: "",
    }));
  },
}));
export default documentModuleStore;
