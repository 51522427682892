import React, { useRef, useEffect, useState } from "react";

import styled from "styled-components";

const InputContainerStyle = styled.div`
  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .fillIn {
    height: 15px;
    margin-top: 5px;
    line-height: 15px;
    font: 8px red;
    color: ${(props) => props.color};
  }
`;
const InputStyle = styled.input`
  width: 100%;
  height: calc(${(props) => props.height});
  border: none;
  border-bottom: ${(props) =>
    props.requiredError ? "solid red 2px" : "none"} !important;
  background-color: transparent;

  color: ${(props) => props.color};
  pointer-events: ${(props) => (props.readOnly ? "none" : "")};

  font-size: ${(props) =>
    props.fontSize ? props.fontSize : `calc((${props.height}) / 5 * 2.2)`};
  ::placeholder {
    color: ${(props) => props.color};
    font-weight: 100;
  }
  :focus {
    outline-width: 0;
  }
  outline-width: 0;

  :focus::placeholder {
    color: transparent;
  }
  //date type
  ${(props) =>
    props.value
      ? ""
      : `::-webkit-datetime-edit-text {
    -webkit-appearance: none;
    display: none;
  }
  ::-webkit-datetime-edit-month-field {
    -webkit-appearance: none;
    display: none;
  }
  ::-webkit-datetime-edit-day-field {
    -webkit-appearance: none;
    display: none;
  }
  ::-webkit-datetime-edit-year-field {
    -webkit-appearance: none;
    display: none;
  }`}
`;

export default function Input({
  width,
  height,
  placeholder,
  setState,
  name,
  color,
  state,
  type,
  requiredError,
  fontSize,
  late,
  pattern,
  errorMessage,
  onKeyDown,
  required,
  readOnly,
}) {
  let timer;
  const inputRef = useRef();
  const [isError, setisError] = useState(false);
  const regEx = new RegExp(pattern);
  const handleText = (e) => {
    e.preventDefault();

    // uncontrolled input으로 사용을 하면서, 디바운서를 이용해 200ms동안 입력이 없을 경우 setstate를 진행
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(
      () => {
        if (setState) {
          if (pattern) {
            // console.log(regEx.test(e.target.value));
            setisError(!regEx.test(e.target.value));
          }
          setState(e.target.value);
        }
      },
      late ? 600 : 300
    );
  };
  useEffect(() => {
    if (state) {
      inputRef.current.value = state;
    } else {
      inputRef.current.value = "";
    }
  }, [state]);
  return (
    <InputContainerStyle
      width={width}
      height={`${height} + 20px`}
      color={color}
    >
      <InputStyle
        width={width}
        height={height}
        fontSize={fontSize}
        color={color}
        readOnly={readOnly}
        placeholder={placeholder ? placeholder + (required ? "*" : "") : ""}
        onChange={handleText}
        name={name}
        ref={inputRef}
        type={type}
        requiredError={isError}
        onKeyDown={onKeyDown || (() => {})}
        // pattern={pattern || ""}
      />
      {isError ? <div className="fillIn">{errorMessage}</div> : ""}
    </InputContainerStyle>
  );
}
