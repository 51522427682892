import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { Outlet, useNavigate } from "react-router-dom";
import BoldText from "../atom/BoldText";
import commonStore from "../store/commonStore";
import designModuleStore from "../store/DesignModuleStore";
import resultText from "../Text";

const DesignModuleStyle = styled.div`
  margin-top: 80px;
  width: 100%;
  min-width: 1000px;
  min-height: 580px;
  height: calc(100vh - 80px);

  background-color: var(--lightGray);
  overflow: scroll;

  ::-webkit-scrollbar {
    height: 7px;
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
  position: relative;
`;
const DesignModulePageStyle = styled.div`
  padding-right: 4%;
  width: 100%;
  min-width: 1000px;
  /* min-height: calc(100vh - 80px); */
  //80px는 헤더 높이

  .itemInfo {
    height: 80px;
    display: flex;
    align-items: center;
    line-height: 10px;
    img {
      width: 50px;
      height: 50px;
      border-radius: 100px;
      background-color: var(--textDarkGray);
      margin-right: 5px;
    }
    .itemNumber {
      font-size: 12px;
      color: var(--textDarkGray);
      font-weight: 700;
    }
    .itemName {
      font-size: 16px;
    }
  }
  .colbox {
    cursor: pointer;
  }
`;

const CategoryBar = styled.div`
  width: 100%;
  min-width: 1000px;
  border-bottom: solid 1px var(--eciBlue);

  display: flex;

  .category {
    height: 40px;
    width: 18%;

    font-size: 12px;

    line-height: 40px;
    text-decoration: none;
    transform: translate(0, 1.5px);
    padding-left: 4px;
    border-bottom: solid 3px var(--eciBlue);
    .plus {
      font-size: 14px;
      color: black;
      color: var(--eciBlue);
      margin-right: 10px;
    }
  }
`;

function DesignModule() {
  const { setCurrentModule } = commonStore();
  const {
    isLoading,
    designItemName,
    designItemNumber,
    designItemImageAddress,
    designItemId,
  } = designModuleStore();
  const navigate = useNavigate();
  useEffect(() => {
    setCurrentModule("Item");
  }, [setCurrentModule]);
  return (
    <DesignModuleStyle>
      <DesignModulePageStyle isLoading={isLoading()}>
        <BoldText fontSize="30px" color="var(--eciBlue)">
          {resultText.design.design}
        </BoldText>
        <br />
        <div className="itemInfo">
          <img src={designItemImageAddress} alt=" " />
          <div
            className="colbox"
            onClick={() => {
              navigate(`/item/detail/${designItemId}`);
            }}
          >
            <span className="itemNumber">{designItemNumber}</span>
            <br />
            <br />
            <span className="itemName">{designItemName}</span>
          </div>
        </div>
        <CategoryBar>
          <div className="category">
            <span className="material-icons plus">add</span>
            <BoldText fontSize="14px" color="var(--eciBlue)">
              {resultText.design.addNewDesign}
            </BoldText>
          </div>
        </CategoryBar>
        <Outlet />
      </DesignModulePageStyle>
    </DesignModuleStyle>
  );
}
export default React.memo(DesignModule);
