import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import BoldText from "../../atom/BoldText";
import Button from "../../atom/Button";
import RoundTextBox from "../../atom/RoundTextBox";
import TableIndexRow from "../../atom/TableIndexRow";
import TableRow from "../../atom/TableRow";
import BOMNumberTree from "../../molecule/BOMNumberTree";
import BOMPropTree from "../../molecule/BOMPropTree";
import PaginationTable from "../../molecule/PaginationTable";
import ScrollController from "../../molecule/ScrollController";
import BOMModuleStore from "../../store/BOMModuleStore";
import commonStore from "../../store/commonStore";
import ModalBox from "../ModalBox";
import RouteSection from "../RouteSection";
import text from "../../Text";
import FilterSearchBox from "../../molecule/FilterSearchBox";
import ExportExcelButton from "../ExportExcelButton";

const DevelopBOMStyle = styled.div`
  width: 100%;
  padding-left: 30px;

  .top {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: space-between;
  }
  .index {
    margin-left: 30px;
  }
  .treeBox {
    width: calc(100% + 85px);
    display: flex;
    transform: translatex(-60px);
    .numberBox {
      width: 28%;
      overflow-x: scroll;

      overflow-y: visible;
      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      ::-webkit-scrollbar-track {
        background-color: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        border: 15px solid transparent;
        width: 5px;
        background: rgba(4, 8, 15, 0.3);
        border-radius: 8px;
      }
    }
    .propBox {
      width: 72%;
    }
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`;
const FormSectionStyle = styled.div`
  width: 100%;
  height: ${(props) =>
    props.isClassificationBox ? "760px" : "calc(100vh - 200px)"};

  padding-left: 18%;
  padding-right: 2%;
  padding-top: 20px;
  height: calc(100vh - 300px);
  overflow-y: scroll;
  overflow-x: visible;

  ::-webkit-scrollbar {
    height: 7px;
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
  .routeContainer {
    width: 50%;
    /* opacity: ${(props) => (props.isRouteActivate ? 1 : 0)}; */
  }
`;
export default function DevelopBOM() {
  const tokenAxios = commonStore((state) => state.tokenAxios);
  const {
    developId,
    developType,
    developTree,
    setdevelopTree,
    searchBoxIndexArray,
    setsearchBoxIndexArray,
    setdevelopTreeRow,
    routeData,
    setrouteData,
    isRouteActivate,
    setisRouteActivate,
    routeNumber,
    setrouteNumber,
    setrouteRef,
    setdevelopTreeState,
    adddevelopTreeState,
    developAddedRowIds,
    developAddedRowParentIds,
    routeRef,
    adddevelopFoldedRowIndex,
    deletedevelopFoldedRowIndex,
    deletedevelopTreeState,
    developFoldedRowIndex,
  } = BOMModuleStore();

  const formRef = useRef();
  const informationRef = useRef();
  const [isReject, setisReject] = useState(false);
  // const [isRevise, setisRevise] = useState(false);
  const [isFirstTempId, setisFirstTempId] = useState(false);

  const getData = async () => {
    let response = await tokenAxios.get(`/development/${developId}`);
    setrouteNumber(response.data.result.data.routeId);
    const tmpTree = response.data.result.data.developmentBom;
    if (
      response.data.result.data.reviseId > 0 &&
      response.data.result.data.edit !== true
    ) {
      // setisRevise(true);
      response = await tokenAxios.get(
        `/development/${response.data.result.data.reviseId}`
      );
    } else {
      setisRouteActivate(response.data.result.data.readonly);
    }
    tmpTree.children = response.data.result.data.developmentBom.children;
    setdevelopTree(tmpTree);
    // edit 도 devType add로 와

    if (developType === "review" || developType === "readonly") {
      setisRouteActivate(true);
    }
    setisReject(response.data.result.data.preRejected);
  };
  const navigate = useNavigate();
  const saveBOM = async () => {
    const formdata = new FormData();
    formdata.append("childId", developAddedRowIds);
    formdata.append("parentId", developAddedRowParentIds);
    formdata.append("devId", developId);
    try {
      const response = await tokenAxios.post("/development", formdata);
      setrouteNumber(response.data.result.data.routeId);
      setisRouteActivate(true);

      alert("ok!");
    } catch (error) {
      if (error.response.data.result) {
        console.log(error.response);
        alert(error.response.data.result.msg);
      } else {
        console.log(error.response);
        // navigate("/notFound");
      }
    }
  };

  const tempSave = async () => {
    const formData = new FormData();
    formData.append("childId", developAddedRowIds);
    formData.append("parentId", developAddedRowParentIds);
    formData.append("devId", developId);
    try {
      // if (isFirstTempId) {
      //   const response = await tokenAxios.put(
      //     `/development/temp/${isFirstTempId}`,
      //     formData
      //   );
      // } else {
      const response = await tokenAxios.post("/development/temp", formData);
      //   setisFirstTempId(response.data.result.data.id);
      // }
      alert(text.approveDone);
    } catch (error) {
      if (error.response.data.result) {
        console.log(error.response);
        alert(error.response.data.result.msg);
      } else {
        console.log(error.response);
        // navigate("/notFound");
      }
    }
  };
  // Modal
  const [searchText, setsearchText] = useState("");
  const [itemName, setitemName] = useState("");
  const [itemNumber, setitemNumber] = useState("");
  const [carType, setcarType] = useState("");
  const [carTypeArr, setcarTypeArr] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [pageNum, setPageNum] = useState("0");
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [dataList, setdataList] = useState([]);
  const rows =
    dataList &&
    dataList[0] &&
    dataList[0].map((item, i) => {
      return (
        <TableRow
          key={i}
          widthArray={[0.2, 1, 1, 1, 1, 1]}
          itemArray={[
            "",
            item && item.cardNumber,
            item && item.classification,
            item && item.cardName,
            item && item.cardType && item.cardType,
            item && item.sharing && item.sharing === "공용" ? (
              <RoundTextBox
                width="50%"
                backgroundColor="var(--lightGray)"
                color="var(--eciBlue)"
                height="30px"
              >
                {text.item.sharing}
              </RoundTextBox>
            ) : (
              <RoundTextBox
                width="50%"
                backgroundColor="var(--lightGray)"
                color="var(--eciBlue)"
                height="30px"
              >
                {text.item.notSharing}{" "}
              </RoundTextBox>
            ),
          ]}
          onClick={() => {
            setdevelopTreeRow(
              {
                classification: item && item.classification,
                cardName: item && item.cardName,
                cardType: item && item.cardType && item.cardType,
                cardNumber: item && item.cardNumber,
                sharing: item && item.sharing,
                children: item && item.children,
                cardId: item && item.cardId,
                plusPossible: item && item.plusPossible,
              },
              ...searchBoxIndexArray
            );
            setsearchBoxIndexArray([]);
          }}
        />
      );
    });

  const getModalData = async () => {
    if (developId) {
      const response = await tokenAxios.get(
        `/dev/bom/items/page/${developId}?size=${rowsPerPage}&page=${pageNum}&name=${searchText}&itemName=${itemName}&itemNumber=${itemNumber}&carTypeId=${carType}`
      );

      setdataList([response.data.content]);
      setTotalPage(response.data.totalPages);
    }
  };

  function flattenData(data, depth = 0, parent = null, res = []) {
    // 컬럼 순서대로 데이터 추출
    const filteredData = {
      depth, // 중첩 깊이
      ItemNumber: data.cardNumber,
      ItemName: data.cardName,
      classification: data.classification,
      Type: data.cardType,
      sharing: data.sharing,
      revision: data.revision,
    };

    res.push(filteredData);

    if (data.children) {
      data.children.forEach((child) =>
        flattenData(child, depth + 1, data, res)
      );
    }

    return res;
  }

  // 모달 끝
  useEffect(() => {
    if (developId) {
      getData();
    }
    getModalData();
  }, [developId]);
  useEffect(() => {
    getModalData();
  }, [searchText, itemName, itemNumber, carType, pageNum, rowsPerPage]);
  return (
    <DevelopBOMStyle>
      <ExportExcelButton
        data={flattenData(developTree)}
        cssStyle="margin-left: auto;"
        title={`${developTree.cardNumber}-development-BOM.xlsx`}
      />
      <ModalBox
        isActivate={searchBoxIndexArray.length > 0}
        setisActivate={(bool) => {
          if (!bool) {
            setsearchBoxIndexArray([]);
          }
        }}
      >
        <FilterSearchBox
          searchText={searchText}
          setsearchText={setsearchText}
          setitemName={setitemName}
          setitemNumber={setitemNumber}
          setcarType={setcarType}
        />{" "}
        <br />
        <br />
        <PaginationTable
          minHeight="480px"
          totalPage={totalPage}
          setRowsPerPage={setrowsPerPage}
          rowsPerPageDefault={7}
          pageNum={pageNum}
          rowsPerPage={rowsPerPage}
          setpageNum={setPageNum}
        >
          <TableIndexRow
            widthArray={[1, 1, 1, 1, 1]}
            itemArray={text.bom.devIndexArray}
          />
          {rows}
        </PaginationTable>
      </ModalBox>
      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, text.bom.bomInfo],
            [routeRef, text.bom.routeInfo],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>
      <FormSectionStyle ref={formRef} isRouteActivate={isRouteActivate}>
        <div className="top" ref={informationRef}>
          <BoldText fontSize="14px" color="var(--eciBlue)">
            {text.bom.bomList}{" "}
          </BoldText>
          {developType === "add" && !isRouteActivate && (
            <Button
              type="button"
              onClick={tempSave}
              backgroundColor="transparent"
              fontSize="12px"
              color="var(--textDarkGray)"
            >
              {text.saveAsDraft}
            </Button>
          )}
        </div>

        <div className="index">
          <TableIndexRow
            itemArray={text.bom.devIndexArray}
            widthArray={[6, 4, 4, 4, 4]}
            backgroundColor="white"
          />
          <div className="treeBox">
            <div className="numberBox">
              <BOMNumberTree
                data={developTree}
                indent="30px"
                indexArray={[0]}
                searchOnly
                readOnly={isRouteActivate}
                setTreeState={setdevelopTreeState}
                addTreeState={adddevelopTreeState}
                setfold={adddevelopFoldedRowIndex}
                deletefold={deletedevelopFoldedRowIndex}
              />
            </div>
            <div className="propBox">
              <BOMPropTree
                data={developTree}
                indexArray={[0]}
                searchOnly
                readOnly={isRouteActivate}
                foldedArray={developFoldedRowIndex}
                onDelete={deletedevelopTreeState}
              />
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        {isRouteActivate ? (
          ""
        ) : (
          <Button
            backgroundColor="var(--eciBlue)"
            width="30%"
            height="30px"
            color="white"
            onClick={saveBOM}
            // condition
          >
            {text.saveAndRoute}
          </Button>
        )}
        <div className="routeContainer">
          <RouteSection
            activate={isRouteActivate || isReject}
            routeNumber={routeNumber}
            setrouteRef={setrouteRef}
            routeData={routeData}
            setrouteData={setrouteData}
            rejecting={isReject && !isRouteActivate}
          />
        </div>
      </FormSectionStyle>
    </DevelopBOMStyle>
  );
}
