import React from "react";
import styled from "styled-components";

import NavBarCategoryBox from "../molecule/NavBarCategoryBox";
import resultText from "../Text";

import commonStore, { userStore } from "../store/commonStore";
import NavBarLogoBox from "../molecule/NavBarLogoBox";
import NavBarBackground from "../molecule/NavBarBackground";
import NavBarUserBox from "../molecule/NavBarUserBox";

const NavigationBarStyle = styled.div`
  height: 100vh;
  width: ${(props) => (props.isHover ? "150px" : "70px")};
  padding-top: 50px;

  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 1;

  background-color: transparent;
  border-radius: 0 30px 30px 0;

  font-family: Helvetica;
`;
export default function NavigationBar() {
  const { isHover, setIsHover } = commonStore((state) => ({
    setIsHover: state.setIsHover,
    isHover: state.isHover,
  }));
  const { userData } = userStore();

  return (
    <NavigationBarStyle
      isHover={isHover}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      <NavBarLogoBox />
      <NavBarCategoryBox
        src="home"
        name={resultText.common.NAVBAR.Home}
        to="/"
      />
      <NavBarCategoryBox
        src="lightbulb"
        name={resultText.common.NAVBAR.Project}
        to="/project"
      />
      <NavBarCategoryBox
        src="published_with_changes"
        name={resultText.common.NAVBAR.Design_Change}
        to="/C/R"
      />
      <NavBarCategoryBox
        src="build"
        name={resultText.common.NAVBAR.Item}
        to="/item"
      />
      <NavBarCategoryBox
        src="description"
        name={resultText.common.NAVBAR.Document}
        to="/document"
      />
      <NavBarCategoryBox
        src="exit_to_app"
        name={resultText.common.NAVBAR.Release}
        to="/release"
      />
      {userData.admin && (
        <NavBarCategoryBox
          src="admin_panel_settings"
          name={resultText.common.NAVBAR.Admin}
          to="/admin/"
        />
      )}

      <NavBarBackground />
      <NavBarUserBox />
    </NavigationBarStyle>
  );
}
