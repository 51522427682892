import React, { useState } from "react";
import styled from "styled-components";
import RoundImgBox from "../atom/RoundImgBox";
import TableRow from "../atom/TableRow";

const AdminItemRowStyle = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
`;
export default function AdminItemRow({
  data,
  setmemberArray,
  members,
  deletememberArray,
}) {
  return (
    <AdminItemRowStyle>
      <TableRow
        widthArray={[1, 2, 2, 2, 2]}
        onClick={() => {
          if (members && members.indexOf(data.itemId) >= 0) {
            deletememberArray(data.itemId);
          } else {
            setmemberArray(data);
          }
        }}
        itemArray={[
          <input
            type="checkbox"
            value="1"
            checked={members && members.indexOf(data.itemId) >= 0}
            onChange={() => {
              // if (members && members.indexOf(data.id) >= 0) {
              //   deletememberArray(data.id);
              // } else {
              //   setmemberArray(data);
              // }
            }}
          />,
          data.itemNumber,
          data.itemName,
          data.itemRevision,
          data.itemStatus,
        ]}
      />
    </AdminItemRowStyle>
  );
}
