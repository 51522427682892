import React from "react";
import styled from "styled-components";

const PlusButtonStyle = styled.button`
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  display: flex;
  align-items: center;
  background-color: var(--eciBlue);
  border-radius: 10px;
  cursor: pointer;
  color: white;
  font-family: "Helvetica";
  font-weight: 700;
  font-size: ${(props) => props.fontSize};

  .material-icons {
    margin-right: 15px;
    font-size: ${(props) => props.fontSize};
  }
`;
export default function PlusButton({
  width,
  height,
  onClick,
  children,
  fontSize,
}) {
  return (
    <PlusButtonStyle
      width={width}
      height={height}
      onClick={onClick}
      fontSize={fontSize}
    >
      <span className="material-icons">add</span> {children}
    </PlusButtonStyle>
  );
}
