import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LineTitle from "../atom/LineTitle";
import TableIndexRow from "../atom/TableIndexRow";
import TableRow from "../atom/TableRow";
import AnimationDateInput from "../molecule/AnimationDateInput";
import AnimationInput from "../molecule/AnimationInput";
import AnimationSelectBox from "../molecule/AnimationSelectBox";
import SearchInput from "../molecule/SearchInput";
import Table from "../molecule/Table";
import projectModuleStore from "../store/projectModuleStore";
import resultText from "../Text";

const ProjectInformationSectionStyle = styled.div`
  pointer-events: ${(props) => (props.readOnly ? "none" : "")};

  .gridContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: repeat(7, 60px);
    grid-column-gap: 15px;
    grid-row-gap: 10px;
    .date {
      display: flex;
      justify-content: space-between;
    }
    .line {
      grid-column: 1/3;
      border-bottom: solid 2px var(--deemGray);
      height: 15px;
    }
  }
  .period {
    color: ${(props) =>
      props.period ? "var(--eciBlue)" : "var(--disableGray)"};
    font-size: 15px;
    font-weight: 600;
    position: relative;
    .dateType {
      position: absolute;
      left: 50%;
    }
  }
  .deleteDiv {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;
export default function ProjectInformationSection({ readOnly }) {
  const {
    // date
    protoStartPeriod,
    protoOverPeriod,
    p1StartPeriod,
    p1OverPeriod,
    p2StartPeriod,
    p2OverPeriod,
    sopStartPeriod,
    sopOverPeriod,
    setprotoStartPeriod,
    setprotoOverPeriod,
    setp1StartPeriod,
    setp1OverPeriod,
    setp2StartPeriod,
    setp2OverPeriod,
    setsopStartPeriod,
    setsopOverPeriod,
    projectType,
    projectLevel,
    clientOrganizationId,
    supplierId,
    carTypeId,
    clientItemNumber,
    itemName,
    itemRevision,
    itemNumber,
    itemClassification,
    itemStatus,
    setprojectType,
    setclientItemNumber,
    setsearchBoxType,
    setinformationRef,
    linkedItemList,
    setlinkedItemList,
    deletedLinkedItemList,
    setdeletedLinkedItemList,
  } = projectModuleStore();
  const informationRef = useRef();
  const navigate = useNavigate();
  const linkedItemRows =
    linkedItemList &&
    linkedItemList.map((item, i) => {
      const onDelete = () => {
        setdeletedLinkedItemList([...deletedLinkedItemList, item.itemId]);
        const tmpArr = [...linkedItemList];
        tmpArr.splice(i, 1);
        setlinkedItemList(tmpArr);
      };

      return (
        <TableRow
          key={i}
          widthArray={[3, 3, 2, 3, 2, 1]}
          onClick={() => {
            navigate(`/item/detail/${item.id}`); //  확인
          }}
          itemArray={[
            item.itemNumber,
            item.itemName,
            item.itemRevision,
            item.itemClassification && item.itemClassification.value,
            item.itemStatus,
            readOnly ? (
              ""
            ) : (
              <div className="deleteDiv">
                <span className="material-icons delete" onClick={onDelete}>
                  delete
                </span>
              </div>
            ),
          ]}
        />
      );
    });
  useEffect(() => {
    setinformationRef(informationRef);
  }, [setinformationRef]);
  return (
    <ProjectInformationSectionStyle
      ref={informationRef}
      readOnly={readOnly}
      period={
        protoStartPeriod &&
        protoOverPeriod &&
        p1StartPeriod &&
        p1OverPeriod &&
        p2StartPeriod &&
        p2OverPeriod &&
        sopStartPeriod &&
        sopOverPeriod
      }
    >
      <LineTitle
        fontSize="16px"
        color="var(--eciBlue)"
        width="100%"
        height="60px"
      >
        {resultText.project.projectInformation}
      </LineTitle>
      <div className="gridContainer">
        <div className="period">
          Project Period <span className="dateType">Proto</span>{" "}
        </div>
        <div className="date">
          <AnimationDateInput
            width="45%"
            height="40px"
            placeholder="Start date"
            state={protoStartPeriod}
            setState={setprotoStartPeriod}
            readOnly={readOnly}
          />
          <AnimationDateInput
            width="45%"
            height="40px"
            placeholder="End date"
            state={protoOverPeriod}
            setState={setprotoOverPeriod}
            error={
              protoStartPeriod &&
              protoOverPeriod &&
              protoStartPeriod > protoOverPeriod
            }
            errorMessage={resultText.project.protoError}
            readOnly={readOnly}
          />
        </div>
        <div className="period">
          <span className="dateType">P1</span>
        </div>
        <div className="date">
          <AnimationDateInput
            width="45%"
            height="40px"
            placeholder="Start date"
            state={p1StartPeriod}
            setState={setp1StartPeriod}
            readOnly={readOnly}
          />
          <AnimationDateInput
            width="45%"
            height="40px"
            placeholder="End date"
            state={p1OverPeriod}
            setState={setp1OverPeriod}
            error={
              p1StartPeriod && p1OverPeriod && p1StartPeriod > p1OverPeriod
            }
            errorMessage={resultText.project.protoError}
            readOnly={readOnly}
          />
        </div>
        <div className="period">
          <span className="dateType">P2</span>
        </div>

        <div className="date">
          <AnimationDateInput
            width="45%"
            height="40px"
            placeholder="Start date"
            state={p2StartPeriod}
            setState={setp2StartPeriod}
            readOnly={readOnly}
          />
          <AnimationDateInput
            width="45%"
            height="40px"
            placeholder="End date"
            state={p2OverPeriod}
            setState={setp2OverPeriod}
            error={
              p2StartPeriod && p2OverPeriod && p2StartPeriod > p2OverPeriod
            }
            errorMessage={resultText.project.protoError}
            readOnly={readOnly}
          />
        </div>
        <div className="period">
          <span className="dateType">SOP</span>
        </div>

        <div className="date">
          <AnimationDateInput
            width="45%"
            height="40px"
            placeholder="Start date"
            state={sopStartPeriod}
            setState={setsopStartPeriod}
            readOnly={readOnly}
          />
          <AnimationDateInput
            width="45%"
            height="40px"
            placeholder="End date"
            state={sopOverPeriod}
            setState={setsopOverPeriod}
            error={
              sopStartPeriod && sopOverPeriod && sopStartPeriod > sopOverPeriod
            }
            errorMessage={resultText.project.protoError}
            readOnly={readOnly}
          />
        </div>

        <AnimationSelectBox
          width="100%"
          height="40px"
          placeholder={resultText.project.projectType}
          state={projectType.id}
          setState={setprojectType}
          required
          readOnly={readOnly}
        >
          <option value="1">{resultText.project.yangsan}</option>
          <option value="2">{resultText.project.preDev}</option>
        </AnimationSelectBox>
        <SearchInput
          required
          width="100%"
          height="40px"
          placeholder={resultText.project.projectLevel}
          state={projectLevel.name}
          onClick={() => {
            setsearchBoxType("projectLevel");
          }}
          readOnly={readOnly}
        />
        <SearchInput
          width="100%"
          height="40px"
          placeholder={resultText.project.supplier}
          state={supplierId && supplierId.name}
          onClick={() => {
            setsearchBoxType("supplierId");
          }}
          readOnly={readOnly}
        />
        <SearchInput
          width="100%"
          height="40px"
          placeholder={resultText.project.client}
          state={clientOrganizationId && clientOrganizationId.name}
          onClick={() => {
            setsearchBoxType("clientOrganizationId");
          }}
          readOnly={readOnly}
        />
        <SearchInput
          width="100%"
          height="40px"
          placeholder={resultText.project.carType}
          state={carTypeId && carTypeId.name}
          onClick={() => {
            setsearchBoxType("carTypeId");
          }}
          required
          readOnly={readOnly}
        />
        <AnimationInput
          width="100%"
          height="40px"
          placeholder={resultText.project.clientItemNumber}
          state={clientItemNumber}
          setState={setclientItemNumber}
          readOnly={readOnly}
        />
      </div>
      <div className="blue">
        <SearchInput
          width="100%"
          height="40px"
          placeholder={resultText.item.item}
          state={itemNumber}
          onClick={() => {
            setsearchBoxType("item-candidates");
          }}
          required
          readOnly={readOnly}
          backgroundColor="var(--eciBlue)"
        />
        <Table width="100%" minHeight="120px">
          <TableIndexRow
            widthArray={[3, 3, 2, 3, 3]}
            itemArray={resultText.project.itemIndexArray}
          />
          {linkedItemRows}
        </Table>
      </div>
    </ProjectInformationSectionStyle>
  );
}
