import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BoldText from "../atom/BoldText";
import LineTitle from "../atom/LineTitle";
import AnimationInput from "../molecule/AnimationInput";
import AnimationSelectBox from "../molecule/AnimationSelectBox";
import DragDropBox from "../molecule/DragDropBox";
import SearchInput from "../molecule/SearchInput";
import UnitInput from "../molecule/UnitInput";
import commonStore from "../store/commonStore";
import itemModuleStore from "../store/itemModuleStore";
import text from "../Text";

const ItemInformationSectionStyle = styled.div`
  pointer-events: ${(props) => props.readOnly && "none"};
`;
const TopGridStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 60px 60px 60px 60px 240px 60px 60px;
  column-gap: 15px;
  row-gap: 10px;

  .long {
    grid-column: 1/3;
  }
`;
const BottomGridStyle = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: repeat(${(props) => props.bottomRowNumber} 60px);
  column-gap: 15px;
  row-gap: 10px;
`;
function ItemInformationSection({
  readOnly,
  setisClassificationBox,
  isRevise,
}) {
  const informationRef = useRef();
  const tokenAxios = commonStore((state) => state.tokenAxios);
  const {
    // item-default
    itemRevision,
    isMajor,
    itemClassification,
    itemNumber,
    itemName,
    customItemNumber,
    sharing,
    carTypeId,
    typeId,
    thumbnail,
    setsharing,
    setitemName,
    setcustomItemNumber,
    setisMajor,
    setthumbnail,
    settypeId,
    setinformationRef,
    // searchBox
    setsearchBoxType,
    setattachmentTagOptionList,
    // condition
    setrequiredPropertyList,
    addrequiredPropertyList,
    miniInit,
  } = itemModuleStore((state) => ({
    // item-default
    itemClassification: state.itemClassification,
    itemNumber: state.itemNumber,
    itemName: state.itemName,
    customItemNumber: state.customItemNumber,

    isMajor: state.isMajor,
    sharing: state.sharing,
    carTypeId: state.carTypeId,
    typeId: state.typeId,
    thumbnail: state.thumbnail,
    itemRevision: state.itemRevision,

    setsharing: state.setsharing,
    setitemName: state.setitemName,
    setcustomItemNumber: state.setcustomItemNumber,

    setisMajor: state.setisMajor,
    setthumbnail: state.setthumbnail,
    settypeId: state.settypeId,
    setinformationRef: state.setinformationRef,
    // searchBox
    setsearchBoxType: state.setsearchBoxType,
    setattachmentTagOptionList: state.setattachmentTagOptionList,
    // condition
    requiredPropertyList: state.requiredPropertyList,
    setrequiredPropertyList: state.setrequiredPropertyList,
    addrequiredPropertyList: state.addrequiredPropertyList,
    miniInit: state.miniInit,
  }));

  const itemStore = itemModuleStore();
  const [inputPropertyList, setinputPropertyList] = useState([]);
  // itemType관련
  const [typeIdSelectList, settypeIdSelectList] = useState([]);
  const typeIdOptionList =
    typeIdSelectList &&
    itemClassification &&
    typeIdSelectList.map((optionObject, j) => {
      return (
        <option key={j} value={optionObject.id}>
          {optionObject.name}
        </option>
      );
    });
  // classification별 인풋
  const unitObj = {
    weight: "g",
    height: "mm",
    width: "mm",
    thickness: "mm",
    modulus: "Pa",
  };
  const inputList =
    inputPropertyList &&
    inputPropertyList.map((item, i) => {
      let resultInput;
      switch (item.inputType) {
        case "normal": {
          if (
            item.requestName === "weight" ||
            item.requestName === "height" ||
            item.requestName === "width" ||
            item.requestName === "thickness" ||
            item.requestName === "modulus"
          ) {
            resultInput = (
              <UnitInput
                key={i}
                width="100%"
                height="40px"
                placeholder={item.name}
                state={
                  typeof itemStore[item.requestName] === "object" &&
                  itemStore[item.requestName]
                    ? itemStore[item.requestName].name
                    : itemStore[item.requestName]
                }
                setState={itemStore[`set${item.requestName}`]}
                required={item.required === 1}
                optionArray={[
                  {
                    value: unitObj[item.requestName],
                    name: unitObj[item.requestName],
                  },
                ]}
              />
            );
          } else {
            resultInput = (
              <AnimationInput
                readOnly={readOnly}
                key={i}
                width="100%"
                height="40px"
                placeholder={item.name}
                state={
                  typeof itemStore[item.requestName] === "object" &&
                  itemStore[item.requestName]
                    ? itemStore[item.requestName].name
                    : itemStore[item.requestName]
                }
                setState={itemStore[`set${item.requestName}`]}
                required={item.required === 1}
              />
            );
          }

          break;
        }
        case "DB": {
          resultInput = (
            <SearchInput
              readOnly={readOnly}
              key={i}
              width="100%"
              height="40px"
              placeholder={item.name}
              state={
                typeof itemStore[item.requestName] === "object"
                  ? itemStore[item.requestName].name
                  : itemStore[item.requestName]
              }
              onClick={() => {
                setisClassificationBox(false);
                setsearchBoxType(item.requestName);
              }}
              required={item.required === 1}
            />
          );
          break;
        }

        case "choice": {
          const optionList =
            item.choiceFields &&
            item.choiceFields.map((optionObject, j) => {
              return (
                <option key={j} value={optionObject.value}>
                  {optionObject.name}
                </option>
              );
            });

          resultInput = (
            <AnimationSelectBox
              readOnly={readOnly}
              key={i}
              width="100%"
              height="40px"
              placeholder={item.name}
              state={
                typeof itemStore[item.requestName] === "object"
                  ? itemStore[item.requestName].name
                  : itemStore[item.requestName]
              }
              setState={itemStore[`set${item.requestName}`]}
              required={item.required === 1}
            >
              {optionList}
            </AnimationSelectBox>
          );
          break;
        }
        case "unit": {
          resultInput = (
            <AnimationInput
              readOnly={readOnly}
              key={i}
              width="100%"
              height="40px"
              placeholder={item.name}
              backgroundColor="var(--disableGray)"
              state={
                typeof itemStore[item.requestName] === "object"
                  ? itemStore[item.requestName].name
                  : itemStore[item.requestName]
              }
              setState={itemStore[`set${item.requestName}`]}
              required={item.required === 1}
            />
          );
          break;
        }
        default:
          resultInput = <div />;

          break;
      }
      return resultInput;
    });

  const getinputPropertyList = async (clas) => {
    const response = await tokenAxios.get(`attributes/${clas}`);
    setinputPropertyList(response.data.result.data.attributesDtoList);
    settypeIdSelectList(response.data.result.data.selectableItemTypes);
    setrequiredPropertyList(["typeId", "itemName", "sharing"]);
    const abc =
      response.data.result.data.attributesDtoList &&
      response.data.result.data.attributesDtoList.forEach((item) => {
        if (item.required === 1) {
          addrequiredPropertyList(item.requestName);
        }
      });

    setattachmentTagOptionList(
      response.data.result.data.selectableAttachmentTags
    );
  };
  useEffect(() => {
    setinformationRef(informationRef);

    if (itemClassification) {
      getinputPropertyList(itemClassification.classification);
    }
    return () => {
      if (!itemClassification) {
        settypeIdSelectList([]);
      }
    };
  }, [itemClassification]);
  return (
    <ItemInformationSectionStyle ref={informationRef} readOnly={readOnly}>
      <LineTitle
        fontSize="16px"
        color="var(--eciBlue)"
        width="100%"
        height="60px"
      >
        {text.item.itemInformation}{" "}
      </LineTitle>

      <TopGridStyle>
        <AnimationInput
          width="100%"
          height="40px"
          backgroundColor="var(--disableGray)"
          placeholder="Revision"
          required
          state={itemRevision}
          readOnly
        />
        {isRevise && (
          <AnimationSelectBox
            width="100%"
            height="40px"
            placeholder={text.item.selectMajor}
            state={isMajor}
            setState={setisMajor}
            required
            readOnly={readOnly}
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </AnimationSelectBox>
        )}
        <div className="long">
          <SearchInput
            width="100%"
            height="40px"
            placeholder={text.item.classification}
            required
            state={itemClassification && itemClassification.value}
            onClick={() => {
              setisClassificationBox(true);
              setsearchBoxType("");
            }}
            readOnly={readOnly}
          />
        </div>
        <AnimationInput
          width="100%"
          height="40px"
          backgroundColor="var(--disableGray)"
          placeholder={text.item.itemNumber}
          state={itemNumber}
          required
          readOnly
        />
        <AnimationInput
          width="100%"
          height="40px"
          placeholder={text.item.itemName}
          state={itemName}
          setState={setitemName}
          required
          readOnly={readOnly}
        />
        <AnimationInput
          width="100%"
          height="40px"
          placeholder={text.item.customerItemNumber}
          state={customItemNumber}
          setState={setcustomItemNumber}
          readOnly={readOnly}
        />
        <div className="long">
          <BoldText color="var(--eciBlue)" fontSize="14px">
            {text.item.thumbnail}{" "}
          </BoldText>
          <DragDropBox
            htmlFor="itemThumb"
            preview
            width="100%"
            height="200px"
            setState={setthumbnail}
            previewImage={
              thumbnail &&
              thumbnail.imageAdress &&
              `${process.env.REACT_APP_BASE_URL}/${thumbnail.imageAdress}`
            }
          />
        </div>
        <AnimationSelectBox
          width="100%"
          height="40px"
          placeholder={text.item.isSharing}
          state={sharing}
          setState={setsharing}
          required
          readOnly={readOnly}
        >
          {/* check */}
          <option value="true">{text.item.sharing}</option>
          <option value="false">{text.item.notSharing}</option>
        </AnimationSelectBox>
        {sharing === "false" ? (
          <SearchInput
            width="100%"
            height="40px"
            placeholder={text.item.carType}
            state={carTypeId.name}
            onClick={() => {
              setisClassificationBox(false);
              setsearchBoxType("carTypeId");
            }}
            readOnly={readOnly}
          />
        ) : (
          <div />
        )}
        {typeIdSelectList && (
          <AnimationSelectBox
            width="100%"
            height="40px"
            placeholder={text.item.itemType}
            state={typeId}
            setState={settypeId}
            required
            readOnly={readOnly}
          >
            {typeIdOptionList}
          </AnimationSelectBox>
        )}
      </TopGridStyle>
      <BottomGridStyle bottomRowNumber={Math.ceil(inputPropertyList.legth / 2)}>
        {itemClassification && inputList}
      </BottomGridStyle>
    </ItemInformationSectionStyle>
  );
}
export default React.memo(ItemInformationSection);
