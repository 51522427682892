import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import CRCOModuleStore from "../../store/CRCOModuleStore";
import Button from "../../atom/Button";
import SearchBox from "../../molecule/SearchBox";
import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";
import LoadingBox from "../LoadingBox";
import LoadingArrowAnimation from "../../molecule/LoadingArrowAnimation";
import BoldText from "../../atom/BoldText";
import AttachmentSection from "../AttachmentSection";
import COInformationSection from "../COInformationSection";
import RouteInitSection from "../RouteInitSection";
import txt from "../../Text";

const CRCOAddSectionStyle = styled.div`
  display: flex;
  position: relative;
  .tempSave {
    height: 40px;

    position: absolute;
    top: -40px;
    left: calc(58% - 110px);
    display: flex;
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`;
const FormSectionStyle = styled.div`
  width: 58%;
  height: calc(100vh - 230px);
  overflow: scroll;
  padding-left: 18%;
  padding-right: 1.5%;
  padding-top: 20px;
  .gap {
    height: 45px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
`;
const SearchBoxSectionStyle = styled.div`
  width: 42%;
`;
function COAddSection() {
  const { tokenAxios } = commonStore((state) => ({
    tokenAxios: state.tokenAxios,
  }));

  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const formRef = useRef();
  const {
    // coinfo
    COId,
    setCOId,
    CONumber,
    clientOrganizationId,
    COClientNumber,
    COClientPublishDate,
    CoClientReceiptDate,
    COisPastCar,
    carTypeId,
    COisCostChange,
    COcost,
    crReasonId,
    COReasonText,
    COLevel,
    COTiming,
    COAffect,
    COImportance,
    COTitle,
    COBody,
    CORelatedCR,
    COAffectedItem,
    changedFeatureId,
    changedFeatureIndex,
    // attachment
    attachmentFileArray,
    attachmentTagArray,
    attachmentCommentArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    // route
    setrouteNumber,
    targetMember,
    settargetMember,
    members,
    setsearchBoxType,
    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // searchBox
    searchBoxType,
    setsearchBoxProperty,
    deletemember,
    // etc
    isRouteActivate,
    setisRouteActivate,
    setcurrentCategory,
    initCOModule,
  } = CRCOModuleStore();
  const [isFirstTempId, setisFirstTempId] = useState(false);

  const appendCOFormData = () => {
    const formData = new FormData();
    formData.append(
      "clientOrganizationId",
      clientOrganizationId.id ? clientOrganizationId.id : ""
    );
    formData.append("clientItemNumber", COClientNumber);
    formData.append("coPublishPeriod", COClientPublishDate || "");
    formData.append("coReceivedPeriod", CoClientReceiptDate || "");
    formData.append("difference", COisPastCar);
    if (carTypeId && carTypeId.id) {
      formData.append("carTypeId", carTypeId.id ? carTypeId.id : "");
    }
    formData.append("costDifferent", COisCostChange);
    formData.append("costDifference", COisCostChange === "true" ? COcost : 0);
    formData.append("coReasonId", crReasonId.id ? crReasonId.id : "");
    formData.append("coReason", crReasonId.id === "1" ? COReasonText : "");
    formData.append(
      "coStageId",
      typeof COLevel === "string" ? COLevel : COLevel.id
    );
    formData.append("applyPeriod", COTiming || "");
    formData.append("coEffectId", COAffect);
    formData.append(
      "coImportanceId",
      typeof COImportance === "string" ? COImportance : COImportance.id
    );
    formData.append("name", COTitle);
    formData.append("content", COBody);
    formData.append(
      "changeRequestIds",
      CORelatedCR.map((item) => item.id)
    );
    // affected item
    formData.append(
      "newItemsIds",
      COAffectedItem.map((item) => item.itemId)
    );
    formData.append(
      "changeContents",
      COAffectedItem.map((item) => item.changedContent)
    );
    formData.append(
      "changeFeaturesIds",
      changedFeatureId && changedFeatureId.map((item) => item.id)
    );

    // attachment
    attachmentFileArray.forEach((file) => {
      formData.append("attachments", file);
    });

    formData.append("attachmentComment", attachmentCommentArray);
    formData.append("tag", attachmentTagArray);

    return formData;
  };

  const saveCO = async () => {
    setisLoading(true);
    const formData = appendCOFormData();
    try {
      let response = "";
      if (!isFirstTempId) {
        response = await tokenAxios.post("/co", formData);
      } else {
        response = await tokenAxios.put(
          `/co/temp/end/${isFirstTempId}`,
          formData
        );
      }
      setisRouteActivate(true);
      setCOId(response.data.result.data.id);
      setisLoading(false);
    } catch (error) {
      console.log("error");
      if (error.response.data.result) {
        console.log(error.response);
        alert(error.response.data.result.msg);
      } else {
        console.log(error.response);
        navigate("/notFound");
      }
      setisRouteActivate(false);
      setisLoading(false);
    }
  };
  const tempSave = async () => {
    setisLoading(true);
    const formData = appendCOFormData();
    if (isFirstTempId) {
      const response = await tokenAxios.put(
        `/co/temp/${isFirstTempId}`,
        formData
      );
    } else {
      try {
        const response = await tokenAxios.post("/co/temp", formData);
        setisFirstTempId(response.data.result.data.id);
        alert(txt.approveDone);
      } catch (error) {
        if (error.response.data.result) {
          console.log(error.response);
          alert(error.response.data.result.msg);
        } else {
          console.log(error.response);
          navigate("/notFound");
        }
      }
    }
    setisLoading(false);
  };
  const params = useParams();

  useEffect(() => {
    setcurrentCategory("CO");

    return () => {
      initCOModule();
    };
  }, []);
  return (
    <CRCOAddSectionStyle>
      <div className="tempSave">
        {!isRouteActivate && (
          <Button
            type="button"
            onClick={tempSave}
            backgroundColor="transparent"
            fontSize="12px"
            color="var(--textDarkGray)"
          >
            {txt.saveAsDraft}{" "}
          </Button>
        )}
        <Button
          type="button"
          backgroundColor="transparent"
          color="var(--textDarkGray)"
          fontSize="12px"
          onClick={() => {
            navigate("/C/O");
          }}
        >
          <span className="material-icons">close</span>
        </Button>
      </div>
      <LoadingBox isLoading={isLoading}>
        <LoadingArrowAnimation />
        <BoldText fontSize="26px" color="var(--eciBlue)">
          S a v i n g
        </BoldText>
        <br />
        <br />
        Please wait while we set
        <br />
        thing up for you!
      </LoadingBox>
      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, txt.crco.COInfo],
            [attachmentRef, txt.crco.COAttachment],
            [routeRef, txt.crco.routeInfo],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>
      <FormSectionStyle ref={formRef}>
        <COInformationSection readOnly={isRouteActivate} />

        <AttachmentSection
          readOnly={isRouteActivate}
          attachmentFileArray={attachmentFileArray}
          setattachmentFileArray={setattachmentFileArray}
          attachmentTagArray={attachmentTagArray}
          setattachmentTagArray={setattachmentTagArray}
          attachmentCommentArray={attachmentCommentArray}
          setattachmentCommentArray={setattachmentCommentArray}
          setattachmentRef={setattachmentRef}
          title={txt.crco.COAttachment}
          tagOptionList={txt.crco.coTagOption}
          deletedFileIds={[]}
          attachmentIdArray={[]}
          attachmentOriginArray={[]}
          setattachmentIdArray={() => {}}
          setattachmentOriginArray={() => {}}
          attachmentUploaderArray={[]}
          setattachmentUploaderArray={() => {}}
          attachmentDateArray={[]}
          setattachmentDateArray={() => {}}
        />

        <div className="gap" />
        {isRouteActivate ? (
          ""
        ) : (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveCO}
            condition={
              !!COisPastCar &&
              !!crReasonId &&
              !!COAffect.length > 0 &&
              !!(COImportance.id || typeof COImportance === "string") &&
              !!COTitle &&
              !!(COAffectedItem.length > 0) &&
              !!COLevel &&
              !!clientOrganizationId
            }
          >
            {txt.saveAndRoute}{" "}
          </Button>
        )}

        <RouteInitSection
          activate={isRouteActivate}
          itemType="CO"
          setrouteRef={setrouteRef}
          itemId={COId}
          setsearchBoxType={setsearchBoxType}
          targetMember={targetMember}
          settargetMember={settargetMember}
          members={members}
          url="/route/co"
        />
      </FormSectionStyle>
      <SearchBoxSectionStyle>
        <SearchBox
          width="100%"
          height="100vh - 218px"
          type={searchBoxType}
          setproperty={setsearchBoxProperty}
          propertyIndex={
            searchBoxType === "members" ? targetMember : changedFeatureIndex
          }
          members={members}
          deletememberArray={deletemember}
        />
      </SearchBoxSectionStyle>
    </CRCOAddSectionStyle>
  );
}
export default React.memo(COAddSection);
