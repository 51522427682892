import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import CRCOModuleStore from "../../store/CRCOModuleStore";
import CRInformationSection from "../CRInformationSection";

import Button from "../../atom/Button";
import SearchBox from "../../molecule/SearchBox";
import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";
import LoadingBox from "../LoadingBox";
import LoadingArrowAnimation from "../../molecule/LoadingArrowAnimation";
import BoldText from "../../atom/BoldText";
import RouteSection from "../RouteSection";
import AttachmentSection from "../AttachmentSection";
import RouteInitSection from "../RouteInitSection";
import txt from "../../Text";

const CRCOAddSectionStyle = styled.div`
  display: flex;
  position: relative;
  .tempSave {
    height: 40px;

    position: absolute;
    top: -40px;
    left: calc(58% - 110px);
    display: flex;
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`;
const FormSectionStyle = styled.div`
  width: 58%;
  height: calc(100vh - 230px);
  overflow: scroll;
  padding-left: 18%;
  padding-right: 1.5%;
  padding-top: 20px;
  .gap {
    height: 45px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
`;
const SearchBoxSectionStyle = styled.div`
  width: 42%;
`;
function CREditPage() {
  const { tokenAxios } = commonStore((state) => ({
    tokenAxios: state.tokenAxios,
  }));

  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [isReject, setisReject] = useState(false);
  const formRef = useRef();
  const {
    // crinfo

    CRNumber,
    crReasonId,
    CRReasonText,
    crImportanceId,
    crSourceId,
    CRTitle,
    CRBody,
    CRSolution,
    CRAffectedItem,
    setCRInfo,
    /// attachment
    attachmentFileArray,
    attachmentIdArray,
    attachmentTagArray,
    attachmentCommentArray,
    attachmentOriginArray,
    attachmentDateArray,
    attachmentUploaderArray,
    attachmentModifiedAtArray,
    setattachmentModifiedAtArray,
    setattachmentDateArray,
    setattachmentUploaderArray,
    setattachmentIdArray,
    setattachmentOriginArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    deletedFileIdArray,
    setdeletedFileIdArray,
    deletedAttachmentArray,
    setdeletedAttachmentArray,
    addedAttachmentArray,
    addedAttachmentTagArray,
    addedAttachmentCommentArray,
    setaddedAttachmentArray,
    setaddedAttachmentTagArray,
    setaddedAttachmentCommentArray,
    // route
    routeNumber,
    routeData,
    setrouteData,
    setrouteNumber,
    targetMember,
    settargetMember,
    members,
    setsearchBoxType,
    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // searchBox
    searchBoxType,
    setsearchBoxProperty,
    deletemember,
    // etc
    isRouteActivate,
    setisRouteActivate,
    setcurrentCategory,
    initCRModule,
  } = CRCOModuleStore();
  const params = useParams();

  const appendCRFormData = () => {
    const formData = new FormData();
    formData.append("crReasonId", crReasonId.id);
    if (CRReasonText) {
      formData.append("crReason", CRReasonText);
    }
    formData.append("crImportanceId", crImportanceId.id);
    formData.append("crSourceId", crSourceId.id ? crSourceId.id : "");
    formData.append("name", CRTitle);
    formData.append("content", CRBody);
    formData.append("solution", CRSolution);
    formData.append("itemId", CRAffectedItem.id ? CRAffectedItem.id : "");
    // attachment
    addedAttachmentArray.forEach((file) => {
      formData.append("addedAttachments", file); // 혹시몰라
    });

    formData.append("addedAttachmentComment", attachmentCommentArray);
    formData.append(
      "addedTag",
      attachmentTagArray.map((item) => (item.id ? item.id : item))
    );

    if (deletedAttachmentArray.length > 0) {
      formData.append("deletedAttachments", deletedAttachmentArray);
    }

    return formData;
  };
  const saveCR = async () => {
    setisLoading(true);
    const formData = appendCRFormData();
    try {
      const response = await tokenAxios.put(
        `/cr/temp/end/${params.id}`,
        formData
      );
      setisRouteActivate(true);
      setrouteNumber(response.data.result.data.routeId);
      setisLoading(false);
    } catch (err) {
      setisRouteActivate(false);
    }
  };
  const tempSave = () => {
    setisLoading(true);
    const formData = appendCRFormData();

    tokenAxios.put(`/cr/temp/${params.id}`, formData).then((res) => {
      setTimeout(() => {
        setisLoading(false);
        alert(txt.approveDone);
      }, 1000);
      alert("ok!");
    });
  };
  const getData = async (itemId) => {
    const response = await tokenAxios.get(`cr/${itemId}`);
    const { data } = response.data.result; // 확인필
    // setCRInfo
    setisRouteActivate(data.readonly);
    setisReject(data.preRejected);
    setCRInfo({
      CRNumber: data.crNumber,
      crReasonId: data.crReasonId,
      CRReasonText: data.crReason,
      crImportanceId: data.crImportanceId,
      crSourceId: data.crSourceId,
      CRTitle: data.name,
      CRBody: data.content,
      CRSolution: data.solution,
      CRAffectedItem: data.item,
    });
    // route
    setrouteNumber(data.routeId);
    // attachment
    const tempfileArray = [];
    const temptagArray = [];
    const tempcommentArray = [];
    const temporiginArray = [];
    const tempuploaderArray = [];
    const tempdateArray = [];
    const tempidArray = [];
    const tempdeletedFileArray = [];
    const tempModifiedAtArray = [];

    data.attachments.forEach((item) => {
      tempfileArray.push(item.originName);
      tempcommentArray.push(item.attach_comment);
      temptagArray.push(item.tag);
      temporiginArray.push(item.attachmentaddress);
      tempuploaderArray.push(item.upload);
      tempdateArray.push(item.date);
      tempidArray.push(item.id);
      if (item.deleted) {
        tempdeletedFileArray.push(item.id);
      }
      tempModifiedAtArray.push(item.modifiedAt ? item.modifiedAt : "");
    });
    setattachmentFileArray(tempfileArray);
    setattachmentIdArray(tempidArray);
    setattachmentCommentArray(tempcommentArray);
    setattachmentTagArray(temptagArray);
    setattachmentOriginArray(temporiginArray);
    setattachmentDateArray(tempdateArray);
    setattachmentUploaderArray(tempuploaderArray);
    setdeletedFileIdArray(tempdeletedFileArray);
    setattachmentModifiedAtArray(tempModifiedAtArray);
  };
  useEffect(() => {
    setcurrentCategory("CR");
    getData(params.id);

    return () => {
      initCRModule();
    };
  }, []);

  return (
    <CRCOAddSectionStyle>
      <div className="tempSave">
        {!isRouteActivate && (
          <Button
            type="button"
            onClick={tempSave}
            backgroundColor="transparent"
            fontSize="12px"
            color="var(--textDarkGray)"
          >
            {txt.saveAsDraft}{" "}
          </Button>
        )}
        <Button
          type="button"
          backgroundColor="transparent"
          color="var(--textDarkGray)"
          fontSize="12px"
          onClick={() => {
            navigate("/C/R");
          }}
        >
          <span className="material-icons">close</span>
        </Button>
      </div>
      <LoadingBox isLoading={isLoading}>
        <LoadingArrowAnimation />
        <BoldText fontSize="26px" color="var(--eciBlue)">
          S a v i n g
        </BoldText>
        <br />
        <br />
        Please wait while we set
        <br />
        thing up for you!
      </LoadingBox>
      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, txt.crco.CRInformation],
            [attachmentRef, txt.crco.CRAttachment],
            [routeRef, txt.crco.routeInfo],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>
      <FormSectionStyle ref={formRef}>
        <CRInformationSection readOnly={isRouteActivate} />

        <AttachmentSection
          readOnly={isRouteActivate}
          attachmentFileArray={attachmentFileArray}
          setattachmentFileArray={setattachmentFileArray}
          attachmentTagArray={attachmentTagArray}
          setattachmentTagArray={setattachmentTagArray}
          attachmentCommentArray={attachmentCommentArray}
          setattachmentCommentArray={setattachmentCommentArray}
          setattachmentRef={setattachmentRef}
          title={txt.crco.crAttachment}
          tagOptionList={txt.crco.crTagOptionList}
          attachmentOriginArray={[]}
          setattachmentOriginArray={() => {}}
          attachmentUploaderArray={[]}
          setattachmentUploaderArray={() => {}}
          attachmentDateArray={[]}
          setattachmentDateArray={() => {}}
          editMode
          attachmentIdArray={attachmentIdArray}
          setattachmentIdArray={setattachmentIdArray}
          deletedFileIds={deletedFileIdArray}
          deletedAttachmentArray={deletedAttachmentArray}
          setdeletedAttachmentArray={setdeletedAttachmentArray}
          addedAttachmentArray={addedAttachmentArray}
          addedAttachmentTagArray={addedAttachmentTagArray}
          addedAttachmentCommentArray={addedAttachmentCommentArray}
          setaddedAttachmentArray={setaddedAttachmentArray}
          setaddedAttachmentTagArray={setaddedAttachmentTagArray}
          setaddedAttachmentCommentArray={setaddedAttachmentCommentArray}
        />

        <div className="gap" />
        {isRouteActivate ? (
          ""
        ) : (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveCR}
            condition={
              !!crReasonId &&
              !!(crImportanceId.id || typeof crImportanceId === "string") && // 여기 확인
              !!crSourceId &&
              !!CRTitle &&
              !!CRBody
            }
          >
            {txt.saveAndRoute}{" "}
          </Button>
        )}

        {isReject ? (
          <RouteSection
            activate={isRouteActivate || isReject}
            routeNumber={routeNumber}
            setrouteRef={setrouteRef}
            routeData={routeData}
            setrouteData={setrouteData}
            rejecting={isReject && !isRouteActivate}
          />
        ) : (
          <RouteInitSection
            activate={isRouteActivate}
            itemType="CR"
            setrouteRef={setrouteRef}
            itemId={params.id}
            setsearchBoxType={setsearchBoxType}
            targetMember={targetMember}
            settargetMember={settargetMember}
            members={members}
            url="/route/cr"
          />
        )}
      </FormSectionStyle>
      <SearchBoxSectionStyle>
        <SearchBox
          width="100%"
          height="100vh - 218px"
          type={searchBoxType}
          setproperty={setsearchBoxProperty}
          propertyIndex={targetMember}
          members={members}
          deletememberArray={deletemember}
        />
      </SearchBoxSectionStyle>
    </CRCOAddSectionStyle>
  );
}
export default React.memo(CREditPage);
// CONumber,
// setCONumber,
// COClient,
// setCOClient,
// COClientNumber,
// setCOClientNumber,
// COClientPublishDate,
// setCOClientPublishDate,
// CoClientReceiptDate,
// setCoClientReceiptDate,
// COisPastCar,
// setCOisPastCar,
// COpastCarType,
// setCOpastCarType,
// COisCostChange,
// setCOisCostChange,
// COcost,
// setCOcost,
// COReason,
// setCOReason,
// COLevel,
// setCOLevel,
// COTiming,
// setCOTiming,
// COAffect,
// setCOAffect,
// COImportance,
// setCOImportance,
// COTitle,
// setCOTitle,
// COBody,
// setCOBody,
// CORelatedCR,
// setCORelatedCR,
// COAffectedItem,
