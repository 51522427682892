import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import TableRow from "../atom/TableRow";
import RoundImgBox from "../atom/RoundImgBox";
import StatusTag from "../atom/StatusTag";
import RoundTextBox from "../atom/RoundTextBox";
import resultText from "../Text";

const RowStyle = styled.div``;

export default function RevisionItemRow({ dataObj }) {
  const revisionRowList = dataObj.children.map((item, i) => (
    <Link to={`detail/${item.id}`} key={i} style={{ textDecoration: "none" }}>
      <TableRow
        widthArray={[13, 1, 2, 2, 2]}
        itemArray={[
          "",
          item.itemRevision,
          <StatusTag height="30px" width="90%">
            {item.status}
          </StatusTag>,
          item.type.name,

          item.sharing ? (
            <RoundTextBox
              color="var(--eciBlue)"
              width="60px"
              height="18px"
              fontSize="11px"
              backgroundColor="var(--lightGray)"
            >
              {resultText.item.sharing}
            </RoundTextBox>
          ) : (
            <RoundTextBox
              color="var(--eciBlue)"
              width="60px"
              height="18px"
              fontSize="11px"
              backgroundColor="var(--lightGray)"
            >
              {resultText.item.notSharing}
            </RoundTextBox>
          ),
        ]}
      />
    </Link>
  ));
  return (
    <RowStyle>
      <Link to={`detail/${dataObj.id}`} style={{ textDecoration: "none" }}>
        <TableRow
          widthArray={[1, 3, 3, 3, 3, 1, 2, 2, 2]}
          itemArray={[
            <RoundImgBox
              src={
                dataObj.thumbnail &&
                dataObj.thumbnail.imageAdress &&
                `${process.env.REACT_APP_BASE_URL}/${dataObj.thumbnail.imageAdress}`
              }
              alt=""
            />,
            dataObj.itemNumber,
            dataObj.carType,
            dataObj.name,
            dataObj.classification.value,
            dataObj.itemRevision,
            <StatusTag height="30px" width="90%">
              {dataObj.status}
            </StatusTag>,
            dataObj.type.name,

            dataObj.sharing ? (
              <RoundTextBox
                color="var(--eciBlue)"
                width="60px"
                height="18px"
                fontSize="11px"
                backgroundColor="var(--lightGray)"
              >
                {resultText.item.sharing}
              </RoundTextBox>
            ) : (
              <RoundTextBox
                color="var(--eciBlue)"
                width="60px"
                height="18px"
                fontSize="11px"
                backgroundColor="var(--lightGray)"
              >
                {resultText.item.notSharing}
              </RoundTextBox>
            ),
          ]}
        />
      </Link>
      {revisionRowList}
    </RowStyle>
  );
}
