import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../atom/Button";
import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";
import LoadingBox from "../LoadingBox";
import LoadingArrowAnimation from "../../molecule/LoadingArrowAnimation";
import BoldText from "../../atom/BoldText";
import itemModuleStore from "../../store/itemModuleStore";
import AttachmentSection from "../AttachmentSection";
import ItemInformationSection from "../ItemInformationSection";
import ItemSearchBox from "../../molecule/ItemSearchBox";
import SearchBox from "../../molecule/SearchBox";
import RouteInitSection from "../RouteInitSection";
import text from "../../Text";

const ItemAddPageStyle = styled.div`
  display: flex;
  position: relative;
  .tempSave {
    height: 40px;

    position: absolute;
    top: -40px;
    left: calc(58% - 110px);
    display: flex;
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`;
const FormSectionStyle = styled.div`
  width: 58%;
  height: ${(props) =>
    props.isClassificationBox ? "760px" : "calc(100vh - 200px)"};
  overflow: scroll;
  padding-left: 18%;
  padding-right: 1.5%;
  padding-top: 20px;
  .gap {
    height: 45px;
  }
  ::-webkit-scrollbar {
    height: 7px;
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
`;
const SearchBoxSectionStyle = styled.div`
  width: 42%;
  position: relative;
  padding-bottom: 30px;
  .searchBox {
    width: 100%;
  }
`;
export default function ItemAddPage() {
  const tokenAxios = commonStore((state) => state.tokenAxios);
  const {
    // item
    itemId,
    setitemId,
    itemClassification,
    setitemClassification,
    // itemProperty
    classification1,
    classification2,
    classification3,
    itemName, // name 대신 itemName
    customItemNumber,
    typeId,
    thumbnail,
    sharing,
    carTypeId,

    forming,
    curve,
    integrate,
    width,
    height,
    thickness,
    weight,
    importance,
    colorId,
    loadQuantity,
    coatingWayId,
    coatingTypeId,
    modulus,
    screw,
    cuttingType,
    lcd,
    displaySize,
    screwHeight,
    clientOrganizationId,
    supplierOrganizationId,
    makersId,
    partnumbers,
    /// attachment
    attachmentFileArray,
    attachmentTagArray,
    attachmentCommentArray,
    attachmentIdArray,
    setattachmentIdArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    attachmentTagOptionList,
    // route

    targetMember,
    settargetMember,
    members,
    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // searchBox
    searchBoxType,
    setsearchBoxType,
    setsearchBoxProperty,
    deletemember,
    // etc
    initItemModule,
    requiredPropertyList,
    setcurrentCategory,
  } = itemModuleStore();
  const itemStore = itemModuleStore();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [isClassificationBox, setisClassificationBox] = useState(false);
  const [isFirstTempId, setisFirstTempId] = useState(false);
  const formRef = useRef();
  const params = useParams();
  const [isRouteActivate, setisRouteActivate] = useState(false);
  const appendItemFormData = () => {
    const formData = new FormData();
    // public
    formData.append("classification1Id", classification1);
    formData.append("classification2Id", classification2);
    formData.append("classification3Id", classification3);
    formData.append("name", itemName);
    formData.append("typeId", typeId);
    if (thumbnail.lastModified) {
      formData.append("thumbnail", thumbnail);
    }
    formData.append("sharing", sharing);
    formData.append("carTypeId", carTypeId.id ? carTypeId.id : ""); // 확인 필요
    // classification
    formData.append("forming", forming);
    formData.append("customItemNumber", customItemNumber);
    formData.append("curve", curve);
    formData.append("integrate", integrate);
    formData.append("width", width);
    formData.append("height", height);
    formData.append("thickness", thickness);
    formData.append("weight", weight);
    formData.append("importance", importance);
    formData.append("colorId", colorId.id ? colorId.id : ""); // 확인필
    formData.append("loadQuantity", loadQuantity);
    formData.append("coatingWayId", coatingWayId.id ? coatingWayId.id : ""); // check
    formData.append("coatingTypeId", coatingTypeId.id ? coatingTypeId.id : ""); // check
    formData.append("modulus", modulus);
    formData.append("screw", screw);
    formData.append("cuttingType", cuttingType);
    formData.append("lcd", lcd);
    formData.append("displaySize", displaySize);
    formData.append("screwHeight", screwHeight);
    formData.append(
      "clientOrganizationId",
      clientOrganizationId.id ? clientOrganizationId.id : ""
    ); // check
    formData.append(
      "supplierOrganizationId",
      supplierOrganizationId.id ? supplierOrganizationId.id : ""
    );

    formData.append("makersId", makersId.id ? makersId.id : "");
    formData.append("partnumbers", partnumbers);
    // attachment + duplicate
    if (params.id !== "new" && attachmentIdArray.length > 0) {
      formData.append(
        "duplicateTargetIds",
        attachmentIdArray.filter((item) => item)
      );
    }
    // attachment
    attachmentFileArray.forEach((file) => {
      if (typeof file !== "string") {
        formData.append("attachments", file);
      }
    });

    formData.append("attachmentComment", attachmentCommentArray);
    formData.append(
      "tag",
      attachmentTagArray.map((item) => (item.id ? item.id : item))
    );
    return formData;
  };

  const saveItem = async () => {
    setisLoading(true);
    const formData = appendItemFormData();
    try {
      let response = "";
      if (!isFirstTempId) {
        response = await tokenAxios.post("/item", formData);
      } else {
        response = await tokenAxios.put(
          `/item/temp/end/${isFirstTempId}`,
          formData
        );
      }
      // const response = await tokenAxios.post("/item", formData);
      setisLoading(false);
      setisRouteActivate(true);

      // route부분으로 스크롤
      setitemId(response.data.result.data.id);
    } catch (err) {
      if (err.response.data.result) {
        console.log(err.response);
        alert(err.response.data.result.msg);
      } else {
        console.log(err.response);
        navigate("/notFound");
      }
      setisLoading(true);
    }
  };
  const condition = requiredPropertyList.every((item) => {
    if (item === "sharing" && itemStore.sharing === "false") {
      return !!itemStore.carTypeId;
    }
    return !!itemStore[item];
  });
  const tempSave = async () => {
    setisLoading(true);
    const formData = appendItemFormData();
    try {
      if (isFirstTempId) {
        const response = await tokenAxios.put(
          `/item/temp/${isFirstTempId}`,
          formData
        );
      } else {
        const response = await tokenAxios.post("/item/temp", formData);
        setisFirstTempId(response.data.result.data.id);
      }
      alert(text.approveDone);

      setisLoading(false);
    } catch (err) {
      if (err.response.data.result) {
        console.log(err.response);
        alert(err.response.data.result.msg);
      } else {
        console.log(err.response);
        navigate("/notFound");
      }
    }
  };

  useEffect(() => {
    setcurrentCategory("Add");
    return () => {
      initItemModule();
    };
  }, []);
  return (
    <ItemAddPageStyle>
      <div className="tempSave">
        {!isRouteActivate && (
          <Button
            type="button"
            onClick={tempSave}
            backgroundColor="transparent"
            fontSize="12px"
            color="var(--textDarkGray)"
          >
            {text.saveAsDraft}{" "}
          </Button>
        )}
        <Button
          type="button"
          backgroundColor="transparent"
          color="var(--textDarkGray)"
          fontSize="12px"
          onClick={() => {
            navigate("/item");
          }}
        >
          <span className="material-icons">close</span>
        </Button>
      </div>
      <LoadingBox isLoading={isLoading}>
        <LoadingArrowAnimation />
        <BoldText fontSize="26px" color="var(--eciBlue)">
          S a v i n g
        </BoldText>
        <br />
        <br />
        Please wait while we set
        <br />
        thing up for you!
      </LoadingBox>

      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, text.item.itemInformation],
            [attachmentRef, text.item.itemAttachment],
            [routeRef, text.item.routeInformation],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>
      <FormSectionStyle ref={formRef} isClassificationBox={isClassificationBox}>
        <ItemInformationSection
          setisClassificationBox={setisClassificationBox}
          isClassificationBox={isClassificationBox}
          readOnly={isRouteActivate}
        />
        <AttachmentSection
          readOnly={isRouteActivate}
          attachmentFileArray={attachmentFileArray}
          setattachmentFileArray={setattachmentFileArray}
          attachmentTagArray={attachmentTagArray}
          setattachmentTagArray={setattachmentTagArray}
          attachmentCommentArray={attachmentCommentArray}
          setattachmentCommentArray={setattachmentCommentArray}
          setattachmentRef={setattachmentRef}
          title={text.item.itemAttachment}
          tagOptionList={attachmentTagOptionList}
          deletedFileIds={[]}
          attachmentIdArray={attachmentIdArray}
          attachmentOriginArray={[]}
          setattachmentIdArray={setattachmentIdArray}
          setattachmentOriginArray={() => {}}
          attachmentUploaderArray={[]}
          setattachmentUploaderArray={() => {}}
          attachmentDateArray={[]}
          setattachmentDateArray={() => {}}
        />
        <div className="gap" />
        {isRouteActivate ? (
          ""
        ) : (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveItem}
            condition={condition}
          >
            {text.saveAndRoute}
          </Button>
        )}
        <RouteInitSection
          activate={isRouteActivate}
          itemType={typeId}
          setrouteRef={setrouteRef}
          itemId={itemId}
          setsearchBoxType={setsearchBoxType}
          targetMember={targetMember}
          settargetMember={settargetMember}
          members={members}
        />
      </FormSectionStyle>
      <SearchBoxSectionStyle>
        <div className="searchBox">
          <ItemSearchBox
            width="100%"
            activate={isClassificationBox && !searchBoxType}
            setitemClassification={setitemClassification}
          />
        </div>
        <div className="searchBox">
          <SearchBox
            width="100%"
            height="100vh - 218px"
            type={searchBoxType}
            setproperty={setsearchBoxProperty}
            propertyIndex={targetMember}
            members={members}
            deletememberArray={deletemember}
          />
        </div>
      </SearchBoxSectionStyle>
    </ItemAddPageStyle>
  );
}
