import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BoldText from "../atom/BoldText";
import itemModuleStore from "../store/itemModuleStore";
import ToDoCard from "./ToDoCard";

const ScrollCardListStyle = styled.div`
  width: ${(props) => props.width};
  min-width: 210px;
  height: 100%;
  font-size: 13px;
  color: var(--deemGray);

  .scrollBox {
    margin-top: 15px;
    overflow-y: scroll;
    height: 80%;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .scrollBox::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;
export default function ScrollCardList({
  width,
  title,
  color,
  dataList,
  url,
  onClick,
}) {
  const { setIsModal } = itemModuleStore();
  const navigate = useNavigate();
  const cardList = dataList
    ? dataList.map((item) => {
        return (
          <ToDoCard
            key={item.id}
            onClick={() => {
              if (item.reviseId > 0) {
                navigate(
                  `${url.split("/")[0]}/edit/${item.id}/${item.reviseId}`
                );
              } else {
                navigate(`${url}/${item.id}`);
              }
              if (onClick) {
                onClick(item);
              }
            }}
            itemName={item.name}
            itemNumber={item.number}
            itemType={item.type}
            color={color}
          />
        );
      })
    : "";
  // datalist가 없어도 에러 안나도록
  return (
    <ScrollCardListStyle width={width}>
      <div className="titlePart">
        <BoldText color="var(--eciBlue)" fontSize="18px">
          {title}
        </BoldText>
        ({cardList.length})
      </div>
      <div className="scrollBox">{cardList}</div>
    </ScrollCardListStyle>
  );
}
