import React from "react";
import styled from "styled-components";

const DeleteInputStyle = styled.div`
  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height});
  padding: 0 15px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;

  font-size: 15px;
  font-weight: 600;
  color: var(--eciBlue);

  .placeholder {
    height: 3px;
    position: absolute;
    left: calc(4%);
    transform: translate(0, calc(-1 * (${(props) => props.height}) / 2));
    line-height: 3px;
    background-color: inherit;
    font-size: 12px;
    font-weight: 900;
    color: var(--eciBlue);
  }
  .link {
    cursor: pointer;
  }
  button {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 11px;
    color: var(--eciBlue);
    cursor: pointer;
  }
`;
export default function DeleteInput({
  width,
  height,
  deleteState,
  state,
  placeholder,
  readOnly,
  onLink,
}) {
  return (
    <DeleteInputStyle width={width} height={height}>
      <div className="placeholder">{placeholder}</div>
      {onLink && (
        <div className="link material-icons" onClick={onLink}>
          open_in_new
        </div>
      )}
      <div className="state"> {state}</div>
      {!readOnly && (
        <button type="button" onClick={() => deleteState(0)}>
          <span className="material-icons">delete</span>{" "}
        </button>
      )}
    </DeleteInputStyle>
  );
}
