import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import BoldText from "../atom/BoldText";
import Button from "../atom/Button";

import Input from "../atom/Input";
import Logo from "../static/logo1.svg";
import PlainSearchInput from "../molecule/PlainSearchInput";
import adminModuleStore from "../store/AdminModuleStore";
import txt from "../Text";
import commonStore from "../store/commonStore";

const SignupStyle = styled.div`
  background-color: white;
  border-radius: 10px;
  height: 75vh;
  width: 400px;
  margin-left: calc(50% - 200px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "Noto Sans KR", sans-serif;
  padding: 20px 0;
  position: relative;
  .bottom {
    border-bottom: solid 2px var(--eciBlue);
    input {
      color: var(--eciBlue);
      font-weight: bold;
    }
  }
  .profileImg {
    display: flex;
    flex-direction: column;
    height: 80px;
    align-items: center;
    justify-content: space-evenly;
    color: var(--textGray);
    font-size: 14px;
    position: relative;
    .sp {
      font-size: 12px;
      color: black;
      margin: 0 auto;
      text-align: center;
    }
    img {
      width: 45px;
      height: 45px;
      border-radius: 400px;
      background-color: white;
    }
    label {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 40px;
      right: -5px;
      background-color: white;
      border-radius: 3px;
      border: solid black 1px;
    }
  }
  input {
    border-bottom: solid 1px var(--textDarkGray);
  }
  .line {
    width: 300px;
    height: 0px;
    border-bottom: solid 1px var(--textDarkGray);
    transform: translate(0, -15px);
  }
  .blueLine {
    width: 300px;
    height: 0px;
    border-bottom: solid 2px var(--eciBlue);
    transform: translate(0, -15px);
  }
  .close {
    color: var(--textGray);
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .isAdmin {
    display: flex;
    margin-top: 8px;
    height: 30px;
    color: var(--textDarkGray);
    font-size: 11px;
    justify-content: space-between;
    align-items: center;
  }
`;

export default function SignUp() {
  const {
    signUpTeam,
    position,
    setposition,
    setsignUpTeam,
    setsignUpSearchBoxType,
    setisModalActivate,
    initadminModule,
    infoObj,
    setinfoObj,
    isModalReadOnly,
    setisModalReadOnly,
    isModalEditMode,
    setisModalEditMode,
  } = adminModuleStore();
  const [name, setName] = useState(infoObj.name);
  const [email, setEmail] = useState(infoObj.email);
  const [password, setPassword] = useState(infoObj.password);
  const [phoneNumber, setPhoneNumber] = useState(infoObj.phoneNumber);
  const [profileImage, setprofileImage] = useState({ Logo });
  const [ImagePreview, setImagePreview] = useState(infoObj.imagePreview);
  const [isAdmin, setisAdmin] = useState("N");

  const tokenAxios = commonStore((state) => state.tokenAxios);

  const navigate = useNavigate();
  const signUp = async () => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("username", name);
    // formData.append("department", department);
    formData.append("contact", phoneNumber);
    if (
      profileImage &&
      !profileImage.Logo &&
      typeof profileImage !== "string"
    ) {
      formData.append("profileImage", profileImage);
    }
    formData.append(
      "department1Id",
      signUpTeam.classification && signUpTeam.classification.split("/")[0]
    );
    formData.append(
      "department2Id",
      signUpTeam.classification && signUpTeam.classification.split("/")[1]
    );
    formData.append(
      "department3Id",
      signUpTeam.classification && signUpTeam.classification.split("/")[2]
    );
    formData.append(
      "department4Id",
      signUpTeam.classification && signUpTeam.classification.split("/")[3]
    );
    formData.append(
      "positionId",
      isAdmin === "Y" ? [position.id, 6] : position.id
    );

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/sign-up`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setisModalActivate(false);
    } catch (error) {
      if (error.response.data.result) {
        console.log(error.response);
        alert(error.response.data.result.msg);
      } else {
        console.log(error.response);
        navigate("/notFound");
      }
    }
  };
  const edit = async () => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("username", name);
    // formData.append("department", department);
    formData.append("contact", phoneNumber);
    if (
      profileImage &&
      !profileImage.Logo &&
      typeof profileImage !== "string"
    ) {
      formData.append("profileImage", profileImage);
    }
    formData.append(
      "department1Id",
      signUpTeam.classification && signUpTeam.classification.split("/")[0]
    );
    formData.append(
      "department2Id",
      signUpTeam.classification && signUpTeam.classification.split("/")[1]
    );
    formData.append(
      "department3Id",
      signUpTeam.classification && signUpTeam.classification.split("/")[2]
    );
    formData.append(
      "department4Id",
      signUpTeam.classification && signUpTeam.classification.split("/")[3]
    );
    formData.append(
      "positionId",
      isAdmin === "Y" ? [position.id, 6] : position.id
    );
    try {
      const response = await tokenAxios.put(
        `${process.env.REACT_APP_BASE_URL}/members/${infoObj.id}`,
        formData
      );
      setisModalActivate(false);
    } catch (error) {
      if (error.response.data.result) {
        console.log(error.response);
        alert(error.response.data.result.msg);
      } else {
        console.log(error.response);
        navigate("/notFound");
      }
    }
  };
  const onChangeFiles = (e) => {
    const { files } = e.target;

    setprofileImage(files[0]);

    const reader = new FileReader();
    reader.onload = (eve) => {
      setImagePreview(eve.target.result);
    };
    reader.readAsDataURL(files[0]);
  };
  useEffect(() => {
    setName(infoObj.name);
    setEmail(infoObj.email);
    setPassword(infoObj.password);
    setPhoneNumber(infoObj.phoneNumber);
    setprofileImage(infoObj.profileImage);
    setImagePreview(
      infoObj.profileImage &&
        `${process.env.REACT_APP_BASE_URL}/${infoObj.profileImage}`
    );
    setisAdmin(infoObj.isAdmin);
    setposition(infoObj.position);
    setsignUpTeam(infoObj.signUpTeam);
    return () => {
      setsignUpSearchBoxType("");
      window.location.reload();
    };
  }, []);
  //  valid check
  const regExPhoneNumber = new RegExp("^[0-9]{3}[0-9]{3,4}[0-9]{4}$");
  const regExPassword = new RegExp(
    "^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$"
  );
  const unValidRegEx = "^(?=314159365358979axx).+";

  const [errMessageObj, seterrMessageObj] = useState({
    //  초기값 or 새로 할당받은 값
    phoneNumber: txt.common.phoneNumberErrorMessage,
    password: txt.common.loginErrorMessage,
    email: "",
  });

  const [regExObj, setregExObj] = useState({
    // 초기값 or unvalidRegEx
    phoneNumber: regExPhoneNumber,
    password: regExPassword,
    email: "",
  });

  const checkIsFormValid = async () => {
    const result = await tokenAxios.post();
    // 결과에서 에러들을 가져온다
    // 에러가 있다면 에러메시지를 변경하고 regExp를 unvalid로 바꿔둔다.
    //
  };
  return (
    <SignupStyle>
      <BoldText color="var(--textDarkGray)" fontSize="18px">
        {!isModalEditMode && !isModalReadOnly && "Add New Member"}
      </BoldText>
      <span
        className="material-icons close"
        onClick={() => {
          setisModalActivate(false);
          setisModalReadOnly(false);
        }}
      >
        close
      </span>
      <br />
      <div className="profileImg">
        <img src={ImagePreview || Logo} alt="" />
        <label htmlFor="profileImage">
          <span className="material-icons sp">mode_edit</span>
          <input
            type="file"
            style={{ display: "none" }}
            id="profileImage"
            onChange={onChangeFiles}
          />
        </label>
      </div>
      <BoldText fontSize="14px" color="var(--textGray)">
        Profile
      </BoldText>{" "}
      <div className="inputContainer">
        <div className={infoObj.name !== name ? "bottom" : ""}>
          <Input
            width="300px"
            height="24px"
            state={name}
            setState={setName}
            placeholder="Name"
            color="var(--textDarkGray)"
            readOnly={isModalReadOnly}
          />
        </div>

        <div className={infoObj.email !== email ? "bottom" : ""}>
          <Input
            width="300px"
            height="24px"
            state={email}
            setState={setEmail}
            placeholder="Email"
            color="var(--textDarkGray)"
            // pattern={regExObj.email}
            // errorMessage={errMessageObj.email}
            readOnly={isModalReadOnly}
          />
        </div>

        <div
          className={
            infoObj.password !== password && regExPassword.test(password)
              ? "bottom"
              : ""
          }
        >
          <Input
            width="300px"
            height="24px"
            state={password}
            setState={setPassword}
            placeholder="Password"
            color="var(--textDarkGray)"
            pattern={regExObj.password}
            errorMessage={errMessageObj.password}
            readOnly={isModalReadOnly}
          />
        </div>
        <div
          className={
            infoObj.phoneNumber !== phoneNumber &&
            regExPhoneNumber.test(phoneNumber)
              ? "bottom"
              : ""
          }
        >
          <Input
            width="300px"
            height="24px"
            state={phoneNumber}
            setState={setPhoneNumber}
            placeholder="Phone Number"
            color="var(--textDarkGray)"
            pattern={regExObj.phoneNumber}
            errorMessage={errMessageObj.phoneNumber}
            readOnly={isModalReadOnly}
          />
        </div>

        <div
          className={`isAdmin ${infoObj.isAdmin !== isAdmin ? "bottom" : ""}`}
        >
          <div className="adminT">{txt.admin.isAdminCheck}</div>
          <input
            checked={isAdmin === "Y"}
            type="checkbox"
            value={isAdmin}
            onChange={(e) => {
              if (!isModalReadOnly) {
                setisAdmin(isAdmin === "Y" ? "N" : "Y");
              }
            }}
          />
        </div>

        <br />
        <br />
      </div>
      <BoldText fontSize="14px" color="var(--textGray)">
        Team Info
      </BoldText>
      <br />
      <PlainSearchInput
        width="300px"
        height="30px"
        placeholder="Team"
        state={signUpTeam.value}
        onClick={() => {
          if (!isModalReadOnly) {
            setsignUpSearchBoxType("Team");
          }
        }}
        noAnimation
      />
      <div
        className={infoObj.signUpTeam === signUpTeam ? "line" : "blueLine"}
      />
      <PlainSearchInput
        width="300px"
        height="30px"
        placeholder="Position"
        state={position.name}
        onClick={() => {
          if (!isModalReadOnly) {
            setsignUpSearchBoxType("Position");
          }
        }}
        borderBottom={
          infoObj.position === position
            ? "var(--textDarkGray)"
            : "var(--eciBlue)"
        }
        noAnimation
        readOnly={!signUpTeam.value}
      />
      <div className={infoObj.position === position ? "line" : "blueLine"} />
      <br />
      <Button
        backgroundColor="var(--eciBlue)"
        color="var(--textDarkGray)"
        width="320px"
        height="50px"
        fontSize="14px"
        onClick={() => {
          if (isModalReadOnly) {
            setisModalReadOnly(false);
            setisModalEditMode(true);
          } else {
            if (isModalEditMode) {
              edit();
            } else {
              signUp();
            }
            setisModalEditMode(false);
          }
        }}
        condition={
          isModalReadOnly
            ? true
            : !!name &&
              !!position &&
              !!email &&
              !!phoneNumber &&
              !!signUpTeam.value &&
              // regExPassword.test(password) &&
              regExPhoneNumber.test(phoneNumber)
        }
      >
        {isModalReadOnly ? "Edit Information" : "Apply"}
      </Button>
    </SignupStyle>
  );
}
