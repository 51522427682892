import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import releaseModuleStore from "../../store/releaseModuleStore";
import ReleaseInformationSection from "../ReleaseInformationSection";

import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";

import BoldText from "../../atom/BoldText";
import RouteSection from "../RouteSection";
import AttachmentSection from "../AttachmentSection";
import Table from "../../molecule/Table";
import TableIndexRow from "../../atom/TableIndexRow";
import TableRow from "../../atom/TableRow";
import txt from "../../Text";

const ReleaseReviewPageStyle = styled.div`
  display: flex;
  position: relative;
  .tempSave {
    height: 40px;

    position: absolute;
    top: -40px;
    left: calc(58% - 110px);
    display: flex;
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`;
const FormSectionStyle = styled.div`
  width: 58%;
  height: calc(100vh - 230px);
  overflow: scroll;
  padding-left: 18%;
  padding-right: 1.5%;
  padding-top: 20px;
  .gap {
    height: 45px;
  }
  ::-webkit-scrollbar {
    height: 7px;
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
`;
const SearchBoxSectionStyle = styled.div`
  width: 42%;
`;

const ReleaseFromStyle = styled.div``;
function ReleaseReviewPage() {
  const { tokenAxios } = commonStore((state) => ({
    tokenAxios: state.tokenAxios,
  }));

  const {
    initreleaseModule,
    // releaseInfo
    setreleaseInfo,
    releaseId,
    releaseNumber,
    releaseItemId, // {id,name}
    releaseTitle,
    releaseType,
    releaseContent,
    releaseCoId, // {id,name}
    releaseOrganizationId,
    setreleaseOrganizationId,
    // attachment
    attachmentFileArray,
    attachmentTagArray,
    attachmentCommentArray,
    attachmentOriginArray,
    attachmentDateArray,
    attachmentUploaderArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    setattachmentOriginArray,
    setattachmentDateArray,
    setattachmentUploaderArray,
    attachmentModifiedAtArray,
    setattachmentModifiedAtArray,
    attachmentIdArray,
    setattachmentIdArray,
    deletedFileIdArray,
    setdeletedFileIdArray,

    // route
    routeNumber,
    routeData,
    setrouteData,
    setrouteNumber,

    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // etc

    setcurrentCategory,
  } = releaseModuleStore();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const formRef = useRef();
  const fromRef = useRef();

  const getReleaseData = useCallback(
    async (Id) => {
      const response = await tokenAxios.get(`release/${Id}`);
      const { data } = response.data.result;
      setreleaseInfo({
        releaseId: data.id,
        releaseNumber: data.releaseNumber,
        releaseItemId: data.item, // {id,name}
        releaseTitle: data.releaseTitle,
        releaseType: data.releaseType.id,
        releaseContent: data.releaseContent,
        releaseCoId: data.releaseCoId, // {id,name}
        releaseOrganizationId: data.releaseOrganizationId, // routeNumbers
        routeNumber: data.routeId,
      });

      // attachment
      const tempfileArray = [];
      const temptagArray = [];
      const tempcommentArray = [];
      const temporiginArray = [];
      const tempuploaderArray = [];
      const tempdateArray = [];
      const tempidArray = [];
      const tempdeletedFileArray = [];
      const tempModifiedAtArray = [];

      if (data.projectAttachments) {
        data.projectAttachments.forEach((item) => {
          tempfileArray.push(item.originName);
          tempcommentArray.push(item.attach_comment);
          temptagArray.push(item.tag);
          temporiginArray.push(item.attachmentaddress);
          tempuploaderArray.push(item.upload);
          tempdateArray.push(item.date);
          tempidArray.push(item.id);
          if (item.deleted) {
            tempdeletedFileArray.push(item.id);
          }
          tempModifiedAtArray.push(item.modifiedAt ? item.modifiedAt : "");
        });
        setattachmentFileArray(tempfileArray);
        setattachmentIdArray(tempidArray);
        setattachmentCommentArray(tempcommentArray);
        setattachmentTagArray(temptagArray);
        setattachmentOriginArray(temporiginArray);
        setattachmentDateArray(tempdateArray);
        setattachmentUploaderArray(tempuploaderArray);
        setdeletedFileIdArray(tempdeletedFileArray);
        setattachmentModifiedAtArray(tempModifiedAtArray);
      }
    },
    [tokenAxios]
  );

  const params = useParams();
  const sendReleaseReviewConfirm = async () => {
    const response = await tokenAxios.post(`release-check/${params.releaseId}`);
  };
  useEffect(() => {
    setcurrentCategory("List");
    getReleaseData(params.releaseId);
    sendReleaseReviewConfirm();
    return () => {
      initreleaseModule();
    };
  }, [setcurrentCategory]);

  return (
    <ReleaseReviewPageStyle>
      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, txt.rel.relInfo],
            [attachmentRef, txt.rel.relAttachment],
            [fromRef, txt.rel.relFrom],
            [routeRef, txt.rel.routeInfo],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>
      <FormSectionStyle ref={formRef}>
        <ReleaseInformationSection readOnly />

        <AttachmentSection
          readOnly
          attachmentModifiedAtArray={attachmentModifiedAtArray}
          attachmentFileArray={attachmentFileArray}
          setattachmentFileArray={setattachmentFileArray}
          attachmentTagArray={attachmentTagArray}
          setattachmentTagArray={setattachmentTagArray}
          attachmentCommentArray={attachmentCommentArray}
          attachmentOriginArray={attachmentOriginArray}
          setattachmentOriginArray={setattachmentOriginArray}
          setattachmentCommentArray={setattachmentCommentArray}
          attachmentDateArray={attachmentDateArray}
          attachmentUploaderArray={attachmentUploaderArray}
          setattachmentDateArray={setattachmentDateArray}
          setattachmentUploaderArray={setattachmentUploaderArray}
          setattachmentRef={setattachmentRef}
          title={txt.rel.relAttachment}
          // edit
          attachmentIdArray={attachmentIdArray}
          setattachmentIdArray={setattachmentIdArray}
          deletedFileIds={deletedFileIdArray}
        />
        <br />
        <ReleaseFromStyle ref={fromRef}>
          <BoldText fontSize="14px" color="var(--eciBlue)">
            {txt.rel.relFrom}{" "}
          </BoldText>
          <br />

          <Table width="100%" minHeight="120px">
            <TableIndexRow widthArray={[1]} itemArray={txt.rel.relIndexArray} />
            {releaseOrganizationId &&
              releaseOrganizationId.map((item, i) => {
                const onDelete = () => {
                  const tmpArr = [...releaseOrganizationId];
                  tmpArr.splice(i, 1);
                  setreleaseOrganizationId(tmpArr);
                };
                return (
                  <TableRow key={i} widthArray={[9]} itemArray={[item.name]} />
                );
              })}
          </Table>
        </ReleaseFromStyle>
        <div className="gap" />

        <RouteSection
          activate
          routeNumber={routeNumber}
          setrouteRef={setrouteRef}
          routeData={routeData}
          setrouteData={setrouteData}
        />
      </FormSectionStyle>
    </ReleaseReviewPageStyle>
  );
}
export default React.memo(ReleaseReviewPage);
