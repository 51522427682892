import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import StatusTag from "../../atom/StatusTag";
import TableIndexRow from "../../atom/TableIndexRow";
import TableRow from "../../atom/TableRow";
import PaginationTable from "../../molecule/PaginationTable";
import SearchBar from "../../molecule/SearchBar";
import commonStore from "../../store/commonStore";
import documentModuleStore from "../../store/DocumentModuleStore";
import txt from "../../Text";

const DocumentListPageStyle = styled.div``;
const SearchSectionStyle = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function DocumentListPage() {
  const { tokenAxios } = commonStore((state) => ({
    tokenAxios: state.tokenAxios,
  }));

  const { setcurrentCategory } = documentModuleStore();

  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [pageNum, setPageNum] = useState("0");
  const [documentArray, setdocumentArray] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [searchText, setsearchText] = useState("");

  const documentRowList =
    documentArray &&
    documentArray.map((item, i) => {
      return (
        <Link
          to={`detail/${item.id}`}
          key={i}
          style={{ textDecoration: "none" }}
        >
          <TableRow
            key={i}
            itemArray={[
              item.documentNumber,
              item.documentTitle,
              item.revision,
              item.tag && item.tag.name,
              <StatusTag height="30px" width="90%">
                {item.status}
              </StatusTag>,
              item.createdAt,
              // 이거 나중에 id붙이기
              item.creator && item.creator.username,
            ]}
            widthArray={[4, 6, 3, 3, 3, 3, 3]}
          />
        </Link>
      );
    });

  useEffect(() => {
    setcurrentCategory("List");
    try {
      tokenAxios
        .get(`/doc/page?size=${rowsPerPage}&page=${pageNum}&name=${searchText}`)
        .then((res) => {
          setdocumentArray(res.data.content);
          setTotalPage(res.data.totalPages);
        })
        .catch((err) => {
          //
        });
    } catch (err) {
      // console.log(err);
    }
  }, [setcurrentCategory, pageNum, searchText, rowsPerPage]);
  useEffect(() => {
    setPageNum("0");
  }, [searchText]);
  return (
    <DocumentListPageStyle>
      <SearchSectionStyle>
        <SearchBar
          width="170px"
          height="30px"
          placeholder="Search Items"
          state={searchText}
          setState={setsearchText}
        />
        <div className="buttons" />
      </SearchSectionStyle>
      <PaginationTable
        width="100%"
        minHeight="300px"
        totalPage={totalPage}
        rowsPerPageDefault={10}
        pageNum={pageNum}
        setRowsPerPage={setRowsPerPage}
        setPageNum={setPageNum}
        rowsPerPage={rowsPerPage}
        noshadow
      >
        <TableIndexRow
          itemArray={txt.doc.docListIndexArray}
          widthArray={[4, 6, 3, 3, 3, 3, 3]}
        />
        {documentRowList}
      </PaginationTable>
    </DocumentListPageStyle>
  );
}
