import React from "react";
import styled from "styled-components";
import StatusTag from "../atom/StatusTag";
import resultText from "../Text";

const StatisticCircleBoxStyle = styled.div`
  width: 320px;
  height: 290px;
  background-color: white;
  border-radius: 10px;
  position: relative;
  svg {
    background-color: transparent;
    transform: rotate(-90deg);

    margin: 0px 35px;
  }

  .background {
    stroke-dashoffset: 0;
    stroke-dasharray: 503 503;
  }
  .working {
    stroke-dashoffset: 0;
    stroke-dasharray: ${(props) =>
        props.working > 5 ? props.working - 5 : props.working}
      503;
  }
  .complete {
    stroke-dashoffset: -${(props) => props.working};
    stroke-dasharray: ${(props) =>
        props.complete > 5 ? props.complete - 5 : props.complete}
      503;
  }
  .pending {
    stroke-dashoffset: -${(props) => props.complete + props.working};
    stroke-dasharray: ${(props) =>
        props.pending > 5 ? props.pending - 5 : props.pending}
      503;
  }
  .drop {
    stroke-dashoffset: -${(props) => props.pending + props.complete + props.working};
    stroke-dasharray: ${(props) =>
        props.drop > 5 ? props.drop - 5 : props.drop}
      503;
  }
  /* .drop {
    stroke-dashoffset: -${(props) =>
    props.pending + props.complete + props.complete + props.working};
    stroke-dasharray: ${(props) =>
    props.drop > 5 ? props.drop - 5 : props.drop}
      503;
  } */
  .tagList {
    height: 60px;
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .total {
    width: 130px;
    height: 130px;
    padding-top: 35px;
    position: absolute;
    top: 110px;
    left: 95px;

    border-radius: 1000px;
    background-color: var(--eciBlue);

    color: white;
    text-align: center;
    font-size: 12px;

    span {
      font-size: 24px;
      font-weight: 600;
    }
  }
`;
export default function StatisticCircleBox({
  working,
  complete,
  pending,
  drop,
  total,
}) {
  return (
    <StatisticCircleBoxStyle
      working={working * 503}
      complete={complete * 503}
      pending={pending * 503}
      drop={drop * 503}
    >
      <div className="tagList">
        <StatusTag width="120px" height="26px">
          {resultText.home.stat.working}
        </StatusTag>
        <StatusTag width="120px" height="26px">
          {resultText.home.stat.complete}
        </StatusTag>

        <StatusTag width="120px" height="26px">
          {resultText.home.stat.pending}
        </StatusTag>
        <StatusTag width="120px" height="26px">
          {resultText.home.stat.drop}
        </StatusTag>
      </div>
      <div className="total">
        {resultText.home.stat.total} <br />
        <span>{total}</span>
      </div>
      <svg width="220" height="220">
        <circle
          className="background"
          cx="125"
          cy="125"
          r="80"
          stroke="white"
          strokeWidth="14"
          fill="none"
        />
        <circle
          className="working"
          cx="125"
          cy="125"
          r="80"
          stroke="var(--todoYellow)"
          strokeWidth="14"
          fill="none"
        />
        <circle
          className="complete"
          cx="125"
          cy="125"
          r="80"
          stroke="var(--eciBlue)"
          strokeWidth="14"
          fill="none"
        />
        <circle
          className="pending"
          cx="125"
          cy="125"
          r="80"
          stroke="var(--deemGray)"
          strokeWidth="14"
          fill="none"
        />
        <circle
          className="drop"
          cx="125"
          cy="125"
          r="80"
          stroke="var(--todoRed)"
          strokeWidth="14"
          fill="none"
        />
      </svg>
    </StatisticCircleBoxStyle>
  );
}
