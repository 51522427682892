import create from "zustand";

const designModuleStore = create((set, get) => ({
  isMatchConfirmed: false,
  setisMatchConfirmed: (val) => {
    set(() => ({ isMatchConfirmed: val }));
  },
  // design state
  designId: "",
  setdesignId: (val) => {
    set(() => ({ designId: val }));
  },
  // item
  designItemId: "",
  setdesignItemId: (val) => {
    set(() => ({ designItemId: val }));
  },
  designItemName: "",
  setdesignItemName: (val) => {
    set(() => ({ designItemName: val }));
  },
  designItemNumber: "",
  setdesignItemNumber: (val) => {
    set(() => ({ designItemNumber: val }));
  },
  designItemImageAddress: "",
  roomId: "",
  setroomId: (val) => {
    set(() => ({ roomId: val }));
  },
  setdesignItemInfo: (val) => {
    set(() => val);
  },
  // file
  cadFile: "",
  setcadFile: (val) => {
    set(() => ({ cadFile: val }));
  },
  cadFileList: [],
  setcadFileList: (val) => {
    set(() => ({ cadFileList: val }));
  },
  choiceDesignId: "",
  setchoiceDesignId: (val) => {
    set(() => ({ choiceDesignId: val }));
  },

  //  uploader
  uploaderType: "Catia", // Catia, Cadian, Link
  setuploaderType: (val) => {
    set(() => ({ uploaderType: val }));
  },
  // attachment
  attachmentFileArray: [],
  setattachmentFileArray: (val) => {
    set(() => ({ attachmentFileArray: val }));
  },
  attachmentOriginArray: [],
  setattachmentOriginArray: (val) => {
    const newVal =
      val &&
      val.map((item) => {
        return `${process.env.REACT_APP_BASE_URL}/${item.toString()}`;
      });
    set(() => ({
      attachmentOriginArray: newVal,
    }));
  },
  attachmentTagArray: [],
  setattachmentTagArray: (val) => {
    set(() => ({ attachmentTagArray: val }));
  },

  attachmentCommentArray: [],
  setattachmentCommentArray: (val) => {
    set(() => ({ attachmentCommentArray: val }));
  },
  attachmentUploaderArray: [],
  setattachmentUploaderArray: (val) => {
    set(() => ({ attachmentUploaderArray: val }));
  },
  attachmentDateArray: [],
  setattachmentDateArray: (val) => {
    set(() => ({ attachmentDateArray: val }));
  },
  setattachmentInfo: (val) => {
    set(() => val);
  },
  // edit attachment
  // edit attachment
  deletedFileIdArray: [],
  setdeletedFileIdArray: (val) => {
    set(() => ({ deletedFileIdArray: val }));
  },
  attachmentIdArray: [],
  setattachmentIdArray: (val) => {
    set(() => ({ attachmentIdArray: val }));
  },
  attachmentModifiedAtArray: [],
  setattachmentModifiedAtArray: (val) => {
    set(() => ({ attachmentModifiedAtArray: val }));
  },
  addedAttachmentArray: [],
  setaddedAttachmentArray: (val) => {
    set(() => ({ addedAttachmentArray: val }));
  },
  addedAttachmentTagArray: [],
  setaddedAttachmentTagArray: (val) => {
    set(() => ({ addedAttachmentTagArray: val }));
  },
  addedAttachmentCommentArray: [],
  setaddedAttachmentCommentArray: (val) => {
    set(() => ({ addedAttachmentCommentArray: val }));
  },
  deletedAttachmentArray: [],
  setdeletedAttachmentArray: (val) => {
    set(() => ({ deletedAttachmentArray: val }));
  },
  // route
  routeNumber: "",
  setrouteNumber: (val) => {
    set(() => ({ routeNumber: val }));
  },
  routeData: [],
  setrouteData: (val) => {
    set(() => ({ routeData: val }));
  },
  // 스크롤 ref 모음
  informationRef: "",
  setinformationRef: (val) => {
    set(() => ({ informationRef: val }));
  },
  attachmentRef: "",
  setattachmentRef: (val) => {
    set(() => ({ attachmentRef: val }));
  },
  routeRef: "",
  setrouteRef: (val) => {
    set(() => ({ routeRef: val }));
  },
  // 로딩창
  isLoading: () => {
    return !!get().cadFile && get().cadFileList.length === 0;
  },
  // 초기화 함수
  initdesignModule: () => {
    set(() => ({
      isMatchConfirmed: false,
      uploaderType: "Catia", // Catia, Cadian, Link
      choiceDesignId: "",

      designItemId: "",
      designItemName: "",
      designItemNumber: "",
      cadFile: "",
      cadFileList: [],
      attachmentTagOptionList: [],
      attachmentFileArray: [],
      attachmentOriginArray: [],
      attachmentTagArray: [],
      attachmentCommentArray: [],
      attachmentUploaderArray: [],
      attachmentModifiedAtArray: [],

      addedAttachmentArray: [],
      addedAttachmentTagArray: [],
      addedAttachmentCommentArray: [],
      deletedAttachmentArray: [],
      routeNumber: "",
      routeData: [],
    }));
  },
}));
export default designModuleStore;
