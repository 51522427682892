import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import LineTitle from "../atom/LineTitle";
import RoundTextBox from "../atom/RoundTextBox";
import TableIndexRow from "../atom/TableIndexRow";
import TableRow from "../atom/TableRow";
import AnimationInput from "../molecule/AnimationInput";
import AnimationSelectBox from "../molecule/AnimationSelectBox";
import PaginationTable from "../molecule/PaginationTable";
import SearchBar from "../molecule/SearchBar";
import SearchInput from "../molecule/SearchInput";
import TextBox from "../molecule/TextBox";
import commonStore from "../store/commonStore";
import CRCOModuleStore from "../store/CRCOModuleStore";
import ModalBox from "./ModalBox";
import txt from "../Text";
import FilterSearchBox from "../molecule/FilterSearchBox";

const CRInformationSectionStyle = styled.div`
  pointer-events: ${(props) => (props.readOnly ? "none" : "")};

  .gridContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: repeat(9, 60px);
    grid-column-gap: 15px;
    grid-row-gap: 10px;
  }
  .blueBox {
    grid-column: 1/3;
    grid-row: 4/9;
    padding: 15px;
    background-color: var(--eciBlue);
    border-radius: 10px;
    margin-bottom: 10px;
  }
`;
export default function CRInformationSection({ readOnly }) {
  const {
    // crinfo
    CRNumber,
    setCRNumber,
    crReasonId,
    setCRReason,
    CRReasonText,
    setCRReasonText,
    crImportanceId,
    setcrImportanceId,
    crSourceId,
    setCRSource,
    CRTitle,
    setCRTitle,
    CRBody,
    setCRBody,
    CRSolution,
    setCRSolution,
    CRAffectedItem,
    setCRAffectedItem,
    // searchbox
    searchBoxType,
    setsearchBoxType,
    // etc
    setinformationRef,
  } = CRCOModuleStore();
  const informationRef = useRef();
  const [isModalBox, setisModalBox] = useState(false);
  const { tokenAxios } = commonStore();
  // Modal
  const [searchText, setsearchText] = useState("");
  const [itemName, setitemName] = useState("");
  const [itemNumber, setitemNumber] = useState("");
  const [carType, setcarType] = useState("");
  const [carTypeArr, setcarTypeArr] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [pageNum, setPageNum] = useState("0");
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [dataList, setdataList] = useState([]);
  const rows =
    dataList &&
    dataList[0] &&
    dataList[0].map((item, i) => {
      return (
        <TableRow
          key={i}
          widthArray={[0.2, 1, 1, 1, 1, 1]}
          itemArray={[
            "",
            item && item.cardNumber,
            item && item.classification,
            item && item.cardName,
            item && item.cardType,
            item && item.sharing && item.sharing === "공용" ? (
              <RoundTextBox
                width="50%"
                backgroundColor="var(--lightGray)"
                color="var(--eciBlue)"
                height="30px"
              >
                {txt.item.sharing}{" "}
              </RoundTextBox>
            ) : (
              <RoundTextBox
                width="50%"
                backgroundColor="var(--lightGray)"
                color="var(--eciBlue)"
                height="30px"
              >
                {txt.item.notSharing}{" "}
              </RoundTextBox>
            ),
          ]}
          onClick={() => {
            setCRAffectedItem({
              name: item && item.cardName,
              id: item && item.cardId,
            });

            setisModalBox(false);
          }}
        />
      );
    });

  const getModalData = async () => {
    const response = await tokenAxios.get(
      `affected/items/page?size=${rowsPerPage}&page=${pageNum}&name=${searchText}&itemName=${itemName}&itemNumber=${itemNumber}&carTypeId=${carType}`
    );

    setdataList([response.data.content]);
    setTotalPage(response.data.totalPages);
  };
  // 모달 끝
  useEffect(() => {
    setinformationRef(informationRef);
    if (!readOnly) {
      getModalData();
    }
    setPageNum("0");
  }, [setinformationRef, searchText]);
  useEffect(() => {
    getModalData();
  }, [searchText, itemName, itemNumber, carType, pageNum, rowsPerPage]);
  return (
    <CRInformationSectionStyle ref={informationRef} readOnly={readOnly}>
      <ModalBox
        isActivate={isModalBox}
        onClick={(val) => {}}
        setisActivate={setisModalBox}
      >
        <FilterSearchBox
          searchText={searchText}
          setsearchText={setsearchText}
          setitemName={setitemName}
          setitemNumber={setitemNumber}
          setcarType={setcarType}
        />
        <br />
        <br />
        <PaginationTable
          minHeight="480px"
          totalPage={totalPage}
          setRowsPerPage={setrowsPerPage}
          rowsPerPageDefault={7}
          pageNum={pageNum}
          rowsPerPage={rowsPerPage}
          setpageNum={setPageNum}
        >
          <TableIndexRow
            widthArray={[1, 1, 1, 1, 1]}
            itemArray={txt.crco.crModalIndexArray}
          />
          {rows}
        </PaginationTable>
      </ModalBox>
      <LineTitle
        fontSize="16px"
        color="var(--eciBlue)"
        width="100%"
        height="60px"
      >
        {txt.crco.crInfo}{" "}
      </LineTitle>
      <div className="gridContainer">
        <AnimationInput
          width="100%"
          height="40px"
          placeholder={txt.crco.crNum}
          state={CRNumber}
          setState={setCRNumber}
          readOnly
          backgroundColor="var(--textDarkGray)"
        />
        <div />
        <SearchInput
          required
          width="100%"
          height="40px"
          placeholder={txt.crco.crReason}
          state={crReasonId && crReasonId.name}
          onClick={() => {
            setsearchBoxType("crReasonId");
          }}
          readOnly={readOnly}
        />
        {crReasonId && crReasonId.id === 1 ? (
          <AnimationInput
            width="100%"
            height="40px"
            placeholder={txt.crco.crReason}
            state={CRReasonText}
            setState={setCRReasonText}
            readOnly={readOnly}
          />
        ) : (
          <div />
        )}
        <AnimationSelectBox
          width="100%"
          height="40px"
          placeholder={txt.crco.crImportance}
          state={crImportanceId && crImportanceId.id}
          setState={setcrImportanceId}
          required
          readOnly={readOnly}
        >
          <option value="1">A</option>
          <option value="2">B</option>
          <option value="3">C</option>
        </AnimationSelectBox>
        <SearchInput
          required
          width="100%"
          height="40px"
          placeholder={txt.crco.crSource}
          state={crSourceId && crSourceId.name}
          onClick={() => {
            setsearchBoxType("crSourceId");
          }}
          readOnly={readOnly}
        />
        <div className="blueBox">
          <AnimationInput
            width="50%"
            height="40px"
            placeholder={txt.crco.crTitle}
            state={CRTitle}
            setState={setCRTitle}
            readOnly={readOnly}
            required
          />
          <TextBox
            width="100%"
            height="100px"
            placeholder={txt.crco.crContent}
            required
            state={CRBody}
            setState={setCRBody}
            readOnly={readOnly}
          />
          <br />
          <TextBox
            width="100%"
            height="100px"
            placeholder={txt.crco.proposedSol}
            required
            state={CRSolution}
            setState={setCRSolution}
            readOnly={readOnly}
          />
        </div>
        <SearchInput
          required
          width="100%"
          height="40px"
          placeholder={txt.crco.affectedItem}
          state={
            CRAffectedItem.name ? CRAffectedItem.name : CRAffectedItem.itemName
          }
          onClick={() => {
            setisModalBox(true);
          }}
          readOnly={readOnly}
        />
      </div>
    </CRInformationSectionStyle>
  );
}
