import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import NotificationsNone from "../static/NotificationsNone.svg";
import Language from "../static/Language.svg";
import commonStore from "../store/commonStore";
import OptionModal from "../organism/OptionModal";

const HeaderIconListStyle = styled.div`
  display: flex;
  width: 150px;
  justify-content: space-evenly;
  margin-right: 60px;
  span {
    cursor: pointer;
    color: var(--textDarkGray);
  }
  .lang {
    position: relative;
    .langModal {
      position: absolute;
      top: 20px;
    }
    cursor: pointer;
  }
`;
export default function HeaderIconList() {
  const { setIsLogged } = commonStore();
  const [isLangModal, setisLangModal] = useState(false);
  const [lang, setlang] = useState("eng");
  const navigate = useNavigate();

  const logOut = async () => {
    /*eslint-disable */
    if (confirm("Log out?") === true) {
      setIsLogged(false);
      const userId = JSON.parse(localStorage.getItem("undefined")).state
        .userData.id;
      fetch(`${process.env.REACT_APP_BASE_URL}/logout/${userId}`, {
        // credentials: "include",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        // body: {},
      });
      localStorage.removeItem("token");
      localStorage.removeItem("alarmArr");

      navigate("/");
    } else {
    }
  };

  return (
    <HeaderIconListStyle>
      <div
        className="lang"
        onClick={(e) => {
          e.stopPropagation();
          setisLangModal(!isLangModal);
        }}
      >
        <img className="lang" src={Language} alt="" />

        {isLangModal && (
          <div className="langModal">
            <OptionModal
              title="Choose Language"
              uiObject={{ language: { KOR: "kor", ENG: "eng", CHN: "chn" } }}
              setisModal={setisLangModal}
              width="140px"
              setStateList={[
                (val) => {
                  localStorage.setItem("lang", val);
                  window.location.reload();
                },
              ]}
            />
          </div>
        )}
      </div>
      <span
        className="material-icons"
        onClick={() => {
          window.location.reload();
        }}
      >
        refresh
      </span>
      <span className="material-icons" onClick={logOut}>
        logout
      </span>
    </HeaderIconListStyle>
  );
}
