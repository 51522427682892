import React from "react";
import styled from "styled-components";
import TableRow from "../atom/TableRow";
import logo1 from "../static/logo1.svg";

const TreeIndentListStyle = styled.div`
  /* border: solid; */
  .indentBox {
    margin-left: ${(props) => props.indent};
  }
  .red {
    height: 70%;
    width: 2px;
    border-left: solid 4px var(--todoRed);
  }
  .blue {
    height: 70%;
    width: 2px;
    border-left: solid 4px var(--eciBlue);
  }
  img {
    width: 20px;
    height: 20px;
    border: solid;
    border-radius: 100px;
  }
`;
export default function TreeIndentList({ data, indent }) {
  const childTree =
    data &&
    data.children &&
    data.children.map((item, i) => {
      return <TreeIndentList key={i} data={item} indent={indent} />;
    });
  return (
    <TreeIndentListStyle indent={indent}>
      <TableRow
        noBorder
        widthArray={[0.5, 2, 6, 12, 5]}
        itemArray={[
          data && data.match ? (
            <div className="blue"> </div>
          ) : (
            <div className="red"> </div>
          ),
          data && data.thumbnail ? (
            <img src={data && data.thumbnail} alt="tmb" />
          ) : (
            <img src={logo1} alt="tmb" />
          ),
          data && data.itemNumber,
          data && data.name,
          data && data.quantity,
        ]}
      />
      {data && data.children && data.children[0] ? (
        <div className="indentBox"> {childTree} </div>
      ) : (
        ""
      )}
    </TreeIndentListStyle>
  );
}
