import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import BoldText from "../atom/BoldText";
import StatusTag from "../atom/StatusTag";
import TableIndexRow from "../atom/TableIndexRow";
import TableRow from "../atom/TableRow";
import AlarmBox from "../molecule/AlarmBox";
import PaginationTable from "../molecule/PaginationTable";
import ScrollCardList from "../molecule/ScrollCardList";
import StatisticCircleBox from "../molecule/StatisticCircleBox";
import TodoNumberBox from "../molecule/TodoNumberBox";
import commonStore from "../store/commonStore";
import releaseModuleStore from "../store/releaseModuleStore";
import resultText from "../Text";

const HomeStyle = styled.div`
  padding-top: 80px;
  width: 100%;
  min-width: 1000px;
  min-height: 580px;
  height: calc(100vh);
  overflow: scroll;
  padding-right: 4%;
  padding-bottom: 50px;
  background-color: var(--lightGray);
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 15px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
const TopSectionStyle = styled.div`
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .left {
    font-size: 30px;
    color: var(--eciBlue);
    width: 65%;
    .todo {
      margin: 40px 0 16px 10px;
      font-size: 20px;
      font-weight: 600;
    }
    .todoList {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
  }
  .right {
    width: 30%;
    height: 150px;
    padding: 15px 0;
  }
  .box {
    background-color: white;
    border-radius: 10px;
    border: solid 6px white;
    border-right: none;
    height: calc(100% - 20px);
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 5px;
      height: 7px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border: 15px solid transparent;
      width: 5px;
      background: rgba(4, 8, 15, 0.3);
      border-radius: 8px;
    }
  }
`;
const TodoListSection = styled.div`
  width: 100%;
  padding: 20px 60px;
  padding-left: 50px;
  padding-top: 20px;
  overflow: scroll;
  display: ${(props) => (props.active ? "flex" : "none")};
  background-color: white;
  border: solid 2px var(--eciBlue);
  border-radius: 8px;
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 15px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
  height: ${(props) => props.height}px;
`;
const Border = styled.div`
  padding-bottom: 100px;
  cursor: pointer;
`;
const StatisticSection = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .table {
    width: calc(100% - 336px);
  }
`;

export default function Home() {
  const { setCurrentModule, tokenAxios } = commonStore((state) => ({
    setCurrentModule: state.setCurrentModule,
    tokenAxios: state.tokenAxios,
  }));
  const { setreleaseType } = releaseModuleStore();
  // todo관리
  const [todoCategory, settodoCategory] = useState(-1);
  const [todoData, settodoData] = useState([]);
  const [todoCount, settodoCount] = useState([]);
  // myproject관리
  const [myProjectListData, setmyProjectListData] = useState([]);
  const rowsPerPageDefault = 4;
  const [rowsPerPage, setrowsPerPage] = useState(rowsPerPageDefault);
  const [pageNum, setpageNum] = useState(0);
  const [totalPage, settotalPage] = useState(0);
  // statistic circle 관리
  const [totalProject, settotalProject] = useState(0);
  const [working, setworking] = useState(0);
  const [development, setdevelopment] = useState(0);
  const [complete, setcomplete] = useState(0);
  const [pending, setpending] = useState(0);
  const [drop, setdrop] = useState(0);
  // alarm관리
  const [alarmList, setalarmList] = useState([]);
  const localAlarmArr = localStorage.getItem("alarmArr")
    ? localStorage.getItem("alarmArr")
    : "[]";
  // list들
  const todoNumberBoxList = resultText.home.todoCategoryArray.map((item, i) => {
    return (
      <TodoNumberBox
        key={i}
        isActive={todoCategory === i}
        onClick={() => settodoCategory(i)}
        width="12%"
        number={todoCount[i]}
        name={item}
      />
    );
  });
  const todoList =
    todoCategory >= 0 && todoData[todoCategory]
      ? todoData[todoCategory].map((item, i) => {
          let url = "notFound";
          let onClick = () => {};
          const urlId = false;
          switch (todoCategory) {
            case 0:
              switch (item.category) {
                case "Save as Draft":
                  url = `project/edit`;

                  break;
                case "New Project":
                  url = "project/add";

                  break;
                default:
              }
              break;
            case 1:
              switch (item.category) {
                case "Save as Draft":
                  url = `design/edit`;

                  break;
                case "New Design":
                  url = "design/add";
                  break;
                case "Waiting Review":
                  url = `design/review`;
                  break;
                case "Rejected Design":
                  url = `design/edit`;
                  break;
                default:
              }
              break;
            case 2:
              url = `BOM`;
              break;
            case 3:
              switch (item.category) {
                case "Save as Draft":
                  url = `item/edit`;

                  break;
                case "Need Revise":
                  url = "item/edit";
                  break;
                case "Waiting Review":
                  url = `item/review`;
                  break;
                case "Rejected Item":
                  url = `item/edit`;
                  break;
                default:
              }
              break;
            case 4:
              switch (item.category) {
                case "Save as Draft CR":
                  url = `C/R/edit`;

                  break;
                case "Save as Draft CO":
                  url = `C/O/edit`;

                  break;
                case "Rejected CR":
                  url = `C/R/edit`;
                  break;
                case "Rejected CO":
                  url = `C/O/edit`;
                  break;

                case "Waiting Review CR":
                  url = `C/R/review`;
                  break;
                case "Waiting Review CO":
                  url = `C/O/review`;
                  break;

                default:
              }
              break;
            case 5:
              switch (item.category) {
                case "Save as Draft":
                  url = `release/edit`;
                  break;
                case "New First Release":
                  onClick = () => {
                    setreleaseType("1");
                  };

                  url = `release/add`;
                  break;
                case "New CO Release":
                  url = `release/add`;
                  onClick = () => {
                    setreleaseType("2");
                  };
                  break;
                case "Rejected Release":
                  url = `release/edit`;
                  break;
                case "Waiting Review":
                  url = `release/review`;
                  break;
                case "Check Release":
                  url = `release/review`;
                  break;

                default:
              }
              break;
            case 6:
              switch (item.category) {
                case "Save as Draft":
                  url = `document/edit`;
                  break;
                case "Rejected Document":
                  url = `document/edit`;
                  break;
                case "Waiting Review":
                  url = `document/review`;
                  break;

                default:
              }
              break;
            default:
          }
          return (
            <ScrollCardList
              key={i}
              width="240px"
              title={item.category}
              color="var(--todoYellow)"
              url={url}
              onClick={onClick}
              dataList={item.toDoList}
            />
          );
        })
      : "";

  const myProjectList =
    myProjectListData &&
    myProjectListData.map((item, i) => {
      const lowerRows =
        item.itemProjectDashboardDto &&
        item.itemProjectDashboardDto.map((block, ind) => {
          if (ind === 0) {
            return "";
          }
          return (
            <TableRow
              key={ind}
              itemArray={[
                "",
                block.itemNumber,
                block.name,
                block.phase,
                <StatusTag height="30px" width="90%">
                  {block.status}
                </StatusTag>,
              ]}
              widthArray={[24, 3, 4, 3, 3]}
              width="100%"
            />
          );
        });
      return (
        <Link
          to={`project/detail/${item.id}`}
          key={i}
          style={{ textDecoration: "none" }}
        >
          <TableRow
            key={i}
            itemArray={[
              item.projectNumber,
              item.name,
              item.projectType,
              item.projectLevel,
              item.produceOrganization,
              item.clientOrganization,
              // 이거 나중에 id붙이기
              item.carType && item.carType.name,
              item.clientItemNumber,
              item.itemProjectDashboardDto &&
                item.itemProjectDashboardDto[0].itemNumber,
              item.itemProjectDashboardDto &&
                item.itemProjectDashboardDto[0].name,
              item.itemProjectDashboardDto[0].phase,
              <StatusTag height="30px" width="90%">
                {item.itemProjectDashboardDto[0].status}
              </StatusTag>,
            ]}
            widthArray={[3, 3, 3, 3, 3, 3, 3, 3, 3, 4, 3, 3]}
            width="1500px"
          />
          {lowerRows}
        </Link>
      );
    });

  const alarmBoxList = alarmList.map((item, i) => {
    return (
      <AlarmBox
        key={i}
        width="100%"
        height="60px"
        data={item}
        onClick={(val) => {
          console.log(val);

          settodoCategory(val);
        }}
      />
    );
  });
  const getTodoData = useCallback(async () => {
    const todoNumberList = [];
    const alarmArr = [];
    const response = await tokenAxios.get("/todo");
    const { data } = response.data.result && response.data.result;
    const tempData = Object.keys(data).map((item, i) => {
      let count = 0;
      data[item].lists.forEach((category) => {
        count += category.toDoList.length;
        category.toDoList.forEach((card) => {
          const isInLocal =
            localAlarmArr.indexOf(card.name + item + category.category) > 0;
          if (isInLocal) {
            alarmArr.push({
              id: card.id,
              name: card.name,
              number: card.number,
              category: category.category,
              module: item,
              // color: isInLocal ? "var(--textGray)" : "white",
            });
          } else {
            alarmArr.unshift({
              id: card.id,
              name: card.name,
              number: card.number,
              category: category.category,
              module: item,
              // color: isInLocal ? "var(--textGray)" : "white",
            });
          }
        });
      });
      todoNumberList[i] = count;
      return data[item].lists;
    });

    settodoData(tempData);
    settodoCount(todoNumberList);
    setalarmList(alarmArr);

    // todo에 없으면 localstorage에서도 삭제
    const newLocalArr = [];

    localAlarmArr.split(",").forEach((item) => {
      const isLocalOnTodo = alarmArr.some(
        (card) => card.name + card.module + card.category === item
      );
      if (isLocalOnTodo) {
        newLocalArr.push(item);
        // if(){
        //   newLocalArr.push(item);

        // }else{
        //   newLocalArr.unshift(item);

        // }
      }
    });

    localStorage.setItem("alarmArr", newLocalArr);
  }, [tokenAxios]);

  const getMyProjectList = useCallback(async () => {
    const response = await tokenAxios.get(
      `dashboard/project/page?size=${rowsPerPage}&page=${pageNum}`
    );
    // rowsPerPage가 바뀌면 pageNum = 0으로 초기화
    const { data } = response;
    setmyProjectListData(data.content);
    settotalPage(data.totalPages);
  }, [pageNum, rowsPerPage, tokenAxios]);

  const getStatistics = useCallback(async () => {
    const response = await tokenAxios.get("dashboard/project/total");
    const { data } = response.data.result;
    settotalProject(data.total);
    setworking(data.working);
    // setdevelopment(data.development);
    setcomplete(data.complete);
    setpending(data.pending);
    setdrop(data.drop);
  }, [tokenAxios]);

  useEffect(() => {
    setCurrentModule("Home");
    getTodoData();
    getMyProjectList();
    getStatistics();
  }, []);

  // todo list section size조절
  const ref = useRef(null);
  const [height, setheight] = useState(260);
  const [mouseMoveFlag, setmouseMoveFlag] = useState(false);
  const [top, settop] = useState(0);
  const onMouseDown = (e) => {
    setmouseMoveFlag(true);
  };
  const onMouseUp = (e) => {
    setmouseMoveFlag(false);
  };

  const onMouseMove = (e) => {
    setheight(e.pageY - top > 200 ? e.pageY - top : 200);
  };
  const onMouseLeave = (e) => {
    setmouseMoveFlag(false);
  };
  useLayoutEffect(() => {
    settop(ref.current.getBoundingClientRect().top);
  }, []);
  return (
    <HomeStyle>
      <TopSectionStyle>
        <div className="left">
          <div className="todo">To do</div>
          <div className="todoList">{todoNumberBoxList}</div>
        </div>
        <div className="right">
          <BoldText color="var(--eciBlue)" fontSize="20px">
            Alert
          </BoldText>
          <div className="box">{alarmBoxList}</div>
        </div>
      </TopSectionStyle>
      <Border
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={mouseMoveFlag ? onMouseMove : () => {}}
        onMouseLeave={onMouseLeave}
        ref={ref}
      >
        <TodoListSection
          active={todoCategory >= 0}
          height={height}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
        >
          {todoList}
        </TodoListSection>
      </Border>

      <StatisticSection>
        <div className="table">
          <BoldText fontSize="20px" color="var(--eciBlue)">
            {resultText.home.myProjectStatus}
          </BoldText>
          <PaginationTable
            width="100%"
            height="290px"
            noshadow
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            rowsPerPageDefault={rowsPerPageDefault}
            pageNum={pageNum}
            setRowsPerPage={setrowsPerPage}
            setpageNum={setpageNum}
            innerWidth="1500px"
          >
            <TableIndexRow
              itemArray={resultText.project.projectListIndexArray}
              widthArray={[3, 3, 3, 3, 3, 3, 3, 3, 3, 4, 3, 3]}
              width="1500px"
            />
            {myProjectList}
          </PaginationTable>
        </div>

        <StatisticCircleBox
          working={working}
          // development={development}
          complete={complete}
          pending={pending}
          drop={drop}
          total={totalProject}
        />
      </StatisticSection>
    </HomeStyle>
  );
}
