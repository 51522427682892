import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import StatusTag from "../../atom/StatusTag";
import TableIndexRow from "../../atom/TableIndexRow";
import TableRow from "../../atom/TableRow";
import PaginationTable from "../../molecule/PaginationTable";
import SearchBar from "../../molecule/SearchBar";
import commonStore from "../../store/commonStore";
import releaseModuleStore from "../../store/releaseModuleStore";
import txt from "../../Text";

const ReleaseListSectionStyle = styled.div``;
const SearchSectionStyle = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function ReleaseListSection() {
  const { tokenAxios } = commonStore((state) => ({
    tokenAxios: state.tokenAxios,
  }));

  const { setcurrentCategory } = releaseModuleStore();

  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [pageNum, setPageNum] = useState("0");
  const [releaseArray, setreleaseArray] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [searchText, setsearchText] = useState("");
  const releaseRowList =
    releaseArray &&
    releaseArray.map((item, i) => {
      return (
        <Link
          to={`detail/${item.id}`}
          key={i}
          style={{ textDecoration: "none" }}
        >
          <TableRow
            key={i}
            itemArray={[
              item.releaseNumber,
              item.releaseTitle,
              item.releaseType.name,
              item.itemNumber,
              item.coNumber,
            ]}
            widthArray={[2, 2, 2, 2, 3]}
          />
        </Link>
      );
    });

  useEffect(() => {
    setcurrentCategory("List");
    try {
      tokenAxios
        .get(
          `/release/page?size=${rowsPerPage}&page=${pageNum}&name=${searchText}`
        )
        .then((res) => {
          setreleaseArray(res.data.content);
          setTotalPage(res.data.totalPages);
        })
        .catch((err) => {
          //
        });
    } catch (err) {
      // console.log(err);
    }
  }, [setcurrentCategory, pageNum, searchText, rowsPerPage]);
  useEffect(() => {
    setPageNum("0");
  }, [searchText]);
  return (
    <ReleaseListSectionStyle>
      <SearchSectionStyle>
        <SearchBar
          width="170px"
          height="30px"
          placeholder="Search Items"
          state={searchText}
          setState={setsearchText}
        />
        <div className="buttons" />
      </SearchSectionStyle>
      <PaginationTable
        width="100%"
        minHeight="300px"
        totalPage={totalPage}
        rowsPerPageDefault={10}
        pageNum={pageNum}
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        setPageNum={setPageNum}
        noshadow
      >
        <TableIndexRow
          itemArray={txt.rel.listIndexArray}
          widthArray={[2, 2, 2, 2, 3]}
        />
        {releaseRowList}
      </PaginationTable>
    </ReleaseListSectionStyle>
  );
}
