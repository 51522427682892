import React, { useEffect, useState } from "react";

import styled from "styled-components";
import SearchBar from "./SearchBar";
import Input from "../atom/Input";
import SelectBox from "../atom/SelectBox";
import commonStore from "../store/commonStore";

const FilterSearchBoxStyle = styled.div`
  .flex {
    display: flex;
    gap: 20px;
    .filterButton {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 32px;
      border-radius: 8px;
      color: ${(props) =>
        props.isFilterOpen ? "white" : "var(--textDarkGray)"};
      background-color: ${(props) =>
        props.isFilterOpen ? "var(--eciBlue)" : "white"};
    }
    margin-bottom: 20px;
  }
  .filterBox {
    width: 50%;
    border-radius: 10px 10px 0 0;
    padding: 10px;
    background-color: white;
    .filterRow {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 4px;
      .titleFlex {
        display: flex;
        width: 300px;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: 12px;
          font-weight: 600;
          color: var(--textDarkGray);
        }
      }
    }
  }
  .buttonPart {
    width: 50%;
    border-radius: 0 0 10px 10px;
    border-top: solid var(--textGray);
    height: 40px;
    background-color: white;
    display: flex;
    justify-content: center;

    .but {
      cursor: pointer;
      padding: 8px;
      font-weight: 600;
      color: var(--textDarkGray);
      height: 37px;
    }
    .cancel {
      border-right: solid var(--textGray);
      cursor: pointer;
    }
  }
`;

const InputConatiner = styled.div`
  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: rgba(242, 244, 248, 1);
  display: flex;
  align-items: center;
  padding: 0 5px;
`;
export default function FilterSearchBox({
  searchText,
  setsearchText,
  setitemName,
  setitemNumber,
  setcarType,
}) {
  const { tokenAxios } = commonStore();
  const [isFilterOpen, setisFilterOpen] = useState(false);
  const [tmpName, settmpName] = useState("");
  const [tmpCarType, settmpCarType] = useState([]);
  const [tmpNumber, settmpNumber] = useState("");
  const [carTypeObjList, setcarTypeObjList] = useState([]);
  const [radioCheckedList, setradioCheckedList] = useState([
    false,
    false,
    false,
  ]);
  const setArr = [setitemName, setcarType, setitemNumber];
  const tmpValArr = [tmpName, tmpCarType, tmpNumber];
  const onApply = () => {
    radioCheckedList.forEach((bool, i) => {
      if (bool) {
        setArr[i](tmpValArr[i].id || tmpValArr[i]);
      } else {
        setArr[i]("");
      }
    });
  };
  const optionList =
    carTypeObjList &&
    carTypeObjList.map((carTypeObj, i) => (
      <option key={i} value={carTypeObj.id}>
        {carTypeObj.name}
      </option>
    ));
  const getCarTypeList = async () => {
    const response = await tokenAxios.get("/carTypeIds");
    setcarTypeObjList(response.data.result.data);
  };

  useEffect(() => {
    getCarTypeList();
    setisFilterOpen(false);

    return () => {
      setisFilterOpen(false);
    };
  }, []);
  return (
    <FilterSearchBoxStyle isFilterOpen={isFilterOpen}>
      <div className="flex">
        <SearchBar
          width="320px"
          height="32px"
          placeholder="Search Box"
          setState={setsearchText}
          state={searchText}
        />
        <div
          className="filterButton"
          onClick={() => {
            setisFilterOpen((bool) => !bool);
          }}
        >
          <span className="material-icons">filter_alt</span>
          Filters
        </div>
      </div>
      {isFilterOpen && (
        <>
          <div className="filterBox">
            <div className="filterRow">
              <input
                type="radio"
                id="name"
                name="name"
                value={radioCheckedList[0]}
                onClick={() => {
                  setradioCheckedList((arr) => {
                    const tmpArr = [...arr];
                    tmpArr[0] = !tmpArr[0];
                    return tmpArr;
                  });
                }}
                checked={radioCheckedList[0]}
              />
              <div className="titleFlex">
                <span className="title">Item Name</span>
                <InputConatiner>
                  <Input width="100%" state={tmpName} setState={settmpName} />
                </InputConatiner>
              </div>
            </div>
            <div className="filterRow">
              <input
                type="radio"
                id="CarType"
                name="CarType"
                value={radioCheckedList[1]}
                onClick={() => {
                  setradioCheckedList((arr) => {
                    const tmpArr = [...arr];
                    tmpArr[1] = !tmpArr[1];

                    return tmpArr;
                  });
                }}
                checked={radioCheckedList[1]}
              />
              <div className="titleFlex">
                <span className="title">Car Type</span>
                <SelectBox
                  width="100px"
                  height="30px"
                  backgroundColor="var(--lightGray)"
                  color="var(--deemGray)"
                  face="Specification"
                  fontSize="11px"
                  state={tmpCarType}
                  value={tmpCarType.id || tmpCarType.name}
                  setState={(item) => {
                    settmpCarType(item);
                  }}
                  isSizeFixed
                >
                  {optionList}
                </SelectBox>
              </div>
            </div>
            <div className="filterRow">
              <input
                type="radio"
                id="number"
                name="number"
                value={radioCheckedList[2]}
                onClick={() => {
                  setradioCheckedList((arr) => {
                    const tmpArr = [...arr];
                    tmpArr[2] = !tmpArr[2];
                    return tmpArr;
                  });
                }}
                checked={radioCheckedList[2]}
              />
              <div className="titleFlex">
                <span className="title">Item Number</span>
                <InputConatiner>
                  <Input
                    width="100%"
                    state={tmpNumber}
                    setState={settmpNumber}
                  />
                </InputConatiner>
              </div>
            </div>
          </div>
          <div className="buttonPart">
            <div
              className="cancel but"
              onClick={() => {
                setisFilterOpen(false);
              }}
            >
              cancel
            </div>
            <div className="apply but" onClick={onApply}>
              apply
            </div>
          </div>
        </>
      )}
    </FilterSearchBoxStyle>
  );
}
