import create from "zustand";

const BOMModuleStore = create((set, get) => ({
  isRouteActivate: false,
  setisRouteActivate: (val) => {
    set(() => ({ isRouteActivate: val }));
  },
  currentCategory: "preliminary",
  setcurrentCategory: (val) => {
    set(() => ({ currentCategory: val }));
  },
  // pageType
  preliminaryType: "",
  developType: "",
  compareType: "",
  preliminaryId: "",
  developId: "",
  compareId: "",
  setpage: (pre, dev, compare, preid, devid, compareid) => {
    set(() => ({
      developType: dev,
      preliminaryType: pre,
      compareType: compare,
      preliminaryId: preid,
      developId: devid,
      compareId: compareid,
    }));
  },
  // bom
  BOMId: "",
  setBOMId: (val) => {
    set(() => ({ BOMId: val }));
  },
  // searchBox
  searchBoxIndexArray: [],
  setsearchBoxIndexArray: (val) => {
    set(() => ({ searchBoxIndexArray: val }));
  },
  // preliminary
  preliminaryTree: {},

  setpreliminaryTree: (val) => {
    set(() => ({ preliminaryTree: val }));
  },
  preliminaryFoldedRowIndex: [],
  setpreliminaryFoldedRowIndex: (val) => {
    set(() => ({ preliminaryFoldedRowIndex: val }));
  },
  addpreliminaryFoldedRowIndex: (val) => {
    const temp = get().preliminaryFoldedRowIndex;
    set(() => ({ preliminaryFoldedRowIndex: [...temp, val] }));
  },
  deletepreliminaryFoldedRowIndex: (val) => {
    const temp = get().preliminaryFoldedRowIndex;
    temp.splice(temp.indexOf(val), 1);
    set(() => ({ preliminaryFoldedRowIndex: temp }));
  },

  setpreliminaryTreeState: (val, type, ...index) => {
    const originalTree = get().preliminaryTree;
    let tempTree = originalTree;
    for (let i = 0; i < index.length - 1; i += 1) {
      tempTree = tempTree.children[index[1 + i]];
    }

    tempTree[type] = val;

    set(() => ({ preliminaryTree: originalTree }));
  },
  setpreliminaryTreeRow: (val, ...index) => {
    const originalTree = get().preliminaryTree;
    let tempTree = originalTree;
    for (let i = 0; i < index.length - 1; i += 1) {
      tempTree = tempTree.children[index[1 + i]];
    }
    tempTree.classification = val.classification;
    tempTree.cardName = val.cardName;
    tempTree.cardType = val.cardType;
    tempTree.cardNumber = val.cardNumber;
    tempTree.sharing = val.sharing;
    if (val.children && val.children[0]) {
      tempTree.children = val.children;
    }

    set(() => ({ preliminaryTree: originalTree }));
  },
  addpreliminaryTreeState: (...index) => {
    const originalTree = get().preliminaryTree;
    let tempTree = originalTree;
    for (let i = 0; i < index.length - 1; i += 1) {
      tempTree = tempTree.children[index[1 + i]];
    }

    tempTree.children.push({
      id: "",
      classification: "",
      cardName: "",
      cardType: "",
      cardNumber: "",
      sharing: "",
      deleted: false,
      children: [],
      plusPossible: true,
    });
    set(() => ({ preliminaryTree: originalTree }));
  },

  deletepreliminaryTreeState: (index) => {
    const originalTree = get().preliminaryTree;
    let tempTree = originalTree;
    for (let i = 0; i < index.length - 2; i += 1) {
      tempTree = tempTree.children[index[1 + i]];
    }
    tempTree.children.splice(index[index.length - 1], 1);
    set(() => ({ preliminaryTree: originalTree }));
  },

  // develop
  developTree: {},
  developFoldedRowIndex: [],
  setdevelopFoldedRowIndex: (val) => {
    set(() => ({ developFoldedRowIndex: val }));
  },
  adddevelopFoldedRowIndex: (val) => {
    const temp = get().developFoldedRowIndex;
    set(() => ({ developFoldedRowIndex: [...temp, val] }));
  },
  deletedevelopFoldedRowIndex: (val) => {
    const temp = get().developFoldedRowIndex;
    temp.splice(temp.indexOf(val), 1);
    set(() => ({ developFoldedRowIndex: temp }));
  },
  developAddedRowIds: [],

  developAddedRowParentIds: [],
  developAddedRowIdsIndex: [],

  setdevelopTree: (val) => {
    // val.children이용 developTreeRowID, parentID...등등 초기화
    const tmpIdArr = [];
    const tmpParentArr = [];
    const tmpIndexArr = [];

    val.children.forEach((cardObj, i) => {
      tmpParentArr.push(val.cardId);
      tmpIdArr.push(cardObj.cardId);
      tmpIndexArr.push(`0,${i}`);
    });
    set((state) => ({
      developTree: val,
      developAddedRowIds: tmpIdArr,
      developAddedRowParentIds: tmpParentArr,
      developAddedRowIdsIndex: tmpIndexArr,
    }));
  },

  setdevelopTreeState: (val, type, ...index) => {
    const originalTree = get().developTree;
    let tempTree = originalTree;
    for (let i = 0; i < index.length - 1; i += 1) {
      tempTree = tempTree.children[index[1 + i]];
    }

    tempTree[type] = val;

    set(() => ({ developTree: originalTree }));
  },

  setdevelopTreeRow: (val, ...index) => {
    const originalTree = structuredClone(get().developTree);
    const originalAddedRowsIdsIndex = get().developAddedRowIdsIndex;
    let tempTree = originalTree;

    let parentId;
    for (let i = 0; i < index.length - 1; i += 1) {
      parentId = tempTree.cardId;
      tempTree = tempTree.children[index[1 + i]];
    }
    tempTree.classification = val.classification;
    tempTree.cardName = val.cardName;
    tempTree.cardType = val.cardType;
    tempTree.cardNumber = val.cardNumber;
    tempTree.sharing = val.sharing;
    tempTree.cardId = val.cardId;
    tempTree.plusPossible = val.plusPossible;

    if (val.children) {
      tempTree.children = val.children;
    }
    // 추가로 부모, 자식의 id를 따로 넘기는 부분
    const originalAddedRowIds = get().developAddedRowIds;
    const originalAddedRowParentIds = get().developAddedRowParentIds;
    let newAddedRowIds = [...originalAddedRowIds];
    let newAddedRowParentIds = [...originalAddedRowParentIds];
    let newAddedRowIdsIndex = [...originalAddedRowsIdsIndex];
    if (originalAddedRowsIdsIndex.indexOf(index.toString()) >= 0) {
      newAddedRowIds[originalAddedRowsIdsIndex.indexOf(index.toString())] =
        val.cardId;
      newAddedRowParentIds[
        originalAddedRowsIdsIndex.indexOf(index.toString())
      ] = parentId;
    } else {
      newAddedRowIds = [...originalAddedRowIds, val.cardId];
      newAddedRowParentIds = [...originalAddedRowParentIds, parentId];
      newAddedRowIdsIndex = [...originalAddedRowsIdsIndex, index.toString()];
    }

    set(() => ({
      developTree: originalTree,
      developAddedRowIds: newAddedRowIds,
      developAddedRowParentIds: newAddedRowParentIds,
      developAddedRowIdsIndex: newAddedRowIdsIndex,
    }));
  },
  adddevelopTreeState: (...index) => {
    const originalTree = get().developTree;
    let tempTree = originalTree;
    for (let i = 0; i < index.length - 1; i += 1) {
      tempTree = tempTree.children[index[1 + i]];
    }

    tempTree.children.push({
      id: "",
      classification: "",
      cardName: "",
      cardType: "",
      cardNumber: "",
      sharing: "",
      deleted: false,
      cardId: "",
      plusPossible: true,
      children: [],
    });
    set(() => ({ developTree: originalTree }));
  },

  deletedevelopTreeState: (index) => {
    const originalTree = get().developTree;
    let tempTree = originalTree;
    for (let i = 0; i < index.length - 2; i += 1) {
      tempTree = tempTree.children[index[1 + i]];
    }
    tempTree.children.splice(index[index.length - 1], 1);
    const originalIndex = get().developAddedRowIdsIndex;
    const originalAddedRowIds = [...get().developAddedRowIds];
    const originalAddedRowParentIds = [...get().developAddedRowParentIds];

    if (originalIndex.indexOf(index.toString()) >= 0) {
      originalAddedRowIds.splice(originalIndex.indexOf(index.toString()), 1);
      originalAddedRowParentIds.splice(
        originalIndex.indexOf(index.toString()),
        1
      );
    }

    set(() => ({
      developTree: originalTree,
      developAddedRowIds: originalAddedRowIds,
      developAddedRowParentIds: originalAddedRowParentIds,
    }));
  },

  // compare
  compareTargetName: "",
  setcompareTargetName: (val) => {
    set(() => ({ compareTargetName: val }));
  },
  compareTargetId: "",
  setcompareTargetId: (val) => {
    set(() => ({ compareTargetId: val }));
  },
  compareDataArray: [],
  setcompareDataArray: (val) => {
    set(() => ({ compareDataArray: val }));
  }, // itemInfo
  itemId: "",
  setitemId: (val) => {
    set(() => ({ itemId: val }));
  },
  itemNumber: "",
  setitemNumber: (val) => {
    set(() => ({ itemNumber: val }));
  },
  itemName: "",
  setitemName: (val) => {
    set(() => ({ itemName: val }));
  },
  itemThumbnail: "",

  setitemThumbnail: (val) => {
    set(() => ({ itemThumbnail: val }));
  },
  setitemInfo: (id, number, namew, thumbnail) => {
    set(() => ({
      itemId: id,
      itemNumber: number,
      itemName: namew,
      itemThumbnail: `${process.env.REACT_APP_BASE_URL}/${thumbnail}`,
    }));
  },
  // route
  routeNumber: "",
  setrouteNumber: (val) => {
    set(() => ({ routeNumber: val }));
  },
  routeData: [],
  setrouteData: (val) => {
    set(() => ({ routeData: val }));
  },
  // route init
  members: [],
  targetMember: "",
  settargetMember: (val) => {
    set(() => ({ targetMember: val }));
  },
  // ref
  informationRef: "",
  setinformationRef: (val) => {
    set(() => ({ informationRef: val }));
  },

  routeRef: "",
  setrouteRef: (val) => {
    set(() => ({ routeRef: val }));
  },

  /// 초기화
  initBOMModule: () => {
    set(() => ({
      // all
      BOMId: "",
      currentCategory: "preliminary",
      preliminaryType: "",
      developType: "",
      compareType: "",
      preliminaryId: "",
      developId: "",
      compareId: "",
      searchBoxIndexArray: [],
      // itemInfo
      itemId: "",
      itemNumber: "",
      itemName: "",
      itemThumbnail: "",

      // prel
      preliminaryTree: {},
    }));
  },
}));
export default BOMModuleStore;
