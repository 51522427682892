import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import projectModuleStore from "../../store/projectModuleStore";
import ProjectInformationSection from "../ProjectInformationSection";
import RoundTextBox from "../../atom/RoundTextBox";
import Input from "../../atom/Input";
import Button from "../../atom/Button";
import SearchBox from "../../molecule/SearchBox";
import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";
import LoadingBox from "../LoadingBox";
import LoadingArrowAnimation from "../../molecule/LoadingArrowAnimation";
import BoldText from "../../atom/BoldText";
import RouteSection from "../RouteSection";
import AttachmentSection from "../AttachmentSection";
import resultText from "../../Text";
import RouteInitSection from "../RouteInitSection";

const ProjectEditPageStyle = styled.div`
  display: flex;
  position: relative;
  .tempSave {
    height: 40px;

    position: absolute;
    top: -40px;
    left: calc(58% - 110px);
    display: flex;
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`;
const FormSectionStyle = styled.div`
  width: 58%;
  height: calc(100vh - 230px);
  overflow: scroll;
  padding-left: 18%;
  padding-right: 1.5%;
  padding-top: 20px;
  .gap {
    height: 45px;
  }
  ::-webkit-scrollbar {
    height: 7px;
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
`;
const SearchBoxSectionStyle = styled.div`
  width: 42%;
`;
function ProjectEditPage() {
  // const { tokenAxios } = commonStore((state) => ({
  //   tokenAxios: state.tokenAxios,
  // }));
  const tokenAxios = commonStore((state) => state.tokenAxios);
  const {
    // project
    projectName,
    setprojectName,
    protoStartPeriod,
    protoOverPeriod,
    p1StartPeriod,
    p1OverPeriod,
    p2StartPeriod,
    p2OverPeriod,
    sopStartPeriod,
    sopOverPeriod,
    itemId,
    projectType,
    projectLevel,
    clientOrganizationId,
    supplierId,
    carTypeId,
    clientItemNumber,
    setprojectInfo,
    // item
    linkedItemList,
    deletedLinkedItemList,
    initprojectModule,
    // attachment
    attachmentFileArray,
    attachmentTagArray,
    attachmentCommentArray,
    attachmentOriginArray,
    attachmentDateArray,
    attachmentUploaderArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    setattachmentOriginArray,
    setattachmentDateArray,
    setattachmentUploaderArray,
    attachmentIdArray,
    setattachmentIdArray,
    attachmentModifiedAtArray,
    setattachmentModifiedAtArray,
    deletedFileIdArray,
    setdeletedFileIdArray,
    deletedAttachmentArray,
    setdeletedAttachmentArray,
    addedAttachmentArray,
    addedAttachmentTagArray,
    addedAttachmentCommentArray,
    setaddedAttachmentArray,
    setaddedAttachmentTagArray,
    setaddedAttachmentCommentArray,
    // seaechbox
    searchBoxType,
    setsearchBoxProperty,
    // route
    routeNumber,
    routeData,
    setrouteData,
    setrouteNumber,
    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // etc
    isRouteActivate,
    setisRouteActivate,
    setcurrentCategory,
  } = projectModuleStore();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [isReject, setisReject] = useState(false);
  const formRef = useRef();
  const params = useParams();

  const getProjectData = useCallback(
    async (projectId) => {
      const response = await tokenAxios.get(`project/${projectId}`);
      const { data } = response.data.result;
      setisRouteActivate(data.readonly);
      setisReject(data.preRejected);
      setprojectInfo({
        projectName: data.name,
        protoStartPeriod: data.protoStartPeriod ? data.protoStartPeriod : "",
        protoOverPeriod: data.protoOverPeriod ? data.protoOverPeriod : "",
        p1StartPeriod: data.p1StartPeriod ? data.p1StartPeriod : "",
        p1OverPeriod: data.p1OverPeriod ? data.p1OverPeriod : "",
        p2StartPeriod: data.p2StartPeriod ? data.p2StartPeriod : "",
        p2OverPeriod: data.p2OverPeriod ? data.p2OverPeriod : "",
        sopStartPeriod: data.sopStartPeriod ? data.sopStartPeriod : "",
        sopOverPeriod: data.sopOverPeriod ? data.sopOverPeriod : "",
        projectType: data.projectType,
        projectLevel: {
          id: data.projectLevel.id,
          name: data.projectLevel.name,
        },
        clientOrganizationId: {
          id: data.clientOrganization && data.clientOrganization.id,
          name: data.clientOrganization && data.clientOrganization.name,
        },
        supplierId: {
          id: data.supplierOrganizationId && data.supplierOrganizationId.id,
          name: data.supplierOrganizationId && data.supplierOrganizationId.name,
        },
        carTypeId: {
          id: data.carType && data.carType.id,
          name: data.carType && data.carType.name,
        },
        clientItemNumber: data.clientItemNumber,
        linkedItemList: data.item,

        routeNumber: data.routeId,
        creator: data.creator,
        createdAt: data.createdAt,
        modifier: data.modifier,
        modifiedAt: data.modifiedAt,
      });
      // attachment
      const tempfileArray = [];
      const temptagArray = [];
      const tempcommentArray = [];
      const temporiginArray = [];
      const tempuploaderArray = [];
      const tempdateArray = [];
      const tempidArray = [];
      const tempdeletedFileArray = [];
      const tempModifiedAtArray = [];

      if (data.projectAttachments) {
        data.projectAttachments.forEach((item) => {
          tempfileArray.push(item.originName);
          tempcommentArray.push(item.attach_comment);
          temptagArray.push(item.tag);
          temporiginArray.push(item.attachmentaddress);
          tempuploaderArray.push(item.upload);
          tempdateArray.push(item.date);
          tempidArray.push(item.id);
          if (item.deleted) {
            tempdeletedFileArray.push(item.id);
          }
          tempModifiedAtArray.push(item.modifiedAt ? item.modifiedAt : "");
        });
        setattachmentFileArray(tempfileArray);
        setattachmentIdArray(tempidArray);
        setattachmentCommentArray(tempcommentArray);
        setattachmentTagArray(temptagArray);
        setattachmentOriginArray(temporiginArray);
        setattachmentDateArray(tempdateArray);
        setattachmentUploaderArray(tempuploaderArray);
        setdeletedFileIdArray(tempdeletedFileArray);
        setattachmentModifiedAtArray(tempModifiedAtArray);
      }
    },
    [tokenAxios, setprojectInfo]
  );
  const appendProjectFormData = () => {
    const formData = new FormData();
    formData.append("name", projectName);
    if (typeof projectType === "string" ? projectType : projectType.id) {
      formData.append(
        "projectTypeId",
        typeof projectType === "string" ? projectType : projectType.id
      );
    }
    // date
    formData.append("protoStartPeriod", protoStartPeriod);
    formData.append("protoOverPeriod", protoOverPeriod);
    formData.append("p1StartPeriod", p1StartPeriod);
    formData.append("p1OverPeriod", p1OverPeriod);
    formData.append("p2StartPeriod", p2StartPeriod);
    formData.append("p2OverPeriod", p2OverPeriod);
    formData.append("sopStartPeriod", sopStartPeriod);
    formData.append("sopOverPeriod", sopOverPeriod);

    formData.append(
      "itemId",
      linkedItemList.map((itme) => itme.itemId)
    );

    formData.append("deletedItemId", deletedLinkedItemList);
    formData.append("projectLevelId", projectLevel.id ? projectLevel.id : "");
    formData.append(
      "clientOrganizationId",
      clientOrganizationId.id ? clientOrganizationId.id : ""
    );
    formData.append("supplierId", supplierId.id ? supplierId.id : "");
    formData.append("carTypeId", carTypeId.id ? carTypeId.id : "");
    formData.append("clientItemNumber", clientItemNumber);

    // attachment
    addedAttachmentArray.forEach((file) => {
      formData.append("addedAttachments", file);
    });

    formData.append("addedAttachmentComment", attachmentCommentArray);
    formData.append(
      "addedTag",
      attachmentTagArray.map((item) => (item.id ? item.id : item))
    );
    formData.append("deletedAttachments", deletedAttachmentArray);
    return formData;
  };

  const saveProject = async () => {
    setisLoading(true);
    const formData = appendProjectFormData();
    try {
      const response = await tokenAxios.put(
        `/project/temp/end/${params.projectId}`,
        formData
      );
      setisRouteActivate(true);
      // route부분으로 스크롤
      setrouteNumber(response.data.result.data.routeId);
      setisLoading(false);
    } catch (err) {
      if (err.response.data.result) {
        console.log(err.response);
        alert(err.response.data.result.msg);
      } else {
        console.log(err.response);
        navigate("/notFound");
      }
      setisRouteActivate(false);
    }
  };

  const tempSave = () => {
    setisLoading(true);
    const formData = appendProjectFormData();
    try {
      tokenAxios
        .put(`/project/temp/${params.projectId}`, formData)
        .then((res) => {
          alert(resultText.approveDone);

          setisLoading(false);
        });
    } catch (err) {
      if (err.response.data.result) {
        console.log(err.response);
        alert(err.response.data.result.msg);
      } else {
        console.log(err.response);
        navigate("/notFound");
      }
    }
  };

  useEffect(() => {
    setcurrentCategory("Add");
    getProjectData(params.projectId);
    return () => {
      initprojectModule();
    };
  }, [setcurrentCategory, initprojectModule, getProjectData]);

  return (
    <ProjectEditPageStyle>
      <div className="tempSave">
        {!isRouteActivate && (
          <Button
            type="button"
            onClick={tempSave}
            backgroundColor="transparent"
            fontSize="12px"
            color="var(--textDarkGray)"
          >
            {resultText.saveAsDraft}
          </Button>
        )}
        <Button
          type="button"
          backgroundColor="transparent"
          color="var(--textDarkGray)"
          fontSize="12px"
          onClick={() => {
            navigate("/project");
          }}
        >
          <span className="material-icons">close</span>
        </Button>
      </div>
      <LoadingBox isLoading={isLoading}>
        <LoadingArrowAnimation />
        <BoldText fontSize="26px" color="var(--eciBlue)">
          {" "}
          S a v i n g
        </BoldText>{" "}
        <br />
        <br />
        Please wait while we set
        <br />
        thing up for you!
      </LoadingBox>
      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, resultText.project.projectInformation],
            [attachmentRef, resultText.project.projectAttachment],
            [routeRef, resultText.project.routeInformation],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>
      <FormSectionStyle ref={formRef}>
        <Input
          height="40px"
          placeholder="Type Project Name"
          state={projectName}
          setState={setprojectName}
        />
        <ProjectInformationSection readOnly={isRouteActivate} />
        <AttachmentSection
          attachmentModifiedAtArray={attachmentModifiedAtArray}
          readOnly={isRouteActivate}
          attachmentFileArray={attachmentFileArray}
          setattachmentFileArray={setattachmentFileArray}
          attachmentTagArray={attachmentTagArray}
          setattachmentTagArray={setattachmentTagArray}
          attachmentCommentArray={attachmentCommentArray}
          setattachmentCommentArray={setattachmentCommentArray}
          attachmentDateArray={attachmentDateArray}
          attachmentUploaderArray={attachmentUploaderArray}
          setattachmentDateArray={setattachmentDateArray}
          setattachmentUploaderArray={setattachmentUploaderArray}
          attachmentOriginArray={attachmentOriginArray}
          setattachmentOriginArray={setattachmentOriginArray}
          setattachmentRef={setattachmentRef}
          title={resultText.project.projectAttachment}
          editMode
          attachmentIdArray={attachmentIdArray}
          setattachmentIdArray={setattachmentIdArray}
          deletedFileIds={deletedFileIdArray}
          deletedAttachmentArray={deletedAttachmentArray}
          setdeletedAttachmentArray={setdeletedAttachmentArray}
          addedAttachmentArray={addedAttachmentArray}
          addedAttachmentTagArray={addedAttachmentTagArray}
          addedAttachmentCommentArray={addedAttachmentCommentArray}
          setaddedAttachmentArray={setaddedAttachmentArray}
          setaddedAttachmentTagArray={setaddedAttachmentTagArray}
          setaddedAttachmentCommentArray={setaddedAttachmentCommentArray}
          tagOptionList={[
            { id: "4", name: resultText.project.devStat },
            { id: "5", name: resultText.project.clientData },
            { id: "1", name: resultText.etc },
          ]}
        />
        <div className="gap" />
        {isRouteActivate ? (
          ""
        ) : (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveProject}
            condition={
              !!projectName &&
              linkedItemList.length > 0 &&
              !!carTypeId &&
              !!projectType &&
              !!projectLevel
            }
          >
            {resultText.saveAndRoute}
          </Button>
        )}
        {/* <RouteSection
          activate={isRouteActivate || isReject}
          routeNumber={routeNumber}
          setrouteRef={setrouteRef}
          routeData={routeData}
          setrouteData={setrouteData}
          rejecting={isReject && !isRouteActivate}
        /> */}
        <RouteInitSection
          activate={isRouteActivate}
          itemType="project"
          setrouteRef={setrouteRef}
          itemId={params.projectId}
          // setsearchBoxType={setsearchBoxType}
          // targetMember={targetMember}
          // settargetMember={settargetMember}
          // members={members}
        />
      </FormSectionStyle>
      <SearchBoxSectionStyle>
        <SearchBox
          width="100%"
          height="100vh - 218px"
          type={searchBoxType}
          setproperty={setsearchBoxProperty}
        />
      </SearchBoxSectionStyle>
    </ProjectEditPageStyle>
  );
}

export default React.memo(ProjectEditPage);
