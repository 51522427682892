import React, { useState } from "react";
import styled from "styled-components";
import FileDataToggleBox from "../molecule/FileDataToggleBox";

const TreeBoxListStyle = styled.div`
  width: 100%;
  /* border: solid; */
  position: relative;
  .indentBox {
    margin-left: 40px;
  }
  .line {
    opacity: ${(props) => (props.line ? 1 : 0)};
    border-right: solid 3px var(--eciBlue);
    position: absolute;
    top: 256px;
    left: 37px;
    height: calc(100% - 256px);
  }
`;
export default function TreeBoxList({ data, first }) {
  const [isToggle, setisToggle] = useState(true);
  const childTree =
    data &&
    data.children &&
    data.children.map((item, i) => {
      return <TreeBoxList key={i} data={item} first={i === 0} />;
    });
  return (
    <TreeBoxListStyle
      first={first}
      line={data && data.children && isToggle && data.children[0]}
    >
      <div className="line" />
      <FileDataToggleBox
        width="100%"
        itemName={data && data.name}
        designDataName={data && data.designDataName}
        topAssyDrawingName={data && data.topAssyDrawingName}
        dwgName={data && data.dwgName}
        stepName={data && data.stepName}
        pdfName={data && data.pdfName}
        designData={data && data.designData}
        topAssyDrawing={data && data.topAssyDrawing}
        dwg={data && data.dwg}
        step={data && data.step}
        pdf={data && data.pdf}
        isToggle={isToggle}
        setisToggle={setisToggle}
        isToggleButton={data && data.children && data.children[0]}
      />

      {data && data.children && isToggle && data.children[0] ? (
        <div className="indentBox"> {childTree} </div>
      ) : (
        ""
      )}
    </TreeBoxListStyle>
  );
}
