import React, { useState, useEffect } from "react";

import styled from "styled-components";
import BOMModuleStore from "../store/BOMModuleStore";
import BOMPropRow from "./BOMPropRow";

const BOMPropTreeStyle = styled.div`
  width: 100%;
  .indentBox {
  }
`;
export default function BOMPropTree({
  data,
  readOnly,
  searchOnly,
  indexArray,
  foldedArray,
  onDelete,
}) {
  const childTree =
    data &&
    data.children &&
    data.children.map((item, i) => {
      return (
        <BOMPropTree
          key={i}
          data={item}
          indexArray={[...indexArray, i]}
          readOnly={readOnly}
          foldedArray={foldedArray}
          searchOnly={searchOnly}
          onDelete={onDelete}
        />
      );
    });
  const { setpreliminaryTreeState } = BOMModuleStore();
  const [isFolded, setisFolded] = useState(false);

  useEffect(() => {
    let tempfold = false;
    foldedArray.forEach((item) => {
      if (JSON.stringify(item) === JSON.stringify(indexArray)) {
        tempfold = true;
        setisFolded(true);
      }
    });
    if (!tempfold) {
      setisFolded(false);
    }
  });
  return (
    <BOMPropTreeStyle>
      <div className="numberRow">
        <BOMPropRow
          searchOnly={indexArray.length !== 1 && searchOnly}
          width="100% - 25px"
          readOnly={indexArray.length === 1 ? true : readOnly}
          name={data && data.cardName}
          classification={data && data.classification}
          type={data && data.cardType}
          sharing={data && data.sharing} // check
          setname={(val) => {
            setpreliminaryTreeState(val, "cardName", ...indexArray);
          }}
          setclassification={(val) => {
            setpreliminaryTreeState(val, "classification", ...indexArray);
          }}
          settype={(val) => {
            setpreliminaryTreeState(val, "cardType", ...indexArray);
          }}
          setsharing={(val) => {
            setpreliminaryTreeState(val, "sharing", ...indexArray);
          }}
          onDelete={() => {
            onDelete(indexArray);
          }}
          backgroundColor={data && data.gray && "var(--textGray)"}
        />
      </div>

      {data && data.children && data.children[0] && !isFolded ? (
        <div className="indentBox"> {childTree} </div>
      ) : (
        ""
      )}
    </BOMPropTreeStyle>
  );
}
