import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import commonStore from "../../store/commonStore";
import adminModuleStore from "../../store/AdminModuleStore";
import txt from "../../Text";
import AdminMemberSearchBox from "../../molecule/AdminMemberSearchBox";
import AdminPermissionSearchBox from "../../molecule/AdminPermissionSearchBox";
import Button from "../../atom/Button";

const AdminTaskChangePageStyle = styled.div`
  .flexC {
    display: flex;
    justify-content: space-between;
  }
  .fake,
  .tri {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mid {
    pointer-events: ${(props) => props.lastToggle && "none"};
  }
`;

const PositionModalStyle = styled.div`
  width: 200px;
  height: 240px;
  position: absolute;
  right: calc(50% - 420px);
  bottom: calc(30vh - 130px);

  background-color: white;
  border-radius: 10px;
  padding: 22px 10px;
  color: var(--textGray);
  .check {
    display: flex;
    border-bottom: solid 1px var(--textDarkGray);
    height: 40px;
    line-height: 40px;
    input,
    div {
      font-size: 12px;
      color: var(--textDarkGray);
    }
  }
`;
const TeamModalStyle = styled.div`
  width: 200px;
  height: 500px;
  position: absolute;
  right: calc(50% - 420px);
  bottom: calc(30vh - 130px);

  background-color: white;
  border-radius: 10px;
  padding: 10px 0;
  color: var(--textGray);
`;
export default function AdminTaskChangePage() {
  const { tokenAxios } = commonStore((state) => ({
    tokenAxios: state.tokenAxios,
  }));

  const {
    targetMember,
    settargetMember,
    receivingMemberList,
    setreceivingMemberList,
    deletemember,
    taskList,
    settaskList,
    deletetaskList,
    initadminModule,
  } = adminModuleStore();
  const [lastToggle, setlastToggle] = useState(false);
  const saveChange = async () => {
    const formData = new FormData();
    formData.append(
      "routeIds",
      taskList.map((item) => item.itemId)
    );
    formData.append(
      "memberId",
      receivingMemberList.map((item) => item.id)
    );
    const response = await tokenAxios.put("addMember", formData);
    alert("ok");
  };
  const navigate = useNavigate();

  useEffect(() => {
    return initadminModule();
  }, []);

  return (
    <AdminTaskChangePageStyle lastToggle={lastToggle}>
      <div className="flexC">
        <AdminMemberSearchBox
          width="33%"
          height="60vh"
          state={targetMember}
          setstate={settargetMember}
        />
        {targetMember.id ? (
          <div className="mid tri">
            <AdminPermissionSearchBox
              width="100%"
              height="60vh"
              setproperty={settaskList}
              members={taskList}
              deletememberArray={deletetaskList}
              userId={targetMember.id}
              blueBorder={!lastToggle}
            />
            {!lastToggle && (
              <Button
                backgroundColor="var(--eciBlue)"
                width="50%"
                height="40px"
                color="white"
                onClick={() => {
                  setlastToggle(true);
                }}
                condition={taskList.length > 0}
              >
                {txt.admin.addPermission}
              </Button>
            )}
          </div>
        ) : (
          <div className="fake" />
        )}
        {lastToggle ? (
          <div className="tri">
            <AdminMemberSearchBox
              width="100%"
              height="60vh"
              setproperty={setreceivingMemberList}
              members={receivingMemberList}
              deletememberArray={deletemember}
              multiple
            />
            <Button
              backgroundColor="var(--eciBlue)"
              width="50%"
              height="40px"
              color="white"
              onClick={saveChange}
            >
              ok{" "}
            </Button>
          </div>
        ) : (
          <div className="fake" />
        )}
      </div>
    </AdminTaskChangePageStyle>
  );
}
