import create from "zustand";

const adminModuleStore = create((set, get) => ({
  // ListPage
  infoObj: {
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    profileImage: "",
    imagePreview: "",
    isAdmin: "N",
    id: "",
    position: { id: "", name: "" },
    signUpTeam: "",
  },
  setinfoObj: (val) => {
    set(() => ({ infoObj: val }));
  },
  initinfoObj: () => {
    set(() => ({
      infoObj: {
        name: "",
        email: "",
        password: "",
        phoneNumber: "",
        profileImage: "",
        imagePreview: "",
        isAdmin: "N",
        id: "",
      },
    }));
  },
  isModalReadOnly: false,
  setisModalReadOnly: (val) => {
    set(() => ({ isModalReadOnly: val }));
  },
  isModalEditMode: false,
  setisModalEditMode: (val) => {
    set(() => ({ isModalEditMode: val }));
  },
  isModalActivate: false,
  setisModalActivate: (val) => {
    set(() => ({ isModalActivate: val }));
  },
  ListPageUnfoldedRowIndex: [],
  setListPageUnfoldedRowIndex: (val) => {
    set(() => ({ ListPageUnfoldedRowIndex: val }));
  },
  addListPageUnfoldedRowIndex: (val) => {
    const temp = get().ListPageUnfoldedRowIndex;
    set(() => ({ ListPageUnfoldedRowIndex: [...temp, val] }));
  },
  deleteListPageUnfoldedRowIndex: (val) => {
    const temp = get().ListPageUnfoldedRowIndex;
    temp.splice(temp.indexOf(val), 1);
    set(() => ({ ListPageUnfoldedRowIndex: temp }));
  },
  // 회원가입
  team: "",
  setteam: (val) => {
    set(() => ({ team: val }));
  },
  signUpTeam: "",
  setsignUpTeam: (val) => {
    set(() => ({ signUpTeam: val }));
  },

  position: "", // {id,name}
  setposition: (val) => {
    set(() => ({ position: val }));
  },
  signUpSearchBoxType: "",
  setsignUpSearchBoxType: (val) => {
    set(() => ({ signUpSearchBoxType: val }));
  },
  // 권한이양
  targetMember: {}, // {id,name}
  settargetMember: (val) => {
    set(() => ({ targetMember: val }));
  },
  receivingMemberList: [], // {id,name}
  setreceivingMemberList: (val) => {
    let tmpmember = get().receivingMemberList;
    if (tmpmember) {
      tmpmember = [...tmpmember, val];
    } else {
      tmpmember = [val];
    }
    set(() => ({ receivingMemberList: tmpmember }));
  },
  deletemember: (id) => {
    let tmpmemberArr = get().receivingMemberList;
    const newArr = tmpmemberArr && tmpmemberArr.filter((mem) => mem.id !== id);
    tmpmemberArr = newArr;
    set(() => ({ receivingMemberList: tmpmemberArr }));
  },
  taskList: [], // {id,name}
  settaskList: (val, all) => {
    let tmpmember = get().taskList;
    if (tmpmember) {
      tmpmember = [...tmpmember, val];
    } else {
      tmpmember = [val];
    }

    if (all) {
      tmpmember = val;
    }
    set(() => ({ taskList: tmpmember }));
  },
  deletetaskList: (id) => {
    let tmpmemberArr = get().taskList;
    const newArr =
      tmpmemberArr && tmpmemberArr.filter((mem) => mem.itemId !== id);
    tmpmemberArr = newArr;
    set(() => ({ taskList: tmpmemberArr }));
  },
  // 초기화 함수
  initadminModule: () => {
    set(() => ({
      // ListPage
      isModalActivate: false,
      isModalEditMode: false,
      ListPageUnfoldedRowIndex: [],
      infoObj: {
        name: "",
        email: "",
        password: "",
        phoneNumber: "",
        profileImage: "",
        imagePreview: "",
        isAdmin: "N",
        id: "",
        position: { id: "", name: "" },
        signUpTeam: "",
      },
      // 회원가입
      team: "",
      signUpTeam: "",

      position: "", // {id,name}

      signUpSearchBoxType: "",

      // 권한이양
      targetMember: {}, // {id,name}

      receivingMemberList: [], // {id,name}

      taskList: [], // {id,name}
    }));
  },
}));
export default adminModuleStore;
