import create from "zustand";

const CRCOModuleStore = create((set, get) => ({
  // 카테고리 설정
  currentCategory: "CR",
  setcurrentCategory: (val) => {
    if (val !== get().currentCategory) {
      set(() => ({ currentCategory: val }));
    }
  },

  // 스크롤 ref 모음
  informationRef: "",
  setinformationRef: (val) => {
    set(() => ({ informationRef: val }));
  },
  attachmentRef: "",
  setattachmentRef: (val) => {
    set(() => ({ attachmentRef: val }));
  },
  routeRef: "",
  setrouteRef: (val) => {
    set(() => ({ routeRef: val }));
  },

  // 우측 검색창 관리
  searchBoxType: "",
  setsearchBoxType: (val) => {
    set(() => ({ searchBoxType: val }));
  },
  setsearchBoxProperty: (target, val, index) => {
    if (index || index === 0) {
      if (target === "members") {
        const tmpmember = get().members;
        if (tmpmember[index]) {
          tmpmember[index] = [...tmpmember[index], val];
        } else {
          tmpmember[index] = [val];
        }
        set(() => ({ members: tmpmember }));
      } else {
        const targetArray = get()[`${target}`];
        targetArray[index] = val;
        set(() => ({ [target]: targetArray }));
      }
    } else {
      set(() => ({ [target]: val }));
    }
  },
  deletemember: (id, index) => {
    const tmpmemberArr = get().members;
    const newArr =
      tmpmemberArr[index] && tmpmemberArr[index].filter((mem) => mem.id !== id);
    if (newArr[0]) {
      tmpmemberArr[index] = newArr;
    } else {
      tmpmemberArr.splice(index, 1);
    }
    set(() => ({ member: tmpmemberArr }));
  },
  // CR
  CRId: "",
  setCRId: (val) => {
    set(() => ({ CRId: val }));
  },
  CRNumber: "",
  setCRNumber: (val) => {
    set(() => ({ CRNumber: val }));
  },
  // reason은 co랑 cr이랑 같이 써야겠다 아이
  crReasonId: "", // {    "id": 2,    "name": "고객요구"},
  setcrReasonId: (val) => {
    set(() => ({ crReasonId: val }));
  },
  CRReasonText: "",
  setCRReasonText: (val) => {
    set(() => ({ CRReasonText: val }));
  },
  crImportanceId: "", // {    "id": 2,    "name": "고객요구"},
  setcrImportanceId: (val) => {
    set(() => ({ crImportanceId: val }));
  },
  crSourceId: "", // {    "id": 2,    "name": "고객요구"},
  setcrSourceId: (val) => {
    set(() => ({ crSourceId: val }));
  },
  CRTitle: "",
  setCRTitle: (val) => {
    set(() => ({ CRTitle: val }));
  },
  CRBody: "",
  setCRBody: (val) => {
    set(() => ({ CRBody: val }));
  },
  CRSolution: "",
  setCRSolution: (val) => {
    set(() => ({ CRSolution: val }));
  },
  CRAffectedItem: {},
  //   {
  //     "id": 233,
  //     "name": "whole flow ",
  //     "type": "프로덕트제품",
  //     "itemNumber": "24000993",
  //     "revision": "A",
  //     "classification": {
  //         "value": "Part/Electronic//PBA"
  //     },
  //     "status": "WORKING"
  // },
  setCRAffectedItem: (val) => {
    set(() => ({ CRAffectedItem: val }));
  },
  setCRInfo: (val) => {
    set(() => val);
  },

  // CO state
  COId: "",
  setCOId: (val) => {
    set(() => ({ COId: val }));
  },
  CONumber: "",
  setCONumber: (val) => {
    set(() => ({ CONumber: val }));
  },
  clientOrganizationId: "", // {id,name}
  setclientOrganizationId: (val) => {
    set(() => ({ clientOrganizationId: val }));
  },
  COClientNumber: "",
  setCOClientNumber: (val) => {
    set(() => ({ COClientNumber: val }));
  },

  COClientPublishDate: "",
  setCOClientPublishDate: (val) => {
    set(() => ({ COClientPublishDate: val }));
  },
  CoClientReceiptDate: "",
  setCoClientReceiptDate: (val) => {
    set(() => ({ CoClientReceiptDate: val }));
  },
  COisPastCar: "false",
  setCOisPastCar: (val) => {
    set(() => ({ COisPastCar: val }));
  },
  carTypeId: "", // {id,name}
  setcarTypeId: (val) => {
    set(() => ({ carTypeId: val }));
  },
  COisCostChange: "true",
  setCOisCostChange: (val) => {
    set(() => ({ COisCostChange: val }));
  },
  COcost: "",
  setCOcost: (val) => {
    set(() => ({ COcost: val }));
  },
  COReasonText: "",
  setCOReasonText: (val) => {
    set(() => ({ COReasonText: val }));
  },
  COLevel: "", // id,name
  setCOLevel: (val) => {
    set(() => ({ COLevel: val }));
  },
  COTiming: "",
  setCOTiming: (val) => {
    set(() => ({ COTiming: val }));
  },
  COAffect: [], // []
  setCOAffect: (val) => {
    set(() => ({ COAffect: val }));
  },
  COImportance: "",
  setCOImportance: (val) => {
    set(() => ({ COImportance: val }));
  },

  COTitle: "",
  setCOTitle: (val) => {
    set(() => ({ COTitle: val }));
  },
  COBody: "",
  setCOBody: (val) => {
    set(() => ({ COBody: val }));
  },
  CORelatedCR: [],
  //  [
  //   {
  //      id
  //     crNumber: item.crNumber,
  //     CRType: item.crType,
  //     crReasonId: item.crReason, //{id,name}
  //     crImportanceId: item.crImportance,//{id,name}
  //     crSourceId: item.crSource,//{id,name}
  //     name: item.crName,
  //   },
  //
  // ],

  setCORelatedCR: (val) => {
    set(() => ({ CORelatedCR: val }));
  },
  COAffectedItem: [],
  //  [ {
  //    itemId:
  //     itemNumber: item.itemNumber,
  //     itemName: item.itemName,
  //     itemClassification: item.itemClassification,//{value}
  //     itemRevision: item.itemRevision,
  //     itemStatus: item.itemStatus,
  //     changedContent: ,
  //     changedFeature: ,//{id,name}
  //     crNumber
  //   },{}]
  setCOAffectedItem: (val) => {
    set(() => ({ COAffectedItem: val }));
  },
  changedFeatureId: [], // affected item의 changefeat들만 따로(작성용)[{id,name},{id,name}]
  setchangedFeatureId: (val) => {
    set(() => ({ changedFeatureId: val }));
  },
  changedFeatureIndex: "",
  setchangedFeatureIndex: (val) => {
    set(() => ({ changedFeatureIndex: val }));
  },
  setCOInfo: (val) => {
    set(() => val);
  },
  // attachment
  attachmentFileArray: [],
  setattachmentFileArray: (val) => {
    set(() => ({ attachmentFileArray: val }));
  },
  attachmentOriginArray: [],
  setattachmentOriginArray: (val) => {
    const newVal =
      val &&
      val.map((item) => {
        return `${process.env.REACT_APP_BASE_URL}/${item.toString()}`;
      });
    set(() => ({
      attachmentOriginArray: newVal,
    }));
  },
  attachmentTagArray: [],
  setattachmentTagArray: (val) => {
    set(() => ({ attachmentTagArray: val }));
  },

  attachmentCommentArray: [],
  setattachmentCommentArray: (val) => {
    set(() => ({ attachmentCommentArray: val }));
  },
  attachmentUploaderArray: [],
  setattachmentUploaderArray: (val) => {
    set(() => ({ attachmentUploaderArray: val }));
  },
  attachmentDateArray: [],
  setattachmentDateArray: (val) => {
    set(() => ({ attachmentDateArray: val }));
  },
  attachmentModifiedAtArray: [],
  setattachmentModifiedAtArray: (val) => {
    set(() => ({ attachmentModifiedAtArray: val }));
  },
  setattachmentInfo: (val) => {
    set(() => val);
  },
  // edit attachment
  deletedFileIdArray: [],
  setdeletedFileIdArray: (val) => {
    set(() => ({ deletedFileIdArray: val }));
  },
  attachmentIdArray: [],
  setattachmentIdArray: (val) => {
    set(() => ({ attachmentIdArray: val }));
  },
  addedAttachmentArray: [],
  setaddedAttachmentArray: (val) => {
    set(() => ({ addedAttachmentArray: val }));
  },
  addedAttachmentTagArray: [],
  setaddedAttachmentTagArray: (val) => {
    set(() => ({ addedAttachmentTagArray: val }));
  },
  addedAttachmentCommentArray: [],
  setaddedAttachmentCommentArray: (val) => {
    set(() => ({ addedAttachmentCommentArray: val }));
  },
  deletedAttachmentArray: [],
  setdeletedAttachmentArray: (val) => {
    set(() => ({ deletedAttachmentArray: val }));
  },
  // route state
  routeNumber: "",
  setrouteNumber: (val) => {
    set(() => ({ routeNumber: val }));
  },
  routeData: [],
  setrouteData: (val) => {
    set(() => ({ routeData: val }));
  },
  isRouteActivate: false,
  setisRouteActivate: (val) => {
    set(() => ({ isRouteActivate: val }));
  },
  // route init
  members: [],
  targetMember: "",
  settargetMember: (val) => {
    set(() => ({ targetMember: val }));
  },
  // state초기화 함수
  initCRModule: () => {
    set(() => ({
      isRouteActivate: false,
      CRNumber: "",
      CRId: "",
      crReasonId: "",
      CRReasonText: "",
      crImportanceId: "",
      crSourceId: "",
      CRTitle: "",
      CRBody: "",
      CRSolution: "",
      CRAffectedItem: {},
      // attachment
      attachmentTagOptionList: [],
      attachmentFileArray: [],
      attachmentOriginArray: [],
      attachmentTagArray: [],
      attachmentCommentArray: [],
      attachmentUploaderArray: [],
      attachmentModifiedAtArray: [],
      // edit attachment
      deletedFileIdArray: [],
      attachmentIdArray: [],
      addedAttachmentArray: [],
      addedAttachmentTagArray: [],
      addedAttachmentCommentArray: [],
      deletedAttachmentArray: [],
      routeNumber: "",
      routeData: [],
      members: [],
      targetMember: "",
      // 우측 검색창
      searchBoxType: "",
    }));
  },
  initCOModule: () => {
    set(() => ({
      isRouteActivate: false,
      COId: "",
      CONumber: "",
      clientOrganizationId: "",
      COClientNumber: "",
      COClientPublishDate: "",
      CoClientReceiptDate: "",
      COisPastCar: "false",
      carTypeId: "",
      COisCostChange: "true",
      COcost: "",
      crReasonId: "",
      COReasonText: "",
      COLevel: "",
      COTiming: "",
      COAffect: [],
      COImportance: "",
      COTitle: "",
      COBody: "",
      CORelatedCR: [],
      COAffectedItem: [],
      changedFeatureId: [],
      changedFeatureIndex: "",
      // attachment
      attachmentTagOptionList: [],
      attachmentFileArray: [],
      attachmentOriginArray: [],
      attachmentTagArray: [],
      attachmentCommentArray: [],
      attachmentUploaderArray: [],
      attachmentModifiedAtArray: [],
      // edit attachment
      deletedFileIdArray: [],
      attachmentIdArray: [],
      addedAttachmentArray: [],
      addedAttachmentTagArray: [],
      addedAttachmentCommentArray: [],
      deletedAttachmentArray: [],
      routeNumber: "",
      routeData: [],
      members: [],
      targetMember: "",
      // 우측 검색창
      searchBoxType: "",
    }));
  },
}));
export default CRCOModuleStore;
