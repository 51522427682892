import create from "zustand";

const releaseModuleStore = create((set, get) => ({
  currentCategory: "List",
  setcurrentCategory: (val) => {
    set(() => ({ currentCategory: val }));
  },
  // modal
  modalSelectedItemClassification: {}, // classId
  setmodalSelectedItemClassification: (val) => {
    set(() => ({ modalSelectedItemClassification: val }));
  },
  modalClassificationTree: [],
  setmodalClassificationTree: (val) => {
    set(() => ({ modalClassificationTree: val }));
  },
  isModal: false,
  setisModal: (val) => {
    set(() => ({ isModal: val }));
  },
  modalItemList: [],
  setmodalItemList: (val) => {
    set(() => ({ modalItemList: val }));
  },
  modalTempCheckedList: [],
  modalCheckedItemList: [],
  onCheckModalItem: (val) => {
    const arr = get().modalTempCheckedList;
    const tmpArr = [...arr];
    const targetIndex = tmpArr.findIndex(
      (itemObj) => itemObj.cardId === val.cardId
    );
    if (targetIndex > -1) {
      tmpArr.splice(targetIndex, 1);
    } else {
      tmpArr.push(val);
    }
    console.log(tmpArr);
    set(() => ({ modalTempCheckedList: tmpArr }));
  },
  cancelModal: () => {
    set(() => ({
      isModal: false,
      modalTempCheckedList: [],
      modalCheckedItemList: [],
    }));
  },
  applyModalLv2: () => {
    const tmpArr = [...get().modalTempCheckedList];
    set(() => ({ modalCheckedItemList: tmpArr }));
  },
  applyModalLv3: () => {
    const { modalCheckedItemList, releaseItemId } = get();
    const tmpSet = new Set([...releaseItemId, ...modalCheckedItemList]);
    const tmpArr = [...tmpSet].map((item) => ({
      ...item,
      id: item.cardId || item.id,
      itemName: item.cardName,
    }));

    set(() => ({
      releaseItemId: tmpArr,
    }));

    set(() => ({
      isModal: false,
      modalTempCheckedList: [],
      modalCheckedItemList: [],
    }));
  },
  onDeleteModalAddedItems: (id) => {
    set(() => ({
      isModal: false,
      modalTempCheckedList: get().modalTempCheckedList.filter(
        (itemObj) => itemObj.id !== id
      ),
      modalCheckedItemList: get().modalCheckedItemList.filter(
        (itemObj) => itemObj.id !== id
      ),
    }));
  },
  // searchBox
  searchBoxType: "",
  setsearchBoxType: (val) => {
    set(() => ({ searchBoxType: val }));
  },
  setsearchBoxProperty: (target, val, index) => {
    if (target === "releaseOrganizationId") {
      const targetArray = get()[`${target}`];
      set(() => ({ [target]: [...targetArray, val] }));
    } else if (index) {
      if (target === "members") {
        const tmpmember = get().members;
        if (tmpmember[index]) {
          tmpmember[index] = [...tmpmember[index], val];
        } else {
          tmpmember[index] = [val];
        }
        set(() => ({ members: tmpmember }));
      } else {
        const targetArray = get()[`${target}`];
        if (Array.isArray(targetArray)) {
          targetArray[index] = val;
        }
        set(() => ({ [target]: targetArray }));
      }
    } else {
      set(() => ({ [target]: val }));
    }
  },
  deletemember: (id, index) => {
    const tmpmemberArr = get().members;
    const newArr =
      tmpmemberArr[index] && tmpmemberArr[index].filter((mem) => mem.id !== id);
    if (newArr[0]) {
      tmpmemberArr[index] = newArr;
    } else {
      tmpmemberArr.splice(index, 1);
    }
    set(() => ({ member: tmpmemberArr }));
  },

  // release
  setreleaseInfo: (val) => {
    set(() => val);
  },
  releaseId: "",
  setreleaseId: (val) => {
    set(() => ({ releaseId: val }));
  },
  releaseNumber: "",
  setreleaseNumber: (val) => {
    set(() => ({ releaseNumber: val }));
  },
  releaseItemId: [], // {id,name}
  setreleaseItemId: (val) => {
    set(() => ({ releaseItemId: val }));
  },
  releaseTitle: "",
  setreleaseTitle: (val) => {
    set(() => ({ releaseTitle: val }));
  },
  releaseType: "1", // 줄때는 1 , 받을떄, 읽을때는 {id,name}
  setreleaseType: (val) => {
    set(() => ({ releaseType: val }));
  },
  releaseContent: "",
  setreleaseContent: (val) => {
    set(() => ({ releaseContent: val }));
  },

  releaseCoId: [], // {coId: 1, coNumber: '6000266', coType: 'FULL TRACK', coReason: {…}, createdAt: '2022-07-15 02:55:49.000'}
  setreleaseCoId: (val) => {
    set(() => ({ releaseCoId: val }));
  },
  releaseOrganizationId: [], // [{id,name},{}]
  setreleaseOrganizationId: (val) => {
    set(() => ({ releaseOrganizationId: val }));
  },
  // attachment
  attachmentTagOptionList: [
    { id: "11", name: "배포서" },
    { id: "1", name: "ETC" },
  ],
  attachmentFileArray: [],
  setattachmentFileArray: (val) => {
    set(() => ({ attachmentFileArray: val }));
  },
  attachmentOriginArray: [],
  setattachmentOriginArray: (val) => {
    const newVal =
      val &&
      val.map((item) => {
        return `${process.env.REACT_APP_BASE_URL}/${item.toString()}`;
      });
    set(() => ({
      attachmentOriginArray: newVal,
    }));
  },
  attachmentTagArray: [],
  setattachmentTagArray: (val) => {
    set(() => ({ attachmentTagArray: val }));
  },

  attachmentCommentArray: [],
  setattachmentCommentArray: (val) => {
    set(() => ({ attachmentCommentArray: val }));
  },
  attachmentUploaderArray: [],
  setattachmentUploaderArray: (val) => {
    set(() => ({ attachmentUploaderArray: val }));
  },
  attachmentDateArray: [],
  setattachmentDateArray: (val) => {
    set(() => ({ attachmentDateArray: val }));
  },
  setattachmentInfo: (val) => {
    set(() => val);
  },
  // edit attachment
  // edit attachment
  deletedFileIdArray: [],
  setdeletedFileIdArray: (val) => {
    set(() => ({ deletedFileIdArray: val }));
  },
  attachmentIdArray: [],
  setattachmentIdArray: (val) => {
    set(() => ({ attachmentIdArray: val }));
  },
  attachmentModifiedAtArray: [],
  setattachmentModifiedAtArray: (val) => {
    set(() => ({ attachmentModifiedAtArray: val }));
  },
  addedAttachmentArray: [],
  setaddedAttachmentArray: (val) => {
    set(() => ({ addedAttachmentArray: val }));
  },
  addedAttachmentTagArray: [],
  setaddedAttachmentTagArray: (val) => {
    set(() => ({ addedAttachmentTagArray: val }));
  },
  addedAttachmentCommentArray: [],
  setaddedAttachmentCommentArray: (val) => {
    set(() => ({ addedAttachmentCommentArray: val }));
  },
  deletedAttachmentArray: [],
  setdeletedAttachmentArray: (val) => {
    set(() => ({ deletedAttachmentArray: val }));
  },
  // route
  routeNumber: "",
  setrouteNumber: (val) => {
    set(() => ({ routeNumber: val }));
  },
  routeData: [],
  setrouteData: (val) => {
    set(() => ({ routeData: val }));
  },
  // routeinit
  members: [],
  setmembers: (val) => {
    set(() => ({ members: val }));
  },
  targetMember: "",
  settargetMember: (val) => {
    set(() => ({ targetMember: val }));
  },
  // 스크롤 ref 모음
  informationRef: "",
  setinformationRef: (val) => {
    set(() => ({ informationRef: val }));
  },
  attachmentRef: "",
  setattachmentRef: (val) => {
    set(() => ({ attachmentRef: val }));
  },
  routeRef: "",
  setrouteRef: (val) => {
    set(() => ({ routeRef: val }));
  },

  // 초기화 함수
  initreleaseModule: () => {
    set(() => ({
      searchBoxType: "",
      // release
      releaseId: "",
      releaseNumber: "",
      releaseItemId: [], // {id:"",name}
      releaseTitle: "",
      releaseType: "1",
      releaseContent: "",
      releaseCoId: [], // {id:"",name}
      releaseOrganizationId: [],
      // atta
      attachmentFileArray: [],
      attachmentOriginArray: [],
      attachmentTagArray: [],
      attachmentCommentArray: [],
      attachmentUploaderArray: [],
      attachmentModifiedAtArray: [],

      addedAttachmentArray: [],
      addedAttachmentTagArray: [],
      addedAttachmentCommentArray: [],
      deletedAttachmentArray: [],
      routeNumber: "",
      routeData: [],
      members: [],
      targetMember: "",
    }));
  },
}));
export default releaseModuleStore;
