import React, { useState } from "react";
import styled from "styled-components";
import txt from "../Text";

const AlarmBoxStyle = styled.div`
  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height});
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: ${(props) => props.color};
  /* background-color: var(--textGray); */
  padding-top: 5px;
  padding-left: 18px;
  padding-right: 50px;
  cursor: pointer;
  .icon {
    font-size: 12px;
    color: var(--eciBlue);
    margin-right: 8px;
  }
  .module {
    font-size: 12px;
  }
  .message {
    font-size: 13px;
    color: var(--eciBlue);
    font-weight: 600;
  }
`;
export default function AlarmBox({ width, height, data, onClick }) {
  // 여기서 로컬 스토리지의 id와 비교해서 color설정
  const [rerender, setrerender] = useState(true);
  const moduleArr = {
    Project: 0,
    "CR / CO": 4,
    Item: 3,
    Document: 6,
    Release: 5,
    Bom: 2,
    Design: 1,
  };
  const alarmArr = localStorage.getItem("alarmArr")
    ? localStorage.getItem("alarmArr").split(",")
    : [];
  const color =
    alarmArr && alarmArr.indexOf(data.name + data.module + data.category) >= 0
      ? "var(--textGray)"
      : "white";

  return (
    <AlarmBoxStyle
      width={width}
      height={height}
      onClick={() => {
        onClick(moduleArr[data.module]);
        localStorage.setItem("alarmArr", [
          ...alarmArr,
          data.name + data.module + data.category,
        ]);
        setrerender(!rerender);
      }}
      color={color}
      title={`"${data.name}" now on ${data.category}`}
    >
      <div className="top">
        <span className="material-icons icon">
          {txt.home.icons[data.module]}
        </span>
        <span className="module">{data.module}</span>
      </div>{" "}
      <div className="message">
        &quot;{data.name}&quot; now on &quot;{data.category}&quot;
      </div>
    </AlarmBoxStyle>
  );
}
