import create from "zustand";
import { persist } from "zustand/middleware";
import axios from "axios";
import { useEffect, useRef } from "react";

const commonStore = create((set, get) => ({
  isLogged: !!localStorage.getItem("token"),
  //  isLogged:true,
  setIsLogged: (bool) => {
    set(() => ({ isLogged: bool }));
  },
  setUserToken: (token) => {
    set(() => ({ isLogged: true }));
    localStorage.setItem("token", token.accessToken);
  },

  isHover: false,
  setIsHover: (bool) => {
    set(() => ({
      isHover: bool,
    }));
  },

  loadingRequestCnt: 0,
  setloadingRequestCnt: (bool) =>
    set(() => ({
      loadingRequestCnt: bool,
    })),

  currentModule: "Home",
  setCurrentModule: (moduleName) =>
    set(() => {
      return { currentModule: moduleName };
    }),

  tokenAxios: (() => {
    const tokenAxios = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
    });
    tokenAxios.isPass2msObj = {};
    tokenAxios.isResponseArrivedObj = {};
    tokenAxios.timeOutIdArr = [];

    tokenAxios.interceptors.request.use(
      async (config) => {
        if (tokenAxios.isResponseArrivedObj[config.url]) {
          tokenAxios.isResponseArrivedObj[config.url] -= 1;
        } else {
          tokenAxios.isResponseArrivedObj[config.url] = 0;
        }

        const timeOutId = setTimeout(() => {
          if (!tokenAxios.isResponseArrivedObj[config.url]) {
            // response 없으면
            if (tokenAxios.isPass2msObj[config.url]) {
              tokenAxios.isPass2msObj[config.url] += 1;
            } else {
              tokenAxios.isPass2msObj[config.url] = 1;
            }
            set((state) => ({
              loadingRequestCnt: state.loadingRequestCnt + 1,
            }));
          }
        }, 2000);
        tokenAxios.timeOutIdArr.push(timeOutId);
        const configure = config;
        const auth = localStorage.getItem("token");
        configure.headers = {
          Authorization: auth,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        };
        return configure;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    tokenAxios.interceptors.response.use(
      (response) => {
        if (tokenAxios.isResponseArrivedObj[response.config.url]) {
          tokenAxios.isResponseArrivedObj[response.config.url] += 1;
        } else {
          tokenAxios.isResponseArrivedObj[response.config.url] = 1;
        }
        if (tokenAxios.isPass2msObj[response.config.url] > 0) {
          set((state) => ({
            loadingRequestCnt:
              state.loadingRequestCnt <= 0 ? 0 : state.loadingRequestCnt - 1,
          }));

          if (tokenAxios.isPass2msObj[response.config.url]) {
            tokenAxios.isPass2msObj[response.config.url] -= 1;
          }
        }

        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        console.log(error);

        /* eslint-disable */
        originalRequest._retry = true;
        if (
          (error.response && error.response.status > 499) ||
          (error.response &&
            error.response.status <= 499 &&
            !error.response.data.result)
        ) {
          if (error.config.method === "post" || error.config.method === "put") {
            // window.location.href = "/notFound";
          }
        }
        if (error && error.response && error.response.data.result) {
          if (error.response.data.result.msg === "리프레시가 필요합니다.") {
            fetch(`${process.env.REACT_APP_BASE_URL}/refresh-token`, {
              credentials: "include",
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: {},
            })
              .then((response) => {
                if (response.status !== 200) {
                  throw new Error(response.status);
                }

                return response.json();
              })
              .catch((error) => {
                // setIsLogged(false);
                const userId = JSON.parse(localStorage.getItem("undefined"))
                  .state.userData.id;
                fetch(`${process.env.REACT_APP_BASE_URL}/logout/${userId}`, {
                  // credentials: "include",
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                  // body: {},
                });

                alert(
                  "로그인 이후 많은 시간이 경과해 자동으로 로그아웃 되었습니다. 다시 로그인 해주세요."
                );
                localStorage.removeItem("token");
                localStorage.removeItem("alarmArr");

                window.location.reload(); //추가됨
              })
              .then((response) => {
                let aToken = JSON.stringify(response.result.data.accessToken);
                aToken = aToken.replaceAll(`"`, ``);
                localStorage.setItem("token", aToken);
                const rauth = localStorage.getItem("token");
                axios.defaults.headers.common.Authorization = rauth;
                originalRequest.headers.Authorization = rauth;
                if (error.config.method === "get") {
                  window.location.reload(); //추가됨
                } else {
                  return axios(originalRequest);
                }

                return axios(originalRequest);
              });
          } else {
            console.log(error.response);
            // window.location.href = "/notFound";
          }
        }
        alert(error.response.data.result.msg);

        // response일 때와 마찬가지로 loading관련 state들을 다시 백해준다.
        if (tokenAxios.isResponseArrivedObj[error.config.url]) {
          tokenAxios.isResponseArrivedObj[error.config.url] += 1;
        } else {
          tokenAxios.isResponseArrivedObj[error.config.url] = 1;
        }
        if (tokenAxios.isPass2msObj[error.config.url] > 0) {
          set((state) => ({
            loadingRequestCnt:
              state.loadingRequestCnt <= 0 ? 0 : state.loadingRequestCnt - 1,
          }));

          if (tokenAxios.isPass2msObj[error.config.url]) {
            tokenAxios.isPass2msObj[error.config.url] -= 1;
          }
        }
        throw new Error(error);
      }
    );

    return tokenAxios;
  })(),
  tokenAxiosNoLoading: (() => {
    const tokenAxios = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
    });

    tokenAxios.interceptors.request.use(
      async (config) => {
        const configure = config;
        const auth = localStorage.getItem("token");
        configure.headers = {
          Authorization: auth,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        };
        return configure;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    tokenAxios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        console.log(error);

        /* eslint-disable */
        originalRequest._retry = true;

        if (
          (error.response && error.response.status > 499) ||
          (error.response &&
            error.response.status <= 499 &&
            !error.response.data.result)
        ) {
          if (error.config.method === "post" || error.config.method === "put") {
            // window.location.href = "/notFound";
          }
        }
        if (error && error.response && error.response.data.result) {
          if (error.response.data.result.msg === "리프레시가 필요합니다.") {
            fetch(`${process.env.REACT_APP_BASE_URL}/refresh-token`, {
              credentials: "include",
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: {},
            })
              .then((response) => {
                if (response.status !== 200) {
                  throw new Error(response.status);
                }

                return response.json();
              })
              .catch((error) => {
                // setIsLogged(false);
                const userId = JSON.parse(localStorage.getItem("undefined"))
                  .state.userData.id;
                fetch(`${process.env.REACT_APP_BASE_URL}/logout/${userId}`, {
                  // credentials: "include",
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                  // body: {},
                });

                alert(
                  "로그인 이후 많은 시간이 경과해 자동으로 로그아웃 되었습니다. 다시 로그인 해주세요."
                );
                localStorage.removeItem("token");
                localStorage.removeItem("alarmArr");

                window.location.reload(); //추가됨
              })
              .then((response) => {
                let aToken = JSON.stringify(response.result.data.accessToken);
                aToken = aToken.replaceAll(`"`, ``);
                localStorage.setItem("token", aToken);
                const rauth = localStorage.getItem("token");
                axios.defaults.headers.common.Authorization = rauth;
                originalRequest.headers.Authorization = rauth;
                if (error.config.method === "get") {
                  window.location.reload(); //추가됨
                } else {
                  return axios(originalRequest);
                }

                return axios(originalRequest);
              });
          } else {
            console.log(error.response);
            alert(error.response.data.result.msg);
            // window.location.href = "/notFound";
          }
        }
        alert(error.response.data.resut.msg);
        // window.location.href = "/notFound";

        throw new Error(error);
      }
    );

    return tokenAxios;
  })(),
}));
export default commonStore;
export const userStore = create(
  persist((set) => ({
    userData: "",
    setUserData: (data) => {
      set(() => ({ userData: data }));
    },
  }))
);

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
