import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import releaseModuleStore from "../../store/releaseModuleStore";
import ReleaseInformationSection from "../ReleaseInformationSection";

import Button from "../../atom/Button";
import SearchBox from "../../molecule/SearchBox";
import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";
import LoadingBox from "../LoadingBox";
import LoadingArrowAnimation from "../../molecule/LoadingArrowAnimation";
import BoldText from "../../atom/BoldText";
import RouteSection from "../RouteSection";
import AttachmentSection from "../AttachmentSection";
import Table from "../../molecule/Table";
import TableIndexRow from "../../atom/TableIndexRow";
import SearchInput from "../../molecule/SearchInput";
import TableRow from "../../atom/TableRow";
import RouteInitSection from "../RouteInitSection";
import txt from "../../Text";

const ReleaseEditPageStyle = styled.div`
  display: flex;
  position: relative;
  .tempSave {
    height: 40px;

    position: absolute;
    top: -40px;
    left: calc(58% - 110px);
    display: flex;
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`;
const FormSectionStyle = styled.div`
  width: 58%;
  height: calc(100vh - 230px);
  overflow: scroll;
  padding-left: 18%;
  padding-right: 1.5%;
  padding-top: 20px;
  .gap {
    height: 45px;
  }
  ::-webkit-scrollbar {
    height: 7px;
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
`;
const SearchBoxSectionStyle = styled.div`
  width: 42%;
`;

const ReleaseFromStyle = styled.div``;
function ReleaseEditPage() {
  const { tokenAxios } = commonStore((state) => ({
    tokenAxios: state.tokenAxios,
  }));

  const {
    initreleaseModule,
    // releaseInfo
    releaseId,
    releaseNumber,
    releaseItemId, // {id,name}
    releaseTitle,
    releaseType,
    releaseContent,
    releaseCoId, // {id,name}
    releaseOrganizationId,
    setreleaseOrganizationId,
    setreleaseInfo,

    /// attachment
    attachmentFileArray,
    attachmentTagArray,
    attachmentCommentArray,
    attachmentOriginArray,
    attachmentDateArray,
    attachmentUploaderArray,
    setattachmentDateArray,
    setattachmentUploaderArray,
    deletedFileIdArray,
    attachmentIdArray,
    setattachmentIdArray,
    setdeletedFileIdArray,
    attachmentTagOptionList,
    attachmentModifiedAtArray,
    setattachmentModifiedAtArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    setattachmentOriginArray,
    deletedAttachmentArray,
    setdeletedAttachmentArray,
    addedAttachmentArray,
    addedAttachmentTagArray,
    addedAttachmentCommentArray,
    setaddedAttachmentArray,
    setaddedAttachmentTagArray,
    setaddedAttachmentCommentArray,
    // seaechbox
    searchBoxType,
    setsearchBoxType,
    setsearchBoxProperty,
    // route
    routeNumber,
    routeData,
    setrouteData,
    setrouteNumber,
    members,
    targetMember,
    settargetMember,
    deletemember,
    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // etc

    setcurrentCategory,
  } = releaseModuleStore();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [isRouteActivate, setisRouteActivate] = useState(false);
  const [isReject, setisReject] = useState(false);
  const formRef = useRef();
  const fromRef = useRef();

  const appendReleaseFormData = () => {
    const formData = new FormData();

    formData.append("releaseType", releaseType); // 보낼때는 releaseType:1, 받을떄, 보여줄때는 {id,name}
    if (releaseCoId.coId || releaseCoId.id) {
      formData.append("releaseCoId", releaseCoId.coId || releaseCoId.id);
    }
    formData.append(
      "releaseItemId",
      releaseItemId.map((item) => item.id)
    );
    formData.append("releaseTitle", releaseTitle);
    formData.append("releaseContent", releaseContent);
    formData.append(
      "releaseOrganizationId",
      releaseOrganizationId.map((item) => item.id)
    );
    // attachment
    addedAttachmentArray.forEach((file) => {
      formData.append("addedAttachments", file);
    });

    formData.append("addedAttachmentComment", attachmentCommentArray);
    formData.append(
      "addedTag",
      attachmentTagArray.map((item) => (item.id ? item.id : item))
    );
    formData.append("deletedAttachments", deletedAttachmentArray);
    return formData;
  };
  const params = useParams();

  const saveRelease = async () => {
    setisLoading(true);
    const formData = appendReleaseFormData();
    try {
      const response = await tokenAxios.put(
        `/release/temp/end/${params.releaseId}`,
        formData
      );
      setisRouteActivate(true);
      // route부분으로 스크롤
      setrouteNumber(response.data.result.data.routeId);
      setisLoading(false);
    } catch (err) {
      if (err.response.data.result) {
        console.log(err.response);
        alert(err.response.data.result.msg);
      } else {
        console.log(err.response);
        navigate("/notFound");
      }
      setisRouteActivate(false);
    }
  };

  const tempSave = () => {
    setisLoading(true);
    const formData = appendReleaseFormData();
    try {
      tokenAxios
        .put(`release/temp/${params.releaseId}`, formData)
        .then((res) => {
          setTimeout(() => {
            alert(txt.approveDone);

            setisLoading(false);
          }, 1000);
          // alert("임시저장 완료!");
        });
    } catch (err) {
      if (err.response.data.result) {
        console.log(err.response);
        alert(err.response.data.result.msg);
      } else {
        console.log(err.response);
        navigate("/notFound");
      }
    }
  };
  const getReleaseData = useCallback(
    async (Id) => {
      const response = await tokenAxios.get(`release/${Id}`);
      const { data } = response.data.result;
      // edit phase 확인
      setisRouteActivate(data.readonly);
      setisReject(data.preRejected);
      // set시작
      setreleaseInfo({
        releaseId: data.id,
        releaseNumber: data.releaseNumber,
        releaseItemId: data.item, // {id,name}
        releaseTitle: data.releaseTitle,
        releaseType: data.releaseType.id,
        releaseContent: data.releaseContent,
        releaseCoId: data.releaseCoId, // {id,name}
        releaseOrganizationId: data.releaseOrganizationId, // routeNumbers
        routeNumber: data.routeId,
      });

      // attachment
      const tempfileArray = [];
      const temptagArray = [];
      const tempcommentArray = [];
      const temporiginArray = [];
      const tempuploaderArray = [];
      const tempdateArray = [];
      const tempidArray = [];
      const tempdeletedFileArray = [];
      const tempModifiedAtArray = [];

      if (data.projectAttachments) {
        data.projectAttachments.forEach((item) => {
          tempfileArray.push(item.originName);
          tempcommentArray.push(item.attach_comment);
          temptagArray.push(item.tag);
          temporiginArray.push(item.attachmentaddress);
          tempuploaderArray.push(item.upload);
          tempdateArray.push(item.date);
          tempidArray.push(item.id);
          if (item.deleted) {
            tempdeletedFileArray.push(item.id);
          }
          tempModifiedAtArray.push(item.modifiedAt ? item.modifiedAt : "");
        });
        setattachmentFileArray(tempfileArray);
        setattachmentIdArray(tempidArray);
        setattachmentCommentArray(tempcommentArray);
        setattachmentTagArray(temptagArray);
        setattachmentOriginArray(temporiginArray);
        setattachmentDateArray(tempdateArray);
        setattachmentUploaderArray(tempuploaderArray);
        setdeletedFileIdArray(tempdeletedFileArray);
        setattachmentModifiedAtArray(tempModifiedAtArray);
      }
    },
    [tokenAxios]
  );
  useEffect(() => {
    initreleaseModule();
    setcurrentCategory("Add");
    getReleaseData(params.releaseId);

    return () => {
      initreleaseModule();
    };
  }, [setcurrentCategory]);

  return (
    <ReleaseEditPageStyle>
      <div className="tempSave">
        {!isRouteActivate && (
          <Button
            type="button"
            onClick={tempSave}
            backgroundColor="transparent"
            fontSize="12px"
            color="var(--textDarkGray)"
          >
            {txt.saveAsDraft}{" "}
          </Button>
        )}
        <Button
          type="button"
          backgroundColor="transparent"
          color="var(--textDarkGray)"
          fontSize="12px"
          onClick={() => {
            navigate("/release");
          }}
        >
          <span className="material-icons">close</span>
        </Button>
      </div>
      <LoadingBox isLoading={isLoading}>
        <LoadingArrowAnimation />
        <BoldText fontSize="26px" color="var(--eciBlue)">
          S a v i n g
        </BoldText>
        <br />
        <br />
        Please wait while we set
        <br />
        thing up for you!
      </LoadingBox>
      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, txt.rel.relInfo],
            [attachmentRef, txt.rel.relAttachment],
            [fromRef, txt.rel.relFrom],
            [routeRef, txt.rel.routeInfo],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>
      <FormSectionStyle ref={formRef}>
        <ReleaseInformationSection readOnly={isRouteActivate} />

        <AttachmentSection
          attachmentModifiedAtArray={attachmentModifiedAtArray}
          readOnly={isRouteActivate}
          attachmentFileArray={attachmentFileArray}
          setattachmentFileArray={setattachmentFileArray}
          attachmentTagArray={attachmentTagArray}
          setattachmentTagArray={setattachmentTagArray}
          attachmentCommentArray={attachmentCommentArray}
          setattachmentCommentArray={setattachmentCommentArray}
          attachmentDateArray={attachmentDateArray}
          attachmentUploaderArray={attachmentUploaderArray}
          setattachmentDateArray={setattachmentDateArray}
          setattachmentUploaderArray={setattachmentUploaderArray}
          attachmentOriginArray={attachmentOriginArray}
          setattachmentOriginArray={setattachmentOriginArray}
          setattachmentRef={setattachmentRef}
          title={txt.rel.relAttachment}
          tagOptionList={txt.rel.tagOptionList}
          editMode
          attachmentIdArray={attachmentIdArray}
          setattachmentIdArray={setattachmentIdArray}
          deletedFileIds={deletedFileIdArray}
          deletedAttachmentArray={deletedAttachmentArray}
          setdeletedAttachmentArray={setdeletedAttachmentArray}
          addedAttachmentArray={addedAttachmentArray}
          addedAttachmentTagArray={addedAttachmentTagArray}
          addedAttachmentCommentArray={addedAttachmentCommentArray}
          setaddedAttachmentArray={setaddedAttachmentArray}
          setaddedAttachmentTagArray={setaddedAttachmentTagArray}
          setaddedAttachmentCommentArray={setaddedAttachmentCommentArray}
        />
        <br />
        <ReleaseFromStyle ref={fromRef}>
          <BoldText fontSize="14px" color="var(--eciBlue)">
            {txt.rel.relFrom}{" "}
          </BoldText>
          <br />
          <SearchInput
            required
            width="100%"
            height="40px"
            placeholder={txt.rel.relPart}
            onClick={() => {
              setsearchBoxType("releaseOrganizationId");
            }}
            backgroundColor="var(--eciBlue)"
            readOnly={isRouteActivate}
          />
          <Table width="100%" minHeight="120px">
            <TableIndexRow widthArray={[1]} itemArray={txt.rel.relIndexArray} />
            {releaseOrganizationId &&
              releaseOrganizationId.map((item, i) => {
                const onDelete = () => {
                  const tmpArr = [...releaseOrganizationId];
                  tmpArr.splice(i, 1);
                  setreleaseOrganizationId(tmpArr);
                };
                return (
                  <TableRow
                    key={i}
                    widthArray={isRouteActivate ? [1] : [9, 1]}
                    itemArray={[
                      item.name,
                      !isRouteActivate && (
                        <span
                          className="material-icons delete"
                          onClick={onDelete}
                        >
                          delete
                        </span>
                      ),
                    ]}
                  />
                );
              })}
          </Table>
        </ReleaseFromStyle>
        <div className="gap" />
        {isRouteActivate ? (
          ""
        ) : (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveRelease}
            condition={
              !!releaseType &&
              !!releaseTitle &&
              !!releaseContent &&
              (releaseType === "1" ? !!releaseCoId : !!releaseItemId) &&
              releaseOrganizationId &&
              releaseOrganizationId.length > 0
            }
          >
            {txt.saveAndRoute}{" "}
          </Button>
        )}

        {isReject ? (
          <RouteSection
            activate={isRouteActivate || isReject}
            routeNumber={routeNumber}
            setrouteRef={setrouteRef}
            routeData={routeData}
            setrouteData={setrouteData}
            rejecting={isReject && !isRouteActivate}
          />
        ) : (
          <RouteInitSection
            activate={isRouteActivate}
            itemType="release"
            setrouteRef={setrouteRef}
            itemId={releaseId}
            setsearchBoxType={setsearchBoxType}
            targetMember={targetMember}
            settargetMember={settargetMember}
            members={members}
            url="/route/release"
          />
        )}
      </FormSectionStyle>
      <SearchBoxSectionStyle>
        <SearchBox
          width="100%"
          height="100vh - 218px"
          type={searchBoxType}
          setproperty={setsearchBoxProperty}
          propertyIndex={targetMember}
          members={members}
          deletememberArray={deletemember}
        />
      </SearchBoxSectionStyle>
    </ReleaseEditPageStyle>
  );
}
export default React.memo(ReleaseEditPage);
