import React from "react";
import styled from "styled-components";

const StatusTagStyle = styled.div`
  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height});
  border-radius: 5px;

  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  text-align: center;
  line-height: calc(${(props) => props.height}); ;
`;
export default function StatusTag({ width, height, children }) {
  let color;
  let backgroundColor;
  switch (children) {
    case "WORKING":
      color = "#FDBF4D";
      backgroundColor = "#FFF7E7";
      break;
    case "COMPLETE":
      color = "var(--eciBlue)";
      backgroundColor = "#F2F4F8";
      break;
    case "RELEASE":
      color = "#63C2A0";
      backgroundColor = "#EBF9F4";
      break;
    case "PENDING":
      color = "#474747";
      backgroundColor = "#DADADA";
      break;
    case "DROP":
      color = "#E3738D";
      backgroundColor = "#FDF4F6";
      break;
    default:
  }
  return (
    <StatusTagStyle
      width={width}
      height={height}
      color={color}
      backgroundColor={backgroundColor}
    >
      {children}
    </StatusTagStyle>
  );
}
