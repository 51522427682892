import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import styled from "styled-components";
import BoldText from "../atom/BoldText";
import LineTitle from "../atom/LineTitle";
import TableIndexRow from "../atom/TableIndexRow";
import DeleteInput from "../molecule/DeleteInput";
import Table from "../molecule/Table";
import commonStore, { useInterval } from "../store/commonStore";
import designModuleStore from "../store/DesignModuleStore";
import TreeIndentList from "./TreeIndentList";
import TreeBoxList from "./TreeBoxList";
import resultText from "../Text";
import ModalBox from "./ModalBox";
import SearchBar from "../molecule/SearchBar";
import PaginationTable from "../molecule/PaginationTable";
import TableRow from "../atom/TableRow";
import RoundImgBox from "../atom/RoundImgBox";
import RoundTextBox from "../atom/RoundTextBox";
import FilterSearchBox from "../molecule/FilterSearchBox";

const DesignInformationSectionStyle = styled.div`
  width: ${(props) =>
    !props.readOnly || props.type === "Catia" ? "50%" : "100%"};
  .openButton {
    background-color: var(--eciBlue);
    color: white;
    width: 90px;
    height: 32px;
    border-radius: 5px;
    font-size: 11px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: calc(50% - 30px);
    cursor: pointer;
  }
  .radioContainer {
    width: 80%;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .uploader {
      font-size: 14px;
      font-weight: 600;
      color: var(--eciBlue);
      margin-right: 20px;
    }
    .radioFlex {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px 0;
    }
    .radio {
      width: 30%;
      display: flex;
      align-items: center;
    }
  }
  /* pointer-events: ${(props) => (props.readOnly ? "none" : "")}; */
`;
const MatchBoxStyle = styled.div`
  width: 200%;
  padding: 20px 10px;
  margin-top: 30px;
  border: solid 1px var(--eciBlue);
  border-radius: 5px;

  background-color: white;

  .caditemcontainer {
    display: flex;
    .partBox {
      width: 50%;
    }
  }
  button {
    height: 30px;
    width: 80px;
    margin: 0 auto;
    margin-top: 20px;
    display: block;

    border: 1px solid var(--deemGray);
    border-radius: 5px;
    background-color: ${(props) =>
      props.okButtonActive ? "var(--eciBlue)" : "var(--disableGray)"};
    color: ${(props) => (props.okButtonActive ? "white" : "var(--deemGray)")};
    pointer-events: ${(props) => (props.okButtonActive ? "" : "none")};
    cursor: pointer;
  }
`;

const CadianBoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 40%;
  .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    align-items: center;
    color: var(--eciBlue);
    div {
      font-size: 12px;
      color: var(--eciBlue);
      font-weight: 600;
    }

    .whiteBox {
      width: 80%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 10px;
      height: 24px;
      line-height: 24px;
      background-color: white;
      border-radius: 5px;
      cursor: pointer;
      a {
        width: 100%;
      }
    }
  }
`;
export default React.memo(function DesignInformationSection({
  readOnly,
  setisTempLoading,
  designItemId,
}) {
  const { tokenAxios, tokenAxiosNoLoading } = commonStore();
  const {
    cadFile,
    designId,
    setdesignId,
    setcadFile,
    cadFileList,
    setcadFileList,
    setinformationRef,
    isMatchConfirmed,
    setisMatchConfirmed,
    roomId,
    setroomId,
    isTempLoading,
    uploaderType,
    setuploaderType,
    choiceDesignId,
    setchoiceDesignId,
    // designItemId,
  } = designModuleStore();

  const informationRef = useRef();

  const navigate = useNavigate();
  // Modal
  const [isModalBox, setisModalBox] = useState(false);

  const [searchText, setsearchText] = useState("");
  const [itemName, setitemName] = useState("");
  const [itemNumber, setitemNumber] = useState("");
  const [carType, setcarType] = useState("");
  const [carTypeArr, setcarTypeArr] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [pageNum, setPageNum] = useState("0");
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [dataList, setdataList] = useState([]);
  const rows =
    dataList &&
    dataList[0] &&
    dataList[0].map((item, i) => {
      return (
        <TableRow
          key={i}
          widthArray={[0.2, 1, 1, 1, 1, 1]}
          itemArray={[
            <RoundImgBox
              src={
                item.thumbnail &&
                `${process.env.REACT_APP_BASE_URL}/${item.thumbnail}`
              }
              alt=""
            />,
            item && item.itemNumber,
            item && item.itemName,
            item && item.classification,
            item && item.type,
            item && item.sharing && item.sharing === "공용" ? (
              <RoundTextBox
                width="50%"
                backgroundColor="var(--lightGray)"
                color="var(--eciBlue)"
                height="30px"
              >
                {resultText.item.sharing}{" "}
              </RoundTextBox>
            ) : (
              <RoundTextBox
                width="50%"
                backgroundColor="var(--lightGray)"
                color="var(--eciBlue)"
                height="30px"
              >
                {resultText.item.notSharing}{" "}
              </RoundTextBox>
            ),
          ]}
          onClick={async () => {
            setcadFile(
              item.designInformationFiles[0]
                ? item.designInformationFiles[0]
                : item.designInformationFiles
            );
            const response = await tokenAxios.get(`design/${item.id}`);
            const { data } = response.data.result;
            setcadFileList(JSON.parse(data.designContent));
            let status;
            if (data.uploaderType === "cad") {
              status = "Catia";
            } else if (data.uploaderType === "cadian") {
              status = "Cadian";
            } else {
              status = "Link";
            }
            setuploaderType(status);

            setchoiceDesignId(item.id);
            setisModalBox(false);
            //  unmatched라면 alert
            if (
              JSON.parse(data.designContent).unmatched &&
              JSON.parse(data.designContent).unmatched > 0
            ) {
              alert(resultText.design.cadFileAlert);
            }
          }}
        />
      );
    });
  const getModalData = async () => {
    const response = await tokenAxios.get(
      `design/page?size=${rowsPerPage}&page=${pageNum}&name=${searchText}&itemName=${itemName}&itemNumber=${itemNumber}&carTypeId=${carType}`
    );
    setdataList([response.data.content]);
    setTotalPage(response.data.totalPages);
  };
  //  modal end

  useEffect(() => {
    getModalData();
  }, [searchText, itemName, itemNumber, carType, pageNum, rowsPerPage]);

  // socket 대신 pulling적용
  const [isPulling, setisPulling] = useState(false);
  const sendPullingRequest = async () => {
    try {
      const response = await tokenAxiosNoLoading.get(
        `design-file/${designItemId}`
      );
      const data = JSON.parse(response.data.result.data);
      if (data) {
        setcadFile(data.title);
        setcadFileList(data);
        setisTempLoading(false);
        setisPulling(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useInterval(
    () => {
      sendPullingRequest();
    },
    isPulling ? 5000 : null
  );
  useEffect(() => {
    setinformationRef(informationRef);
  }, [readOnly]);

  //  socket 버전
  // const ws = useRef(null);
  // const [isSocketSend, setisSocketSend] = useState(false);

  // const sendWebSocket = async () => {
  //   const response = await tokenAxios.post("design-file");
  //   const roomid = response.data.roomId;
  //   setroomId(roomid);
  //   ws.current.send(
  //     JSON.stringify({
  //       type: "ENTER",
  //       roomId: roomid,
  //       message: "",
  //       itemId: designItemId,
  //     })
  //   );
  // };

  // const openWebSocket = () => {
  //   if (!ws.current && !readOnly) {
  //     ws.current = new WebSocket(
  //       `${process.env.REACT_APP_SOCKET_URL}/ws/design-file`
  //     );
  //     ws.current.onopen = () => {
  //       console.log("connected to open");
  //       sendWebSocket();
  //     };
  //     ws.current.onclose = (error) => {
  //       console.log("disconnect from close");
  //       console.log(error);
  //       // re open
  //       ws.current = null;
  //       openWebSocket();
  //     };
  //     ws.current.onerror = (error) => {
  //       console.log("connection error error");
  //       console.log(error);
  //     };
  //     ws.current.onmessage = (error) => {
  //       setisSocketSend(true);
  //     };
  //   }
  // };
  // useEffect(() => {
  //   setinformationRef(informationRef);
  //   openWebSocket();
  // }, [readOnly]);
  // useEffect(() => {
  //   if (isSocketSend) {
  //     ws.current.onmessage = (evt) => {
  //       console.log("message", evt);
  //       const data = JSON.parse(evt.data);
  //       const message = JSON.parse(data.message);
  //       if (message && message.title === " ") {
  //         setisTempLoading(false);
  //         if (data.type !== "ENTER") alert("아이템을 확인해주세요");
  //       } else {
  //         setcadFile(message.title);
  //         setcadFileList(message);
  //         setdesignId(message.desginId);
  //         setisTempLoading(false);
  //       }
  //     };
  //   }
  // }, [isSocketSend]);
  return (
    <DesignInformationSectionStyle
      ref={informationRef}
      readOnly={readOnly}
      type={uploaderType}
    >
      <ModalBox
        isActivate={isModalBox}
        onClick={(val) => {}}
        setisActivate={setisModalBox}
      >
        <FilterSearchBox
          searchText={searchText}
          setsearchText={setsearchText}
          setitemName={setitemName}
          setitemNumber={setitemNumber}
          setcarType={setcarType}
        />
        <br />
        <br />

        <PaginationTable
          minHeight="480px"
          totalPage={totalPage}
          setRowsPerPage={setrowsPerPage}
          rowsPerPageDefault={7}
          pageNum={pageNum}
          rowsPerPage={rowsPerPage}
          setpageNum={setPageNum}
        >
          <TableIndexRow
            widthArray={[0.4, 2, 2, 2, 2, 2]}
            itemArray={resultText.rel.modalIndexArray}
          />
          {rows}
        </PaginationTable>
      </ModalBox>
      <LineTitle
        fontSize="16px"
        color="var(--eciBlue)"
        width="100%"
        height="60px"
      >
        {resultText.design.designInformation}
      </LineTitle>
      {(typeof cadFile === "object" ? cadFile.name : cadFile) || readOnly ? (
        <DeleteInput
          onLink={
            uploaderType === "Link"
              ? () => {
                  navigate(`/design/detail/${choiceDesignId}`);
                }
              : ""
          }
          width="100%"
          height="40px"
          state={typeof cadFile === "object" ? cadFile.name : cadFile} // 여기 추가
          deleteState={(val) => {
            setcadFile(val);
            setisMatchConfirmed(false);
          }}
          placeholder={`${uploaderType} Design File`}
          readOnly={readOnly}
        />
      ) : (
        <div>
          <div className="radioContainer">
            <div className="uploader">uploader </div>
            <div className="radioFlex">
              <div className="radio">
                <input
                  type="radio"
                  id="Catia"
                  name="Catia"
                  value="Catia"
                  onChange={() => {
                    setuploaderType("Catia");
                  }}
                  checked={uploaderType === "Catia"}
                />
                <label htmlFor="Catia">Catia</label>
              </div>
              <div className="radio">
                <input
                  type="radio"
                  id="Cadian"
                  name="Cadian"
                  value="Cadian"
                  onChange={() => {
                    setuploaderType("Cadian");
                  }}
                  checked={uploaderType === "Cadian"}
                />
                <label htmlFor="Cadian">Cadian</label>
              </div>
              <div className="radio">
                <input
                  type="radio"
                  id="Link"
                  name="Link"
                  value="Link"
                  onChange={() => {
                    setuploaderType("Link");
                  }}
                  checked={uploaderType === "Link"}
                />
                <label htmlFor="Link">Link Design File</label>
              </div>
              {"단순구매품이면?" && ( // check
                <>
                  <div className="radio">
                    <input
                      type="radio"
                      id="catOnly"
                      name="catOnly"
                      value="catOnly"
                      onChange={() => {
                        setuploaderType("catOnly");
                      }}
                      checked={uploaderType === "catOnly"}
                    />
                    <label htmlFor="catOnly">Cat File Only</label>
                  </div>
                  <div className="radio">
                    <input
                      type="radio"
                      id="designOnly"
                      name="designOnly"
                      value="designOnly"
                      onChange={() => {
                        setuploaderType("designOnly");
                      }}
                      checked={uploaderType === "designOnly"}
                    />
                    <label htmlFor="designOnly">Design File Only</label>
                  </div>
                </>
              )}
            </div>
          </div>

          {uploaderType !== "Link" && (
            /*eslint-disable */
            <a
              // href={
              //   uploaderType === "Catia"
              //     ? `catiauploader:${designItemId}/${roomId}/${
              //         process.env.REACT_APP_BASE_URL.split("//")[1].split(
              //           ":"
              //         )[0]
              //       }`
              //     : uploaderType === "Cadian"
              //     ? `dwgUploader:${designItemId}/${roomId}`
              //     : uploaderType === "catOnly"
              //     ? `catOnlyUploader:${designItemId}/${roomId}`
              //     : `designOnlyUploader:${designItemId}/${roomId}`
              // }
              href={
                uploaderType === "Catia"
                  ? `catiauploader:${designItemId}/${roomId}/${
                      process.env.REACT_APP_BASE_URL.split("//")[1].split(
                        ":"
                      )[0]
                    }`
                  : uploaderType === "Cadian"
                  ? `dwgUploader:${designItemId}/${roomId}`
                  : uploaderType === "catOnly"
                  ? `catOnlyUploader:${designItemId}/${roomId}`
                  : `designOnlyUploader:${designItemId}/${roomId}`
              }
            >
              <div
                className="openButton"
                onClick={() => {
                  setisPulling(true);
                  setisTempLoading("catia");
                }}
              >
                Ok <span />
              </div>
            </a>
          )}
          {uploaderType === "Link" && (
            <div
              className="openButton"
              onClick={() => {
                setisModalBox(true);
              }}
            >
              Ok <span />
            </div>
          )}
        </div>
      )}
      <br />

      {uploaderType === "Catia" &&
        (typeof cadFile === "object" ? cadFile.name : !!cadFile) &&
        !!cadFileList &&
        (!isMatchConfirmed ? ( //  여기 체크
          <MatchBoxStyle okButtonActive={cadFileList.unmatched * 1 === 0}>
            <BoldText
              color={cadFileList.unmatched > 0 ? "red" : "var(--eciBlue)"}
              fontSize="22px"
            >
              {cadFileList.unmatched > 0
                ? `${cadFileList.unmatched} items do not match, Please upload a new CAD file`
                : "All items have been matched!"}
            </BoldText>
            <br />
            <br />
            <div className="caditemcontainer">
              <div className="cadParts partBox">
                <BoldText fontSize="16px" color="var(--eciBlue)">
                  {resultText.design.cadParts}
                </BoldText>
                <Table width="95%" noshadow>
                  <TableIndexRow
                    widthArray={[9, 12, 5]}
                    itemArray={["Item Number", "Item Name", "Quantity"]}
                    height="30px"
                    width="95%"
                    noBorder
                  />
                  <TreeIndentList indent="20px" data={cadFileList.item} />
                </Table>
              </div>
              <div className="itemParts partBox">
                <BoldText fontSize="16px" color="var(--eciBlue)">
                  {resultText.design.itemParts}
                </BoldText>
                <Table width="95%" noshadow>
                  <TableIndexRow
                    widthArray={[9, 12, 5]}
                    itemArray={["Item Number", "Item Name", "Quantity"]}
                    height="30px"
                    width="95%"
                    noBorder
                  />
                  <TreeIndentList indent="20px" data={cadFileList.cad} />
                </Table>
              </div>
            </div>
            <button
              type="button"
              onClick={() => {
                setisMatchConfirmed(true);
              }}
            >
              OK
            </button>
          </MatchBoxStyle>
        ) : (
          <TreeBoxList data={cadFileList.item} />
        ))}
      {uploaderType === "Cadian" &&
        (typeof cadFile === "object" ? !!cadFile.name : !!cadFile) &&
        !!cadFileList && (
          <CadianBoxStyle>
            <div className="row">
              <div className="label">dwg</div>
              <div className="whiteBox">
                <a
                  target="_blank"
                  href={
                    cadFileList.item &&
                    cadFileList.item.dwg &&
                    `${process.env.REACT_APP_BASE_URL}/${cadFileList.item.dwg}`
                  }
                  rel="noreferrer"
                >
                  {cadFileList.item && cadFileList.item.dwgName}
                </a>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="label">pdf</div>
              <div className="whiteBox">
                <a
                  target="_blank"
                  href={
                    cadFileList.item &&
                    cadFileList.item.pdf &&
                    `${process.env.REACT_APP_BASE_URL}/${cadFileList.item.pdf}`
                  }
                  rel="noreferrer"
                >
                  {cadFileList.item && cadFileList.item.pdfName}
                </a>
              </div>
            </div>
          </CadianBoxStyle>
        )}

      <div className="gap" />
    </DesignInformationSectionStyle>
  );
});
