import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../atom/Button";
import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";
import LoadingBox from "../LoadingBox";
import LoadingArrowAnimation from "../../molecule/LoadingArrowAnimation";
import BoldText from "../../atom/BoldText";
import itemModuleStore from "../../store/itemModuleStore";
import AttachmentSection from "../AttachmentSection";
import ItemInformationSection from "../ItemInformationSection";
import ItemSearchBox from "../../molecule/ItemSearchBox";
import SearchBox from "../../molecule/SearchBox";
import RouteInitSection from "../RouteInitSection";
import RouteSection from "../RouteSection";
import text from "../../Text";

const ItemEditPageStyle = styled.div`
  display: flex;
  position: relative;
  .tempSave {
    height: 40px;

    position: absolute;
    top: -40px;
    left: calc(58% - 110px);
    display: flex;
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`;
const FormSectionStyle = styled.div`
  width: 58%;
  height: calc(100vh - 200px);
  overflow: scroll;
  padding-left: 18%;
  padding-right: 1.5%;
  padding-top: 20px;
  .gap {
    height: 45px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
`;
const SearchBoxSectionStyle = styled.div`
  width: 42%;
  position: relative;
  .searchBox {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
export default function ItemEditPage() {
  const tokenAxios = commonStore((state) => state.tokenAxios);
  const {
    // item
    itemId,
    setitemId,
    setitemClassification,
    setitemInfo,
    // itemProperty
    classification1,
    classification2,
    classification3,
    itemName, // name 대신 itemName
    customItemNumber,
    typeId,
    thumbnail,
    sharing,
    carTypeId,

    forming,
    curve,
    integrate,
    width,
    height,
    thickness,
    weight,
    importance,
    colorId,
    loadQuantity,
    coatingWayId,
    coatingTypeId,
    modulus,
    screw,
    cuttingType,
    lcd,
    displaySize,
    screwHeight,
    clientOrganizationId,
    supplierOrganizationId,
    makersId,
    maker,
    partnumbers,
    /// attachment
    attachmentFileArray,
    attachmentTagArray,
    attachmentCommentArray,
    attachmentOriginArray,
    attachmentDateArray,
    attachmentUploaderArray,
    setattachmentDateArray,
    setattachmentUploaderArray,
    deletedFileIdArray,
    attachmentIdArray,
    setattachmentIdArray,
    setdeletedFileIdArray,
    attachmentTagOptionList,
    attachmentModifiedAtArray,
    setattachmentModifiedAtArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    setattachmentOriginArray,

    deletedAttachmentArray,
    setdeletedAttachmentArray,
    addedAttachmentArray,
    addedAttachmentTagArray,
    addedAttachmentCommentArray,
    setaddedAttachmentArray,
    setaddedAttachmentTagArray,
    setaddedAttachmentCommentArray,
    // route
    routeNumber,
    routeData,
    setrouteNumber,
    setrouteData,
    targetMember,
    settargetMember,
    members,
    setmembers,
    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // searchBox
    searchBoxType,
    setsearchBoxType,
    setsearchBoxProperty,
    deletemember,
    // etc
    initItemModule,
    isRevising,
    setisRevising,
    requiredPropertyList,
    setcurrentCategory,
  } = itemModuleStore();
  const itemStore = itemModuleStore();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [isClassificationBox, setisClassificationBox] = useState(false);
  const formRef = useRef();
  const params = useParams();
  const [isRouteActivate, setisRouteActivate] = useState(false);
  const [isReject, setisReject] = useState(false);
  const appendProjectFormData = () => {
    const formData = new FormData();
    // public
    formData.append("classification1Id", classification1);
    formData.append("classification2Id", classification2);
    formData.append("classification3Id", classification3);
    formData.append("name", itemName);
    formData.append("typeId", typeId);
    if (thumbnail.lastModified) {
      formData.append("thumbnail", thumbnail);
    }
    formData.append("customItemNumber", customItemNumber);

    formData.append("sharing", sharing);
    formData.append("carTypeId", carTypeId.id ? carTypeId.id : ""); // 확인 필요
    // classification
    formData.append("forming", forming);
    formData.append("curve", curve);
    formData.append("integrate", integrate);
    formData.append("width", width);
    formData.append("height", height);
    formData.append("thickness", thickness);
    formData.append("weight", weight);
    formData.append("importance", importance);
    formData.append("colorId", colorId.id ? colorId.id : ""); // 확인필
    formData.append("loadQuantity", loadQuantity);
    formData.append("coatingWayId", coatingWayId.id ? coatingWayId.id : ""); // check
    formData.append("coatingTypeId", coatingTypeId.id ? coatingTypeId.id : ""); // check
    formData.append("modulus", modulus);
    formData.append("screw", screw);
    formData.append("cuttingType", cuttingType.id ? cuttingType.id : "");
    formData.append("lcd", lcd);
    formData.append("displaySize", displaySize);
    formData.append("screwHeight", screwHeight);
    formData.append(
      "clientOrganizationId",
      clientOrganizationId.id ? clientOrganizationId.id : ""
    ); // check
    formData.append(
      "supplierOrganizationId",
      supplierOrganizationId.id ? supplierOrganizationId.id : ""
    );

    formData.append("makersId", makersId.id ? makersId.id : "");
    formData.append("partnumbers", partnumbers);
    // attachment
    addedAttachmentArray.forEach((file) => {
      formData.append("addedAttachments", file);
    });

    formData.append("addedAttachmentComment", attachmentCommentArray);
    formData.append(
      "addedTag",
      attachmentTagArray.map((item) => (item.id ? item.id : item))
    );
    formData.append("deletedAttachments", deletedAttachmentArray);
    return formData;
  };

  const saveItem = async () => {
    setisLoading(true);
    const formData = appendProjectFormData();
    try {
      let response;
      if (isRevising) {
        response = await tokenAxios.post(`/item/${params.itemId}`, formData);
      } else {
        response = await tokenAxios.put(`/item/temp/end/${itemId}`, formData);
      }

      setisLoading(false);
      setisRouteActivate(true);
      setisRevising(false);

      // route부분으로 스크롤
      setitemId(response.data.result.data.id);
      setrouteNumber(response.data.result.data.routeId);
    } catch (err) {
      if (err.response.data.result) {
        console.log(err.response);
        alert(err.response.data.result.msg);
      } else {
        console.log(err.response);
        navigate("/notFound");
      }
      setisLoading(false);
    }
  };

  const condition = requiredPropertyList.every((item) => {
    if (item === "sharing" && itemStore.sharing === "false") {
      return !!itemStore.carTypeId;
    }
    return !!itemStore[item];
  });
  const tempSave = async () => {
    setisLoading(true);
    const formData = appendProjectFormData();
    try {
      if (isRevising) {
        const response = await tokenAxios.post(
          `/item/temp/${params.itemId}`,
          formData
        );
      } else {
        const response = await tokenAxios.put(`/item/temp/${itemId}`, formData);
      }
      alert(text.approveDone);

      setisLoading(false);
    } catch (err) {
      if (err.response.data.result) {
        console.log(err.response);
        alert(err.response.data.result.msg);
      } else {
        console.log(err.response);
        navigate("/notFound");
      }
    }
  };

  const getData = async (itemIdd) => {
    const response = await tokenAxios.get(`item/${itemIdd}`);
    const { data } = response.data.result;
    // edit phase 확인
    setisRouteActivate(data.readonly);
    setisReject(data.preRejected);
    setitemInfo({
      itemClassification: data.classification,
      classification1: data.classification.classification.split("/")[0],
      classification2: data.classification.classification.split("/")[1],
      classification3: data.classification.classification.split("/")[2],
      itemName: data.name,
      itemId: data.id,
      itemNumber: data.itemNumber,
      customItemNumber: data.customItemNumber,
      itemRevision: data.itemRevision,

      typeId: data.itemTypes.id,
      thumbnail: data.thumbnail,
      sharing: data.sharing ? "true" : "false",
      carTypeId: data.carTypeId,
      forming: data.forming,
      curve: data.curve,
      integrate: data.integrate,
      width: data.width,
      height: data.height,
      thickness: data.thickness,
      weight: data.weight,
      importance: data.importance,
      colorId: data.colorId,
      loadQuantity: data.loadQuantity,
      coatingWayId: data.coatingWayId,
      coatingTypeId: data.coatingTypeId,
      modulus: data.modulus,
      screw: data.screw,
      cuttingType: data.cuttingType,
      lcd: data.lcd,
      displaySize: data.displaySize,
      screwHeight: data.screwHeight,
      clientOrganizationId: data.clientOrganizationId,
      supplierOrganizationId: data.supplierOrganizationId,
      makersId: data.makersId,
      partnumbers: data.partnumbers,
      // routeNumbers
      routeNumber: data.routeId,
    });
    // setrouteMember
    if (data.revise_progress) {
      const routeMembers = await tokenAxios.get(
        `route/members/${data.routeId}`
      );
      const routememberData = routeMembers.data.result.data;
      setmembers(
        routememberData.map((arr) => {
          return arr[0];
        })
      );
      setisRevising(true);
    }
    // attachment
    const tempfileArray = [];
    const temptagArray = [];
    const tempcommentArray = [];
    const temporiginArray = [];
    const tempuploaderArray = [];
    const tempdateArray = [];
    const tempidArray = [];
    const tempdeletedFileArray = [];
    const tempModifiedAtArray = [];

    data.attachments.forEach((item) => {
      tempfileArray.push(item.originName);
      tempcommentArray.push(item.attach_comment);
      temptagArray.push(item.tag);
      temporiginArray.push(item.attachmentaddress);
      tempuploaderArray.push(item.upload);
      tempdateArray.push(item.date);
      tempidArray.push(item.id);
      if (item.deleted) {
        tempdeletedFileArray.push(item.id);
      }
      tempModifiedAtArray.push(item.modifiedAt ? item.modifiedAt : "");
    });
    setattachmentFileArray(tempfileArray);
    setattachmentIdArray(tempidArray);
    setattachmentCommentArray(tempcommentArray);
    setattachmentTagArray(temptagArray);
    setattachmentOriginArray(temporiginArray);
    setattachmentDateArray(tempdateArray);
    setattachmentUploaderArray(tempuploaderArray);
    setdeletedFileIdArray(tempdeletedFileArray);
    setattachmentModifiedAtArray(tempModifiedAtArray);
  };
  useEffect(() => {
    initItemModule();
    setcurrentCategory("Add");
    getData(params.itemId);
    return () => {
      initItemModule();
    };
  }, []);
  return (
    <ItemEditPageStyle>
      <div className="tempSave">
        {!isRouteActivate && (
          <Button
            type="button"
            onClick={tempSave}
            backgroundColor="transparent"
            fontSize="12px"
            color="var(--textDarkGray)"
          >
            {text.saveAsDraft}{" "}
          </Button>
        )}
        <Button
          type="button"
          backgroundColor="transparent"
          color="var(--textDarkGray)"
          fontSize="12px"
          onClick={() => {
            navigate("/item");
          }}
        >
          <span className="material-icons">close</span>
        </Button>
      </div>
      <LoadingBox isLoading={isLoading}>
        <LoadingArrowAnimation />
        <BoldText fontSize="26px" color="var(--eciBlue)">
          S a v i n g
        </BoldText>
        <br />
        <br />
        Please wait while we set
        <br />
        thing up for you!
      </LoadingBox>

      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, text.item.itemInformation],
            [attachmentRef, text.item.itemAttachment],
            [routeRef, text.item.routeInformation],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>
      <FormSectionStyle ref={formRef}>
        <ItemInformationSection
          setisClassificationBox={setisClassificationBox}
          isClassificationBox={isClassificationBox}
          readOnly={isRouteActivate && !isRevising}
        />
        <AttachmentSection
          attachmentModifiedAtArray={attachmentModifiedAtArray}
          readOnly={isRouteActivate}
          attachmentFileArray={attachmentFileArray}
          setattachmentFileArray={setattachmentFileArray}
          attachmentTagArray={attachmentTagArray}
          setattachmentTagArray={setattachmentTagArray}
          attachmentCommentArray={attachmentCommentArray}
          setattachmentCommentArray={setattachmentCommentArray}
          attachmentDateArray={attachmentDateArray}
          attachmentUploaderArray={attachmentUploaderArray}
          setattachmentDateArray={setattachmentDateArray}
          setattachmentUploaderArray={setattachmentUploaderArray}
          attachmentOriginArray={attachmentOriginArray}
          setattachmentOriginArray={setattachmentOriginArray}
          setattachmentRef={setattachmentRef}
          title={text.item.itemAttachment}
          tagOptionList={attachmentTagOptionList}
          editMode
          attachmentIdArray={attachmentIdArray}
          setattachmentIdArray={setattachmentIdArray}
          deletedFileIds={deletedFileIdArray}
          deletedAttachmentArray={deletedAttachmentArray}
          setdeletedAttachmentArray={setdeletedAttachmentArray}
          addedAttachmentArray={addedAttachmentArray}
          addedAttachmentTagArray={addedAttachmentTagArray}
          addedAttachmentCommentArray={addedAttachmentCommentArray}
          setaddedAttachmentArray={setaddedAttachmentArray}
          setaddedAttachmentTagArray={setaddedAttachmentTagArray}
          setaddedAttachmentCommentArray={setaddedAttachmentCommentArray}
        />
        <div className="gap" />
        {isRouteActivate && !isRevising ? (
          ""
        ) : (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveItem}
            condition={condition}
          >
            {text.saveAndRoute}
          </Button>
        )}
        {isRouteActivate && isReject ? (
          <RouteSection
            activate={isRouteActivate || isReject}
            routeNumber={routeNumber}
            setrouteRef={setrouteRef}
            routeData={routeData}
            setrouteData={setrouteData}
            rejecting={isReject && !isRouteActivate}
          />
        ) : (
          <RouteInitSection
            activate={isRouteActivate && !isRevising}
            itemType={typeId}
            setrouteRef={setrouteRef}
            itemId={itemId}
            setsearchBoxType={setsearchBoxType}
            targetMember={targetMember}
            settargetMember={settargetMember}
            members={members}
          />
        )}
      </FormSectionStyle>
      <SearchBoxSectionStyle>
        <div className="searchBox">
          <ItemSearchBox
            width="100%"
            activate={isClassificationBox && !searchBoxType}
            setitemClassification={setitemClassification}
          />
        </div>
        <div className="searchBox">
          <SearchBox
            width="100%"
            height="100vh - 218px"
            type={searchBoxType}
            setproperty={setsearchBoxProperty}
            propertyIndex={targetMember}
            members={members}
            deletememberArray={deletemember}
          />
        </div>
      </SearchBoxSectionStyle>
    </ItemEditPageStyle>
  );
}
