import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import CRCOModuleStore from "../../store/CRCOModuleStore";

import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";

import RouteSection from "../RouteSection";
import AttachmentSection from "../AttachmentSection";
import COInformationSection from "../COInformationSection";
import txt from "../../Text";

const CRCODetailPageStyle = styled.div`
  display: flex;
  position: relative;
  .tempSave {
    height: 40px;

    position: absolute;
    top: -40px;
    left: calc(58% - 110px);
    display: flex;
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`;
const FormSectionStyle = styled.div`
  width: 58%;
  height: calc(100vh - 230px);
  overflow: scroll;
  padding-left: 18%;
  padding-right: 1.5%;
  padding-top: 20px;
  .gap {
    height: 45px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
`;
const SearchBoxSectionStyle = styled.div`
  width: 42%;
`;
function CODetailPage() {
  const { tokenAxios } = commonStore((state) => ({
    tokenAxios: state.tokenAxios,
  }));

  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const formRef = useRef();
  const {
    // coinfo

    setCOInfo,
    /// attachment
    attachmentFileArray,
    attachmentIdArray,
    attachmentTagArray,
    attachmentCommentArray,
    attachmentOriginArray,
    attachmentDateArray,
    attachmentUploaderArray,
    attachmentModifiedAtArray,
    setattachmentModifiedAtArray,
    setattachmentDateArray,
    setattachmentUploaderArray,
    setattachmentIdArray,
    setattachmentOriginArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    deletedFileIdArray,
    setdeletedFileIdArray,
    // route
    routeNumber,
    routeData,
    setrouteData,
    setrouteNumber,
    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // searchBox
    searchBoxType,
    setsearchBoxProperty,
    // etc
    isRouteActivate,
    setisRouteActivate,
    setcurrentCategory,
    initCOModule,
  } = CRCOModuleStore();

  const params = useParams();
  const getData = async (id) => {
    const response = await tokenAxios.get(`co/${id}`);
    const { data } = response.data.result; // 확인필
    // setCRInfo
    setCOInfo({
      COId: data.id,
      CONumber: data.coNumber,
      clientOrganizationId: data.clientOrganizationId,
      COClientNumber: data.clientItemNumber,
      COClientPublishDate: data.coPublishPeriod,
      CoClientReceiptDate: data.coReceivedPeriod,
      COisPastCar: data.difference,
      carTypeId: data.carTypeId,
      COisCostChange: data.costDifferent,
      COcost: data.costDifference,
      crReasonId: data.coReasonId,
      COReasonText: data.reason,
      COLevel: data.coStageId,
      COTiming: data.applyPeriod,
      COAffect: data.coEffectId.map((item) => item.id),
      COImportance: data.crImportanceId,
      COTitle: data.name,
      COBody: data.content,
      CORelatedCR: data.relatedCr,
      COAffectedItem: data.affectedItemList,
      changedFeatureId:
        data.affectedItemList &&
        data.affectedItemList.map((item) => item.changedFeature),
    });
    // route
    setrouteNumber(data.routeId);
    // attachment
    const tempfileArray = [];
    const temptagArray = [];
    const tempcommentArray = [];
    const temporiginArray = [];
    const tempuploaderArray = [];
    const tempdateArray = [];
    const tempidArray = [];
    const tempdeletedFileArray = [];
    const tempModifiedAtArray = [];

    if (data.attachments) {
      data.attachments.forEach((item) => {
        tempfileArray.push(item.originName);
        tempcommentArray.push(item.attach_comment);
        temptagArray.push(item.tag);
        temporiginArray.push(item.attachmentaddress);
        tempuploaderArray.push(item.upload);
        tempdateArray.push(item.date);
        tempidArray.push(item.id);
        if (item.deleted) {
          tempdeletedFileArray.push(item.id);
        }
        tempModifiedAtArray.push(item.modifiedAt ? item.modifiedAt : "");
      });
      setattachmentFileArray(tempfileArray);
      setattachmentIdArray(tempidArray);
      setattachmentCommentArray(tempcommentArray);
      setattachmentTagArray(temptagArray);
      setattachmentOriginArray(temporiginArray);
      setattachmentDateArray(tempdateArray);
      setattachmentUploaderArray(tempuploaderArray);
      setdeletedFileIdArray(tempdeletedFileArray);
      setattachmentModifiedAtArray(tempModifiedAtArray);
    }
  };
  useEffect(() => {
    setcurrentCategory("CO");
    getData(params.id);
    return () => {
      initCOModule();
    };
  }, []);

  return (
    <CRCODetailPageStyle>
      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, txt.crco.COInfo],
            [attachmentRef, txt.crco.COAttachment],
            [routeRef, txt.crco.routeInfo],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>
      <FormSectionStyle ref={formRef}>
        <COInformationSection readOnly />
        <AttachmentSection
          readOnly
          attachmentFileArray={attachmentFileArray}
          setattachmentFileArray={setattachmentFileArray}
          attachmentTagArray={attachmentTagArray}
          setattachmentTagArray={setattachmentTagArray}
          attachmentCommentArray={attachmentCommentArray}
          attachmentOriginArray={attachmentOriginArray}
          attachmentModifiedAtArray={attachmentModifiedAtArray}
          setattachmentOriginArray={setattachmentOriginArray}
          setattachmentCommentArray={setattachmentCommentArray}
          attachmentDateArray={attachmentDateArray}
          attachmentUploaderArray={attachmentUploaderArray}
          setattachmentDateArray={setattachmentDateArray}
          setattachmentUploaderArray={setattachmentUploaderArray}
          setattachmentRef={setattachmentRef}
          title={txt.crco.COAttachment}
          // edit
          attachmentIdArray={attachmentIdArray}
          setattachmentIdArray={setattachmentIdArray}
          deletedFileIds={deletedFileIdArray}
        />

        <div className="gap" />

        <RouteSection
          activate
          readOnly
          routeNumber={routeNumber}
          setrouteRef={setrouteRef}
          routeData={routeData}
          setrouteData={setrouteData}
        />
      </FormSectionStyle>
    </CRCODetailPageStyle>
  );
}
export default React.memo(CODetailPage);
