import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../atom/Button";
import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";
import LoadingBox from "../LoadingBox";
import LoadingArrowAnimation from "../../molecule/LoadingArrowAnimation";
import BoldText from "../../atom/BoldText";
import documentModuleStore from "../../store/DocumentModuleStore";
import AttachmentSection from "../AttachmentSection";
import DocumentInformationSection from "../DocumentInformationSection";
import ItemSearchBox from "../../molecule/ItemSearchBox";
import SearchBox from "../../molecule/SearchBox";
import RouteInitSection from "../RouteInitSection";
import RouteSection from "../RouteSection";
import txt from "../../Text";

const DocumentRevisePageStyle = styled.div`
  display: flex;
  position: relative;
  .tempSave {
    height: 40px;

    position: absolute;
    top: -40px;
    left: calc(58% - 110px);
    display: flex;
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`;
const FormSectionStyle = styled.div`
  width: 58%;
  height: ${(props) =>
    props.isClassificationBox ? "760px" : "calc(100vh - 200px)"};
  overflow: scroll;
  padding-left: 18%;
  padding-right: 1.5%;
  padding-top: 20px;
  .gap {
    height: 45px;
  }
  ::-webkit-scrollbar {
    height: 7px;
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
`;
const SearchBoxSectionStyle = styled.div`
  width: 42%;
  position: relative;
  padding-bottom: 30px;
  .searchBox {
    width: 100%;
  }
`;
export default function DocumentRevisePage() {
  const tokenAxios = commonStore((state) => state.tokenAxios);
  const {
    // dcument
    setdocumentInfo,

    documentId,
    setdocumentId,
    documentTag,
    documentContent,
    documentTitle,
    documentClassification,
    setdocumentClassification,
    classification1,
    classification2,
    setisRevise,
    /// attachment
    attachmentFileArray,
    attachmentTagArray,
    attachmentCommentArray,
    attachmentOriginArray,
    attachmentDateArray,
    attachmentUploaderArray,
    setattachmentDateArray,
    setattachmentUploaderArray,
    deletedFileIdArray,
    attachmentIdArray,
    setattachmentIdArray,
    setdeletedFileIdArray,
    attachmentTagOptionList,
    attachmentModifiedAtArray,
    setattachmentModifiedAtArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    setattachmentOriginArray,

    deletedAttachmentArray,
    setdeletedAttachmentArray,
    addedAttachmentArray,
    addedAttachmentTagArray,
    addedAttachmentCommentArray,
    setaddedAttachmentArray,
    setaddedAttachmentTagArray,
    setaddedAttachmentCommentArray,
    // route
    routeNumber,
    routeData,
    setrouteNumber,
    setrouteData,
    targetMember,
    settargetMember,
    members,
    setmembers,
    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // searchBox
    searchBoxType,
    setsearchBoxType,
    setsearchBoxProperty,
    deletemember,
    // etc
    initdocumentModule,
    setcurrentCategory,
  } = documentModuleStore();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [isClassificationBox, setisClassificationBox] = useState(false);
  const formRef = useRef();
  const [isRouteActivate, setisRouteActivate] = useState(false);
  const [isReject, setisReject] = useState(false);
  const params = useParams();

  const appendProjectFormData = () => {
    const formData = new FormData();
    formData.append("classification1Id", classification1);
    formData.append("classification2Id", classification2);
    formData.append("tagId", documentTag.id ? documentTag.id : "");
    formData.append("title", documentTitle);
    formData.append("content", documentContent);
    // attachment
    if (attachmentIdArray.length > 0) {
      formData.append(
        "duplicateTargetIds",
        attachmentIdArray.filter((item) => item)
      );
    }
    addedAttachmentArray.forEach((file) => {
      formData.append("addedAttachments", file); // 혹시몰라
    });
    formData.append("deletedAttachments", deletedAttachmentArray);

    return formData;
  };

  const saveItem = async () => {
    setisLoading(true);
    const formData = appendProjectFormData();
    try {
      const response = await tokenAxios.post(
        `/doc/${params.documentId}`,
        formData
      );
      setisLoading(false);
      setisRouteActivate(true);

      // route부분으로 스크롤
      setdocumentId(response.data.result.data.id);
    } catch (err) {
      if (err.response.data.result) {
        console.log(err.response);
        alert(err.response.data.result.msg);
      } else {
        console.log(err.response);
        navigate("/notFound");
      }
      setisLoading(true);
    }
  };

  const tempSave = async () => {
    setisLoading(true);
    const formData = appendProjectFormData();
    try {
      const response = await tokenAxios.post(
        `/doc/temp/${params.documentId}`,
        formData
      );
      alert(txt.approveDone);

      setisLoading(false);
    } catch (err) {
      if (err.response.data.result) {
        console.log(err.response);
        alert(err.response.data.result.msg);
      } else {
        console.log(err.response);
        navigate("/notFound");
      }
    }
  };
  const getdocumentData = useCallback(
    async (Id) => {
      const response = await tokenAxios.get(`doc/${Id}`);
      const { data } = response.data.result;
      setdocumentInfo({
        documentTag: data.tag, // {id,name}
        documentContent: data.documentContent,
        documentNumber: data.documentNumber,
        revision: data.revision,
        documentTitle: data.documentTitle,
        routeNumber: data.routeId,
        documentId: params.documentId,
      });
      setdocumentClassification(data.classification);

      // attachment
      const tempfileArray = [];
      const temptagArray = [];
      const tempcommentArray = [];
      const temporiginArray = [];
      const tempuploaderArray = [];
      const tempdateArray = [];
      const tempidArray = [];
      const tempdeletedFileArray = [];
      const tempModifiedAtArray = [];

      if (data.documentAttachmentDtos) {
        data.documentAttachmentDtos.forEach((item) => {
          tempfileArray.push(item.originName);
          tempcommentArray.push(item.attach_comment);
          temptagArray.push(item.tag);
          temporiginArray.push(item.attachmentaddress);
          tempuploaderArray.push(item.upload);
          tempdateArray.push(item.date);
          tempidArray.push(item.id);
          if (item.deleted) {
            tempdeletedFileArray.push(item.id);
          }
          tempModifiedAtArray.push(item.modifiedAt ? item.modifiedAt : "");
        });
        setattachmentFileArray(tempfileArray);
        setattachmentIdArray(tempidArray);
        setattachmentCommentArray(tempcommentArray);
        setattachmentTagArray(temptagArray);
        setattachmentOriginArray(temporiginArray);
        setattachmentDateArray(tempdateArray);
        setattachmentUploaderArray(tempuploaderArray);
        setdeletedFileIdArray(tempdeletedFileArray);
        setattachmentModifiedAtArray(tempModifiedAtArray);
      }
    },
    [tokenAxios]
  );
  useEffect(() => {
    setcurrentCategory("Add");
    getdocumentData(params.documentId);
    setisRevise(true);
    return () => {
      initdocumentModule();
    };
  }, []);
  return (
    <DocumentRevisePageStyle>
      <div className="tempSave">
        {!isRouteActivate && (
          <Button
            type="button"
            onClick={tempSave}
            backgroundColor="transparent"
            fontSize="12px"
            color="var(--textDarkGray)"
          >
            {txt.saveAsDraft}{" "}
          </Button>
        )}
      </div>
      <LoadingBox isLoading={isLoading}>
        <LoadingArrowAnimation />
        <BoldText fontSize="26px" color="var(--eciBlue)">
          S a v i n g
        </BoldText>
        <br />
        <br />
        Please wait while we set
        <br />
        thing up for you!
      </LoadingBox>

      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, txt.doc.docInfo],
            [routeRef, txt.doc.routeInfo],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>
      <FormSectionStyle ref={formRef} isClassificationBox={isClassificationBox}>
        <DocumentInformationSection
          setisClassificationBox={setisClassificationBox}
          isClassificationBox={isClassificationBox}
          readOnly={isRouteActivate}
        />
        <AttachmentSection
          oneLine
          attachmentModifiedAtArray={attachmentModifiedAtArray}
          readOnly={isRouteActivate}
          attachmentFileArray={attachmentFileArray}
          setattachmentFileArray={setattachmentFileArray}
          attachmentTagArray={attachmentTagArray}
          setattachmentTagArray={setattachmentTagArray}
          attachmentCommentArray={attachmentCommentArray}
          setattachmentCommentArray={setattachmentCommentArray}
          attachmentDateArray={attachmentDateArray}
          attachmentUploaderArray={attachmentUploaderArray}
          setattachmentDateArray={setattachmentDateArray}
          setattachmentUploaderArray={setattachmentUploaderArray}
          attachmentOriginArray={attachmentOriginArray}
          setattachmentOriginArray={setattachmentOriginArray}
          setattachmentRef={setattachmentRef}
          title={txt.doc.docAttachment}
          tagOptionList={attachmentTagOptionList}
          editMode
          attachmentIdArray={attachmentIdArray}
          setattachmentIdArray={setattachmentIdArray}
          deletedFileIds={deletedFileIdArray}
          deletedAttachmentArray={deletedAttachmentArray}
          setdeletedAttachmentArray={setdeletedAttachmentArray}
          addedAttachmentArray={addedAttachmentArray}
          addedAttachmentTagArray={addedAttachmentTagArray}
          addedAttachmentCommentArray={addedAttachmentCommentArray}
          setaddedAttachmentArray={setaddedAttachmentArray}
          setaddedAttachmentTagArray={setaddedAttachmentTagArray}
          setaddedAttachmentCommentArray={setaddedAttachmentCommentArray}
        />
        <div className="gap" />
        {isRouteActivate ? (
          ""
        ) : (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveItem}
            condition={!!classification1 && !!documentTitle}
          >
            {txt.saveAndRoute}{" "}
          </Button>
        )}
        {isReject ? (
          <RouteSection
            activate={isRouteActivate || isReject}
            routeNumber={routeNumber}
            setrouteRef={setrouteRef}
            routeData={routeData}
            setrouteData={setrouteData}
            rejecting={isReject && !isRouteActivate}
          />
        ) : (
          <RouteInitSection
            activate={isRouteActivate}
            itemType="document"
            setrouteRef={setrouteRef}
            itemId={documentId}
            setsearchBoxType={setsearchBoxType}
            targetMember={targetMember}
            settargetMember={settargetMember}
            members={members}
            url="/route/doc"
          />
        )}
      </FormSectionStyle>
      <SearchBoxSectionStyle>
        <div className="searchBox">
          <ItemSearchBox
            width="100%"
            activate={isClassificationBox && !searchBoxType}
            setitemClassification={setdocumentClassification}
            document
          />
        </div>
        <div className="searchBox">
          <SearchBox
            width="100%"
            height="100vh - 218px"
            type={searchBoxType}
            setproperty={setsearchBoxProperty}
            propertyIndex={targetMember}
            members={members}
            deletememberArray={deletemember}
          />
        </div>
      </SearchBoxSectionStyle>
    </DocumentRevisePageStyle>
  );
}
