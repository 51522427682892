import React from "react";
import styled from "styled-components";
import Input from "../atom/Input";

const PencilInputStyle = styled.div`
  pointer-events: ${(props) => props.readOnly && "none"};

  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height});
  display: flex;
  background-color: ${(props) => props.backgroundColor};
  .icon {
    height: calc(${(props) => props.height});
  }
`;
export default function PencilInput({
  width,
  height,
  state,
  setState,
  placeholder,
  backgroundColor,
  readOnly,
  required,
}) {
  return (
    <PencilInputStyle
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      readOnly={readOnly}
    >
      <span className="material-icons icon">edit</span>

      <Input
        width="100%"
        height={`${height} - 50%`}
        placeholder={placeholder}
        setState={setState}
        color="var(--eciBlue)"
        state={state}
      />
    </PencilInputStyle>
  );
}
