import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import projectModuleStore from "../../store/projectModuleStore";
import ProjectInformationSection from "../ProjectInformationSection";
import Input from "../../atom/Input";
import Button from "../../atom/Button";
import SearchBox from "../../molecule/SearchBox";
import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";
import LoadingBox from "../LoadingBox";
import LoadingArrowAnimation from "../../molecule/LoadingArrowAnimation";
import BoldText from "../../atom/BoldText";
import AttachmentSection from "../AttachmentSection";
import resultText from "../../Text";
import RouteInitSection from "../RouteInitSection";

const ProjectAddSectionStyle = styled.div`
  display: flex;
  position: relative;
  .tempSave {
    height: 40px;

    position: absolute;
    top: -40px;
    left: calc(58% - 110px);
    display: flex;
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`;
const FormSectionStyle = styled.div`
  width: 58%;
  height: calc(100vh - 230px);
  overflow: scroll;
  padding-left: 18%;
  padding-right: 1.5%;
  padding-top: 20px;
  .gap {
    height: 45px;
  }
  ::-webkit-scrollbar {
    height: 7px;
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
`;
const SearchBoxSectionStyle = styled.div`
  width: 42%;
`;
function ProjectAddSection() {
  const { tokenAxios } = commonStore((state) => ({
    tokenAxios: state.tokenAxios,
  }));

  const {
    // project
    projectName,
    setprojectName,
    protoStartPeriod,
    protoOverPeriod,
    p1StartPeriod,
    p1OverPeriod,
    p2StartPeriod,
    p2OverPeriod,
    sopStartPeriod,
    sopOverPeriod,
    linkedItemList,
    projectType,
    projectLevel,
    supplierId,
    carTypeId,
    clientOrganizationId,
    clientItemNumber,
    // // item
    // itemName,
    // itemNumber,
    // itemClassification,
    // itemRevision,
    // itemStatus,
    // setitemInfo,
    projectId,
    setprojectId,
    initprojectModule,
    // attachment
    attachmentFileArray,
    attachmentTagArray,
    attachmentCommentArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    // seaechbox
    searchBoxType,
    setsearchBoxProperty,
    // route
    routeNumber,
    routeData,
    setrouteData,
    setrouteNumber,
    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // etc
    isRouteActivate,
    setisRouteActivate,
    setcurrentCategory,
  } = projectModuleStore((state) => ({
    // project
    projectName: state.projectName,
    setprojectName: state.setprojectName,
    protoStartPeriod: state.protoStartPeriod,
    protoOverPeriod: state.protoOverPeriod,
    p1StartPeriod: state.p1StartPeriod,
    p1OverPeriod: state.p1OverPeriod,
    p2StartPeriod: state.p2StartPeriod,
    p2OverPeriod: state.p2OverPeriod,
    sopStartPeriod: state.sopStartPeriod,
    sopOverPeriod: state.sopOverPeriod,
    linkedItemList: state.linkedItemList,
    projectType: state.projectType,
    projectLevel: state.projectLevel,
    supplierId: state.supplierId,
    carTypeId: state.carTypeId,
    clientOrganizationId: state.clientOrganizationId,
    clientItemNumber: state.clientItemNumber,
    initprojectModule: state.initprojectModule,

    // // item
    // itemName: state.itemName,
    // itemNumber: state.itemNumber,
    // itemClassification: state.itemClassification,
    // itemRevision: state.itemRevision,
    // itemStatus: state.itemStatus,
    // setitemInfo: state.setitemInfo,
    // attachment
    projectId: state.projectId,
    setprojectId: state.setprojectId,
    attachmentFileArray: state.attachmentFileArray,
    attachmentTagArray: state.attachmentTagArray,
    attachmentCommentArray: state.attachmentCommentArray,
    setattachmentFileArray: state.setattachmentFileArray,
    setattachmentTagArray: state.setattachmentTagArray,
    setattachmentCommentArray: state.setattachmentCommentArray,
    // seaechbox
    searchBoxType: state.searchBoxType,
    setsearchBoxProperty: state.setsearchBoxProperty,
    // route
    routeNumber: state.routeNumber,
    routeData: state.routeData,
    setrouteData: state.setrouteData,
    setrouteNumber: state.setrouteNumber,
    // ref
    informationRef: state.informationRef,
    attachmentRef: state.attachmentRef,
    routeRef: state.routeRef,
    setattachmentRef: state.setattachmentRef,
    setrouteRef: state.setrouteRef,
    // etc
    isRouteActivate: state.isRouteActivate,
    setisRouteActivate: state.setisRouteActivate,
    setcurrentCategory: state.setcurrentCategory,
  }));
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [isFirstTempId, setisFirstTempId] = useState(false);
  const formRef = useRef();
  const appendProjectFormData = () => {
    const formData = new FormData();
    formData.append("name", projectName);
    formData.append("projectTypeId", projectType);
    // date
    formData.append("protoStartPeriod", protoStartPeriod);
    formData.append("protoOverPeriod", protoOverPeriod);
    formData.append("p1StartPeriod", p1StartPeriod);
    formData.append("p1OverPeriod", p1OverPeriod);
    formData.append("p2StartPeriod", p2StartPeriod);
    formData.append("p2OverPeriod", p2OverPeriod);
    formData.append("sopStartPeriod", sopStartPeriod);
    formData.append("sopOverPeriod", sopOverPeriod);

    formData.append(
      "itemId",
      linkedItemList.map((itme) => itme.itemId)
    );
    attachmentFileArray.forEach((file) => {
      formData.append("attachments", file);
    });

    formData.append("attachmentComment", attachmentCommentArray);
    formData.append("tag", attachmentTagArray);
    formData.append(
      "projectLevelId",
      projectLevel && projectLevel.id ? projectLevel.id : ""
    );
    formData.append(
      "clientOrganizationId",
      clientOrganizationId && clientOrganizationId.id
        ? clientOrganizationId.id
        : ""
    );
    formData.append(
      "supplierId",
      supplierId && supplierId.id ? supplierId.id : ""
    );
    formData.append("carTypeId", carTypeId && carTypeId.id ? carTypeId.id : "");
    formData.append("clientItemNumber", clientItemNumber);

    return formData;
  };

  const saveProject = async () => {
    setisLoading(true);
    const formData = appendProjectFormData();
    try {
      let response = "";
      if (!isFirstTempId) {
        response = await tokenAxios.post("/project", formData);
      } else {
        response = await tokenAxios.put(`/project/temp/end`, formData);
      }
      // const response = await tokenAxios.post("/project", formData);
      setisRouteActivate(true);
      // route부분으로 스크롤
      // setrouteNumber(response.data.result.data.routeId);
      setprojectId(response.data.result.data.id);
      setisLoading(false);
    } catch (err) {
      navigate("/notFound");
      setisRouteActivate(false);
      setisLoading(false);
    }
  };
  const tempSave = async () => {
    setisLoading(true);
    const formData = appendProjectFormData();
    try {
      if (isFirstTempId) {
        const response = await tokenAxios.put(
          `/project/temp/${isFirstTempId}`,
          formData
        );
      } else {
        const response = await tokenAxios.post("/project/temp", formData);
        setisFirstTempId(response.data.result.data.id);
      }
      alert(resultText.approveDone);

      setisLoading(false);
    } catch (err) {
      if (err.response.data.result) {
        console.log(err.response);
        alert(err.response.data.result.msg);
      } else {
        console.log(err.response);
        navigate("/notFound");
      }
    }
  };

  const params = useParams();

  useEffect(() => {
    setcurrentCategory("Add");

    return () => {
      initprojectModule();
    };
  }, [setcurrentCategory, initprojectModule]);

  return (
    <ProjectAddSectionStyle>
      <div className="tempSave">
        {!isRouteActivate && (
          <Button
            type="button"
            onClick={tempSave}
            backgroundColor="transparent"
            fontSize="12px"
            color="var(--textDarkGray)"
          >
            {resultText.saveAsDraft}
          </Button>
        )}
        <Button
          type="button"
          backgroundColor="transparent"
          color="var(--textDarkGray)"
          fontSize="12px"
          onClick={() => {
            navigate("/project");
          }}
        >
          <span className="material-icons">close</span>
        </Button>
      </div>
      <LoadingBox isLoading={isLoading}>
        <LoadingArrowAnimation />
        <BoldText fontSize="26px" color="var(--eciBlue)">
          S a v i n g
        </BoldText>
        <br />
        <br />
        Please wait while we set
        <br />
        thing up for you!
      </LoadingBox>
      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, resultText.project.projectInformation],
            [attachmentRef, resultText.project.projectAttachment],
            [routeRef, resultText.project.routeInformation],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>
      <FormSectionStyle ref={formRef}>
        <Input
          height="40px"
          placeholder={resultText.project.typeProjectName}
          state={projectName}
          setState={setprojectName}
        />

        <ProjectInformationSection readOnly={isRouteActivate} />

        <AttachmentSection
          readOnly={isRouteActivate}
          attachmentFileArray={attachmentFileArray}
          setattachmentFileArray={setattachmentFileArray}
          attachmentTagArray={attachmentTagArray}
          setattachmentTagArray={setattachmentTagArray}
          attachmentCommentArray={attachmentCommentArray}
          setattachmentCommentArray={setattachmentCommentArray}
          setattachmentRef={setattachmentRef}
          title={resultText.project.projectAttachment}
          tagOptionList={[
            { id: "4", name: resultText.project.devStat },
            { id: "5", name: resultText.project.clientData },
            { id: "1", name: resultText.etc }, // 나중에 확인
          ]}
          deletedFileIds={[]}
          attachmentIdArray={[]}
          attachmentOriginArray={[]}
          setattachmentIdArray={() => {}}
          setattachmentOriginArray={() => {}}
          attachmentUploaderArray={[]}
          setattachmentUploaderArray={() => {}}
          attachmentDateArray={[]}
          setattachmentDateArray={() => {}}
        />

        <div className="gap" />
        {isRouteActivate ? (
          ""
        ) : (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveProject}
            condition={
              !!projectName &&
              linkedItemList.length > 0 &&
              !!projectLevel &&
              !!carTypeId &&
              !!projectType
            }
          >
            {resultText.saveAndRoute}
          </Button>
        )}

        {/* <RouteSection
          activate={isRouteActivate}
          routeNumber={routeNumber}
          setrouteRef={setrouteRef}
          routeData={routeData}
          setrouteData={setrouteData}
        /> */}
        <RouteInitSection
          activate={isRouteActivate}
          itemType="project"
          setrouteRef={setrouteRef}
          itemId={projectId}
          // setsearchBoxType={setsearchBoxType}
          // targetMember={targetMember}
          // settargetMember={settargetMember}
          // members={members}
        />
      </FormSectionStyle>
      <SearchBoxSectionStyle>
        <SearchBox
          width="100%"
          height="100vh - 218px"
          type={searchBoxType}
          setproperty={setsearchBoxProperty}
        />
      </SearchBoxSectionStyle>
    </ProjectAddSectionStyle>
  );
}
export default React.memo(ProjectAddSection);
