import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import CRCOModuleStore from "../../store/CRCOModuleStore";
import Button from "../../atom/Button";
import SearchBox from "../../molecule/SearchBox";
import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";
import LoadingBox from "../LoadingBox";
import LoadingArrowAnimation from "../../molecule/LoadingArrowAnimation";
import BoldText from "../../atom/BoldText";
import RouteSection from "../RouteSection";
import AttachmentSection from "../AttachmentSection";
import COInformationSection from "../COInformationSection";
import RouteInitSection from "../RouteInitSection";
import txt from "../../Text";

const CRCOEditPageStyle = styled.div`
  display: flex;
  position: relative;
  .tempSave {
    height: 40px;

    position: absolute;
    top: -40px;
    left: calc(58% - 110px);
    display: flex;
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`;
const FormSectionStyle = styled.div`
  width: 58%;
  height: calc(100vh - 230px);
  overflow: scroll;
  padding-left: 18%;
  padding-right: 1.5%;
  padding-top: 20px;
  .gap {
    height: 45px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
`;
const SearchBoxSectionStyle = styled.div`
  width: 42%;
`;
function COEditPage() {
  const { tokenAxios } = commonStore((state) => ({
    tokenAxios: state.tokenAxios,
  }));

  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [isReject, setisReject] = useState(false);

  const formRef = useRef();
  const {
    // coinfo
    // coinfo
    COId,
    CONumber,
    clientOrganizationId,
    COClientNumber,
    COClientPublishDate,
    CoClientReceiptDate,
    COisPastCar,
    carTypeId,
    COisCostChange,
    COcost,
    crReasonId,
    COReasonText,
    COLevel,
    COTiming,
    COAffect,
    COImportance,
    COTitle,
    COBody,
    CORelatedCR,
    COAffectedItem,
    changedFeatureId,
    changedFeatureIndex,
    setCOInfo,
    /// attachment
    attachmentFileArray,
    attachmentIdArray,
    attachmentTagArray,
    attachmentCommentArray,
    attachmentOriginArray,
    attachmentDateArray,
    attachmentUploaderArray,
    attachmentModifiedAtArray,
    setattachmentModifiedAtArray,
    setattachmentDateArray,
    setattachmentUploaderArray,
    setattachmentIdArray,
    setattachmentOriginArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    deletedFileIdArray,
    setdeletedFileIdArray,
    deletedAttachmentArray,
    setdeletedAttachmentArray,
    addedAttachmentArray,
    addedAttachmentTagArray,
    addedAttachmentCommentArray,
    setaddedAttachmentArray,
    setaddedAttachmentTagArray,
    setaddedAttachmentCommentArray,
    // route
    routeNumber,
    routeData,
    setrouteData,
    setrouteNumber,
    targetMember,
    settargetMember,
    members,
    setsearchBoxType,
    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // searchBox
    searchBoxType,
    setsearchBoxProperty,
    deletemember,
    // etc
    isRouteActivate,
    setisRouteActivate,
    setcurrentCategory,
    initCOModule,
  } = CRCOModuleStore();

  const appendCOFormData = () => {
    const formData = new FormData();
    formData.append(
      "clientOrganizationId",
      clientOrganizationId.id ? clientOrganizationId.id : ""
    );
    formData.append("clientItemNumber", COClientNumber);
    formData.append("coPublishPeriod", COClientPublishDate || "");
    formData.append("coReceivedPeriod", CoClientReceiptDate || "");
    formData.append("difference", COisPastCar);
    if (carTypeId && carTypeId.id) {
      formData.append("carTypeId", carTypeId.id ? carTypeId.id : "");
    }
    formData.append("costDifferent", COisCostChange);
    formData.append("costDifference", COisCostChange === "true" ? COcost : 0);
    formData.append("coReasonId", crReasonId.id ? crReasonId.id : "");
    formData.append("coReason", crReasonId.id === "1" ? COReasonText : "");
    if (typeof COLevel === "string" ? COLevel : COLevel.id) {
      formData.append(
        "coStageId",
        typeof COLevel === "string" ? COLevel : COLevel.id
      );
    }

    formData.append("applyPeriod", COTiming || "");
    formData.append("coEffectId", COAffect);
    if (typeof COImportance === "string" ? COImportance : COImportance.id) {
      formData.append(
        "coImportanceId",
        typeof COImportance === "string" ? COImportance : COImportance.id
      );
    }

    formData.append("name", COTitle);
    formData.append("content", COBody);
    formData.append(
      "changeRequestIds",
      CORelatedCR.map((item) => item.id)
    );
    // affected item
    formData.append(
      "newItemsIds",
      COAffectedItem.map((item) => item.itemId)
    );
    formData.append(
      "changeContents",
      COAffectedItem.map((item) => item.changedContent)
    );
    formData.append(
      "changeFeaturesIds",
      changedFeatureId && changedFeatureId.map((item) => item.id)
    );

    // attachment
    addedAttachmentArray.forEach((file) => {
      formData.append("addedAttachments", file);
    });

    formData.append("addedAttachmentComment", attachmentCommentArray);
    formData.append(
      "addedTag",
      attachmentTagArray.map((item) => (item.id ? item.id : item))
    );
    formData.append("deletedAttachments", deletedAttachmentArray);
    return formData;
  };
  const params = useParams();

  const saveCO = async () => {
    setisLoading(true);
    const formData = appendCOFormData();
    try {
      const response = await tokenAxios.put(
        `/co/temp/end/${params.id}`,
        formData
      );
      setisRouteActivate(true);
      setrouteNumber(response.data.result.data.routeId);
      setisLoading(false);
    } catch (err) {
      setisRouteActivate(false);
    }
  };

  const tempSave = () => {
    setisLoading(true);
    const formData = appendCOFormData();

    tokenAxios.put(`/co/temp/${params.id}`, formData).then((res) => {
      setTimeout(() => {
        setisLoading(false);
        alert(txt.approveDone);
      }, 1000);
    });
  };
  const getData = async (id) => {
    const response = await tokenAxios.get(`co/${id}`);
    const { data } = response.data.result; // 확인필
    // setCRInfo
    setisRouteActivate(data.readonly);
    setisReject(data.preRejected);
    setCOInfo({
      COId: data.id,
      CONumber: data.coNumber,
      clientOrganizationId: data.clientOrganizationId,
      COClientNumber: data.clientItemNumber,
      COClientPublishDate: data.coPublishPeriod,
      CoClientReceiptDate: data.coReceivedPeriod,
      COisPastCar: data.difference,
      carTypeId: data.carTypeId,
      COisCostChange: data.costDifferent,
      COcost: data.costDifference,
      crReasonId: data.coReasonId,
      COReasonText: data.reason,
      COLevel: data.coStageId,
      COTiming: data.applyPeriod,
      COAffect: data.coEffectId.map((item) => item.id),
      COImportance: data.crImportanceId,
      COTitle: data.name,
      COBody: data.content,
      CORelatedCR: data.relatedCr,
      COAffectedItem: data.affectedItemList,
      changedFeatureId:
        data.affectedItemList &&
        data.affectedItemList.map((item) => item.changedFeature),
    });
    // route
    setrouteNumber(data.routeId);
    // attachment
    const tempfileArray = [];
    const temptagArray = [];
    const tempcommentArray = [];
    const temporiginArray = [];
    const tempuploaderArray = [];
    const tempdateArray = [];
    const tempidArray = [];
    const tempdeletedFileArray = [];
    const tempModifiedAtArray = [];

    if (data.attachments) {
      data.attachments.forEach((item) => {
        tempfileArray.push(item.originName);
        tempcommentArray.push(item.attach_comment);
        temptagArray.push(item.tag);
        temporiginArray.push(item.attachmentaddress);
        tempuploaderArray.push(item.upload);
        tempdateArray.push(item.date);
        tempidArray.push(item.id);
        if (item.deleted) {
          tempdeletedFileArray.push(item.id);
        }
        tempModifiedAtArray.push(item.modifiedAt ? item.modifiedAt : "");
      });
      setattachmentFileArray(tempfileArray);
      setattachmentIdArray(tempidArray);
      setattachmentCommentArray(tempcommentArray);
      setattachmentTagArray(temptagArray);
      setattachmentOriginArray(temporiginArray);
      setattachmentDateArray(tempdateArray);
      setattachmentUploaderArray(tempuploaderArray);
      setdeletedFileIdArray(tempdeletedFileArray);
      setattachmentModifiedAtArray(tempModifiedAtArray);
    }
  };

  useEffect(() => {
    setcurrentCategory("CO");
    getData(params.id);
    return () => {
      initCOModule();
    };
  }, []);

  return (
    <CRCOEditPageStyle>
      <div className="tempSave">
        {!isRouteActivate && (
          <Button
            type="button"
            onClick={tempSave}
            backgroundColor="transparent"
            fontSize="12px"
            color="var(--textDarkGray)"
          >
            {txt.saveAsDraft}{" "}
          </Button>
        )}
        <Button
          type="button"
          backgroundColor="transparent"
          color="var(--textDarkGray)"
          fontSize="12px"
          onClick={() => {
            navigate("/C/O");
          }}
        >
          <span className="material-icons">close</span>
        </Button>
      </div>
      <LoadingBox isLoading={isLoading}>
        <LoadingArrowAnimation />
        <BoldText fontSize="26px" color="var(--eciBlue)">
          S a v i n g
        </BoldText>
        <br />
        <br />
        Please wait while we set
        <br />
        thing up for you!
      </LoadingBox>
      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, txt.crco.COInfo],
            [attachmentRef, txt.crco.COAttachment],
            [routeRef, txt.crco.routeInfo],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>
      <FormSectionStyle ref={formRef}>
        <COInformationSection readOnly={isRouteActivate} />

        <AttachmentSection
          attachmentModifiedAtArray={attachmentModifiedAtArray}
          readOnly={isRouteActivate}
          attachmentFileArray={attachmentFileArray}
          setattachmentFileArray={setattachmentFileArray}
          attachmentTagArray={attachmentTagArray}
          setattachmentTagArray={setattachmentTagArray}
          attachmentCommentArray={attachmentCommentArray}
          setattachmentCommentArray={setattachmentCommentArray}
          attachmentDateArray={attachmentDateArray}
          attachmentUploaderArray={attachmentUploaderArray}
          setattachmentDateArray={setattachmentDateArray}
          setattachmentUploaderArray={setattachmentUploaderArray}
          attachmentOriginArray={attachmentOriginArray}
          setattachmentOriginArray={setattachmentOriginArray}
          setattachmentRef={setattachmentRef}
          editMode
          attachmentIdArray={attachmentIdArray}
          setattachmentIdArray={setattachmentIdArray}
          deletedFileIds={deletedFileIdArray}
          deletedAttachmentArray={deletedAttachmentArray}
          setdeletedAttachmentArray={setdeletedAttachmentArray}
          addedAttachmentArray={addedAttachmentArray}
          addedAttachmentTagArray={addedAttachmentTagArray}
          addedAttachmentCommentArray={addedAttachmentCommentArray}
          setaddedAttachmentArray={setaddedAttachmentArray}
          setaddedAttachmentTagArray={setaddedAttachmentTagArray}
          setaddedAttachmentCommentArray={setaddedAttachmentCommentArray}
          title={txt.crco.COAttachment}
          tagOptionList={txt.crco.coTagOption}
        />

        <div className="gap" />
        {isRouteActivate ? (
          ""
        ) : (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveCO}
            condition={
              !!COisPastCar &&
              !!crReasonId &&
              !!COAffect.length > 0 &&
              !!(COImportance.id || typeof COImportance === "string") &&
              !!COTitle &&
              !!(COAffectedItem.length > 0) &&
              !!COLevel &&
              !!clientOrganizationId
            }
          >
            {txt.saveAndRoute}{" "}
          </Button>
        )}

        {isReject ? (
          <RouteSection
            activate={isRouteActivate || isReject}
            routeNumber={routeNumber}
            setrouteRef={setrouteRef}
            routeData={routeData}
            setrouteData={setrouteData}
            rejecting={isReject && !isRouteActivate}
          />
        ) : (
          <RouteInitSection
            activate={isRouteActivate}
            itemType="CO"
            setrouteRef={setrouteRef}
            itemId={params.id}
            setsearchBoxType={setsearchBoxType}
            targetMember={targetMember}
            settargetMember={settargetMember}
            members={members}
            url="/route/co"
          />
        )}
      </FormSectionStyle>
      <SearchBoxSectionStyle>
        <SearchBox
          width="100%"
          height="100vh - 218px"
          type={searchBoxType}
          setproperty={setsearchBoxProperty}
          propertyIndex={
            searchBoxType === "members" ? targetMember : changedFeatureIndex
          }
          members={members}
          deletememberArray={deletemember}
        />
      </SearchBoxSectionStyle>
    </CRCOEditPageStyle>
  );
}
export default React.memo(COEditPage);
