import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import BoldText from "../../atom/BoldText";
import Button from "../../atom/Button";
import RoundTextBox from "../../atom/RoundTextBox";
import TableIndexRow from "../../atom/TableIndexRow";
import TableRow from "../../atom/TableRow";
import BOMNumberTree from "../../molecule/BOMNumberTree";
import BOMPropTree from "../../molecule/BOMPropTree";
import PaginationTable from "../../molecule/PaginationTable";
import SearchBar from "../../molecule/SearchBar";
import BOMModuleStore from "../../store/BOMModuleStore";
import commonStore from "../../store/commonStore";
import ModalBox from "../ModalBox";
import text from "../../Text";
import FilterSearchBox from "../../molecule/FilterSearchBox";

const PreliminaryBOMStyle = styled.div`
  width: 100%;
  padding-left: 30px;
  .top {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: space-between;
  }
  .index {
    margin-left: 30px;
  }
  .treeBox {
    width: calc(100% + 85px);
    display: flex;
    transform: translatex(-60px);
    .numberBox {
      width: 28%;
      overflow-x: scroll;

      overflow-y: visible;
      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      ::-webkit-scrollbar-track {
        background-color: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        border: 15px solid transparent;
        width: 5px;
        background: rgba(4, 8, 15, 0.3);
        border-radius: 8px;
      }
    }
    .propBox {
      width: 72%;
    }
  }
`;
export default function PreliminaryBOM() {
  const tokenAxios = commonStore((state) => state.tokenAxios);
  const {
    preliminaryId,
    developId,

    preliminaryType,
    preliminaryTree,
    setpreliminaryTree,
    setpreliminaryTreeState,
    addpreliminaryTreeState,
    searchBoxIndexArray,
    setsearchBoxIndexArray,
    setpreliminaryTreeRow,
    addpreliminaryFoldedRowIndex,
    deletepreliminaryFoldedRowIndex,
    deletepreliminaryTreeState,
    preliminaryFoldedRowIndex,
  } = BOMModuleStore();
  const getData = async () => {
    const response = await tokenAxios.get(`/preliminary/${preliminaryId}`);
    setpreliminaryTree(JSON.parse(response.data.result.data.info));
  };
  const navigate = useNavigate();
  const save = async () => {
    const formdata = new FormData();
    formdata.append("content", JSON.stringify(preliminaryTree));
    formdata.append("preliminaryId", preliminaryId);
    try {
      const response = await tokenAxios.post("preliminary", formdata);
      alert("저장 완료");
    } catch (err) {
      if (err.response.data.result) {
        console.log(err.response);
        alert(err.response.data.result.msg);
      } else {
        console.log(err.response);
        navigate("/notFound");
      }
    }
  };

  // Modal
  const [searchText, setsearchText] = useState("");
  const [itemName, setitemName] = useState("");
  const [itemNumber, setitemNumber] = useState("");
  const [carType, setcarType] = useState("");
  const [carTypeArr, setcarTypeArr] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [pageNum, setPageNum] = useState("0");
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [dataList, setdataList] = useState([]);
  const rows =
    dataList &&
    dataList[0] &&
    dataList[0].map((item, i) => {
      return (
        <TableRow
          key={i}
          widthArray={[0.2, 1, 1, 1, 1, 1]}
          itemArray={[
            "",
            item && item.cardNumber,
            item && item.classification,
            item && item.cardName,
            item && item.cardType && item.cardType,
            item && item.sharing === "공용" ? (
              <RoundTextBox
                width="50%"
                backgroundColor="var(--lightGray)"
                color="var(--eciBlue)"
                height="30px"
              >
                {text.item.sharing}
              </RoundTextBox>
            ) : (
              <RoundTextBox
                width="50%"
                backgroundColor="var(--lightGray)"
                color="var(--eciBlue)"
                height="30px"
              >
                {text.item.notSharing}
              </RoundTextBox>
            ),
          ]}
          onClick={() => {
            setpreliminaryTreeRow(
              {
                classification: item && item.classification,
                cardName: item && item.cardName,
                cardType: item && item.cardType && item.cardType,
                cardNumber: item && item.cardNumber,
                sharing: item && item.sharing,
                children: item && item.children,
                cardId: item && item.cardId,
                plusPossible: item && item.plusPossible,
              },
              ...searchBoxIndexArray
            );
            setsearchBoxIndexArray([]);
          }}
        />
      );
    });
  const getModalData = async () => {
    if (preliminaryId) {
      const response = await tokenAxios.get(`/dev/bom/items/page/${developId}`);
      setdataList([response.data.content]);
      setTotalPage(response.data.totalPages);
    }
  };
  // 모달 끝
  useEffect(() => {
    if (preliminaryId) {
      getData();
      getModalData();
    }
  }, [preliminaryId]);
  useEffect(() => {
    getModalData();
  }, [searchText, itemName, itemNumber, carType, pageNum, rowsPerPage]);
  return (
    <PreliminaryBOMStyle>
      <ModalBox
        isActivate={searchBoxIndexArray.length > 0}
        setisActivate={(bool) => {
          if (!bool) {
            setsearchBoxIndexArray([]);
          }
        }}
      >
        <FilterSearchBox
          searchText={searchText}
          setsearchText={setsearchText}
          setitemName={setitemName}
          setitemNumber={setitemNumber}
          setcarType={setcarType}
        />{" "}
        <br />
        <br />
        <PaginationTable
          minHeight="480px"
          totalPage={totalPage}
          setRowsPerPage={setrowsPerPage}
          rowsPerPageDefault={7}
          pageNum={pageNum}
          rowsPerPage={rowsPerPage}
          setpageNum={setPageNum}
        >
          <TableIndexRow
            widthArray={[1, 1, 1, 1, 1]}
            itemArray={text.bom.prelIndexArray}
          />
          {rows}
        </PaginationTable>
      </ModalBox>
      <div className="top">
        <BoldText fontSize="14px" color="var(--eciBlue)">
          {text.bom.bomList}
        </BoldText>
        <Button
          type="button"
          onClick={save}
          backgroundColor="transparent"
          fontSize="12px"
          color="var(--textDarkGray)"
        >
          Save
        </Button>
      </div>

      <div className="index">
        <TableIndexRow
          itemArray={[
            "ItemNumber",
            "Item Name",
            "Item Classification",
            "Item Type",
            "공용유무",
          ]}
          widthArray={[6, 4, 4, 4, 4]}
          backgroundColor="white"
        />
        <div className="treeBox">
          <div className="numberBox">
            <BOMNumberTree
              data={preliminaryTree}
              indent="30px"
              indexArray={[0]}
              readOnly={preliminaryType !== "add"}
              setTreeState={setpreliminaryTreeState}
              addTreeState={addpreliminaryTreeState}
              setfold={addpreliminaryFoldedRowIndex}
              deletefold={deletepreliminaryFoldedRowIndex}
            />
          </div>
          <div className="propBox">
            <BOMPropTree
              data={preliminaryTree}
              indexArray={[0]}
              readOnly={preliminaryType !== "add"}
              foldedArray={preliminaryFoldedRowIndex}
              onDelete={deletepreliminaryTreeState}
            />
          </div>
        </div>
      </div>
    </PreliminaryBOMStyle>
  );
}
