import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import BoldText from "../../atom/BoldText";
import Button from "../../atom/Button";

import LoadingArrowAnimation from "../../molecule/LoadingArrowAnimation";
import ScrollController from "../../molecule/ScrollController";
import commonStore from "../../store/commonStore";
import designModuleStore from "../../store/DesignModuleStore";
import AttachmentSection from "../AttachmentSection";
import DesignInformationSection from "../DesignInformationSection";
import LoadingBox from "../LoadingBox";
import RouteSection from "../RouteSection";
import resultText from "../../Text";

const DesignEditPageStyle = styled.div`
  display: flex;
  position: relative;
  .tempSave {
    height: 40px;

    position: absolute;
    top: -40px;
    left: calc(58% - 110px);
    display: flex;
  }
  .retry {
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
    text-decoration: none;
    color: var(--textDarkGray);
  }
  padding-top: 20px;
  .x {
    width: 100%;
    cursor: pointer;
    font-size: 24px;
    text-align: end;
    color: black;
    padding-top: 12px;
    padding-right: 18px;
    font-weight: 600;
  }
`;
const ScrollControllerStyle = styled.div`
  position: absolute;
  left: 0;
`;
const FormSectionStyle = styled.div`
  padding-left: 18%;
  padding-right: 1.5%;

  width: 100%;
  height: calc(100vh - 280px);
  overflow-y: scroll;
  overflow-x: visible;
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }

  .gap {
    height: 45px;
  }
  .att {
    width: 50%;
  }
`;

export default function DesignEditPage() {
  const { tokenAxios } = commonStore();

  const {
    designId,
    setdesignId,
    setcadFileList,
    cadFileList,
    // item
    designItemId,
    setcadFile,
    setdesignItemInfo,
    setisMatchConfirmed,
    uploaderType,
    setuploaderType,
    // attachment
    attachmentFileArray,
    attachmentTagArray,
    attachmentCommentArray,
    attachmentOriginArray,
    attachmentDateArray,
    attachmentUploaderArray,
    setattachmentFileArray,
    setattachmentTagArray,
    setattachmentCommentArray,
    setattachmentOriginArray,
    setattachmentDateArray,
    setattachmentUploaderArray,
    attachmentModifiedAtArray,
    setattachmentModifiedAtArray,
    attachmentIdArray,
    setattachmentIdArray,
    deletedFileIdArray,
    deletedAttachmentArray,
    setdeletedAttachmentArray,
    addedAttachmentArray,
    addedAttachmentTagArray,
    addedAttachmentCommentArray,
    setaddedAttachmentArray,
    setaddedAttachmentTagArray,
    setaddedAttachmentCommentArray,
    setdeletedFileIdArray,
    // route
    routeNumber,
    routeData,
    setrouteNumber,
    setrouteData,
    // ref
    informationRef,
    attachmentRef,
    routeRef,
    setattachmentRef,
    setrouteRef,
    // etc
    isMatchConfirmed,
    initdesignModule,
    roomId,
    cadFile,
    choiceDesignId,
  } = designModuleStore();
  const [isRouteActivate, setisRouteActivate] = useState(false);
  const [isReject, setisReject] = useState(false);
  const formRef = useRef();
  const navigate = useNavigate();
  const params = useParams();

  const [isTempLoading, setisTempLoading] = useState(false);
  const setItemInfo = useCallback(
    async (itemID) => {
      const response = await tokenAxios.get(`items/${itemID}`);
      const { data } = response.data.result;
      setdesignItemInfo({
        designItemId: data.id,
        designItemName: data.name,
        designItemNumber: data.itemNumber,
        designItemImageAddress:
          data.imageAddress &&
          `${process.env.REACT_APP_BASE_URL}/${data.imageAddress}`,
      });
    },
    [tokenAxios, setdesignItemInfo]
  );
  const getDesignData = useCallback(
    async (itemID) => {
      const response = await tokenAxios.get(`design/${itemID}`);
      const { data } = response.data.result;
      setisRouteActivate(data.readonly);

      setdesignId(data.id);
      const designContent = JSON.parse(data.designContent);
      setcadFileList(designContent || []);
      setcadFile({ name: designContent ? designContent.title : "" });
      setisMatchConfirmed(true);
      let status;
      if (data.uploaderType === "cad") {
        status = "Catia";
      } else if (data.uploaderType === "cadian") {
        status = "Cadian";
      } else {
        status = "Link";
      }
      setuploaderType(status);
      setdesignItemInfo({
        designItemName: data.item.name,
        designItemId: data.item.id,
        designItemNumber: data.item.itemNumber,
        designItemImageAddress:
          data.item.imageAddress &&
          `${process.env.REACT_APP_BASE_URL}/${data.item.imageAddress}`,
        // routeNumbers
        routeNumber: data.routeId,
      });
      // attachment
      const tempfileArray = [];
      const temptagArray = [];
      const tempcommentArray = [];
      const temporiginArray = [];
      const tempuploaderArray = [];
      const tempdateArray = [];
      const tempidArray = [];
      const tempdeletedFileArray = [];
      const tempModifiedAtArray = [];

      if (data.designAttachments) {
        data.designAttachments.forEach((item) => {
          tempfileArray.push(item.originName);
          tempcommentArray.push(item.attach_comment);
          temptagArray.push(item.tag);
          temporiginArray.push(item.attachmentaddress);
          tempuploaderArray.push(item.upload);
          tempdateArray.push(item.date);
          tempidArray.push(item.id);

          if (item.deleted) {
            tempdeletedFileArray.push(item.id);
          }
          tempModifiedAtArray.push(item.modifiedAt ? item.modifiedAt : "");
        });
      }
      setattachmentFileArray(tempfileArray);
      setattachmentIdArray(tempidArray);
      setattachmentCommentArray(tempcommentArray);
      setattachmentTagArray(temptagArray);
      setattachmentOriginArray(temporiginArray);
      setattachmentDateArray(tempdateArray);
      setattachmentUploaderArray(tempuploaderArray);
      setdeletedFileIdArray(tempdeletedFileArray);
      setattachmentModifiedAtArray(tempModifiedAtArray);

      setrouteNumber(data.routeId);
      setisReject(data.preRejected);
    },
    [tokenAxios, setdesignItemInfo, setrouteNumber]
  );
  const saveDesign = async () => {
    setisTempLoading(true);

    const formData = new FormData();
    formData.append("designContent", JSON.stringify(cadFileList));

    formData.append("itemId", designItemId);
    formData.append("designId", designId);
    let status;
    if (uploaderType === "Catia") {
      status = "cad";
    } else if (uploaderType === "Cadian") {
      status = "cadian";
    } else {
      status = "link";
    }
    formData.append("status", status);
    if (uploaderType === "Link") {
      formData.append("choiceDesignId", choiceDesignId);
    }
    // attachment
    addedAttachmentArray.forEach((file) => {
      formData.append("addedAttachments", file);
    });

    formData.append("addedAttachmentComment", attachmentCommentArray);
    formData.append(
      "addedTag",
      attachmentTagArray.map((item) => (item.id ? item.id : item))
    );
    formData.append("deletedAttachments", deletedAttachmentArray);

    try {
      const response = await tokenAxios.put(
        `/design/temp/end/${designId}`,
        formData
      );
      setisRouteActivate(true);
      setrouteNumber(response.data.result.data.routeId);
      setisTempLoading(false);
    } catch (err) {
      setisRouteActivate(false);
    }
  };
  const tempSave = async () => {
    setisTempLoading(true);
    const formData = new FormData();
    formData.append("itemId", designItemId);
    formData.append("designId", designId);
    let status;
    if (uploaderType === "Catia") {
      status = "cad";
    } else if (uploaderType === "Cadian") {
      status = "cadian";
    } else {
      status = "link";
    }
    formData.append("status", status);
    if (uploaderType === "Link") {
      formData.append("choiceDesignId", choiceDesignId);
    }
    formData.append("designContent", JSON.stringify(cadFileList));
    addedAttachmentArray.forEach((file) => {
      formData.append("addedAttachments", file);
    });
    formData.append("attachmentComment", attachmentCommentArray);
    formData.append(
      "addedTag",
      attachmentTagArray.map((item) => (item.id ? item.id : item))
    );
    formData.append("deletedAttachments", deletedAttachmentArray);
    try {
      const response = await tokenAxios.put(
        `/design/temp/${designId}`,
        formData
      );
      // setisRouteActivate(true);
      // route부분으로 스크롤
      // setrouteNumber(response.data.result.data.routeId);
      alert(resultText.approveDone);

      setisTempLoading(false);
    } catch (error) {
      if (error.response.data.result) {
        console.log(error.response);
        alert(error.response.data.result.msg);
      } else {
        console.log(error.response);
        navigate("/notFound");
      }
      setisRouteActivate(false);
    }
  };

  useEffect(() => {
    getDesignData(params.designId);

    return () => {
      initdesignModule();
    };
  }, [initdesignModule, setItemInfo, getDesignData]);

  return (
    <DesignEditPageStyle>
      <div className="tempSave">
        {!isRouteActivate && (
          <Button
            type="button"
            onClick={tempSave}
            backgroundColor="transparent"
            fontSize="12px"
            color="var(--textDarkGray)"
          >
            {resultText.saveAsDraft}
          </Button>
        )}
        {/* <Button
          type="button"
          backgroundColor="transparent"
          color="var(--textDarkGray)"
          fontSize="12px"
          onClick={() => {
            navigate("/project/itemsToAdd");
          }}
        >
          <span className="material-icons">close</span>
        </Button> */}
      </div>
      <LoadingBox isLoading={isTempLoading}>
        <div
          className="x"
          onClick={() => {
            setisTempLoading(false);
          }}
        >
          x
        </div>
        <LoadingArrowAnimation />
        <BoldText fontSize="26px" color="var(--eciBlue)">
          S a v i n g
        </BoldText>{" "}
        <br />
        <br />
        {isTempLoading === "catia" ? (
          <a
            href={
              uploaderType === "Catia"
                ? `catiauploader:${designItemId}/${roomId}/${
                    process.env.REACT_APP_BASE_URL.split("//")[1].split(":")[0]
                  }`
                : `dwgUploader:${designItemId}/${roomId}`
            }
          >
            <div className="retry">retry</div>
          </a>
        ) : (
          <>
            Please wait while we set
            <br />
            thing up for you!
          </>
        )}
      </LoadingBox>
      <ScrollControllerStyle>
        <ScrollController
          width="100px"
          buttonArray={[
            [informationRef, resultText.design.designInformation],
            [attachmentRef, resultText.design.designAttachment],
            [routeRef, resultText.design.routeInformation],
          ]}
          scrollRef={formRef}
        />
      </ScrollControllerStyle>

      <FormSectionStyle ref={formRef}>
        <DesignInformationSection
          readOnly={isRouteActivate}
          setisTempLoading={setisTempLoading}
          designItemId={designItemId || params.itemId || params.designId}
        />
        <div className="att">
          <AttachmentSection
            attachmentModifiedAtArray={attachmentModifiedAtArray}
            readOnly={isRouteActivate}
            attachmentFileArray={attachmentFileArray}
            setattachmentFileArray={setattachmentFileArray}
            attachmentTagArray={attachmentTagArray}
            setattachmentTagArray={setattachmentTagArray}
            attachmentCommentArray={attachmentCommentArray}
            setattachmentCommentArray={setattachmentCommentArray}
            attachmentDateArray={attachmentDateArray}
            attachmentUploaderArray={attachmentUploaderArray}
            setattachmentDateArray={setattachmentDateArray}
            setattachmentUploaderArray={setattachmentUploaderArray}
            attachmentOriginArray={attachmentOriginArray}
            setattachmentOriginArray={setattachmentOriginArray}
            setattachmentRef={setattachmentRef}
            editMode
            attachmentIdArray={attachmentIdArray}
            setattachmentIdArray={setattachmentIdArray}
            deletedFileIds={deletedFileIdArray}
            deletedAttachmentArray={deletedAttachmentArray}
            setdeletedAttachmentArray={setdeletedAttachmentArray}
            addedAttachmentArray={addedAttachmentArray}
            addedAttachmentTagArray={addedAttachmentTagArray}
            addedAttachmentCommentArray={addedAttachmentCommentArray}
            setaddedAttachmentArray={setaddedAttachmentArray}
            setaddedAttachmentTagArray={setaddedAttachmentTagArray}
            setaddedAttachmentCommentArray={setaddedAttachmentCommentArray}
            title={resultText.design.designAttachment}
            tagOptionList={[
              { id: "6", name: resultText.design.drReport },
              { id: "7", name: resultText.design.drProceedings },
              { id: "8", name: resultText.design.change },
            ]}
          />
          <div className="gap" />
          {isRouteActivate ? (
            ""
          ) : (
            <Button
              backgroundColor="var(--eciBlue)"
              width="100%"
              height="30px"
              color="white"
              onClick={saveDesign}
              condition={
                uploaderType === "Catia" ? isMatchConfirmed : !!cadFile
              }
            >
              {resultText.saveAndRoute}
            </Button>
          )}
          <RouteSection
            activate={isRouteActivate || isReject}
            routeNumber={routeNumber}
            setrouteRef={setrouteRef}
            routeData={routeData}
            setrouteData={setrouteData}
            rejecting={isReject && !isRouteActivate}
          />{" "}
        </div>
      </FormSectionStyle>
    </DesignEditPageStyle>
  );
}
