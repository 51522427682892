import React, { useCallback, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";

import styled from "styled-components";
import BoldText from "../atom/BoldText";
import commonStore from "../store/commonStore";
import projectModuleStore from "../store/projectModuleStore";
import resultText from "../Text";

const ProjectModuleStyle = styled.div`
  padding-top: 80px;
  width: 100%;
  min-width: 1000px;
  height: 100vh;
  min-height: 580px;

  position: relative;
`;
const ProjectModulePageStyle = styled.div`
  padding-top: 24px;
  padding-right: 4%;
  width: 100%;
  min-width: 1000px;
  min-height: calc(100vh - 80px);
  //80px는 헤더 높이

  background-color: var(--lightGray);

  .description {
    height: 40px;

    font-size: 12px;
    line-height: 40px;
  }
`;

const CategoryBar = styled.div`
  width: 100%;
  border-bottom: solid 1px var(--eciBlue);

  display: flex;

  .category {
    height: 40px;
    width: 18%;

    font-size: 12px;

    line-height: 40px;
    text-decoration: none;
    transform: translate(0, 1.5px);
  }
  .List {
    border-bottom: ${(props) =>
      props.currentCategory === "List" ? "solid 3px var(--eciBlue)" : "none"};
  }
  .Add {
    padding-left: 4px;
    border-bottom: ${(props) =>
      props.currentCategory === "Add" ? "solid 3px var(--eciBlue)" : "none"};
    .plus {
      font-size: 14px;
      color: black;
      color: ${(props) =>
        props.currentCategory === "Add"
          ? " var(--eciBlue)"
          : " var(--darkGray)"};
      margin-right: 10px;
    }
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: var(--eciBlueHover);
`;
function ProjectModule() {
  const setCurrentModule = commonStore((state) => state.setCurrentModule);

  const { currentCategory, initprojectModule } = projectModuleStore(
    (state) => ({
      currentCategory: state.currentCategory,
      initprojectModule: state.initprojectModule,
    })
  );
  useEffect(() => {
    setCurrentModule("Project");
  }, [setCurrentModule]);
  return (
    <ProjectModuleStyle>
      <ProjectModulePageStyle>
        <BoldText fontSize="30px" color="var(--eciBlue)">
          {resultText.project.project}
        </BoldText>
        <br />
        <div className="description">
          {resultText.project.projectDescription}
        </div>
        <CategoryBar currentCategory={currentCategory}>
          <div className="List category">
            <StyledLink to="/project">
              <BoldText
                fontSize="14px"
                color={
                  currentCategory === "List"
                    ? "var(--eciBlue)"
                    : "var(--darkGray)"
                }
              >
                {resultText.project.list}
              </BoldText>
            </StyledLink>
          </div>
          <div className="Add category">
            <StyledLink to="add/new" onClick={initprojectModule}>
              <span className="material-icons plus">add</span>
              <BoldText
                fontSize="14px"
                color={
                  currentCategory === "Add"
                    ? "var(--eciBlue)"
                    : "var(--darkGray)"
                }
              >
                {resultText.project.addNewProject}
              </BoldText>
            </StyledLink>
          </div>
        </CategoryBar>
        <Outlet />
      </ProjectModulePageStyle>
    </ProjectModuleStyle>
  );
}
export default React.memo(ProjectModule);
